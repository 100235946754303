import React, {Component} from 'react';
import {Form, Button, Col} from 'react-bootstrap';
import {Multiselect} from 'multiselect-react-dropdown';
import {mappedCountries as mappedCountries} from "../../../constants/countries";
import Select from 'react-select';

class OrgInfo extends Component {

    render() {
        const {
            information = {},
            name,
            handleOrgInfoUpdate: onHandleUpdate,
            handleOrgInfoSubmit: handleSubmit
        } = this.props;

        const {
            phone = "",
            uk_vat = "",
            address = {},
            business_unit = "",
            company_number = "",
            billing_address = {},
            legal_company_name = "",
            company_registration_number = ""
        } = information;
        const {
            town: main_town = "",
            region: main_region = "",
            state: main_state = "",
            street: main_street = [],
            country: main_country = "",
            postcode: main_postcode = "",
        } = address;
        const {
            town: billing_town = "",
            region: billing_region = "",
            state: billing_state = "",
            street: billing_street = [],
            country: billing_country = "",
            postcode: billing_postcode = "",
        } = billing_address;


        return (
            <div className='companyInfoBlock'>

                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Name (The one we use to display in the Xelacore system)</Form.Label>
                            <Form.Control
                                placeholder="The name within the xelacore system"
                                onChange={(e) => {
                                    onHandleUpdate({name: e.target.value})
                                }}
                                value={name}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridLegalCompanyName">
                            <Form.Label>Legal Company Name (this is the first step to supporting display name, but
                                doesn't do anything yet; Name is the one displayed :))</Form.Label>
                            <Form.Control
                                placeholder="The legal company name"
                                onChange={(e) => {
                                    onHandleUpdate({information: {legal_company_name: e.target.value}})
                                }}
                                value={legal_company_name}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridLegalCompanyRegistrationNumber">
                            <Form.Label>Company Registration Number</Form.Label>
                            <Form.Control
                                placeholder="Company Registration Number"
                                onChange={(e) => {
                                    onHandleUpdate({information: {company_registration_number: e.target.value}})
                                }}
                                value={company_registration_number}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridBusinessUnit">
                            <Form.Label>Business Unit</Form.Label>
                            <Form.Control
                                placeholder="Company business unit (If applicable)"
                                onChange={(e) => {
                                    onHandleUpdate({information: {business_unit: e.target.value}})
                                }}
                                value={business_unit}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCompanyNumber">
                            <Form.Label>Company Number</Form.Label>
                            <Form.Control
                                placeholder="Company number"
                                onChange={(e) => {
                                    onHandleUpdate({information: {company_number: e.target.value}})
                                }}
                                value={company_number}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                placeholder="Company phone number"
                                onChange={(e) => {
                                    onHandleUpdate({information: {phone: e.target.value}})
                                }}
                                value={phone}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridUKVat">
                            <Form.Label>UK Vat</Form.Label>
                            <Form.Control
                                placeholder="UK VAT number"
                                onChange={(e) => {
                                    onHandleUpdate({information: {uk_vat: e.target.value}})
                                }}
                                value={uk_vat}
                            />
                        </Form.Group>
                    </Form.Row>
                    <hr/>
                    <Form.Label>Main Office Address</Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridStreet">
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control as="textarea" rows="3"
                                          placeholder="Main office street address"
                                          onChange={(e) => {
                                              onHandleUpdate({information: {address: {street: e.target.value}}})
                                          }}
                                          value={main_street}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridTown">
                            <Form.Label>Town</Form.Label>
                            <Form.Control
                                placeholder="Main office town"
                                onChange={(e) => {
                                    onHandleUpdate({information: {address: {town: e.target.value}}})
                                }}
                                value={main_town}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridRegion">
                            <Form.Label>Region</Form.Label>
                            <Form.Control
                                placeholder="Main office region"
                                onChange={(e) => {
                                    onHandleUpdate({information: {address: {region: e.target.value}}})
                                }}
                                value={main_region}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                                placeholder="Main office state (if applicable)"
                                onChange={(e) => {
                                    onHandleUpdate({information: {address: {state: e.target.value}}})
                                }}
                                value={main_state}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCountry">
                            <Form.Label>Country</Form.Label>
                            <Select
                                onChange={(e) => {
                                    onHandleUpdate({information: {address: {country: e.value}}})
                                }}
                                options={mappedCountries}
                                value={mappedCountries.find(item=>{
                                    return item.value== main_country
                                })}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridPostcode">
                            <Form.Label>Postcode / Zipcode</Form.Label>
                            <Form.Control
                                placeholder="Main office postcode / zipcode"
                                onChange={(e) => {
                                    onHandleUpdate({information: {address: {postcode: e.target.value}}})
                                }}
                                value={main_postcode}
                            />
                        </Form.Group>
                    </Form.Row>


                    <hr/>
                    <Form.Label>Billing Address</Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridBillingLocationAddress">
                            <Form.Label>Billing Street Address</Form.Label>
                            <Form.Control as="textarea" rows="3"
                                          placeholder="Billing street address"
                                          onChange={(e) => {
                                              onHandleUpdate({information: {billing_address: {street: e.target.value}}})
                                          }}
                                          value={billing_street}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridBillingLocationAddressCity">
                            <Form.Label>Billing Town</Form.Label>
                            <Form.Control
                                placeholder="Billing town"
                                onChange={(e) => {
                                    onHandleUpdate({information: {billing_address: {town: e.target.value}}})
                                }}
                                value={billing_town}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridRegion">
                            <Form.Label>Billing Region</Form.Label>
                            <Form.Control
                                placeholder="Billing region"
                                onChange={(e) => {
                                    onHandleUpdate({information: {billing_address: {region: e.target.value}}})
                                }}
                                value={billing_region}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridBillingState">
                            <Form.Label>Billing State</Form.Label>
                            <Form.Control
                                placeholder="Billing state"
                                onChange={(e) => {
                                    onHandleUpdate({information: {billing_address: {state: e.target.value}}})
                                }}
                                value={billing_state}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridBillingLocationAddressCountry">
                            <Form.Label>Billing Country</Form.Label>
                            <Form.Control
                                placeholder="Billing country"
                                onChange={(e) => {
                                    onHandleUpdate({information: {billing_address: {country: e.target.value}}})
                                }}
                                value={billing_country}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridBillingLocationAddressPostcodeZipcode">
                            <Form.Label>Billing Postcode / Zipcode</Form.Label>
                            <Form.Control
                                placeholder="Billing postcode / zipcode"
                                onChange={(e) => {
                                    onHandleUpdate({information: {billing_address: {postcode: e.target.value}}})
                                }}
                                value={billing_postcode}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="actions-row">
                        <Button className="submit" onClick={() => handleSubmit()}>Save</Button>
                    </Form.Row>
                </Form>

            </div>
        )
    }
}

export default OrgInfo;
