import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Container, Button, Row, Col } from 'react-bootstrap';

import updateUserInfo from 'actions/api/users/updateUserInfo';

function globalStateMapper(globalState) {
    return {};
}

class UpdateUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            prevPropsUserID: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedUser.user_id !== state.prevPropsUserID) {
            return props.selectedUser && props.selectedUser.information
                ? {
                     firstName: props.selectedUser.information.first_name,
                     lastName: props.selectedUser.information.last_name,
                     prevPropsUserID: props.selectedUser.user_id
                } : {
                     firstName: '',
                     lastName: ''
                };
        }

        return null;
    }

    onHandleChangeFirst = (e) => {
        this.setState({ firstName: e.target.value });
    };

    onHandleChangeLast = (e) => {
        this.setState({ lastName: e.target.value });
    };

    updateUser = () => {
        const { selectedUser = {}, getUser } = this.props;
        const { firstName, lastName } = this.state;

        let params = {
            first_name: firstName,
            last_name: lastName
        };

        this.props.updateUserInfo(selectedUser, params).then(() => {
            getUser();
        })
    };

    render() {
        const { firstName, lastName } = this.state;

        return (
            <div className="company-informtion data-capture-section">
                <Container fluid className="pt-2">
                    <Form onSubmit={(e)=>{
                        e.preventDefault();
                        this.updateUser()
                    }}>
                        <Col>
                            <Form.Group as={Row} controlId="formGridName">
                                <Col>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        required
                                        placeholder="First Name"
                                        onChange={(e) => this.onHandleChangeFirst(e)}
                                        value={firstName}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        required
                                        placeholder="Last Name"
                                        onChange={(e) => this.onHandleChangeLast(e)}
                                        value={lastName}
                                    />
                                </Col>
                                <Col>
                                    <Button className="submit mt-4"
                                            type="submit">
                                        Update
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Form>
                </Container>
            </div>
        )
    }
}

export default connect(
    globalStateMapper,
    { updateUserInfo }
)(UpdateUser);