export const SUBLIST_SPLITTER = ':';

export const NAME_KEY = 'name';
export const VALUES_KEY = 'values';
export const CHILDREN_KEY = 'children';

// v1 data capture
export const LICENSEE_SCHEME = {
  licensor: {
    brand: {
      franchise: {
        character: {},
        territory: {},
        category: {}
      }
    }
  }
};

// v1 data capture
export const LICENSOR_SCHEME = {
  brand: {
    franchise: {
      character: {},
      territory: {},
      category: {}
    }
  }
};

export const RETAIL_CHANNELS_SCHEME = {
  retail_channel: {
    retail_sub_channel: {}
  }
}

export const PRODUCT_CATEGORIES_SCHEME = {
  category: {
    sub_category_1: {
      sub_category_2: {}
    }
  }
}

export const BRAND_HIERARCHY_SCHEME = {
  brand: {
    franchise: {
      character: {}
    }
  }
}
