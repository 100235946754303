import React, { Component } from 'react';
import { Form, Container, Button, Modal,  } from 'react-bootstrap';
import OrgContact from './OrgContact';


class AddContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {
        type: "",
        title: "Mr",
        first_name: "",
        last_name: "",
        email: "",
        biz_phone: "",
        mobile_phone: "",
        office_address: [],
        city: "",
        country: "",
        postcode_zipcode: "",
        pa_title: "",
        pa_first_name: "",
        pa_last_name: "",
        pa_email: "",
        pa_phone: "",
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.remainingContactTypes !== this.props.remainingContactTypes) {
      //Perform some operation
      const { contact } = this.state;
      this.setState({contact: { ...contact, type: nextProps?.remainingContactTypes[0]?.toLowerCase() } });
    }
  }

  onUpdateContactDetails = (newAddContactData) => {
    const { addContactData } = this.props;
    this.props.handleUpdateAddContactData({ ...addContactData, ...newAddContactData });
  }

  handleUpdate = (contact) => {
    this.setState({ contact });
  }

  handleAddContact = () => {
    const { contact } = this.state;
    this.props.onAddContact(contact);
    this.props.togglePopUp();
  }

  render() {
    const { show = true, togglePopUp, remainingContactTypes = [] } = this.props;
    const { contact } = this.state;
    return (
      <div className="x-add-org-modal">
        <Modal show={show} onHide={togglePopUp} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container fluid>
            <Form>
                <OrgContact 
                  config={{
                    showDeleteButton: false,
                    showUpdateButton: false,
                    showTypePicker: true
                  }}
                  contact={contact}
                  onHandleUpdate={this.handleUpdate}
                  remainingContactTypes={remainingContactTypes}
                />
            </Form>
          </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel" onClick={togglePopUp} >
              Close
            </Button>
            <Button className="add" onClick={this.handleAddContact}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AddContactModal;
