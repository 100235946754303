import React from 'react';
import xelacoreLogo from 'assets/icons/xelacore-logo.svg';
import './GeneralHeader.css';

function Header() {
  return (
    <header className='xelacore-header-general'>
      <img src={xelacoreLogo} alt='Xelacore logo' />
    </header>
  );
};

export default Header;