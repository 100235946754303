import React from 'react';
import './TableButton.css';

function TableButton({ type, text, onClick, disabled }) {
  return (
    <button
      type={type}
      className="table-button"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

export default TableButton;
