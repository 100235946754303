export const LinkedBrandsTable = [
    {
        field: 'agent_organisation_name',
        label: 'Agent',
        order: 1,
    },
    {
        field: 'brand_name',
        label: 'Brand',
        order: 2,
        filter: true,
    },
  ]
