import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(params) {
  let call = {
    url: `${Config.get('ADMIN_URL')}trigger-notification`,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    },
  }
  
  let resp = await callHandler(call);
  return resp;
}