import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import findIndex from "lodash/findIndex";

export function structureComposer(scheme) {
  return Object.keys(scheme).map(key => ({
    name: key,
    values: [],
    ...!isEmpty(scheme[key]) && {
      children: structureComposer(scheme[key])
    }
  }));
}

function getParentIndexes({ data, path }) {
  const indexesObj = path.reduce((accumulator, currentValue) => {
    const index = findIndex(accumulator.currentObj[currentValue.name], { name: currentValue.value });
    return {
      indexes: [...accumulator.indexes, index],
      currentObj: get(accumulator.currentObj, `${currentValue.name}.${index}`)
    }
  }, {
    currentObj: data,
    indexes: []
  });

  return indexesObj;
}

export function getSubDataPath({ data, path }) {
  const indexesObj = getParentIndexes({ data, path });
  return path.map(({ name }, i) => {
    return `${name}.${indexesObj.indexes[i]}`;
  }).join('.');
}

export function getSubDataObject({ data, path }) {
  const subDataPath = getSubDataPath({ data, path });
  return subDataPath ? get(data, subDataPath) : data;
}

export function getNamePath({ basePath, name }) {
  return (basePath ? `${basePath}.` : '') + `${name}`;
}
