import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId,agentOrganisationId) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/agent/${organisationId}/brands`
  }
  let { brandAgentLinks } = await callHandler(call);
  return brandAgentLinks;
}