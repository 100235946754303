import React, {Component} from 'react';
import {connect} from "react-redux";
import FilterBar from './FilterBar';
import listUsers from 'actions/api/users/list';
import Table from 'components/lists/Table';
import {userTable} from 'constants/tableheaders';
import AddUserModal from 'components/AddUserModal';

function globalStateMapper(globalState) {
    const {organisations, users} = globalState;
    return {
        organisations,
        users
    };
}

class UserList extends Component {
    state = {
        currentPage: 1,
        totalPages: 1,
        filter: {},
        showAddUserPopup: false,
        addUserData: {},
        fabUsers: [],
    }

    onPageChanged = (currentPage) => {
        this.setState({
            currentPage
        });

        window.scroll({
            top: 0,
            behavior: "auto"
        });
    }

    toggleAddUserPopup = () => {
        const {showAddUserPopup} = this.state;
        this.setState({showAddUserPopup: !showAddUserPopup});
    }

    handleUpdateFilter = (newFilter) => {
        this.setState({filter: newFilter});
    }

    render() {
        const {users = {}} = this.props;
        const {filter = {}, showAddUserPopup} = this.state;
        const {search = null} = filter;

        const tableData = users.list
            .filter(user => {
                if (!search) return user;
                const {information, user_id, email = ''} = user;
                const {last_name = '', first_name = ''} = information;
                return first_name.toLowerCase().includes(search.toLowerCase()) ||
                    last_name.toLowerCase().includes(search.toLowerCase()) ||
                    user_id.toLowerCase().includes(search.toLowerCase()) ||
                    email.toLowerCase().includes(search.toLowerCase())
            }).map(user => {
                const {user_id, information, email,} = user;
                const {last_name, first_name} = information;
                return {email, first_name, last_name, user_id}
            });
        let columns = Object.keys((tableData[0] || [])).map(k => {
            return {
                name: k, label: userTable[k] || k
            }
        });

        const noData = tableData.length === 0;

        return (
            <div>
                <FilterBar onUpdateFilter={this.handleUpdateFilter}
                           onToggleAddUserPopup={this.toggleAddUserPopup}></FilterBar>
                <div className="company-information-form">
                    {
                        noData
                            ?
                            (users.list.length > 0 ? "There is no data to show right now" : "Loading...")
                            :
                            <div className='licensee-list'>
                                <Table
                                    columns={columns}
                                    filterQuery={{}}
                                    data={tableData}
                                    idField='user_id'
                                    link='users'
                                    // onUpdateFilterFields={this.onUpdateFilterFields}
                                />
                                {/* <Pagination
                current={currentPage}
                total={totalPages}
                onPageChanged={this.onPageChanged}
              /> */}
                            </div>
                    }
                </div>
                <AddUserModal userListTableData={tableData} addUserCallback={() => {
                    return;
                }} show={showAddUserPopup} togglePopUp={this.toggleAddUserPopup}></AddUserModal>
            </div>
        );
    }
}

// adduser missing
export default connect(
    globalStateMapper,
    {listUsers}
)(UserList);
