import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, { userId, information, notify }) {
  let call = {
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/employees`,
    method: 'post',
    data: { userId, information, notify },
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let addResp = await callHandler(call);
  return addResp;
}