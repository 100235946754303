import {
  DISPLAY_NOTIFICATION,
  HIDE_NOTIFICATION
} from "actions/notifications";

const getInitialState = () => ({
  message: null,
  type: null,
  timeout: null
});

export default function notification(state = getInitialState(), action) {
  switch (action.type) {
    case DISPLAY_NOTIFICATION:
      return {
        ...state,
        ...action.payload
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        ...getInitialState()
      };
    default:
      return state;
  }
}
