export const groupedCountries = [
  {
    label: 'Asia',
    items: [
      {value: 'AFG', label: 'Afghanistan'},
      {value: 'ARM', label: 'Armenia'},
      {value: 'AZE', label: 'Azerbaijan'},
      {value: 'BHR', label: 'Bahrain'},
      {value: 'BGD', label: 'Bangladesh'},
      {value: 'BTN', label: 'Bhutan'},
      {value: 'IOT', label: 'British Indian Ocean Territory'},
      {value: 'BRN', label: 'Brunei'},
      {value: 'KHM', label: 'Cambodia'},
      {value: 'CHN', label: 'China'},
      {value: 'CXR', label: 'Christmas Island'},
      {value: 'CCK', label: 'Cocos (Keeling) Islands'},
      {value: 'GEO', label: 'Georgia'},
      {value: 'HKG', label: 'Hong Kong'},
      {value: 'IND', label: 'India'},
      {value: 'IDN', label: 'Indonesia'},
      {value: 'IRN', label: 'Iran, Islamic Republic of'},
      {value: 'IRQ', label: 'Iraq'},
      {value: 'ISR', label: 'Israel'},
      {value: 'JPN', label: 'Japan'},
      {value: 'JOR', label: 'Jordan'},
      {value: 'KAZ', label: 'Kazakhstan'},
      {value: 'PRK', label: 'Korea, Democratic People\'s Republic of'},
      {value: 'KOR', label: 'Korea, Republic of'},
      {value: 'KWT', label: 'Kuwait'},
      {value: 'KGZ', label: 'Kyrgyzstan'},
      {value: 'LAO', label: 'Lao People\'s Democratic Republic'},
      {value: 'LBN', label: 'Lebanon'},
      {value: 'MAC', label: 'Macao'},
      {value: 'MYS', label: 'Malaysia'},
      {value: 'MDV', label: 'Maldives'},
      {value: 'MNG', label: 'Mongolia'},
      {value: 'MMR', label: 'Myanmar'},
      {value: 'NPL', label: 'Nepal'},
      {value: 'OMN', label: 'Oman'},
      {value: 'PAK', label: 'Pakistan'},
      {value: 'PSE', label: 'Palestinian Territory, Occupied'},
      {value: 'PHL', label: 'Philippines'},
      {value: 'QAT', label: 'Qatar'},
      {value: 'SAU', label: 'Saudi Arabia'},
      {value: 'SGP', label: 'Singapore'},
      {value: 'LKA', label: 'Sri Lanka'},
      {value: 'SYR', label: 'Syrian Arab Republic'},
      {value: 'TWN', label: 'Taiwan'},
      {value: 'TJK', label: 'Tajikistan'},
      {value: 'THA', label: 'Thailand'},
      {value: 'TUR', label: 'Turkey'},
      {value: 'TKM', label: 'Turkmenistan'},
      {value: 'ARE', label: 'United Arab Emirates'},
      {value: 'UZB', label: 'Uzbekistan'},
      {value: 'VNM', label: 'Vietnam'},
      {value: 'YEM', label: 'Yemen'}
    ]
  },
  {
    label: 'Europe',
    items: [
      {value: 'ALB', label: 'Albania'},
      {value: 'AND', label: 'Andorra'},
      {value: 'AUT', label: 'Austria'},
      {value: 'BLR', label: 'Belarus'},
      {value: 'BEL', label: 'Belgium'},
      {value: 'BIH', label: 'Bosnia and Herzegovina'},
      {value: 'BGR', label: 'Bulgaria'},
      {value: 'HRV', label: 'Croatia'},
      {value: 'CYP', label: 'Cyprus'},
      {value: 'CZE', label: 'Czech Republic'},
      {value: 'DNK', label: 'Denmark'},
      {value: 'EST', label: 'Estonia'},
      {value: 'FRO', label: 'Faroe Islands'},
      {value: 'FIN', label: 'Finland'},
      {value: 'FRA', label: 'France'},
      {value: 'DEU', label: 'Germany'},
      {value: 'GIB', label: 'Gibraltar'},
      {value: 'GRC', label: 'Greece'},
      {value: 'GGY', label: 'Guernsey'},
      {value: 'VAT', label: 'Holy See (Vatican City State)'},
      {value: 'HUN', label: 'Hungary'},
      {value: 'ISL', label: 'Iceland'},
      {value: 'IRL', label: 'Ireland'},
      {value: 'IMN', label: 'Isle of Man'},
      {value: 'ITA', label: 'Italy'},
      {value: 'JEY', label: 'Jersey'},
      {value: 'LVA', label: 'Latvia'},
      {value: 'LIE', label: 'Liechtenstein'},
      {value: 'LTU', label: 'Lithuania'},
      {value: 'LUX', label: 'Luxembourg'},
      {value: 'MKD', label: 'Macedonia, the former Yugoslav Republic of'},
      {value: 'MLT', label: 'Malta'},
      {value: 'MDA', label: 'Moldova, Republic of'},
      {value: 'MCO', label: 'Monaco'},
      {value: 'MNE', label: 'Montenegro'},
      {value: 'NLD', label: 'Netherlands'},
      {value: 'NOR', label: 'Norway'},
      {value: 'POL', label: 'Poland'},
      {value: 'PRT', label: 'Portugal'},
      {value: 'ROU', label: 'Romania'},
      {value: 'RUS', label: 'Russia'},
      {value: 'SMR', label: 'San Marino'},
      {value: 'SRB', label: 'Serbia'},
      {value: 'SVK', label: 'Slovakia'},
      {value: 'SVN', label: 'Slovenia'},
      {value: 'ESP', label: 'Spain'},
      {value: 'SJM', label: 'Svalbard and Jan Mayen'},
      {value: 'SWE', label: 'Sweden'},
      {value: 'CHE', label: 'Switzerland'},
      {value: 'UKR', label: 'Ukraine'},
      {value: 'GBR', label: 'United Kingdom'}
    ]
  },
  {
    label: 'Africa',
    items: [
      {value: 'DZA', label: 'Algeria'},
      {value: 'AGO', label: 'Angola'},
      {value: 'BEN', label: 'Benin'},
      {value: 'BWA', label: 'Botswana'},
      {value: 'BFA', label: 'Burkina Faso'},
      {value: 'BDI', label: 'Burundi'},
      {value: 'CMR', label: 'Cameroon'},
      {value: 'CPV', label: 'Cape Verde'},
      {value: 'CAF', label: 'Central African Republic'},
      {value: 'TCD', label: 'Chad'},
      {value: 'COM', label: 'Comoros'},
      {value: 'COG', label: 'Congo'},
      {value: 'COD', label: 'Congo, the Democratic Republic of the'},
      {value: 'CIV', label: 'Ivory Coast'},
      {value: 'DJI', label: 'Djibouti'},
      {value: 'EGY', label: 'Egypt'},
      {value: 'GNQ', label: 'Equatorial Guinea'},
      {value: 'ERI', label: 'Eritrea'},
      {value: 'ETH', label: 'Ethiopia'},
      {value: 'GAB', label: 'Gabon'},
      {value: 'GMB', label: 'Gambia'},
      {value: 'GHA', label: 'Ghana'},
      {value: 'GIN', label: 'Guinea'},
      {value: 'GNB', label: 'Guinea-Bissau'},
      {value: 'KEN', label: 'Kenya'},
      {value: 'LSO', label: 'Lesotho'},
      {value: 'LBR', label: 'Liberia'},
      {value: 'LBY', label: 'Libya'},
      {value: 'MDG', label: 'Madagascar'},
      {value: 'MWI', label: 'Malawi'},
      {value: 'MLI', label: 'Mali'},
      {value: 'MRT', label: 'Mauritania'},
      {value: 'MUS', label: 'Mauritius'},
      {value: 'MYT', label: 'Mayotte'},
      {value: 'MAR', label: 'Morocco'},
      {value: 'MOZ', label: 'Mozambique'},
      {value: 'NAM', label: 'Namibia'},
      {value: 'NER', label: 'Niger'},
      {value: 'NGA', label: 'Nigeria'},
      {value: 'REU', label: 'Réunion'},
      {value: 'RWA', label: 'Rwanda'},
      {value: 'SHN', label: 'Saint Helena, Ascension and Tristan da Cunha'},
      {value: 'STP', label: 'Sao Tome and Principe'},
      {value: 'SEN', label: 'Senegal'},
      {value: 'SYC', label: 'Seychelles'},
      {value: 'SLE', label: 'Sierra Leone'},
      {value: 'SOM', label: 'Somalia'},
      {value: 'ZAF', label: 'South Africa'},
      {value: 'SDN', label: 'Sudan'},
      {value: 'SWZ', label: 'Swaziland'},
      {value: 'TZA', label: 'Tanzania, United Republic of'},
      {value: 'TGO', label: 'Togo'},
      {value: 'TUN', label: 'Tunisia'},
      {value: 'UGA', label: 'Uganda'},
      {value: 'ESH', label: 'Western Sahara'},
      {value: 'ZWB', label: 'Zambia'},
      {value: 'ZWE', label: 'Zimbabwe'}
    ]
  },
  {
    label: 'Australia',
    items: [
      {value: 'ASM', label: 'American Samoa'},
      {value: 'AUS', label: 'Australia'},
      {value: 'COK', label: 'Cook Islands'},
      {value: 'FJI', label: 'Fiji'},
      {value: 'PYF', label: 'French Polynesia'},
      {value: 'GUM', label: 'Guam'},
      {value: 'KIR', label: 'Kiribati'},
      {value: 'MHL', label: 'Marshall Islands'},
      {value: 'FSM', label: 'Micronesia, Federated States of'},
      {value: 'NRU', label: 'Nauru'},
      {value: 'NCL', label: 'New Caledonia'},
      {value: 'NZL', label: 'New Zealand'},
      {value: 'NIU', label: 'Niue'},
      {value: 'NFK', label: 'Norfolk Island'},
      {value: 'MNP', label: 'Northern Mariana Islands'},
      {value: 'PLW', label: 'Palau'},
      {value: 'PNG', label: 'Papua New Guinea'},
      {value: 'PCN', label: 'Pitcairn'},
      {value: 'WSM', label: 'Samoa'},
      {value: 'SLB', label: 'Solomon Islands'},
      {value: 'TLS', label: 'Timor-Leste'},
      {value: 'TKL', label: 'Tokelau'},
      {value: 'TON', label: 'Tonga'},
      {value: 'TUV', label: 'Tuvalu'},
      {value: 'UMI', label: 'United States Minor Outlying Islands'},
      {value: 'VUT', label: 'Vanuatu'},
      {value: 'WLF', label: 'Wallis and Futuna'}
    ]
  },
  {
    label: 'North America',
    items: [
      {value: 'AIA', label: 'Anguilla'},
      {value: 'ATG', label: 'Antigua and Barbuda'},
      {value: 'ABW', label: 'Aruba'},
      {value: 'BHS', label: 'Bahamas'},
      {value: 'BRB', label: 'Barbados'},
      {value: 'BLZ', label: 'Belize'},
      {value: 'BMU', label: 'Bermuda'},
      {value: 'CAN', label: 'Canada'},
      {value: 'CYM', label: 'Cayman Islands'},
      {value: 'CRI', label: 'Costa Rica'},
      {value: 'CUB', label: 'Cuba'},
      {value: 'DMA', label: 'Dominica'},
      {value: 'DOM', label: 'Dominican Republic'},
      {value: 'SLV', label: 'El Salvador'},
      {value: 'GRL', label: 'Greenland'},
      {value: 'GRD', label: 'Grenada'},
      {value: 'GLP', label: 'Guadeloupe'},
      {value: 'GTM', label: 'Guatemala'},
      {value: 'HTI', label: 'Haiti'},
      {value: 'HND', label: 'Honduras'},
      {value: 'JAM', label: 'Jamaica'},
      {value: 'MTQ', label: 'Martinique'},
      {value: 'MEX', label: 'Mexico'},
      {value: 'MSR', label: 'Montserrat'},
      {value: 'NIC', label: 'Nicaragua'},
      {value: 'PAN', label: 'Panama'},
      {value: 'PRI', label: 'Puerto Rico'},
      {value: 'KNA', label: 'Saint Kitts and Nevis'},
      {value: 'LCA', label: 'Saint Lucia'},
      {value: 'SPM', label: 'Saint Pierre and Miquelon'},
      {value: 'VCT', label: 'Saint Vincent and the Grenadines'},
      {value: 'TTO', label: 'Trinidad and Tobago'},
      {value: 'TCA', label: 'Turks and Caicos Islands'},
      {value: 'USA', label: 'United States'},
      {value: 'VGB', label: 'Virgin Islands, British'},
      {value: 'VIR', label: 'Virgin Islands, U.S.'}
    ]
  },
  {
    label: 'Antarctica',
    items: [
      {value: 'ATA', label: 'Antarctica'},
      {value: 'BVT', label: 'Bouvet Island'},
      {value: 'ATF', label: 'French Southern Territories'},
      {value: 'HMD', label: 'Heard Island and McDonald Islands'},
      {value: 'SGS', label: 'South Georgia and the South Sandwich Islands'}
    ]
  },
  {
    label: 'South America',
    items: [
      {value: 'ARG', label: 'Argentina'},
      {value: 'BOL', label: 'Bolivia'},
      {value: 'BRA', label: 'Brazil'},
      {value: 'CHL', label: 'Chile'},
      {value: 'COL', label: 'Colombia'},
      {value: 'ECU', label: 'Ecuador'},
      {value: 'FLK', label: 'Falkland Islands (Malvinas)'},
      {value: 'GUF', label: 'French Guiana'},
      {value: 'GUY', label: 'Guyana'},
      {value: 'ANT', label: 'Netherlands Antilles'},
      {value: 'PRY', label: 'Paraguay'},
      {value: 'PER', label: 'Peru'},
      {value: 'SUR', label: 'Suriname'},
      {value: 'URY', label: 'Uruguay'},
      {value: 'VEN', label: 'Venezuela'}
    ]
  }
];

export const countries = [
  {
    "label": "Afghanistan (AF)",
    "value": "Afghanistan (AF)"
  },
  {
    "label": "Albania (AL)",
    "value": "Albania (AL)"
  },
  {
    "label": "Algeria (DZ)",
    "value": "Algeria (DZ)"
  },
  {
    "label": "American Samoa (AS)",
    "value": "American Samoa (AS)"
  },
  {
    "label": "Andorra (AD)",
    "value": "Andorra (AD)"
  },
  {
    "label": "Angola (AO)",
    "value": "Angola (AO)"
  },
  {
    "label": "Anguilla (AI)",
    "value": "Anguilla (AI)"
  },
  {
    "label": "Antarctica (AQ)",
    "value": "Antarctica (AQ)"
  },
  {
    "label": "Antigua and Barbuda (AG)",
    "value": "Antigua and Barbuda (AG)"
  },
  {
    "label": "Argentina (AR)",
    "value": "Argentina (AR)"
  },
  {
    "label": "Armenia (AM)",
    "value": "Armenia (AM)"
  },
  {
    "label": "Aruba (AW)",
    "value": "Aruba (AW)"
  },
  {
    "label": "Australia (AU)",
    "value": "Australia (AU)"
  },
  {
    "label": "Austria (AT)",
    "value": "Austria (AT)"
  },
  {
    "label": "Azerbaijan (AZ)",
    "value": "Azerbaijan (AZ)"
  },
  {
    "label": "Bahamas (BS)",
    "value": "Bahamas (BS)"
  },
  {
    "label": "Bahrain (BH)",
    "value": "Bahrain (BH)"
  },
  {
    "label": "Bangladesh (BD)",
    "value": "Bangladesh (BD)"
  },
  {
    "label": "Barbados (BB)",
    "value": "Barbados (BB)"
  },
  {
    "label": "Belarus (BY)",
    "value": "Belarus (BY)"
  },
  {
    "label": "Belgium (BE)",
    "value": "Belgium (BE)"
  },
  {
    "label": "Belize (BZ)",
    "value": "Belize (BZ)"
  },
  {
    "label": "Benin (BJ)",
    "value": "Benin (BJ)"
  },
  {
    "label": "Bermuda (BM)",
    "value": "Bermuda (BM)"
  },
  {
    "label": "Bhutan (BT)",
    "value": "Bhutan (BT)"
  },
  {
    "label": "Bolivia (BO)",
    "value": "Bolivia (BO)"
  },
  {
    "label": "Bosnia and Herzegovina (BA)",
    "value": "Bosnia and Herzegovina (BA)"
  },
  {
    "label": "Botswana (BW)",
    "value": "Botswana (BW)"
  },
  {
    "label": "Bouvet Island (BV)",
    "value": "Bouvet Island (BV)"
  },
  {
    "label": "Brazil (BR)",
    "value": "Brazil (BR)"
  },
  {
    "label": "British Indian Ocean Territory (IO)",
    "value": "British Indian Ocean Territory (IO)"
  },
  {
    "label": "Brunei (BN)",
    "value": "Brunei (BN)"
  },
  {
    "label": "Bulgaria (BG)",
    "value": "Bulgaria (BG)"
  },
  {
    "label": "Burkina Faso (BF)",
    "value": "Burkina Faso (BF)"
  },
  {
    "label": "Burundi (BI)",
    "value": "Burundi (BI)"
  },
  {
    "label": "Cambodia (KH)",
    "value": "Cambodia (KH)"
  },
  {
    "label": "Cameroon (CM)",
    "value": "Cameroon (CM)"
  },
  {
    "label": "Canada (CA)",
    "value": "Canada (CA)"
  },
  {
    "label": "Cape Verde (CV)",
    "value": "Cape Verde (CV)"
  },
  {
    "label": "Cayman Islands (KY)",
    "value": "Cayman Islands (KY)"
  },
  {
    "label": "Central African Republic (CF)",
    "value": "Central African Republic (CF)"
  },
  {
    "label": "Chad (TD)",
    "value": "Chad (TD)"
  },
  {
    "label": "Chile (CL)",
    "value": "Chile (CL)"
  },
  {
    "label": "China (CN)",
    "value": "China (CN)"
  },
  {
    "label": "Christmas Island (CX)",
    "value": "Christmas Island (CX)"
  },
  {
    "label": "Cocos (Keeling) Islands (CC)",
    "value": "Cocos (Keeling) Islands (CC)"
  },
  {
    "label": "Colombia (CO)",
    "value": "Colombia (CO)"
  },
  {
    "label": "Comoros (KM)",
    "value": "Comoros (KM)"
  },
  {
    "label": "Congo (CG)",
    "value": "Congo (CG)"
  },
  {
    "label": "Cook Islands (CK)",
    "value": "Cook Islands (CK)"
  },
  {
    "label": "Costa Rica (CR)",
    "value": "Costa Rica (CR)"
  },
  {
    "label": "Croatia (HR)",
    "value": "Croatia (HR)"
  },
  {
    "label": "Cuba (CU)",
    "value": "Cuba (CU)"
  },
  {
    "label": "Cyprus (CY)",
    "value": "Cyprus (CY)"
  },
  {
    "label": "Czech Republic (CZ)",
    "value": "Czech Republic (CZ)"
  },
  {
    "label": "Denmark (DK)",
    "value": "Denmark (DK)"
  },
  {
    "label": "Djibouti (DJ)",
    "value": "Djibouti (DJ)"
  },
  {
    "label": "Dominica (DM)",
    "value": "Dominica (DM)"
  },
  {
    "label": "Dominican Republic (DO)",
    "value": "Dominican Republic (DO)"
  },
  {
    "label": "East Timor (TP)",
    "value": "East Timor (TP)"
  },
  {
    "label": "Ecuador (EC)",
    "value": "Ecuador (EC)"
  },
  {
    "label": "Egypt (EG)",
    "value": "Egypt (EG)"
  },
  {
    "label": "El Salvador (SV)",
    "value": "El Salvador (SV)"
  },
  {
    "label": "Equatorial Guinea (GQ)",
    "value": "Equatorial Guinea (GQ)"
  },
  {
    "label": "Eritrea (ER)",
    "value": "Eritrea (ER)"
  },
  {
    "label": "Estonia (EE)",
    "value": "Estonia (EE)"
  },
  {
    "label": "Ethiopia (ET)",
    "value": "Ethiopia (ET)"
  },
  {
    "label": "Falkland Islands (FK)",
    "value": "Falkland Islands (FK)"
  },
  {
    "label": "Faroe Islands (FO)",
    "value": "Faroe Islands (FO)"
  },
  {
    "label": "Fiji Islands (FJ)",
    "value": "Fiji Islands (FJ)"
  },
  {
    "label": "Finland (FI)",
    "value": "Finland (FI)"
  },
  {
    "label": "France (FR)",
    "value": "France (FR)"
  },
  {
    "label": "French Guiana (GF)",
    "value": "French Guiana (GF)"
  },
  {
    "label": "French Polynesia (PF)",
    "value": "French Polynesia (PF)"
  },
  {
    "label": "French Southern territories (TF)",
    "value": "French Southern territories (TF)"
  },
  {
    "label": "Gabon (GA)",
    "value": "Gabon (GA)"
  },
  {
    "label": "Gambia (GM)",
    "value": "Gambia (GM)"
  },
  {
    "label": "Georgia (GE)",
    "value": "Georgia (GE)"
  },
  {
    "label": "Germany (DE)",
    "value": "Germany (DE)"
  },
  {
    "label": "Ghana (GH)",
    "value": "Ghana (GH)"
  },
  {
    "label": "Gibraltar (GI)",
    "value": "Gibraltar (GI)"
  },
  {
    "label": "Greece (GR)",
    "value": "Greece (GR)"
  },
  {
    "label": "Greenland (GL)",
    "value": "Greenland (GL)"
  },
  {
    "label": "Grenada (GD)",
    "value": "Grenada (GD)"
  },
  {
    "label": "Guadeloupe (GP)",
    "value": "Guadeloupe (GP)"
  },
  {
    "label": "Guam (GU)",
    "value": "Guam (GU)"
  },
  {
    "label": "Guatemala (GT)",
    "value": "Guatemala (GT)"
  },
  {
    "label": "Guernsey (GG)",
    "value": "Guernsey (GG)"
  },
  {
    "label": "Guinea (GN)",
    "value": "Guinea (GN)"
  },
  {
    "label": "Guinea-Bissau (GW)",
    "value": "Guinea-Bissau (GW)"
  },
  {
    "label": "Guyana (GY)",
    "value": "Guyana (GY)"
  },
  {
    "label": "Haiti (HT)",
    "value": "Haiti (HT)"
  },
  {
    "label": "Heard Island and McDonald Islands (HM)",
    "value": "Heard Island and McDonald Islands (HM)"
  },
  {
    "label": "Holy See (Vatican City State) (VA)",
    "value": "Holy See (Vatican City State) (VA)"
  },
  {
    "label": "Honduras (HN)",
    "value": "Honduras (HN)"
  },
  {
    "label": "Hong Kong (HK)",
    "value": "Hong Kong (HK)"
  },
  {
    "label": "Hungary (HU)",
    "value": "Hungary (HU)"
  },
  {
    "label": "Iceland (IS)",
    "value": "Iceland (IS)"
  },
  {
    "label": "India (IN)",
    "value": "India (IN)"
  },
  {
    "label": "Indonesia (ID)",
    "value": "Indonesia (ID)"
  },
  {
    "label": "Iran (IR)",
    "value": "Iran (IR)"
  },
  {
    "label": "Iraq (IQ)",
    "value": "Iraq (IQ)"
  },
  {
    "label": "Ireland (IE)",
    "value": "Ireland (IE)"
  },
  {
    "label": "Isle of Man (IM)",
    "value": "Isle of Man (IM)"
  },
  {
    "label": "Israel (IL)",
    "value": "Israel (IL)"
  },
  {
    "label": "Italy (IT)",
    "value": "Italy (IT)"
  },
  {
    "label": "Ivory Coast (CI)",
    "value": "Ivory Coast (CI)"
  },
  {
    "label": "Jamaica (JM)",
    "value": "Jamaica (JM)"
  },
  {
    "label": "Japan (JP)",
    "value": "Japan (JP)"
  },
  {
    "label": "Jersey (JE)",
    "value": "Jersey (JE)"
  },
  {
    "label": "Jordan (JO)",
    "value": "Jordan (JO)"
  },
  {
    "label": "Kazakhstan (KZ)",
    "value": "Kazakhstan (KZ)"
  },
  {
    "label": "Kenya (KE)",
    "value": "Kenya (KE)"
  },
  {
    "label": "Kiribati (KI)",
    "value": "Kiribati (KI)"
  },
  {
    "label": "Kuwait (KW)",
    "value": "Kuwait (KW)"
  },
  {
    "label": "Kyrgyzstan (KG)",
    "value": "Kyrgyzstan (KG)"
  },
  {
    "label": "Laos (LA)",
    "value": "Laos (LA)"
  },
  {
    "label": "Latvia (LV)",
    "value": "Latvia (LV)"
  },
  {
    "label": "Lebanon (LB)",
    "value": "Lebanon (LB)"
  },
  {
    "label": "Lesotho (LS)",
    "value": "Lesotho (LS)"
  },
  {
    "label": "Liberia (LR)",
    "value": "Liberia (LR)"
  },
  {
    "label": "Libyan Arab Jamahiriya (LY)",
    "value": "Libyan Arab Jamahiriya (LY)"
  },
  {
    "label": "Liechtenstein (LI)",
    "value": "Liechtenstein (LI)"
  },
  {
    "label": "Lithuania (LT)",
    "value": "Lithuania (LT)"
  },
  {
    "label": "Luxembourg (LU)",
    "value": "Luxembourg (LU)"
  },
  {
    "label": "Macao (MO)",
    "value": "Macao (MO)"
  },
  {
    "label": "North Macedonia (MK)",
    "value": "North Macedonia (MK)"
  },
  {
    "label": "Madagascar (MG)",
    "value": "Madagascar (MG)"
  },
  {
    "label": "Malawi (MW)",
    "value": "Malawi (MW)"
  },
  {
    "label": "Malaysia (MY)",
    "value": "Malaysia (MY)"
  },
  {
    "label": "Maldives (MV)",
    "value": "Maldives (MV)"
  },
  {
    "label": "Mali (ML)",
    "value": "Mali (ML)"
  },
  {
    "label": "Malta (MT)",
    "value": "Malta (MT)"
  },
  {
    "label": "Marshall Islands (MH)",
    "value": "Marshall Islands (MH)"
  },
  {
    "label": "Martinique (MQ)",
    "value": "Martinique (MQ)"
  },
  {
    "label": "Mauritania (MR)",
    "value": "Mauritania (MR)"
  },
  {
    "label": "Mauritius (MU)",
    "value": "Mauritius (MU)"
  },
  {
    "label": "Mayotte (YT)",
    "value": "Mayotte (YT)"
  },
  {
    "label": "Mexico (MX)",
    "value": "Mexico (MX)"
  },
  {
    "label": "Micronesia, Federated States of (FM)",
    "value": "Micronesia, Federated States of (FM)"
  },
  {
    "label": "Moldova (MD)",
    "value": "Moldova (MD)"
  },
  {
    "label": "Monaco (MC)",
    "value": "Monaco (MC)"
  },
  {
    "label": "Mongolia (MN)",
    "value": "Mongolia (MN)"
  },
  {
    "label": "Montenegro (ME)",
    "value": "Montenegro (ME)"
  },
  {
    "label": "Montserrat (MS)",
    "value": "Montserrat (MS)"
  },
  {
    "label": "Morocco (MA)",
    "value": "Morocco (MA)"
  },
  {
    "label": "Mozambique (MZ)",
    "value": "Mozambique (MZ)"
  },
  {
    "label": "Myanmar (MM)",
    "value": "Myanmar (MM)"
  },
  {
    "label": "Namibia (NA)",
    "value": "Namibia (NA)"
  },
  {
    "label": "Nauru (NR)",
    "value": "Nauru (NR)"
  },
  {
    "label": "Nepal (NP)",
    "value": "Nepal (NP)"
  },
  {
    "label": "Netherlands (NL)",
    "value": "Netherlands (NL)"
  },
  {
    "label": "Netherlands Antilles (AN)",
    "value": "Netherlands Antilles (AN)"
  },
  {
    "label": "New Caledonia (NC)",
    "value": "New Caledonia (NC)"
  },
  {
    "label": "New Zealand (NZ)",
    "value": "New Zealand (NZ)"
  },
  {
    "label": "Nicaragua (NI)",
    "value": "Nicaragua (NI)"
  },
  {
    "label": "Niger (NE)",
    "value": "Niger (NE)"
  },
  {
    "label": "Nigeria (NG)",
    "value": "Nigeria (NG)"
  },
  {
    "label": "Niue (NU)",
    "value": "Niue (NU)"
  },
  {
    "label": "Norfolk Island (NF)",
    "value": "Norfolk Island (NF)"
  },
  {
    "label": "North Korea (KP)",
    "value": "North Korea (KP)"
  },
  {
    "label": "Northern Ireland (GB)",
    "value": "Northern Ireland (GB)"
  },
  {
    "label": "Northern Mariana Islands (MP)",
    "value": "Northern Mariana Islands (MP)"
  },
  {
    "label": "Norway (NO)",
    "value": "Norway (NO)"
  },
  {
    "label": "Oman (OM)",
    "value": "Oman (OM)"
  },
  {
    "label": "Pakistan (PK)",
    "value": "Pakistan (PK)"
  },
  {
    "label": "Palau (PW)",
    "value": "Palau (PW)"
  },
  {
    "label": "Palestine (PS)",
    "value": "Palestine (PS)"
  },
  {
    "label": "Panama (PA)",
    "value": "Panama (PA)"
  },
  {
    "label": "Papua New Guinea (PG)",
    "value": "Papua New Guinea (PG)"
  },
  {
    "label": "Paraguay (PY)",
    "value": "Paraguay (PY)"
  },
  {
    "label": "Peru (PE)",
    "value": "Peru (PE)"
  },
  {
    "label": "Philippines (PH)",
    "value": "Philippines (PH)"
  },
  {
    "label": "Pitcairn (PN)",
    "value": "Pitcairn (PN)"
  },
  {
    "label": "Poland (PL)",
    "value": "Poland (PL)"
  },
  {
    "label": "Portugal (PT)",
    "value": "Portugal (PT)"
  },
  {
    "label": "Puerto Rico (PR)",
    "value": "Puerto Rico (PR)"
  },
  {
    "label": "Qatar (QA)",
    "value": "Qatar (QA)"
  },
  {
    "label": "Reunion (RE)",
    "value": "Reunion (RE)"
  },
  {
    "label": "Romania (RO)",
    "value": "Romania (RO)"
  },
  {
    "label": "Russian Federation (RU)",
    "value": "Russian Federation (RU)"
  },
  {
    "label": "Rwanda (RW)",
    "value": "Rwanda (RW)"
  },
  {
    "label": "Saint Helena (SH)",
    "value": "Saint Helena (SH)"
  },
  {
    "label": "Saint Kitts and Nevis (KN)",
    "value": "Saint Kitts and Nevis (KN)"
  },
  {
    "label": "Saint Lucia (LC)",
    "value": "Saint Lucia (LC)"
  },
  {
    "label": "Saint Pierre and Miquelon (PM)",
    "value": "Saint Pierre and Miquelon (PM)"
  },
  {
    "label": "Saint Vincent and the Grenadines (VC)",
    "value": "Saint Vincent and the Grenadines (VC)"
  },
  {
    "label": "Samoa (WS)",
    "value": "Samoa (WS)"
  },
  {
    "label": "San Marino (SM)",
    "value": "San Marino (SM)"
  },
  {
    "label": "Sao Tome and Principe (ST)",
    "value": "Sao Tome and Principe (ST)"
  },
  {
    "label": "Saudi Arabia (SA)",
    "value": "Saudi Arabia (SA)"
  },
  {
    "label": "Senegal (SN)",
    "value": "Senegal (SN)"
  },
  {
    "label": "Serbia (RS)",
    "value": "Serbia (RS)"
  },
  {
    "label": "Seychelles (SC)",
    "value": "Seychelles (SC)"
  },
  {
    "label": "Sierra Leone (SL)",
    "value": "Sierra Leone (SL)"
  },
  {
    "label": "Singapore (SG)",
    "value": "Singapore (SG)"
  },
  {
    "label": "Slovakia (SK)",
    "value": "Slovakia (SK)"
  },
  {
    "label": "Slovenia (SI)",
    "value": "Slovenia (SI)"
  },
  {
    "label": "Solomon Islands (SB)",
    "value": "Solomon Islands (SB)"
  },
  {
    "label": "Somalia (SO)",
    "value": "Somalia (SO)"
  },
  {
    "label": "South Africa (ZA)",
    "value": "South Africa (ZA)"
  },
  {
    "label": "South Georgia and the South Sandwich Islands (GS)",
    "value": "South Georgia and the South Sandwich Islands (GS)"
  },
  {
    "label": "South Korea (KR)",
    "value": "South Korea (KR)"
  },
  {
    "label": "South Sudan (SS)",
    "value": "South Sudan (SS)"
  },
  {
    "label": "Spain (ES)",
    "value": "Spain (ES)"
  },
  {
    "label": "Sri Lanka (LK)",
    "value": "Sri Lanka (LK)"
  },
  {
    "label": "Sudan (SD)",
    "value": "Sudan (SD)"
  },
  {
    "label": "Suriname (SR)",
    "value": "Suriname (SR)"
  },
  {
    "label": "Svalbard and Jan Mayen (SJ)",
    "value": "Svalbard and Jan Mayen (SJ)"
  },
  {
    "label": "Swaziland (SZ)",
    "value": "Swaziland (SZ)"
  },
  {
    "label": "Sweden (SE)",
    "value": "Sweden (SE)"
  },
  {
    "label": "Switzerland (CH)",
    "value": "Switzerland (CH)"
  },
  {
    "label": "Syria (SY)",
    "value": "Syria (SY)"
  },
  {
    "label": "Tajikistan (TJ)",
    "value": "Tajikistan (TJ)"
  },
  {
    "label": "Tanzania (TZ)",
    "value": "Tanzania (TZ)"
  },
  {
    "label": "Thailand (TH)",
    "value": "Thailand (TH)"
  },
  {
    "label": "The Democratic Republic of Congo (CD)",
    "value": "The Democratic Republic of Congo (CD)"
  },
  {
    "label": "Timor-Leste (TL)",
    "value": "Timor-Leste (TL)"
  },
  {
    "label": "Togo (TG)",
    "value": "Togo (TG)"
  },
  {
    "label": "Tokelau (TK)",
    "value": "Tokelau (TK)"
  },
  {
    "label": "Tonga (TO)",
    "value": "Tonga (TO)"
  },
  {
    "label": "Trinidad and Tobago (TT)",
    "value": "Trinidad and Tobago (TT)"
  },
  {
    "label": "Tunisia (TN)",
    "value": "Tunisia (TN)"
  },
  {
    "label": "Turkey (TR)",
    "value": "Turkey (TR)"
  },
  {
    "label": "Turkmenistan (TM)",
    "value": "Turkmenistan (TM)"
  },
  {
    "label": "Turks and Caicos Islands (TC)",
    "value": "Turks and Caicos Islands (TC)"
  },
  {
    "label": "Tuvalu (TV)",
    "value": "Tuvalu (TV)"
  },
  {
    "label": "Uganda (UG)",
    "value": "Uganda (UG)"
  },
  {
    "label": "Ukraine (UA)",
    "value": "Ukraine (UA)"
  },
  {
    "label": "United Arab Emirates (AE)",
    "value": "United Arab Emirates (AE)"
  },
  {
    "label": "United Kingdom (GB)",
    "value": "United Kingdom (GB)"
  },
  {
    "label": "United States (US)",
    "value": "United States (US)"
  },
  {
    "label": "United States Minor Outlying Islands (UM)",
    "value": "United States Minor Outlying Islands (UM)"
  },
  {
    "label": "Uruguay (UY)",
    "value": "Uruguay (UY)"
  },
  {
    "label": "Uzbekistan (UZ)",
    "value": "Uzbekistan (UZ)"
  },
  {
    "label": "Vanuatu (VU)",
    "value": "Vanuatu (VU)"
  },
  {
    "label": "Venezuela (VE)",
    "value": "Venezuela (VE)"
  },
  {
    "label": "Vietnam (VN)",
    "value": "Vietnam (VN)"
  },
  {
    "label": "Virgin Islands, British (VG)",
    "value": "Virgin Islands, British (VG)"
  },
  {
    "label": "Virgin Islands, U.S. (VI)",
    "value": "Virgin Islands, U.S. (VI)"
  },
  {
    "label": "Wallis and Futuna (WF)",
    "value": "Wallis and Futuna (WF)"
  },
  {
    "label": "Western Sahara (EH)",
    "value": "Western Sahara (EH)"
  },
  {
    "label": "Yemen (YE)",
    "value": "Yemen (YE)"
  },
  {
    "label": "Yugoslavia (YU)",
    "value": "Yugoslavia (YU)"
  },
  {
    "label": "Zambia (ZM)",
    "value": "Zambia (ZM)"
  },
  {
    "label": "Zimbabwe (ZW)",
    "value": "Zimbabwe (ZW)"
  }
];

export const mappedCountries = [
  {
    value: 'AFG',
    label: 'Afghanistan',
  },
  {
    value: 'ALB',
    label: 'Albania',
  },
  {
    value: 'DZA',
    label: 'Algeria',
  },
  {
    value: 'ASM',
    label: 'American Samoa',
  },
  {
    value: 'AND',
    label: 'Andorra',
  },
  {
    value: 'AGO',
    label: 'Angola',
  },
  {
    value: 'AIA',
    label: 'Anguilla',
  },
  {
    value: 'ATA',
    label: 'Antarctica',
  },
  {
    value: 'ATG',
    label: 'Antigua and Barbuda',
  },
  {
    value: 'ARG',
    label: 'Argentina',
  },
  {
    value: 'ARM',
    label: 'Armenia',
  },
  {
    value: 'ABW',
    label: 'Aruba',
  },
  {
    value: 'AUS',
    label: 'Australia',
  },
  {
    value: 'AUT',
    label: 'Austria',
  },
  {
    value: 'AZE',
    label: 'Azerbaijan',
  },
  {
    value: 'BHS',
    label: 'Bahamas',
  },
  {
    value: 'BHR',
    label: 'Bahrain',
  },
  {
    value: 'BGD',
    label: 'Bangladesh',
  },
  {
    value: 'BRB',
    label: 'Barbados',
  },
  {
    value: 'BLR',
    label: 'Belarus',
  },
  {
    value: 'BEL',
    label: 'Belgium',
  },
  {
    value: 'BLZ',
    label: 'Belize',
  },
  {
    value: 'BEN',
    label: 'Benin',
  },
  {
    value: 'BMU',
    label: 'Bermuda',
  },
  {
    value: 'BTN',
    label: 'Bhutan',
  },
  {
    value: 'BOL',
    label: 'Bolivia',
  },
  {
    value: 'BIH',
    label: 'Bosnia and Herzegovina',
  },
  {
    value: 'BWA',
    label: 'Botswana',
  },
  {
    value: 'BVT',
    label: 'Bouvet Island',
  },
  {
    value: 'BRA',
    label: 'Brazil',
  },
  {
    value: 'IOT',
    label: 'British Indian Ocean Territory',
  },
  {
    value: 'BRN',
    label: 'Brunei',
  },
  {
    value: 'BGR',
    label: 'Bulgaria',
  },
  {
    value: 'BFA',
    label: 'Burkina Faso',
  },
  {
    value: 'BDI',
    label: 'Burundi',
  },
  {
    value: 'KHM',
    label: 'Cambodia',
  },
  {
    value: 'CMR',
    label: 'Cameroon',
  },
  {
    value: 'CAN',
    label: 'Canada',
  },
  {
    value: 'CPV',
    label: 'Cape Verde',
  },
  {
    value: 'CYM',
    label: 'Cayman Islands',
  },
  {
    value: 'CAF',
    label: 'Central African Republic',
  },
  {
    value: 'TCD',
    label: 'Chad',
  },
  {
    value: 'CHL',
    label: 'Chile',
  },
  {
    value: 'CHN',
    label: 'China',
  },
  {
    value: 'CXR',
    label: 'Christmas Island',
  },
  {
    value: 'CCK',
    label: 'Cocos (Keeling) Islands',
  },
  {
    value: 'COL',
    label: 'Colombia',
  },
  {
    value: 'COM',
    label: 'Comoros',
  },
  {
    value: 'COG',
    label: 'Congo',
  },
  {
    value: 'COD',
    label: 'Congo, the Democratic Republic of the',
  },
  {
    value: 'COK',
    label: 'Cook Islands',
  },
  {
    value: 'CRI',
    label: 'Costa Rica',
  },
  {
    value: 'HRV',
    label: 'Croatia',
  },
  {
    value: 'CUB',
    label: 'Cuba',
  },
  {
    value: 'CYP',
    label: 'Cyprus',
  },
  {
    value: 'CZE',
    label: 'Czech Republic',
  },
  {
    value: 'DNK',
    label: 'Denmark',
  },
  {
    value: 'DJI',
    label: 'Djibouti',
  },
  {
    value: 'DMA',
    label: 'Dominica',
  },
  {
    value: 'DOM',
    label: 'Dominican Republic',
  },
  {
    value: 'ECU',
    label: 'Ecuador',
  },
  {
    value: 'EGY',
    label: 'Egypt',
  },
  {
    value: 'SLV',
    label: 'El Salvador',
  },
  {
    value: 'GNQ',
    label: 'Equatorial Guinea',
  },
  {
    value: 'ERI',
    label: 'Eritrea',
  },
  {
    value: 'EST',
    label: 'Estonia',
  },
  {
    value: 'ETH',
    label: 'Ethiopia',
  },
  {
    value: 'FLK',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    value: 'FRO',
    label: 'Faroe Islands',
  },
  {
    value: 'FJI',
    label: 'Fiji',
  },
  {
    value: 'FIN',
    label: 'Finland',
  },
  {
    value: 'FRA',
    label: 'France',
  },
  {
    value: 'GUF',
    label: 'French Guiana',
  },
  {
    value: 'PYF',
    label: 'French Polynesia',
  },
  {
    value: 'ATF',
    label: 'French Southern Territories',
  },
  {
    value: 'GAB',
    label: 'Gabon',
  },
  {
    value: 'GMB',
    label: 'Gambia',
  },
  {
    value: 'GEO',
    label: 'Georgia',
  },
  {
    value: 'DEU',
    label: 'Germany',
  },
  {
    value: 'GHA',
    label: 'Ghana',
  },
  {
    value: 'GIB',
    label: 'Gibraltar',
  },
  {
    value: 'GRC',
    label: 'Greece',
  },
  {
    value: 'GRL',
    label: 'Greenland',
  },
  {
    value: 'GRD',
    label: 'Grenada',
  },
  {
    value: 'GLP',
    label: 'Guadeloupe',
  },
  {
    value: 'GUM',
    label: 'Guam',
  },
  {
    value: 'GTM',
    label: 'Guatemala',
  },
  {
    value: 'GGY',
    label: 'Guernsey',
  },
  {
    value: 'GIN',
    label: 'Guinea',
  },
  {
    value: 'GNB',
    label: 'Guinea-Bissau',
  },
  {
    value: 'GUY',
    label: 'Guyana',
  },
  {
    value: 'HTI',
    label: 'Haiti',
  },
  {
    value: 'HMD',
    label: 'Heard Island and McDonald Islands',
  },
  {
    value: 'VAT',
    label: 'Holy See (Vatican City State)',
  },
  {
    value: 'HND',
    label: 'Honduras',
  },
  {
    value: 'HKG',
    label: 'Hong Kong',
  },
  {
    value: 'HUN',
    label: 'Hungary',
  },
  {
    value: 'ISL',
    label: 'Iceland',
  },
  {
    value: 'IND',
    label: 'India',
  },
  {
    value: 'IDN',
    label: 'Indonesia',
  },
  {
    value: 'IRN',
    label: 'Iran, Islamic Republic of',
  },
  {
    value: 'IRQ',
    label: 'Iraq',
  },
  {
    value: 'IRL',
    label: 'Ireland',
  },
  {
    value: 'IMN',
    label: 'Isle of Man',
  },
  {
    value: 'ISR',
    label: 'Israel',
  },
  {
    value: 'ITA',
    label: 'Italy',
  },
  {
    value: 'CIV',
    label: 'Ivory Coast',
  },
  {
    value: 'JAM',
    label: 'Jamaica',
  },
  {
    value: 'JPN',
    label: 'Japan',
  },
  {
    value: 'JEY',
    label: 'Jersey',
  },
  {
    value: 'JOR',
    label: 'Jordan',
  },
  {
    value: 'KAZ',
    label: 'Kazakhstan',
  },
  {
    value: 'KEN',
    label: 'Kenya',
  },
  {
    value: 'KIR',
    label: 'Kiribati',
  },
  {
    value: 'PRK',
    label: "Korea, Democratic People's Republic of",
  },
  {
    value: 'KOR',
    label: 'Korea, Republic of',
  },
  {
    value: 'KWT',
    label: 'Kuwait',
  },
  {
    value: 'KGZ',
    label: 'Kyrgyzstan',
  },
  {
    value: 'LAO',
    label: "Lao People's Democratic Republic",
  },
  {
    value: 'LVA',
    label: 'Latvia',
  },
  {
    value: 'LBN',
    label: 'Lebanon',
  },
  {
    value: 'LSO',
    label: 'Lesotho',
  },
  {
    value: 'LBR',
    label: 'Liberia',
  },
  {
    value: 'LBY',
    label: 'Libya',
  },
  {
    value: 'LIE',
    label: 'Liechtenstein',
  },
  {
    value: 'LTU',
    label: 'Lithuania',
  },
  {
    value: 'LUX',
    label: 'Luxembourg',
  },
  {
    value: 'MAC',
    label: 'Macao',
  },
  {
    value: 'MKD',
    label: 'Macedonia, the former Yugoslav Republic of',
  },
  {
    value: 'MDG',
    label: 'Madagascar',
  },
  {
    value: 'MWI',
    label: 'Malawi',
  },
  {
    value: 'MYS',
    label: 'Malaysia',
  },
  {
    value: 'MDV',
    label: 'Maldives',
  },
  {
    value: 'MLI',
    label: 'Mali',
  },
  {
    value: 'MLT',
    label: 'Malta',
  },
  {
    value: 'MHL',
    label: 'Marshall Islands',
  },
  {
    value: 'MTQ',
    label: 'Martinique',
  },
  {
    value: 'MRT',
    label: 'Mauritania',
  },
  {
    value: 'MUS',
    label: 'Mauritius',
  },
  {
    value: 'MYT',
    label: 'Mayotte',
  },
  {
    value: 'MEX',
    label: 'Mexico',
  },
  {
    value: 'FSM',
    label: 'Micronesia, Federated States of',
  },
  {
    value: 'MDA',
    label: 'Moldova, Republic of',
  },
  {
    value: 'MCO',
    label: 'Monaco',
  },
  {
    value: 'MNG',
    label: 'Mongolia',
  },
  {
    value: 'MNE',
    label: 'Montenegro',
  },
  {
    value: 'MSR',
    label: 'Montserrat',
  },
  {
    value: 'MAR',
    label: 'Morocco',
  },
  {
    value: 'MOZ',
    label: 'Mozambique',
  },
  {
    value: 'MMR',
    label: 'Myanmar',
  },
  {
    value: 'NAM',
    label: 'Namibia',
  },
  {
    value: 'NRU',
    label: 'Nauru',
  },
  {
    value: 'NPL',
    label: 'Nepal',
  },
  {
    value: 'NLD',
    label: 'Netherlands',
  },
  {
    value: 'ANT',
    label: 'Netherlands Antilles',
  },
  {
    value: 'NCL',
    label: 'New Caledonia',
  },
  {
    value: 'NZL',
    label: 'New Zealand',
  },
  {
    value: 'NIC',
    label: 'Nicaragua',
  },
  {
    value: 'NER',
    label: 'Niger',
  },
  {
    value: 'NGA',
    label: 'Nigeria',
  },
  {
    value: 'NIU',
    label: 'Niue',
  },
  {
    value: 'NFK',
    label: 'Norfolk Island',
  },
  {
    value: 'MNP',
    label: 'Northern Mariana Islands',
  },
  {
    value: 'NOR',
    label: 'Norway',
  },
  {
    value: 'OMN',
    label: 'Oman',
  },
  {
    value: 'PAK',
    label: 'Pakistan',
  },
  {
    value: 'PLW',
    label: 'Palau',
  },
  {
    value: 'PSE',
    label: 'Palestinian Territory, Occupied',
  },
  {
    value: 'PAN',
    label: 'Panama',
  },
  {
    value: 'PNG',
    label: 'Papua New Guinea',
  },
  {
    value: 'PRY',
    label: 'Paraguay',
  },
  {
    value: 'PER',
    label: 'Peru',
  },
  {
    value: 'PHL',
    label: 'Philippines',
  },
  {
    value: 'PCN',
    label: 'Pitcairn',
  },
  {
    value: 'POL',
    label: 'Poland',
  },
  {
    value: 'PRT',
    label: 'Portugal',
  },
  {
    value: 'PRI',
    label: 'Puerto Rico',
  },
  {
    value: 'QAT',
    label: 'Qatar',
  },
  {
    value: 'ROU',
    label: 'Romania',
  },
  {
    value: 'RUS',
    label: 'Russia',
  },
  {
    value: 'RWA',
    label: 'Rwanda',
  },
  {
    value: 'REU',
    label: 'Réunion',
  },
  {
    value: 'SHN',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    value: 'KNA',
    label: 'Saint Kitts and Nevis',
  },
  {
    value: 'LCA',
    label: 'Saint Lucia',
  },
  {
    value: 'SPM',
    label: 'Saint Pierre and Miquelon',
  },
  {
    value: 'VCT',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    value: 'WSM',
    label: 'Samoa',
  },
  {
    value: 'SMR',
    label: 'San Marino',
  },
  {
    value: 'STP',
    label: 'Sao Tome and Principe',
  },
  {
    value: 'SAU',
    label: 'Saudi Arabia',
  },
  {
    value: 'SEN',
    label: 'Senegal',
  },
  {
    value: 'SRB',
    label: 'Serbia',
  },
  {
    value: 'SYC',
    label: 'Seychelles',
  },
  {
    value: 'SLE',
    label: 'Sierra Leone',
  },
  {
    value: 'SGP',
    label: 'Singapore',
  },
  {
    value: 'SVK',
    label: 'Slovakia',
  },
  {
    value: 'SVN',
    label: 'Slovenia',
  },
  {
    value: 'SLB',
    label: 'Solomon Islands',
  },
  {
    value: 'SOM',
    label: 'Somalia',
  },
  {
    value: 'ZAF',
    label: 'South Africa',
  },
  {
    value: 'SGS',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    value: 'ESP',
    label: 'Spain',
  },
  {
    value: 'LKA',
    label: 'Sri Lanka',
  },
  {
    value: 'SDN',
    label: 'Sudan',
  },
  {
    value: 'SUR',
    label: 'Suriname',
  },
  {
    value: 'SJM',
    label: 'Svalbard and Jan Mayen',
  },
  {
    value: 'SWZ',
    label: 'Swaziland',
  },
  {
    value: 'SWE',
    label: 'Sweden',
  },
  {
    value: 'CHE',
    label: 'Switzerland',
  },
  {
    value: 'SYR',
    label: 'Syrian Arab Republic',
  },
  {
    value: 'TWN',
    label: 'Taiwan',
  },
  {
    value: 'TJK',
    label: 'Tajikistan',
  },
  {
    value: 'TZA',
    label: 'Tanzania, United Republic of',
  },
  {
    value: 'THA',
    label: 'Thailand',
  },
  {
    value: 'TLS',
    label: 'Timor-Leste',
  },
  {
    value: 'TGO',
    label: 'Togo',
  },
  {
    value: 'TKL',
    label: 'Tokelau',
  },
  {
    value: 'TON',
    label: 'Tonga',
  },
  {
    value: 'TTO',
    label: 'Trinidad and Tobago',
  },
  {
    value: 'TUN',
    label: 'Tunisia',
  },
  {
    value: 'TUR',
    label: 'Turkey',
  },
  {
    value: 'TKM',
    label: 'Turkmenistan',
  },
  {
    value: 'TCA',
    label: 'Turks and Caicos Islands',
  },
  {
    value: 'TUV',
    label: 'Tuvalu',
  },
  {
    value: 'UGA',
    label: 'Uganda',
  },
  {
    value: 'UKR',
    label: 'Ukraine',
  },
  {
    value: 'ARE',
    label: 'United Arab Emirates',
  },
  {
    value: 'GBR',
    label: 'United Kingdom',
  },
  {
    value: 'USA',
    label: 'United States',
  },
  {
    value: 'UMI',
    label: 'United States Minor Outlying Islands',
  },
  {
    value: 'URY',
    label: 'Uruguay',
  },
  {
    value: 'UZB',
    label: 'Uzbekistan',
  },
  {
    value: 'VUT',
    label: 'Vanuatu',
  },
  {
    value: 'VEN',
    label: 'Venezuela',
  },
  {
    value: 'VNM',
    label: 'Vietnam',
  },
  {
    value: 'VGB',
    label: 'Virgin Islands, British',
  },
  {
    value: 'VIR',
    label: 'Virgin Islands, U.S.',
  },
  {
    value: 'WLF',
    label: 'Wallis and Futuna',
  },
  {
    value: 'ESH',
    label: 'Western Sahara',
  },
  {
    value: 'YEM',
    label: 'Yemen',
  },
  {
    value: 'ZWB',
    label: 'Zambia',
  },
  {
    value: 'ZWE',
    label: 'Zimbabwe',
  },
];
