import axios from "axios";
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_SUCCESS, TYPE_ERROR } from "constants/notifications";
import { apiRoutes } from "constants/api";

export default function ({
  account_type, _id
}) {
  return (dispatch, getState) => {
    const { form } = getState()
    const company_information = {
      ...form.company_information,
      contacts_information: {
        ...form.contacts_information
      },
      structure: form.data,
      account_type,
      status: 'In Progress'
    };
    const data = {
      company_information
    }
    return axios.patch(apiRoutes.organisationUpdate + _id, data)
      .then(() => {
        dispatch(displayNotification({
          message: 'Company\'s info has been successfully saved',
          type: TYPE_SUCCESS,
          timeout: DEFAULT_NOTIFICATION_TIMEOUT
        }));
      })
      .catch(() => {
        dispatch(displayNotification({
          message: 'Something went wrong with saving the company\'s info',
          type: TYPE_ERROR,
          timeout: DEFAULT_NOTIFICATION_TIMEOUT
        }));
      })
  }
}
