import React from 'react';
import HeadText from 'components/texts/HeadText';
import './SectionWrapper.css';

function SectionWrapper({ title, children }) {
  return (
    <div className="data-capture-section-container">
      <HeadText>{ title }</HeadText>
      <div className="data-capture-section">
        {children }
      </div>
    </div>
  );
}

export default SectionWrapper;
