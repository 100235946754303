import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Form} from 'react-bootstrap';
import categoriesList from '../../../actions/api/productCategories/list';
import addCategory from '../../../actions/api/productCategories/create';
import deleteCategory from '../../../actions/api/productCategories/delete';
import './category-levels.scss'
import caretRightIcon from 'assets/icons/caret_right_icon.svg';
import removeIcon from 'assets/icons/icons-trash.svg';
import fileIcon from 'assets/icons/file.svg';
import folderIcon from 'assets/icons/folder.svg';
import {clone, remove} from 'lodash';
import {displayNotification} from '../../../actions/notifications';
import {DEFAULT_NOTIFICATION_TIMEOUT, TYPE_SUCCESS} from "../../../constants/notifications";
import button from "react-bootstrap/Button";
import cloneDeep from "lodash/cloneDeep";

class ProductCategoriesNames extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productCategories: [],
            categoryToPush: '',
            newCategoryToPush: '',
            newCategoryToPushList: {},
            expandAllChildren: true,
            categoryName: '',
            categories: [],
            category: "",
            categoryLevels: [],
            disableSubmitAction: false,
            categoryDrop: false,
            subCategoryDrop: {},
            filteredCategoriesList: [],
            filteredSubCategoriesList: [],
        }
    };

    categoriesListData = ['Accessories', 'Animal & Pet Supplies', 'Apparel', 'Arts & Crafts', 'Automotive', 'Bags & Luggage', 'Childrenswear', 'Consumer Electronics', 'DIY & Tools', 'Food & Beverage', 'Footwear', 'Health & Beauty', 'Home & Homeware', 'Infant & Toddler', 'Outdoor & Recreation', 'Publishing', 'Software & Digital', 'Sports & Hobbies', 'Stationery', 'Toys & Games', 'Other',]

    subCategoriesListDataGrouped = {
        'Accessories': ['Eyewear', 'Hair Accessories', 'Headwear', 'Jewellery', 'Neckwear', 'Purses & Wallets', 'Watches', 'Other'

        ],
        'Animal & Pet Supplies': ['Pet Accessories', 'Pet Food', 'Other'],
        'Apparel': ['Activewear & Sportswear', 'Clothing Sets', 'Denim', 'Dresses', 'Formalwear', 'Hosiery', 'Hoodies & Sweatshirts', 'Knitwear', 'Loungewear', 'Nightwear', 'Onesies', 'Outerwear', 'Overalls', 'Shorts', 'Skirts', 'Swimwear & Beachwear', 'Trousers', 'T-shirts', 'Underwear', 'Other'],
        'Arts & Crafts': [
            'Crafts',
            'Drawing & Accessories',
            'Painting & Accessories',
            'Other',
        ],
        'Automotive': [
            'Car Accessories',
            'Car Parts',
            'Fuel',
            'Tyres',
            'Vehicle Cleaning',
            'Other',
        ],
        'Bags & Luggage': [
            'Backpacks',
            'Briefcases',
            'Cooler Bag',
            'Duffel Bags',
            'Fanny Packs & Bum Bags',
            'Grocery Bags',
            'Handbags',
            'Suitcases',
            'Tote Bags',
            'Other',
        ],
        'Childrenswear': [
            'Activewear & Sportswear',
            'Beachwear',
            'Clothing Sets',
            'Denim',
            'Dresses',
            'Hoodies & Sweatshirts',
            'Hosiery',
            'Knitwear',
            'Loungewear',
            'Nightwear',
            'Onesies',
            'Outerwear',
            'Shorts',
            'Skirts',
            'Swimwear & Beachwear',
            'Trousers',
            'T-shirts',
            'Underwear',
            'Other',
        ],
        'Consumer Electronics': [
            'Amplifiers',
            'Audio Mixers',
            'Cameras & Camera Accessories',
            'Cell Phone Accessories',
            'Cell Phones',
            'Computer Accessories',
            'Drones',
            'E-book & E-readers',
            'Games Consoles & Accessories',
            'Headphones & Headsets',
            'Laptops & Computers',
            'Microphones',
            'MP3 Player Cases',
            'MP3 Players',
            'Radios',
            'Smartwatches',
            'Speakers',
            'USB Adapters',
            'USB Flash Drive',
            'Voice Recorders',
            'Other',
        ],
        'DIY & Tools': [
            'DIY Building Sets',
            'Gardening',
            'Hand Tools',
            'Hardware',
            'Plumbing & Bath',
            'Power Tool Accessories',
            'Power Tools',
            'Wall Paint',
            'Other',
        ],
        'Food & Beverage': [
            'Alcoholic Beverages',
            'Biscuits, Cookies & Cakes',
            'Bottled Water',
            'Canned Food',
            'Carbonated Drinks',
            'Cereal',
            'Coffee',
            'Condiments',
            'Confectionery',
            'Dairy & Dairy Alternatives',
            'Desserts',
            'Fruit & Veg',
            'Hot Beverages',
            'Ice Cream & Frozen Yogurt',
            'Juices',
            'Pasta & Noodles',
            'Ready Meals',
            'Seasoning & Spices',
            'Snack Foods',
            'Tea',
            'Tobacco',
            'Other',
        ],
        'Footwear': [
            'Athletic Footwear',
            'Boots',
            'Flats & Pumps',
            'Flip Flops',
            'Footwear Cleaning',
            'Formal Shoes',
            'Heels',
            'Safety Footwear',
            'Sandals',
            'Shoelaces',
            'Slippers',
            'Specialist Footwear',
            'Trainers & Sneakers',
            'Other',
        ],
        'Health & Beauty': [
            'Bath & Shower',
            'Female Hygiene Products',
            'First Aid',
            'Fragrance',
            'Haircare',
            'Makeup',
            'Nailcare',
            'Oral Care',
            'Sexual Pleasure & Wellbeing',
            'Shaving & Grooming Products',
            'Skincare',
            'Toilet Paper',
            'Vitamins & Dietary Supplements',
            'Other',
        ],
        'Home & Homeware': [
            'Air Fresheners',
            'Bath Mats & Rugs',
            'Bedding',
            'Carpets & Rugs',
            'Clocks',
            'Cookware',
            'Crockery',
            'Curtains & Drapes',
            'Décor',
            'Fabric',
            'Flasks',
            'Furniture',
            'Glassware',
            'Household Appliances',
            'Household Cleaning',
            'Lunch Boxes',
            'Picture Frames',
            'Shower Curtains',
            'Storage',
            'Tableware',
            'Towels & Beach Towels',
            'Wallpaper',
            'Water Bottles',
            'Other',
        ],
        'Infant & Toddler': [
            'Activity Toys',
            'Bibs',
            'Bottles',
            'Carriers',
            'Clothing Accessories',
            'Daywear',
            'Diapers & Nappies',
            'Footwear',
            'Formula',
            'Furniture',
            'Gates',
            'Monitors',
            'Pacifiers & Teethers',
            'Potty Training',
            'Sleepwear',
            'Strollers',
            'Other',
        ],
        'Outdoor & Recreation': [
            'Camping Equipment',
            'Garden Furniture',
            'Outdoor Lighting',
            'Other',
        ],
        'Publishing': [
            'Books',
            'Magazines & Comics',
            'Stamps',
            'Trading Cards',
            'Other',
        ],
        'Software & Digital': [
            'Antivirus & Security Software',
            'Console Games',
            'Digital Artwork',
            'Educational Software',
            'Mobile Applications',
            'Mobile Games',
            'PC Gaming',
            'Video Editing Software',
            'Other',
        ],
        'Sports & Hobbies': [
            'Ball Sports',
            'Bicycles & Bicycle Accessories',
            'Coins & Medallions',
            'Combat Sports',
            'Gym Equipment',
            'Memorabilia',
            'Musical Instruments & Accessories',
            'Racket Sports',
            'Skateboards, Scooters & Roller Skates',
            'Snow & Ice Sports',
            'Target Sports',
            'Training Equipment',
            'Water Sports',
            'Other',
        ],
        'Stationery': [
            'Calendars & Posters',
            'Coloring Books',
            'Coloring Pens & Pencils',
            'Gift Wrap',
            'Greetings Cards',
            'Money Boxes',
            'Notebooks & Notepads',
            'Occasion Supplies',
            'Office Supplies',
            'Pencil Cases',
            'Pens & Pencils',
            'Planners & Organizers',
            'Postcards',
            'Stationery Sets',
            'Stencils',
            'Stickers',
            'Other',
        ],
        'Toys & Games': [
            'Action Figures',
            'Bath Toys',
            'Board Games',
            'Card Games',
            'Construction Toys',
            'Dice Sets & Games',
            'Dolls & Dollhouses',
            'Dress-Up',
            'Figurines & Models',
            'Infant & Toddler Toys',
            'Jigsaw Puzzles',
            'Novelties',
            'Outdoor Toys & Games',
            'Plush',
            'Remote Control Vehicles',
            'Role-Play Toys',
            'Toy Vehicles',
            'Trading Cards',
            'Other',
        ],
        'Other': ['Other'],
    }

    componentDidMount() {
        this.fetchProductCategory();
    }

    fetchProductCategory = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        categoriesList(organisation_id)
            .then((data) => {
                this.setState({
                    productCategories: data.categories, categoryLevels: data.category_levels
                })
            })
            .catch(err => alert(err.message));
    }

    handleAddProductCategory = (categories) => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};

        this.setState({disableSubmitAction: true});
        addCategory(organisation_id, categories).then((data) => {
            setTimeout(() => {
                this.setState({disableSubmitAction: false});
            }, 333)
            this.props.displayNotification({
                message: 'Success, Category updated.', type: TYPE_SUCCESS, timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            setTimeout(() => {
                this.fetchProductCategory();
            }, 1000)
        })
            .catch(err => alert(err.message));
    }

    handleAddProductCategoryItem = (cIndex, category) => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        let {categoryName} = this.state;
        addCategory(organisation_id, categoryName).then((data) => {
            this.setState({category: ""})
        })
            .catch(err => alert(err.message));
    }

    flatMapCategories = (categories) => {
        let mappedCategories = []
        categories.forEach(item => {
            mappedCategories.push({
                string_path: item.string_path,
                name: item.string_path[item.string_path.length - 1],
                category_id: item.category_id,
                parent_category_id: item.parent_category_id,
                children: [],
                removable: item.removable,
            })
        });
        return mappedCategories
    }

    mapCategoryObject = (categories) => {
        let mappedCategories = this.flatMapCategories(categories);
        let finalMappedTree = [];
        let map = {};
        mappedCategories.forEach((node, node_index) => {
            if (!node.parent_category_id) return finalMappedTree.push(node);
            let parentIndex = map[node.parent_category_id];
            if (parentIndex !== "number") {
                parentIndex = mappedCategories.findIndex(el => el.category_id === node.parent_category_id);
                map[node.parent_category_id] = parentIndex;
            }
            if (!mappedCategories[parentIndex].children) {
                return mappedCategories[parentIndex].children = [node];
            }
            mappedCategories[parentIndex].children.push(node);
        })

        let filteredMapedList = mappedCategories.filter(item => item.parent_category_id === null);

        filteredMapedList.forEach(parent => {
            this.traverse(parent)
        })
        return filteredMapedList
    }

    traverse = (parent, depth) => {
        if (typeof depth == 'number') {
            depth++;
            parent.depth = depth
        } else {
            depth = 1;
            parent.depth = depth
        }
        if (parent.children) {
            parent.children.forEach(child => {
                this.traverse(child, depth);
            })
        }
    }


    handleDeleteProductCategory = (category) => {
        const {category_id} = category;
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};

        deleteCategory(organisation_id, category_id).then((data) => {
        })
            .catch(err => alert(err.message));
    }
    uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
    }

    pushNewChiChild = (node, child) => {
        let updatedCategories = clone(this.state.productCategories);
        let generatedID = this.uuidv4();
        updatedCategories.push({
            category_id: generatedID,
            parent_category_id: !!node ? node.category_id : null,
            string_path: [child.name],
            removable: true,
        })
        this.setState({
            productCategories: updatedCategories, categoryToPush: '', newCategoryToPush: ''
        });
    }

    excludeNodeFromList(node) {
        let updatedCategories = clone(this.state.productCategories);
        remove(updatedCategories, (item) => item.category_id === node.category_id);
        this.setState({
            productCategories: updatedCategories,
        });
    }

    pushUpdatedList = () => {
        let list = this.mapCategoryObject(this.state.productCategories)
        this.handleAddProductCategory(list)
    }
    disableScroll = () => {
        let scrollTop = document.documentElement.scrollTop;
        let scrollLeft = document.documentElement.scrollLeft;
        window.onscroll = function () {
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    enableScroll = () => {
        window.onscroll = function () {
        };
    }

    pushNewCategory = (value, categoryId) => {
        let newCategoryToPushListClone = clone(this.state.newCategoryToPushList);
        newCategoryToPushListClone[categoryId] = value;
        this.disableScroll()
        this.setState({newCategoryToPushList: newCategoryToPushListClone});

        setTimeout(() => {
            this.enableScroll();
            this[`input-${categoryId}`].focus();
        }, 11)
    }

    filterCategories = (categories, searchItem, groupKey) => {
        let categoriesToFilter = groupKey ? categories[groupKey] || [] : categories;
        if (searchItem && searchItem.length > 0) {
            let filteredList = categoriesToFilter.filter(item => item.toLowerCase().includes(searchItem.toLowerCase()))
            return filteredList.length === 1 && filteredList[0].toLowerCase() === searchItem.toLowerCase() ? [] : filteredList;
        } else {
            return categoriesToFilter
        }

    }


    render() {
        const {
            productCategories,
            categoryToPush,
            newCategoryToPushList,
            expandAllChildren,
            categoryLevels,
            disableSubmitAction,
            categoryDrop,
            subCategoryDrop,
        } = this.state;

        const Tree = ({data = [], node = {}}) => {
            return (<div className="d-tree">
                <ul className="d-flex d-tree-container flex-column">
                    {data.map((tree) => (<TreeNode node={tree}/>))}


                </ul>
            </div>);
        };

        const TreeNode = ({node}) => {
            const {expandAllChildren} = this.state;
            const [childVisible, setChildVisiblity] = useState(expandAllChildren);


            const hasChild = node.children.length > 0 ? true : false;

            return (<li className={`d-tree-node ${hasChild ? 'has-child' : ''}`}>
                <div className="d-flex">
                    {hasChild && (<div
                        className={`c-pointer d-inline d-tree-toggler ${childVisible ? "active" : ""}`}
                    >
                                <span className={"node-action-icon"}>
                                    <img className=""
                                         src={caretRightIcon}
                                         alt="indicator icon"
                                    />
                                </span>
                    </div>)}

                    <div className="col d-tree-head"
                         onClick={(e) => setChildVisiblity((v) => !v)}>
                            <span className={"node-icon"}>
                                 {!!hasChild && (<img className=""
                                                      src={folderIcon}
                                                      alt="folder icon"
                                 />)}
                                {!hasChild && (<img className=""
                                                    src={fileIcon}
                                                    alt="file icon"
                                />)}
                            </span>
                        <span className={"node-name"}>

                            {node.name}
                            </span>
                        {node.removable && (<button className={'node-remove-action'} onClick={() => {
                            this.excludeNodeFromList(node)
                        }}>
                            <img className=""
                                 src={removeIcon}
                                 alt="remove node"
                            />
                        </button>)}
                    </div>


                </div>
                <div className={"add-new-category-action"}>

                    {!((!!categoryToPush && !!node.category_id) && categoryToPush === node.category_id) && (node.depth < categoryLevels.length) && (
                        <span className={"btn add"} onClick={() => {
                            this.setState({categoryToPush: node.category_id})
                        }}> + add
                            </span>)}
                    {((!!categoryToPush && !!node.category_id) && categoryToPush === node.category_id) && (<div>
                        <Form.Row>
                            <Form.Group as={Col}
                                        controlId={`userSelector-input-${node.category_id}`}
                                        className="input-wrapper autocomplete-input-wrapper">
                                <Form.Control
                                    placeholder="Name"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        this.pushNewCategory(e.target.value, node.category_id);
                                    }}

                                    onFocus={() => {
                                        let tempSubCategoryDrop = cloneDeep(this.state.subCategoryDrop);
                                        if (!tempSubCategoryDrop[`input-${node.category_id}`] && !!this.filterCategories(this.subCategoriesListDataGrouped, newCategoryToPushList[node.category_id], node.name)) {
                                            tempSubCategoryDrop[`input-${node.category_id}`] = true;
                                            this.setState({subCategoryDrop: tempSubCategoryDrop});
                                        }
                                        setTimeout(() => {
                                            if (this[`input-${node.category_id}`]) {
                                                this[`input-${node.category_id}`].focus();
                                            }
                                        }, 12)
                                    }}
                                    onBlur={() => {
                                        let tempSubCategoryDrop = cloneDeep(this.state.subCategoryDrop);
                                        tempSubCategoryDrop[`input-${node.category_id}`] = false;
                                        setTimeout(() => {
                                            this.setState({subCategoryDrop: tempSubCategoryDrop});
                                        }, 123)

                                    }}
                                    ref={input => this[`input-${node.category_id}`] = input}
                                    value={newCategoryToPushList[node.category_id]}
                                />
                                {subCategoryDrop[`input-${node.category_id}`] && !!this.filterCategories(this.subCategoriesListDataGrouped, newCategoryToPushList[node.category_id], node.name) && (
                                    <div className={"category-drop"}>
                                        <div className="drop-list">
                                            {this.filterCategories(this.subCategoriesListDataGrouped, newCategoryToPushList[node.category_id], node.name).map((item, index) => (
                                                <div key={'category-drop-item' + index}
                                                     className="drop-list-item"
                                                     onClick={() => {
                                                         this.pushNewCategory(item, node.category_id);
                                                         let tempSubCategory = cloneDeep(this.state.subCategoryDrop)
                                                         tempSubCategory[`input-${node.category_id}`] = false;
                                                         this.setState({subCategoryDrop: tempSubCategory});
                                                         setTimeout(() => {
                                                             this[`input-${node.category_id}`].focus()
                                                         }, 222)
                                                     }}
                                                >
                                                    {item}
                                                </div>))}
                                        </div>
                                    </div>)}
                            </Form.Group>
                            <Form.Group as={Col} controlId="addUserButton" className="button-wrapper">

                                {(newCategoryToPushList[node.category_id] && newCategoryToPushList[node.category_id].length > 0) && (
                                    <button className={"btn add"}
                                            disabled={newCategoryToPushList[node.category_id]?.includes('"')}
                                            onClick={() => {
                                                this.pushNewChiChild(node, {
                                                    name: newCategoryToPushList[node.category_id], children: []
                                                });
                                                newCategoryToPushList[node.category_id] = ''
                                            }}> + add to category
                                    </button>)}
                                {newCategoryToPushList[node.category_id]?.includes('"') && (
                                    <p className={`error-label`}>Invalid character <b>"</b></p>)}
                            </Form.Group>


                        </Form.Row>
                    </div>)}
                </div>

                {hasChild && childVisible && (<div className="d-tree-content">
                    <ul className="d-flex d-tree-container flex-column">
                        <Tree node={node} data={node.children}/>
                    </ul>

                </div>)}

            </li>);
        };
        return (<div className='companyInfoBlock category-list-display-builder'>

            <div className={'category-header'}>
                <h3>Category Names</h3>
                <button className={'btn add'} onClick={() => {
                    this.setState({expandAllChildren: !this.state.expandAllChildren})
                }}>
                    {!!expandAllChildren ? 'Collapse' : 'Expand'} tree
                </button>
            </div>


            <div className={"tree-wrapper"}>
                <Tree data={this.mapCategoryObject(productCategories)}/>

                {!(categoryToPush === 'default') && (<button className={"btn add"} onClick={() => {
                    this.setState({categoryToPush: 'default'})
                }}> + add
                </button>)}
                {(categoryToPush === 'default') && (<div>
                    <Form.Row>
                        <Form.Group as={Col}
                                    controlId="userSelector"
                                    className="input-wrapper autocomplete-input-wrapper">
                            <Form.Control
                                placeholder="Name"
                                autoComplete="off"
                                onChange={(e) => {
                                    this.setState({
                                        filteredCategoriesList: this.filterCategories(this.categoriesListData, e.target.value),
                                        newCategoryToPush: e.target.value,
                                    });
                                }}
                                value={this.state.newCategoryToPush}
                                onFocus={() => {
                                    this.setState({categoryDrop: true});
                                }}
                                onBlur={() => {
                                    setTimeout(() => {
                                        this.setState({categoryDrop: false});
                                    }, 234)
                                    //
                                }}
                            />

                            {categoryDrop && (<div className={"category-drop"}>
                                <div className="drop-list">
                                    {this.filterCategories(this.categoriesListData, this.state.newCategoryToPush).map((item, index) => (
                                        <div key={'category-drop-item' + index}
                                             className="drop-list-item"
                                             onClick={() => {
                                                 this.setState({
                                                     newCategoryToPush: item,
                                                 });
                                                 // this.setState({categoryDrop: false});
                                             }}
                                        >
                                            {item}
                                        </div>))}
                                </div>
                            </div>)}

                        </Form.Group>
                        <Form.Group as={Col} controlId="addUserButton" className="button-wrapper">
                            <Button disabled={this.state.newCategoryToPush.length < 1 || this.state.newCategoryToPush?.includes('"')}
                                    onClick={() => {
                                        this.pushNewChiChild(null, {
                                            name: this.state.newCategoryToPush, children: []
                                        });
                                    }}> + add to category
                            </Button>
                            {this.state.newCategoryToPush?.includes('"') && (
                                <p className={`error-label`}>Invalid character <b>"</b></p>)}
                        </Form.Group>
                    </Form.Row>
                </div>)}
            </div>

            <div className={"category-action"}>
                <button className={"btn add"}
                        disabled={disableSubmitAction}
                        onClick={this.pushUpdatedList}>
                    Save Changes
                </button>
            </div>

        </div>)
    }
}

export default connect(null, {displayNotification})(ProductCategoriesNames);
