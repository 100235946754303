import React, { Component } from 'react';
import { connect } from "react-redux";
import TextInput from 'components/inputs/TextInput';
import TextArea from 'components/inputs/TextArea';
import FormDivider from 'components/dataCaptureFormComponents/FormDivider';
import FormDropdown from 'components/inputs/FormDropdown';
import { countries } from 'constants/countries';
import update from "actions/form/update";

class CompanyInformationForm extends Component {
  onChange = ({ name, value }) => {
    this.props.update({
      path: `company_information.${name}`,
      value
    });
  }

  render() {
    const values = this.props.company_information;
    return (
      <div className="company-information-form">
        <TextInput
          name="legal_company_name"
          label="Legal Company Name"
          onChange={this.onChange}
          value={values.legal_company_name}
        />
        <TextInput
          name="company_registration_number"
          label="Company Registration Number"
          onChange={this.onChange}
          value={values.company_registration_number}
        />
        <FormDivider />
        <TextArea
          name="main_office_company_address"
          label="Main Office Company Address"
          warningSign="Put each address on separate line"
          onChange={this.onChange}
          value={values.main_office_company_address}
          rows={3}
        />
        <TextInput
          name="main_office_city"
          label="City"
          onChange={this.onChange}
          value={values.main_office_city}
        />
        <FormDropdown
          name="main_office_country"
          label="Country"
          placeholder="Choose Country"
          onChange={this.onChange}
          options={countries}
          value={values.main_office_country}
        />
        <TextInput
          name="main_office_postcode_zipcode"
          label="Postcode/zipcode"
          onChange={this.onChange}
          value={values.main_office_postcode_zipcode}
        />
        <FormDivider />
        <TextArea
          name="billing_location_address"
          label="Billing Address"
          warningSign="Put each address on separate line"
          onChange={this.onChange}
          value={values.billing_location_address}
          rows={3}
        />
        <TextInput
          name="billing_location_address_city"
          label="City"
          onChange={this.onChange}
          value={values.billing_location_address_city}
        />
        <FormDropdown
          name="billing_location_address_country"
          label="Country"
          placeholder="Choose Country"
          onChange={this.onChange}
          options={countries}
          value={values.billing_location_address_country}
        />
        <TextInput
          name="billing_location_address_postcode_zipcode"
          label="Postcode/zipcode"
          onChange={this.onChange}
          value={values.billing_location_address_postcode_zipcode}
        />
        <FormDivider />
        <TextArea
          name="gcp_list"
          label="List of Global Company Prefixes"
          warningSign="Put each GCP on a separate line"
          onChange={this.onChange}
          value={values.gcp_list}
          rows={6}
        />
        <TextArea
          name="subsidiaries"
          label="Name of Subsidiaries that will be covered by this Xelacore license"
          warningSign="Put each name on separate line"
          onChange={this.onChange}
          value={values.subsidiaries}
          rows={6}
        />
        <TextArea
          name="trade_names"
          label="Trade Names or aliases"
          warningSign="Put each name on separate line"
          onChange={this.onChange}
          value={values.trade_names}
          rows={6}
        />
        <TextArea
          name="doing_business_as"
          label="Doing Business As"
          warningSign="Put each name on separate line"
          onChange={this.onChange}
          value={values.doing_business_as}
          rows={6}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    company_information: state.form.company_information
  }),
  { update }
)(CompanyInformationForm);
