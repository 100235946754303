import callHandler from 'actions/api/common/axiosCallHandler'
import config from 'config';

import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";

export default function nukeRecords(organisation_id) {
    return async dispatch => {
        try {
            let call = {
                method: 'delete',
                url: `${config.get('ADMIN_URL')}organisations/${organisation_id}/nuke`
            };

            let response = await callHandler(call);

            dispatch(displayNotification({
                message: 'All records have been cleared.',
                type: TYPE_SUCCESS,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            }));

            return response;
        } catch (error) {
            dispatch(displayNotification({
                message: 'Oops, something wrong. Please try again.',
                type: TYPE_ERROR,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            }));

            return error;
        }
    }
}