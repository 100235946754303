import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import AdminHeader from 'components/headers/AdminHeader';
import OrgHome from 'components/OrgHome'
import getOrg from 'actions/api/organisations/get';
import getValidations from 'actions/api/validations/orgValidation';
import updateOrg from 'actions/api/organisations/update';
import { displayNotification } from "actions/notifications";
import { DEFAULT_LOGIN_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";
import openSocket from 'socket.io-client';
import { getAuthStorage } from "utils/storage";

import deepmerge from 'deepmerge';
import reValidate from 'actions/api/validations/revalidate';
import Config from "../../config";
import setExports from 'actions/api/exports/setExports'
import setImports from 'actions/api/ingestions/setImports'
import { addRetailerListingExports, addRetailerListingIngestions } from 'actions/retailerListings'


const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

class OrganisationHomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOrg: {},
            validations: []
        }

        const authStorage = getAuthStorage();

        const socket = openSocket(Config.get('WSS_URL'), {
            path: '/v3/socket.io',
            auth: {
                token: authStorage.org_access_token
            }
        });

        socket.on('event', (event) => {
            if (event.name === 'OrganisationValidationCompleted' &&
                this.props.match.params.id === event.message.organisationId) {
                this.setState({ validations: [event.message] });
            }
            if (event.name === 'CatalogExportRequested' || event.name === 'CatalogExportStarted' || event.name === 'CatalogExportCompleted') {
                if (event.message.jobType === 'xelacore-retailer-listings') {
                    this.props.addRetailerListingExports([event.message])
                } else {
                    this.props.setExports([event.message])
                }
            }
            if (event.name === 'RegistrationRecordIngestionCreated' || event.name === 'RegistrationRecordIngestionCompleted') {
                if (event.message.destination === 'xelacore-retailer-listings') {
                    this.props.addRetailerListingIngestions([event.message])
                } else {
                    this.props.setImports([event.message])
                }
            }
        });
    };

    componentDidMount() {
        this.props.getOrg(this.props.match.params.id).then(selectedOrg => {
            this.setState({ selectedOrg });

            this.getValidationsFunction();
        });


    }

    getValidationsFunction(status) {
        return this.props.getValidations(this.props.match.params.id, status).then(response => {
            this.setState({ validations: response });
        });
    }

    handleOrgInfoUpdate = (update, merge = true, submit = false) => {

        const { selectedOrg = {} } = this.state;
        let updatedOrg = null
        if (merge) {
            updatedOrg = deepmerge.all([selectedOrg, update], { arrayMerge: overwriteMerge });
        } else {
            updatedOrg = { ...selectedOrg, ...update };
        }
        this.setState({ selectedOrg: updatedOrg });
        if (submit) {
            this.handleOrgInfoSubmit(updatedOrg);
        }
    }

    handleOrgInfoSubmit = (selectedOrg = null) => {
        if (!selectedOrg) {
            selectedOrg = this.state.selectedOrg || {};
        }

        updateOrg(selectedOrg.organisation_id, selectedOrg)
            .then(() => {
                this.props.displayNotification({
                    message: `Successfully updated organisation information`,
                    type: TYPE_SUCCESS,
                    timeout: DEFAULT_LOGIN_NOTIFICATION_TIMEOUT
                })
            }).catch(err => {
                this.props.displayNotification({
                    message: `Failed to update organisation information`,
                    type: TYPE_ERROR,
                    timeout: DEFAULT_LOGIN_NOTIFICATION_TIMEOUT
                })
            });
    }

    checkForErrors() {
        return this.props.reValidate(this.props.match.params.id).then(() => {
            setTimeout(() => {
                this.getValidationsFunction('completed');
            }, 1000);
        });
    }

    render() {
        const { selectedOrg = {}, validations } = this.state;
        return (
            <div>
                <AdminHeader selectedOrg={selectedOrg} />
                <div className='x-background'>
                    <Container fluid>
                        <OrgHome
                            validations={validations}
                            selectedOrg={selectedOrg}
                            revalidate={() => this.checkForErrors()}
                            handleOrgInfoUpdate={this.handleOrgInfoUpdate}
                            handleOrgInfoSubmit={this.handleOrgInfoSubmit}
                        />
                    </Container>
                </div>
            </div>
        );
    };
}

const mapDispatchToProps = ({
    getOrg,
    displayNotification,
    getValidations,
    reValidate,
    setExports,
    setImports,
    addRetailerListingExports,
    addRetailerListingIngestions,
})

export default connect(null, mapDispatchToProps)(OrganisationHomePage);
