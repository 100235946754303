import { setAuthStorage, clearAuthData } from "utils/storage";
import { displayNotification } from "actions/notifications";
import { fetchUserOrganisation, setUserOrganisation } from "actions/api/auth/login";
import { DEFAULT_LOGIN_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";
import { USER_REFRESH_TOKEN, USER_TOKEN } from "constants/api";

import cookies from 'js-cookie';

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

export default function () {
  return async dispatch => {
    try {
      const userToken = cookies.get(USER_TOKEN);
      const userRefreshToken = cookies.get(USER_REFRESH_TOKEN);

      if(!userToken || !userRefreshToken) {
        clearAuthData();
      }

      const { data: orgData = {} } = await fetchUserOrganisation(userToken);

      const { organisations } = orgData;
      let orgs = organisations.filter(o => (o.modules || []).includes('xelacore-admin')).sort(function (a, b) {
        return a.DepartmentName.localeCompare(b.DepartmentName);
      })

      if (orgs.length === 0) throw new Error('You do not belong to any organisations with admin access');

      //TODO: org selector?
      
      let adminOrg = orgs[0];

      if(adminOrg && adminOrg.organisation_id) {
        let orgAuthData = await setUserOrganisation({ orgId: adminOrg.organisation_id, refreshToken: userRefreshToken });

        if (!!orgAuthData && !!orgAuthData.access_token) {
          setAuthStorage({ org_access_token: orgAuthData.access_token, name: "Admin Person", rememberMe: false, selected_org_id: adminOrg.organisation_id, user_refresh_token: orgAuthData.refresh_token });

          return dispatch({
            type: AUTH_SUCCESS,
            payload: {
              user_refresh_token: userRefreshToken,
              org_access_token: orgAuthData.access_token,
              selected_org_id: adminOrg.organisation_id,
            }
          });
        }
      }
    } catch (err) {
      const message = err.message || 'Something went wrong, try again later';
      dispatch(displayNotification({
        message,
        type: TYPE_ERROR,
        timeout: DEFAULT_LOGIN_NOTIFICATION_TIMEOUT
      }));

      return dispatch({
        type: AUTH_FAILURE,
      });
    }
  };
}
