import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, categories) {
  let call = {
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/categories`,
    method: 'PATCH',
    data: { categories: categories },
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let addResp = await callHandler(call);
  return addResp;
}
