import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Container, Form} from 'react-bootstrap';
import {attributesTable} from './tableHeader';
import getLinkedAgenciesList from 'actions/api/brands/linked_agencies/list';
import linkLinkedAgent from 'actions/api/brands/linked_agencies/link';
import unlinkLinkedAgent from 'actions/api/brands/linked_agencies/unlink';
import CustomTableWithActions from 'components/CustomTableWithActions';
import EmployeesModal from 'components/EmployeesModal';
import {Multiselect} from "multiselect-react-dropdown";
import forEach from "lodash/forEach";
import {orderBy} from "lodash";
import listEmployees from 'actions/api/employees/list';

function globalStateMapper(globalState) {
    const {organisations} = globalState;
    return {
        organisations
    };
}

class LinkedAgencies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linkedListData: [],
            selectedLAgent: [],
            agencies: [{
                name: 'ag 1'
            }],
            employeesModal: false,
            employees: [],
            licensorObj: {}
        }
    };

    componentDidMount() {
        this.fetchLinkedListData();
    }

    fetchLinkedListData() {
        let {selectedOrg, brand} = this.props;
        let {organisation_id} = selectedOrg || {};

        getLinkedAgenciesList(organisation_id, brand.brand_id).then((brandAgentLinks) => {
            this.setState({linkedListData: orderBy(brandAgentLinks, [item => (item['agent_organisation_name'] || '').toLowerCase()], ['asc'])})
        }).catch(err => alert(err.message));
    }

    selectAgency(selectedLAgent, selectedItem) {
        this.setState({selectedLAgent});
    }

    linkSelectedAgent() {
        let {selectedOrg, brand} = this.props;
        let {organisation_id} = selectedOrg || {};
        const {selectedLAgent} = this.state;
        if (selectedLAgent && selectedLAgent.length > 0) {
            forEach(selectedLAgent, agent => {
                let agentData = {
                    brandId: brand.brand_id,
                    brandName: brand.brand,
                    agentOrganisationId: agent.organisation_id,
                    agentOrganisationName: agent.name
                }
                linkLinkedAgent(organisation_id, agentData).then(() => {
                    this.fetchLinkedListData();
                });
            })
        }
        this.setState({selectedLAgent: []});
    }


    filterOrgAcencies(orgList) {
        const {linkedListData} = this.state;
        let mappedLinkedID = linkedListData.map(item => !!item && item.agent_organisation_id)
        return orgList.filter(organisation => organisation.type === 'agent' && !mappedLinkedID.includes(organisation.organisation_id))
    }

    unlinkAgent = (agent) => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};

        unlinkLinkedAgent(organisation_id, agent.link_id).then(() => {
            this.fetchLinkedListData();
        })
            .catch(err => alert(err.message));
    }

    fetchEmployees(licensor) {
        let { selectedOrg } = this.props;
        let { organisation_id } = selectedOrg || {};

        listEmployees(organisation_id)
            .then((data) => {
                this.setState({ employees: data, licensorObj: licensor })
                this.toggleEmployeesModal();
            })
            .catch(err => alert(err.message));
    }

    toggleEmployeesModal() {
        this.setState({ employeesModal: !this.state.employeesModal })
    }

    render() {
        const { linkedListData, selectedLAgent, licensorObj, employees, employeesModal } = this.state;
        const {organisations, selectedOrg} = this.props;
        return (
            <Container fluid className="p-0">
                <div>
                    <Form>
                        <Form.Row>
                            <Col lg>
                                <Form.Group controlId="orgLinkSelect">
                                    <Form.Label>Select Agency to link</Form.Label>
                                    <Multiselect
                                        options={this.filterOrgAcencies(organisations.list)} // Options to display in the dropdown
                                        selectedValues={selectedLAgent} // Preselected value to persist in dropdown
                                        onSelect={(values) => this.selectAgency(values)} // Function will trigger on select event
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs>
                                <Button className="add mt-4"
                                        onClick={() => {
                                            this.linkSelectedAgent()
                                        }}>
                                    Link
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                </div>

                <CustomTableWithActions
                    data={linkedListData}
                    tableheader={attributesTable}
                    links={{
                        'agent_organisation_id': {
                            name: 'agent_organisation_id',
                            type: 'organisations',
                            className: 'link'
                        }
                    }}
                    actions={[
                        {
                            name: "Unlink",
                            className: 'delete',
                            onClick: this.unlinkAgent,
                            message: "You are about to Unlink Agent from this brand",
                            confirmation: true
                        },
                        selectedOrg.type === 'licensor' ? { name: "Notify Licensor", className: 'notify', onClick: (l) => this.fetchEmployees(l), confirmation: false } : { name: undefined }
                    ]}
                />

                <EmployeesModal isAgent={true} show={employeesModal} togglePopUp={() => this.toggleEmployeesModal()} licenseeObj={licensorObj} employees={employees} selectedOrg={selectedOrg}></EmployeesModal>
            </Container>
        );
    }
}

export default connect(
    globalStateMapper,
    null
)(LinkedAgencies);
