import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId) {
  let url  = `${Config.get('ADMIN_URL')}organisations/${organisationId}/categories`
  let call = {
    method: "get",
    url: url
  }
  let { categories,category_levels } = await callHandler(call);
  return { categories,category_levels };
}
