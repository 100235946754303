import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId,agentLinkId) {
  let call = {
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/brand/link-agent/${agentLinkId}`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let deleteResp = await callHandler(call);
  return deleteResp;
}