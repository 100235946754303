import deepmerge from 'deepmerge';
import { get } from 'lodash';
import { boolean } from 'boolean';
import baseConfg from './base';

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
let configObj = deepmerge.all([baseConfg, process.env], { arrayMerge: overwriteMerge });

export default {
  get: (configPath, defaultValue = undefined) => get(configObj, configPath, defaultValue),
  getUrl: (configPath, defaultValue = undefined) => get(configObj, configPath, defaultValue),
  getBool: (configPath, defaultValue = undefined) => boolean(get(configObj, configPath, defaultValue)),
  getInt: (configPath, defaultValue = undefined) => parseInt(get(configObj, configPath, defaultValue), 10),
  getAndSplit: (configPath, splitKey, defaultValue = undefined) => get(configObj, configPath, defaultValue).split(splitKey),
  getServiceUrl: (serviceName) => `${get(configObj, 'API_BASE_URL')}${get(configObj, `${serviceName.toUpperCase()}_URL`)}`
}