import React from 'react';
import './FormDivider.css';

function FormDivider() {
  return (
    <div className="form-divider" />
  );
}

export default FormDivider;
