import React, { Component } from 'react';
import { Form, Col, Container, Button, Modal,  } from 'react-bootstrap';

import UserSelector from 'components/UserSelector'

class AddOrgModal extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selectedUser: null
    }

  }

  onUpdateOrgDetails = (newAddOrgData) => {
    const { addOrgData } = this.props;
    this.props.handleUpdateAddOrgData({ ...addOrgData, ...newAddOrgData });
  }

  render() {
    const { addOrgData = {}, show = false, togglePopUp, users = [] } = this.props;
    return (
      <div className="x-add-org-modal">
        <Modal show={show} onHide={togglePopUp}>
          <Modal.Header closeButton>
            <Modal.Title>Adding {addOrgData.type} Organisation </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container fluid>
            <UserSelector onUserSelect={(selectedUser) => this.onUpdateOrgDetails({ selectedUser })} users={users}></UserSelector>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control 
                    placeholder="Enter company name" 
                    onChange={(e) => { this.onUpdateOrgDetails({ newCompanyName: e.target.value })}} 
                    value={addOrgData.name}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={togglePopUp}>
              Close
            </Button>
            <Button variant="primary" onClick={this.props.handleAddOrganisation}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AddOrgModal;
