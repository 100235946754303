import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/inputs/Checkbox';
import './CheckboxGroup.css';

class CheckboxGroup extends Component {
  static defaultProps = {
    onSelectAll: () => { },
    onUnselectAll: () => { },
    onChange: () => { },
    options: [],
    selected: [],
    withSelectAll: false
  }

  static propTypes = {
    onSelectAll: PropTypes.func,
    onUnselectAll: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })),
    selected: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    withSelectAll: PropTypes.bool
  }

  get optionsState() {
    const { options, selected } = this.props;
    return options.map((optionProps) => ({
      ...optionProps,
      isChecked: selected.includes(optionProps.value)
    }));
  }

  get isAllSelected() {
    return !this.optionsState.some(({ isChecked }) => !isChecked);
  }

  onAllClick = () => {
    if (!this.isAllSelected) {
      this.props.onSelectAll();
    } else {
      this.props.onUnselectAll();
    }
  }

  onRemove = () => {
    this.props.onRemove(this.props.selected);
  }

  render() {
    const { name, onChange, selected, withSelectAll } = this.props;
    const checkboxes = this.optionsState.map(({ label, value, subLabels, isChecked }) => {
      const checkboxProps = {
        label, name, value, onChange, subLabels,
        ...(selected &&
          onChange
          ? { checked: isChecked }
          : { defaultChecked: isChecked }
        )
      };
      return (
        <div key={value} className={`checkbox-group-option ${isChecked ? 'is-checked' : ''}`}>
          <Checkbox {...checkboxProps} />
        </div>
      );
    });
    return (
      <div className="checkbox-group-container">
        <div className="checkbox-group-controls">
          {!!checkboxes.length && (
            <div className="checkbox-group checkbox-group-all">
              <div className="checkbox-group-option checkbox-group-option-all">
                {
                  !!this.props.selected.length ? (
                    <button
                      type="button"
                      className="checkbox-group-all-sign remove-button"
                      onClick={this.onRemove}
                    >
                      Remove selected
                    </button>
                  ) : (<span />)
                }
                {
                  withSelectAll && (
                    <button
                      type="button"
                      className="checkbox-group-all-sign"
                      onClick={this.onAllClick}
                    >
                      {this.isAllSelected ? 'Unselect All' : 'Select All'}
                    </button>
                  )
                }
              </div>
            </div>
          )}
        </div>
        <div className="checkbox-group checkbox-group-list">
          {checkboxes}
        </div>
      </div>
    )
  }
}

export default CheckboxGroup;
