import React, { Component } from 'react';
import { connect } from "react-redux";
import { FormControl, Container, Button, Row, InputGroup} from 'react-bootstrap';
import searchIcon from 'assets/icons/search.svg';

class FilterBar extends Component {

  // constructor(props) {
  //   super(props);
  // }

  updateSearch = (event, tt) => {
    this.props.onUpdateFilter({ search: event.target.value });
  }

  render() {
    return (
      <div className="x-filter-bar">
        <Container>
          <Row>
              <InputGroup>
                <img className="x-filter-bar-search-icon" src={searchIcon} alt="Search icon" />
                <FormControl
                  autoFocus={true}
                  placeholder="Search Users"
                  className="x-filter-bar-search-box"
                  id='x-filter-bar-search-box'
                  onChange={this.updateSearch}
                />
                <Button className="add mr-0" onClick={this.props.onToggleAddUserPopup}>+ New User</Button>
              </InputGroup>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(
  null,
  null
)(FilterBar);
