import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Container, Button, Col } from 'react-bootstrap';
import listLinkedBrands from 'actions/api/linkedBrands/list';
import addAlias from 'actions/api/aliases/create';
import deleteAlias from 'actions/api/aliases/delete';
import {LinkedBrandsTable} from './tableheader'

import CustomTableWithActions from 'components/CustomTableWithActions';
import ErrorAlert from 'components/ErrorAlert';

function globalStateMapper(globalState) {
  return {};
}

class LinkedBrands extends Component {
  constructor(props){
		super(props);
		this.state = {
          linkedBrands: [],
      name: "",
      type: "Trade Name",
    }
  };

  componentDidMount() {
    this.fetchAliases();
  }

  fetchAliases = () => {
    let { selectedOrg = {} } = this.props;
    let { organisation_id,agent_organisation_id } = selectedOrg;

    listLinkedBrands(organisation_id,agent_organisation_id)
    .then((data) => {
      this.setState({ linkedBrands: data })
    })
    .catch(err => alert(err.message));
  }



  render() {
    const { errors, revalidate } = this.props;
    const { linkedBrands } = this.state;
    return (
      <div className="Aliases data-capture-section">
        <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>

        <Container fluid className="">
        <h2>Linked Brands ({linkedBrands.length})</h2>

          <CustomTableWithActions
            data={linkedBrands}
            tableheader={LinkedBrandsTable}
            />
        </Container>
      </div>
    );
  }
}

export default connect(
  globalStateMapper,
  { addAlias }
)(LinkedBrands);
