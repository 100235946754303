import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralAuthWrapper from 'components/wrappers/GeneralAuthWrapper';
import DataCapturePageNav from 'components/dataCaptureFormComponents/DataCapturePageNav';
import form from 'reducers/form';
import erase from 'actions/form/erase';
import './FormContent.css';

class FormContent extends Component {
  constructor(props) {
    super(props);
    const { store } = props;
    store.injectReducer('form', form);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit();
  }

  componentWillUnmount() {
    this.props.erase();
    this.props.store.ejectReducer('form');
  }

  render() {
    const { children, title } = this.props;
    const wrappedChildren = children.map(child => (
      <div
        key={child.props.id}
        className="data-capture-page-form-wrapper-content-block"
        id={child.props.id}
      >{child}</div>
    ));
    const menuItems = children.map(child => (
      { label: child.props.label, id: child.props.id }
    ))
    return (
      <GeneralAuthWrapper>
        <form className="data-capture-page-form-wrapper-main" onSubmit={this.handleSubmit}>
          <div className="data-capture-page-form-wrapper-nav">
            <DataCapturePageNav
              title={title}
              items={menuItems}
            />
          </div>
          <div className="data-capture-page-form-wrapper-content">
            {wrappedChildren}
          </div>
        </form>
      </GeneralAuthWrapper>
    );
  }
}

export default connect(null, { erase })(FormContent);
