import React from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { getFileDownloadUrl, getRejectedRecordsFileDownloadUrl, downloadFromUrl } from 'actions/api/organisations/exportUpdate'

export default function DataTable(props) {

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Date</th>
            {(props.takesLicensor) && <th>Licensor</th>}
            <th>File</th>
            {props.otherCounts && (<th>Submitted</th>)}
            {props.otherCounts && (<th>Saved</th>)}
            {(props.takesRecordsRejected) && <th>Rejected/Error</th>}
            <th>User</th>
            <th>Progress</th>
          </tr>
        </thead>
        <tbody>
          {
            props.entries.map((entry, idx) => {
              let downloadLink = <span className='status pending'>pending</span>;
              let recordsRejectedLink = <span className='status pending'>pending</span>;
              if (entry.status === 'completed') downloadLink =
                <span className="link-text" onClick={
                  () => getFileDownloadUrl((props.isLicensor ? entry.licensorOrganisationId : entry.licenseeOrganisationId) || entry.organisationId, 'private', entry.sourceInfo.category, entry.sourceInfo.filename || entry.sourceInfo.path)
                    .then(url => downloadFromUrl(url))
                }>
                  Download
                </span>

              if (entry.status === 'completed') recordsRejectedLink =
                <span className="link-text" onClick={
                  () => {
                    return entry.recordsRejected > 0 && getRejectedRecordsFileDownloadUrl(entry.ingestionId, (props.isLicensor ? entry.licensorOrganisationId : entry.licenseeOrganisationId), entry.sourceInfo.filename || entry.sourceInfo.path)
                      .then(url => downloadFromUrl(url))
                  }
                }>
                  {(entry.recordsRejected > 0) ? <span>{entry.recordsRejected} Records </span> : <span> - </span>}
                </span>

              if (entry.status === 'failed') downloadLink = <span>-</span>;
              const licensorOrganisation = props.takesLicensor && (props.organisations[entry.licensorOrganisationId] || props.organisations[entry.itemId] || { name: 'Unrecognised Organisation' })

              return (
                <tr key={idx}>
                  <td><span>{moment(entry.createdAt).format('YYYY-MM-DD')}</span></td>
                  {(props.takesLicensor) && <td><span>{licensorOrganisation.name}</span></td>}
                  <td>{downloadLink}</td>
                  {(props.otherCounts) && <td>{entry.recordsSubmitted}</td>}
                  {(props.otherCounts) && <td>{entry.recordsSavedPg}</td>}
                  {(props.takesRecordsRejected) && <td>{recordsRejectedLink}</td>}
                  <td><span>{entry.userEmail}</span></td>
                  <td><span className={`status ${entry.status}`}>{entry.status}</span></td>
                </tr>
              )
            })
          }
        </tbody>

        {!props.entries.length && (
          <tbody>
            <tr>
              <td colSpan={7}>
                <div className='empty-placeholder'>No data available</div>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  )
}
