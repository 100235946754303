import Config from 'config'
import axios from 'axios';

export default async function(organisationId, name) {
  try {
    return axios ({
      method: "delete",
      url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/myFiles/remove/${name}`,
      headers: {
          Accept: '*'
      }
    });
  } catch (err) {
    console.error('Error downloading file', err);
    return;
  }
}