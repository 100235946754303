import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form, Container, Button, Col} from 'react-bootstrap';
import {licenseesorTable} from './tableheader'
import CustomTableWithActions from 'components/CustomTableWithActions';
import linkToAgent from 'actions/api/agentLink/create';
import getAgentLinkList from 'actions/api/agentLink/list';
import deleteLinkedAgent from 'actions/api/agentLink/delete';
import ErrorAlert from 'components/ErrorAlert';
import Select from "react-select";
import getLicensorList from "actions/api/links/list";
import getBrandList from "actions/api/brands/list";
import getLinkedAgenciesList from 'actions/api/brands/linked_agencies/list';

function globalStateMapper(globalState) {
    const {organisations = {}} = globalState;
    return {
        organisations: organisations.list,
    };
}

class LinkToAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisations: [],
            selectedLinkOrgs: [],
            linkToAgentList: [],
            currentSelectedLicensor: null
        }
        this.onSelectLinkOrg = this.onSelectLinkOrg.bind(this);
        this.handleLinkBrandToAgent = this.handleLinkBrandToAgent.bind(this);
        this.handleDeleteOrgLink = this.handleDeleteOrgLink.bind(this);
        this.fetchLinks = this.fetchLinks.bind(this);
        this.removeLinkOrg = this.removeLinkOrg.bind(this);
        this.selectLinkOrg = this.selectLinkOrg.bind(this);
    };

    componentDidMount() {
        this.fetchLinks();
        this.fetchLicensors();
    }


    fetchLicensors = () => {
        let {selectedOrg} = this.props;
        let {organisation_id, type} = selectedOrg || {};

        return getLicensorList(organisation_id, type)
            .then((data) => {
                this.setState({licensorList: data});
            });
    }
    fetchBrandList = (licensor) => {
        if (licensor && licensor.licensor_organisation_id) {
            return getBrandList(licensor.licensor_organisation_id)
                .then((data) => {
                    this.setState({brandList: data})
                });
        }
    }

    filterOrgAcencies(brand) {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};

        getLinkedAgenciesList(organisation_id, brand.brand_id).then((brandAgentLinks) => {
            const excludeAgent = this.state.linkToAgentList.filter(linkedItem =>
                linkedItem.brandId === this.state.currentSelectedBrand.brand_id).map(item =>
                item.agentOrganisationId);
            this.setState({agentLinkList: brandAgentLinks.filter(agent=>!excludeAgent.includes(agent.agent_organisation_id))});
        }).catch(err => alert(err.message));
    }

    fetchLinks() {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        return getAgentLinkList(organisation_id)
            .then((data) => {
                this.setState({linkToAgentList: data})
            });
    }

    handleLinkBrandToAgent() {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        let {currentSelectedAgent, currentSelectedBrand,currentSelectedLicensor} = this.state;

        let dataToSend = {
            "brandId": currentSelectedBrand.brand_id,
            "organisationName": selectedOrg.name,
            "agentOrganisationName": currentSelectedAgent.agent_organisation_name,
            "agentOrganisationId": currentSelectedAgent.agent_organisation_id
        }

        linkToAgent(organisation_id, dataToSend).then(() => {
            this.fetchLinks();
            this.setState({currentSelectedLicensor: null});
            this.setState({currentSelectedAgent: null});
            this.setState({currentSelectedBrand: null});
        })
    }

    handleDeleteOrgLink(link) {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        return deleteLinkedAgent(organisation_id, link.linkId).then((data) => {
            this.fetchLinks();
        });
    }

    onSelectLinkOrg(e) {
        let selectedLinkOrg = JSON.parse(e.target.value);
        this.setState({selectedLinkOrg});
    }

    selectLinkOrg(selectedLinkOrgs, selectedItem) {
        this.setState({selectedLinkOrgs});
    }

    removeLinkOrg(selectedLinkOrgs, removedItem) {
        this.setState({selectedLinkOrgs});
    }

    disableLinkAction() {
        const {currentSelectedLicensor, currentSelectedAgent, currentSelectedBrand} = this.state;
        let licensorSelected = currentSelectedLicensor && currentSelectedLicensor.licensor_organisation_id;
        let brandSelected = currentSelectedBrand && currentSelectedBrand.brand_id;
        let agentSelected = currentSelectedAgent && currentSelectedAgent.agent_organisation_id;
        return !licensorSelected ? 1 : (!brandSelected ? 2 : (!agentSelected ? 3 : 4))
    }

    render() {
        const {linkToAgentList = []} = this.state;
        const {selectedOrg, organisations, errors, revalidate} = this.props;

        return (
            <div className="LinkToLicenseesor data-capture-section">
                <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>

                <Container fluid>
                    <h2>Agent Link</h2>
                    <Form>
                        <Form.Row>
                            <Col lg>

                                <Form.Group controlId="licensorSelector">
                                    <Select
                                        onChange={(e) => {
                                            this.setState({currentSelectedLicensor: e,
                                                currentSelectedAgent: null,
                                                currentSelectedBrand: null,
                                                brandList:[]});
                                            this.fetchBrandList(e);
                                        }}
                                        options={this.state.licensorList}
                                        getOptionSelected={(option, value) => {
                                            return option.licensor_organisation_id === value.licensor_organisation_id
                                        }
                                        }
                                        placeholder={'Select Licensor'}
                                        value={this.state.currentSelectedLicensor}
                                        getOptionLabel={option => option.licensor_organisation_name}
                                        getOptionValue={(option) => option.licensor_organisation_id}
                                    />
                                </Form.Group>

                                <Form.Group controlId="brandSelector">
                                    <Select
                                        isDisabled={this.disableLinkAction() < 2}
                                        onChange={(e) => {
                                            this.setState({currentSelectedBrand: e,
                                                agentLinkList:[]});
                                            this.filterOrgAcencies(e)
                                        }}
                                        options={this.state.brandList}
                                        getOptionSelected={(option, value) => {
                                            return option.brand_id === value.brand_id
                                        }
                                        }
                                        placeholder={'Select Brand'}
                                        value={this.state.currentSelectedBrand}
                                        getOptionLabel={option => option.brand}
                                        getOptionValue={(option) => option.brand_id}
                                    />
                                </Form.Group>

                                <Form.Group controlId="agentSelector">
                                    <Select
                                        isDisabled={this.disableLinkAction() < 3}
                                        onChange={(e) => {
                                            this.setState({currentSelectedAgent: e})
                                        }}
                                        options={this.state.agentLinkList}
                                        getOptionSelected={(option, value) => {
                                            return option.agent_organisation_id === value.agent_organisation_id
                                        }
                                        }
                                        placeholder={'Select Agent'}
                                        value={this.state.currentSelectedAgent}
                                        getOptionLabel={option => option.agent_organisation_name}
                                        getOptionValue={(option) => option.agent_organisation_id}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <div className="col-button-wrapper">
                                    <Button className="add mb-3"
                                            disabled={this.disableLinkAction() < 4}
                                            onClick={this.handleLinkBrandToAgent}>
                                        Link
                                    </Button>
                                </div>

                            </Col>

                        </Form.Row>
                    </Form>
                    {
                        linkToAgentList && linkToAgentList.length > 0
                            ? <CustomTableWithActions
                                data={this.state.linkToAgentList}
                                tableheader={licenseesorTable}
                                links={{
                                    'agentOrganisationId': {
                                        name: 'agentOrganisationId',
                                        type: 'organisations',
                                        className: 'link'
                                    }
                                }}
                                actions={[
                                    {
                                        name: "Remove",
                                        className: 'delete',
                                        onClick: this.handleDeleteOrgLink,
                                        message: "You are about to UNLINK these 2 organisations, this will prevent data being shared between them",
                                        confirmation: true
                                    },
                                ]}
                            />
                            : <p>No Links to show</p>
                    }
                </Container>
            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    null
)(LinkToAgent);
