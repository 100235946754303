import React, {Component} from 'react';
import {Container, Nav} from 'react-bootstrap';
import {
    getPreSignedUrl,
    uploadFile,
    triggerIngest,
} from 'actions/api/organisations/exportUpdate';
import "filepond/dist/filepond.min.css";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faSpinner, faSync} from '@fortawesome/free-solid-svg-icons';
import ErrorAlert from 'components/ErrorAlert';
import LicensorLinkSelector from './components/LicensorLinkSelector';
import ExportSection from './components/ExportSection';
import UploadSection from './components/UploadSection';
import ImportSection from './components/ImportSection';
import {sortBy, uniqBy} from 'lodash'
import {connect} from "react-redux";

library.add(
    faSpinner,
    faSync
);

class ExportUpdate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            historyData: [],
            licensorHistoryData: [],
            selectedLicensor: {},
            selectedLicensorId: '',
            filteredHistoryData: [],
            currentPage: 1,
            itemsPerPage: 5,
            busyRefresh: false,
            downloadInProgress: false,
            hideFilePond: false,
            userId: '',
            pondId: new Date(),
            tab: 'export',
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.imports !== prevProps.imports || this.props.exports !== prevProps.exports) this.getUserList()
    }

    processFile = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        let {selectedOrg} = this.props;
        let {selectedLicensorId} = this.state;

        if (!selectedLicensorId) {
            alert('First select a licensor')
            abort('No Licensor Selected')
            return;
        }

        const request = new XMLHttpRequest();

        let sourceInfo;
        getPreSignedUrl(selectedOrg.organisation_id, 'private', 'catalog/import', 'csv')
            .then(presignedUrl => {
                sourceInfo = presignedUrl.sourceInfo;
                return presignedUrl
            })
            .then(presignedUrl => {
                return uploadFile(file, presignedUrl)
            })
            .then(() => triggerIngest(sourceInfo, selectedOrg.organisation_id, selectedLicensorId, 'xelacore-catalog', 'import', file.name || 'undefined'))
            .then(() => setTimeout(() => this.setState({pondId: new Date()}), 4000))
            .then(load(request.responseText), () => {
                error('oh no')
            })
    };

    handleLicensorLinkSelected = (e) => {
        this.setState({selectedLicensorId: e.licensor_organisation_id})
    };

    handleUserSelected = (e) => {
        this.setState({userId: e.userId})
    };

    getUserList = () => {
        const values = [...Object.values(this.props.imports || {}), ...Object.values(this.props.exports || {})]
        let mappedList = Object.values(values).map(item => {
            return {userId: item.userId, username: item.userEmail}
        })
        let userList = uniqBy(mappedList, 'userId');
        userList = sortBy(userList, 'username');
        userList.unshift({userId: '', username: 'View All'})
        this.setState({userList})
    }

    toggleView = (e, navtab) => {
        e.preventDefault();
        this.setState({tab: navtab});
    }

    render() {
        const {selectedOrg, errors, revalidate} = this.props;
        const {selectedLicensorId, userId, tab} = this.state

        return (
            <div className="export-update data-capture-section">
                <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>
                <Container fluid className="">

                    <Nav defaultActiveKey="export" className="mt--2 pr-0 mb-3">
                        <Nav.Item>
                            <Nav.Link className={`mr-2 pill ${tab === 'export' ? 'active' : ''}`}
                                      onClick={e => this.toggleView(e, 'export')}>
                                Export
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className={`mr-2 pill ${tab === 'update' ? 'active' : ''}`}
                                      onClick={e => this.toggleView(e, 'update')}>
                                Update
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>


                    {this.state.tab === 'export'
                        && (
                            <>

                                <LicensorLinkSelector selectedOrg={selectedOrg}
                                                      licensorOrganisationId={selectedLicensorId}
                                                      onChange={this.handleLicensorLinkSelected}/>
                                <ExportSection selectedOrg={selectedOrg}
                                               licensorOrganisationId={selectedLicensorId}
                                               userId={userId}/>

                            </>
                        )
                    }

                    {this.state.tab === 'update'
                        && (
                            <>
                                <LicensorLinkSelector selectedOrg={selectedOrg}
                                                      licensorOrganisationId={selectedLicensorId}
                                                      onChange={this.handleLicensorLinkSelected}/>

                                <UploadSection disabled={!selectedLicensorId} key={this.state.pondId}
                                               onProcess={this.processFile}/>


                                <ImportSection selectedOrg={selectedOrg}
                                               licensorOrganisationId={selectedLicensorId}
                                               userId={userId}/>
                            </>
                        )
                    }

                </Container>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        imports: state.exportUpdate.imports.byLicenseeOrganisationId[ownProps.selectedOrg.organisation_id],
        exports: state.exportUpdate.exports.byLicenseeOrganisationId[ownProps.selectedOrg.organisation_id],
    }
}

export default connect(mapStateToProps, null)(ExportUpdate);
