import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Nav, Container} from 'react-bootstrap';
import OrgContacts from './OrgContacts';
import OrgInfo from './OrgInfo';
import {omit} from 'lodash';
import ErrorAlert from 'components/ErrorAlert';
import placeholder from 'assets/placeholder.jpg';
import AmazonIdentifiers from "./AmazonIdentifiers";

function globalStateMapper(globalState) {
    return {};
}

class CompanyInformtion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'info',
        };
    };

    toggleView = (e, navtab) => {
        e.preventDefault(); // keep on page
        this.setState({tab: navtab});
    }

    render() {
        const {
            selectedOrg = {},
            handleOrgInfoUpdate,
            handleOrgInfoSubmit,
            handleContactSave,
            errors,
            revalidate
        } = this.props;
        const {information = {}, name = '', organisation_id = ''} = selectedOrg;
        const {contacts = {}} = information;

        // Here we turn contacts into an array for future proofing
        let pseudoId = 0;
        let contactsArray = Object.keys(contacts).map(type => {
            pseudoId += 1;
            return {
                ...contacts[type],
                type, //if it doesn't exist already
                contact_id: pseudoId
            };
        });
        return (

            <div className="company-informtion data-capture-section">
                <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>

                <Container fluid className="">
                    <div className='org-logo'>
                        <img
                            src={`https://assets.xelacore.io/org/${organisation_id}.png`}
                            onError={(event) => event.target.style.display = 'none'}/>

                        <img
                            src={placeholder}
                            onError={(event) => event.target.style.display = 'none'}/>
                    </div>

                    <h2>{name}</h2>
                    <Nav defaultActiveKey="info" className="mt--2 pr-0">
                        <Nav.Item>
                            <Nav.Link className="mr-2 pill" onClick={e => this.toggleView(e, 'info')}>Company
                                Information</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="mr-2 pill ml-0"
                                      onClick={e => this.toggleView(e, 'contacts')}>Contacts</Nav.Link>
                        </Nav.Item>
                        {selectedOrg.type === 'licensee' && (<Nav.Item>
                            <Nav.Link className="pill ml-0" onClick={e => this.toggleView(e, 'amazon_identifiers')}>Amazon
                                Identifiers</Nav.Link>
                        </Nav.Item>)}
                    </Nav>

                    {(() => {
                        switch (this.state.tab) {
                            case 'amazon_identifiers':
                                return (
                                    <AmazonIdentifiers
                                        information={information}
                                        organisationId={organisation_id}
                                        handleOrgInfoUpdate={handleOrgInfoUpdate}
                                        handleOrgInfoSubmit={handleOrgInfoSubmit}
                                    />
                                );

                            case 'contacts':
                                return (
                                    <OrgContacts
                                        contacts={contactsArray}
                                        handleOrgInfoUpdate={handleOrgInfoUpdate}
                                        handleOrgInfoSubmit={handleOrgInfoSubmit}
                                        handleContactSave={handleContactSave}
                                    />
                                );

                            case 'info':
                            default:
                                return (
                                    <OrgInfo
                                        information={omit(information, ['contacts'])}
                                        handleOrgInfoUpdate={handleOrgInfoUpdate}
                                        handleOrgInfoSubmit={handleOrgInfoSubmit}
                                        name={name}
                                        organisationId={organisation_id}
                                    />
                                );
                        }
                    })()}
                </Container>
            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    null
)(CompanyInformtion);