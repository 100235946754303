import React, {Component} from 'react';
import {Container, Form} from 'react-bootstrap';
import listLinks from "../../../../actions/api/links/list";
import Select from "react-select";

class LicensorLinkSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            licensorList: {},
            selected: '',
        }
    };

    componentDidMount() {
        this.fetchLicensors()
    }

    fetchLicensors = () => {
        let {selectedOrg} = this.props;
        let {organisation_id, type} = selectedOrg || {};

        return listLinks(organisation_id, type)
            .then((data) => {
                data.unshift({licensor_organisation_id: '', licensor_organisation_name: 'Please select...'})
                this.setState({licensorList: data})
            });
    }

    onChange = e => {
        this.setState({selected: e.licensor_organisation_id})
        this.props.onChange(e)
    }

    render() {

        const {licensorOrganisationId} = this.props;

        let defaultValue = this.state.licensorList && this.state.licensorList.length > 0 ?
            this.state.licensorList.filter(item => item.licensor_organisation_id === licensorOrganisationId)[0] : null;

        return (
            <Container>
                <Form className="select_licensor">
                    Select Licensor
                    <Form.Group controlId="licensorSelector">
                        <Select
                            onChange={(e) => {
                                this.props.onChange(e)
                            }}
                            options={this.state.licensorList}
                            getOptionSelected={(option, value) => {
                                return option.licensor_organisation_id === value.licensor_organisation_id
                            }
                            }
                            value={defaultValue}
                            getOptionLabel={option => option.licensor_organisation_name}
                            getOptionValue={(option) => option.licensor_organisation_id}
                        />
                    </Form.Group>
                </Form>
            </Container>
        )
    }
}

export default LicensorLinkSelector
