import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function (localOrganisationId, jobType) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/${localOrganisationId}/exports?filter[job_type]=${jobType}`
  }
  let response = await callHandler(call);
  return response;
}
