import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function (organisationId, licensorId, data) {
    let call = {
        url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/categories/mappings/${licensorId}`,
        method: 'put',
        data: {category_mappings: data},
        headers: {
            'Content-Type': 'application/json'
        },
    }
    let addResp = await callHandler(call);
    return addResp;
}
