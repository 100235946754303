import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataCaptureForm from 'components/dataCaptureFormComponents/DataCaptureForm';
import updateOrganization from 'actions/api/organization/update';
import getOrganization from 'actions/api/organization/get';

class LicenseeDataCapture extends Component {
  handleSubmit = () => {
    this.props.updateOrganization({
      account_type: 'licensee',
      _id: this.props.match.params.id
    });
  }

  componentDidMount() {
    this.props.getOrganization({
      _id: this.props.match.params.id
    })
  }

  render() {
    return (
      <DataCaptureForm title="Licensee" onSubmit={this.handleSubmit} />
    )
  }
}

export default connect(null, { updateOrganization, getOrganization })(LicenseeDataCapture);