import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisation_id,licensor_organisation_id) {
    let call = {
        method: "get",
        url: `${Config.get('ADMIN_URL')}organisations/${organisation_id}/ips/licensor/${licensor_organisation_id}`
    }
    let { ips } = await callHandler(call);
    return ips;
}
