import React, { Component } from 'react';
import SectionWrapper from 'components/dataCaptureFormComponents/SectionWrapper';
// import SubSectionDescription from 'components/dataCaptureFormComponents/SubSectionDescription';
import SubSectionWrapper from 'components/dataCaptureFormComponents/SubSectionWrapper';
// import DescriptionText from 'components/texts/DescriptionText';
import CompanyInformationForm from './CompanyInformationForm';

class CompanyInformation extends Component {
  render() {
    return (
      <SectionWrapper title="Company information">
        <SubSectionWrapper
          formComponent={<CompanyInformationForm />}
          // infoComponent={
          //   <SubSectionDescription>
          //     <DescriptionText>
          //       Lorem Ipsum is simply dummy text of the printing and
          //       typesetting industry. Lorem Ipsum has been the industry’s
          //       standard dummy text ever since the 1500s,
          //       when an unknown printer took a galley of type
          //       and scrambled it to make a type specimen book.
          //       It has survived not only five centuries,
          //       but also the leap into electronic
          //     </DescriptionText>
          //     <DescriptionText>
          //     Lorem Ipsum is simply dummy text of the printing and
          //     typesetting industry. Lorem Ipsum has been the
          //     industry’s standard dummy text ever since the 1500s,
          //     when an unknown printer took a galley of type and  
          //     </DescriptionText>
          //   </SubSectionDescription>
          // }
        />
      </SectionWrapper>
    );
  }
}

export default CompanyInformation;
