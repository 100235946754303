import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';

import AdminHeader from 'components/headers/AdminHeader';
import UserHome from 'components/UserHome'
import getUser from 'actions/api/users/get';
import { displayNotification } from "actions/notifications";
// import { DEFAULT_LOGIN_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";

// const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

class UserHomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: {}
        }
    };

    componentDidMount() {
       this.getUser();
    }

    getUser = () => {
        const { getUser, match } = this.props;

        return getUser(match.params.id).then(selectedUser => {
            this.setState({ selectedUser });
        });
    };

    render() {
        const { selectedUser = {} } = this.state;
        const { match } = this.props;

        return (
            <div>
                <AdminHeader selectedUser={selectedUser}/>
                <div className='x-background'>
                    <Container fluid>
                        <UserHome selectedUser={selectedUser}
                                  userId={match.params.id}
                                  getUser={() => this.getUser()}>
                        </UserHome>
                    </Container>
                </div>
            </div>
        );
    };
}

export default connect(null, { getUser, displayNotification })(UserHomePage);
