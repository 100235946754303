import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/aliases`
  }
  let { aliases } = await callHandler(call);
  return aliases;
}