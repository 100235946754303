import {
    LIST_ORGS_VALIDATIONS
  } from "actions/organisations";
  
  const getInitialState = () => ({
    validations: []
  });
  
  export default function validations(state = getInitialState(), action) {
    switch (action.type) {
      case LIST_ORGS_VALIDATIONS:
        return {
          ...state,
          ...action.payload
        };
      default:
        return state;
    }
  }
  