import React from 'react';
import './SecondaryButton.css';

function SecondaryButton({ type, text, onClick }) {
  return (
    <button className="secondary-button" type={type} onClick={onClick}>
      {text}
    </button>
  );
}

export default SecondaryButton;