import React, { Component } from 'react';
import { connect } from "react-redux";
import get from 'lodash/get';
import TextInput from 'components/inputs/TextInput';
import TextArea from 'components/inputs/TextArea';
import FormDropdown from 'components/inputs/FormDropdown';
import RadioButtonGroup from 'components/inputs/RadioButtonGroup';
import { countries } from 'constants/countries';
import update from "actions/form/update";
import './PersonInformationForm.css';

class PersonInformationForm extends Component {
  onChange = ({ name, value }) => {
    const { valuePrefix } = this.props;
    if (valuePrefix) {
      this.props.update({
        path: `contacts_information.${valuePrefix}.${name}`,
        value
      });
    } else {
      this.props.onUpdate({
        name, value
      });
    }
  }

  render() {
    const { valuePrefix, infoValues } = this.props;
    const values = valuePrefix
      ? get(this.props.contacts_information, valuePrefix, {})
      : infoValues;
    return (
      <div className="contacts-information-form-subsection">
        <RadioButtonGroup
          name="title"
          valuePrefix={valuePrefix || 'new-person'}
          options={[
            { label: 'Mr', value: `Mr` },
            { label: 'Mrs', value: `Mrs` }
          ]}
          selected={values.title}
          onChange={this.onChange}
        />
        <TextInput
          name="name"
          label="Name"
          value={values.name}
          onChange={this.onChange}
        />
        <TextInput
          name="email"
          label="Email"
          value={values.email}
          onChange={this.onChange}
        />
        <TextInput
          name="biz_phone"
          label="Biz phone"
          value={values.biz_phone}
          onChange={this.onChange}
        />
        <TextInput
          name="mobile_phone"
          label="Mobile Phone"
          value={values.mobile_phone}
          onChange={this.onChange}
        />
        <TextArea
          name="office_address"
          label="Office address"
          warningSign="Put each address on separate line"
          value={values.office_address}
          onChange={this.onChange}
          rows={3}
        />
        <TextInput
          name="city"
          label="City"
          onChange={this.onChange}
          value={values.city}
        />
        <FormDropdown
          name="country"
          label="Country"
          placeholder="Choose Country"
          onChange={this.onChange}
          options={countries}
          value={values.country}
        />
        <TextInput
          name="postcode_zipcode"
          label="Postcode/zipcode"
          onChange={this.onChange}
          value={values.postcode_zipcode}
        />
        <TextInput
          name="pa_name"
          label="PA Name"
          value={values.pa_name}
          onChange={this.onChange}
        />
        <TextInput
          name="pa_email"
          label="PA email"
          value={values.pa_email}
          onChange={this.onChange}
        />
        <TextInput
          name="pa_phone"
          label="PA phone"
          value={values.pa_phone}
          onChange={this.onChange}
        />
      </div>
    )
  }

}

export default connect(
  state => ({
    contacts_information: state.form.contacts_information
  }),
  { update }
)(PersonInformationForm);
