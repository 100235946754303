import React from 'react';
import HeadText from 'components/texts/HeadText';
import './DataCaptureTableWrapper.css';

function DataCaptureTableWrapper({ children, title }) {
  const numOfColumns = children.length;
  const tableColumns = children.map((child, i) => (
    <div
      key={i}
      className={`data-capture-table-wrapper-column-container column-container-${numOfColumns}`}
    >
      { child }
    </div>
  ));
  return (
    <div className="data-capture-table-wrapper-container">
    <HeadText>{ title }</HeadText>
    <div className="data-capture-table-wrapper">
      { tableColumns }
    </div>
  </div>
  );
}

export default DataCaptureTableWrapper;
