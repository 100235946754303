import React, { Component } from 'react';
import './DataCapturePageNavItem.css';

class DataCapturePageNavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInView: null
    };
  }

  // componentDidMount() {
  //   this.handleLoad();
  // }

  // handleLoad = () => {
  //   const { id } = this.props;
  //   this.sectionElement = document.getElementById(id);
  //   window.addEventListener('scroll', this.handleScroll);
  //   this.handleScroll();
  // }

  // onClick = () => {
  //   const elementToScroll = this.sectionElement;
  //   window.scroll({
  //     top: elementToScroll.offsetTop,
  //     behavior: 'smooth'
  //   });
  // }

  // handleScroll = () => {
  //   const elementRect = this.sectionElement.getBoundingClientRect();
  //   const isInView =
  //     (elementRect.top > 0 && elementRect.top < window.innerHeight)
  //     || (elementRect.top <= 0 && elementRect.height > -elementRect.top)
  //   this.setState({
  //     isInView
  //   })
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }

  render() {
    const { label } = this.props;
    const { isInView } = this.state;
    return (
      <button
        type="button"
        className={`data-capture-page-nav-item ${isInView ? 'in-view' : ''}`}
        onClick={this.onClick}
      >
        {label}
      </button>
    );
  }
}

export default DataCapturePageNavItem;
