import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, invite_token) {
  let call = {
    url: `${Config.get('ADMIN_URL')}admin/organisations/${organisationId}/employees/${invite_token}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let deleteResp = await callHandler(call);
  return deleteResp;
}