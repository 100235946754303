import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createReducer from "./reducers";

function configureStore() {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    createReducer(),
    composeEnhancer(applyMiddleware(thunk))
  );

  store.asyncReducers = {}

  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  }

  store.ejectReducer = (key) => {
    delete store.asyncReducers[key]
    store.replaceReducer(createReducer(store.asyncReducers));
  }

  return store;
};

export default configureStore();
