export const iconColors = {
  red: 'rgba(255,83,84, 1)',
  purple: 'rgba(176, 91, 255, 1)',
  yellow: 'rgba(255, 192, 91, 1)',
  orange: 'rgba(253, 182, 70, 1)',
  green1: 'rgba(191, 235, 159, 1)',
  green2: 'rgba(122, 189, 144, 1)',
  green3: 'rgba(3, 164, 136, 1)',
  blue: 'rgba(55, 163, 255, 1)',
  blank: 'rgba(0, 0, 0, 0)',
  default: '#9fb7d9'
};
