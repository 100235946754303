import axios from 'axios';
import Config from 'config';
import callHandler from 'actions/api/common/axiosCallHandler';

export default async function triggerNotification(emails, notificationName, organisationId = null) {
    const data = {
        emails,
        notification: notificationName
    };

    if (organisationId) {
        data.organisationId = organisationId;
    }

    const call = {
        method: "post",
        url: `${Config.get('ADMIN_URL')}trigger-notification`,
        data
    };

    try {
        const response = await callHandler(call);
        return response;
    } catch (error) {
        console.error('Error triggering notification:', error);
        throw error;
    }
}
