import React from 'react';
import {Button} from 'react-bootstrap';
import listAmazonIdentifiers from "../../../actions/api/amazonIdentifiers/list";

class Exports extends React.Component {
    downloadIdentifiers = async () => {
        const {selectedOrg} = this.props;
        const {organisation_id} = selectedOrg || {};

        try {
            const blob = await listAmazonIdentifiers(organisation_id);
            const url = window.URL.createObjectURL(new Blob([blob], {type: 'text/csv'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'amazon_identifiers.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            alert('Failed to download Amazon Identifiers');
        }
    }

    render() {
        return (
            <div>
                <h1>Exports</h1>

                <div className={'row marg-t'}>
                    <div className={'col-md-6'}>
                        <h4>Licensee Amazon Identifiers</h4>
                    </div>
                    <div className={'col-md-6'}><Button onClick={this.downloadIdentifiers}>Download Amazon
                        Identifiers</Button></div>

                </div>
            </div>
        );
    }
}

export default Exports;
