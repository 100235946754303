export const DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const displayNotification = (payload) => ({
  type: DISPLAY_NOTIFICATION,
  payload
});

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION
});
