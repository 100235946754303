import axios from 'axios';
import config from 'config';

export function exportRecords(licenseeOrganisationId) {
  return axios({
    method: "get",
    url: `${config.get('ADMIN_URL')}organisations/${licenseeOrganisationId}/export/retailer-listings`,
    params: {},
  })
}
