import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
export default async function (organisation_id,invitedUserEmail, invitedUserFirstName, invitedUserLastName, newUserPermissionCanInvite) {

    let dataToSend = {
        email_parameters: {
            web_url: `${Config.get('services').login}`
        },
        invites: [
            {
                information: {
                    first_name: invitedUserFirstName.trim(),
                    last_name: invitedUserLastName.trim(),
                },
                email: invitedUserEmail.trim(),
                role_id: "88c0a3ca-cbce-4b27-b390-f6a001ca129f",
            }
        ]
    };

    if (!!newUserPermissionCanInvite) {
        dataToSend.invites[0].permissions=JSON.parse(`{
            "access": true,
                "modules": {
                "registration": {
                    "can": [
                        "view",
                        "manage"
                    ],
                        "licensors": {},
                    "name": "registration"
                }
            },
            "system": {
                "users": {
                    "can": [
                        "invite",
                        "view",
                        "edit",
                        "delete"
                    ]
                }
            }
        }`);
    }

    let call = {
        url: `${Config.get('ADMIN_URL')}admin/organisations/${organisation_id}/employees`,
        method: 'post',
        data:dataToSend ,
        headers: {
            'Content-Type': 'application/json'
        },
    }
    let {user = {}} = await callHandler(call);
    return user;
}
