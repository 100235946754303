import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import LinkButton from 'components/buttons/LinkButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import DataCapturePageNavItem from './DataCapturePageNavItem';
import './DataCapturePageNav.css';

class DataCapturePageNav extends Component {
  static defaultProps = {
    items: []
  }

  constructor() {
    super();
    this.nav = React.createRef();
    this.navContainer = React.createRef();
    window.addEventListener('scroll', this.handleScrollStick, false);
  }

  handleScrollStick = () => {
    const navEl = this.nav.current;
    const navContainerEl = this.navContainer.current;
    const navContainerRect = navContainerEl.getBoundingClientRect();
    if (navContainerRect.top < 0) {
      navEl.classList.add('fixed');
    } else {
      navEl.classList.remove('fixed');
    }
  }

  onCancel = e => {
    this.props.history.goBack();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollStick, false);
  }

  render() {
    const { title, items, onSave, onSubmit } = this.props;
    const navItems = items.map(({ label, id }) => (
      <DataCapturePageNavItem
        key={label}
        label={label}
        id={id}
      />
    ));
    return (
      <div className="data-capture-page-nav-container" ref={this.navContainer}>
        <nav className="data-capture-page-nav" ref={this.nav}>
          <div className="data-capture-page-nav-title">{title}</div>
          <div className="data-capture-page-nav-content">
            <div className="data-capture-page-nav-list">
              {navItems}
            </div>
            <div className="data-capture-page-nav-controls">
              <div className="data-capture-page-nav-controls-section">
                <div className="data-capture-page-nav-controls-bottom-section-btn-container" />
                <div className="data-capture-page-nav-controls-bottom-section-btn-container">
                  <LinkButton
                    type="submit"
                    text="Save Data"
                    onClick={onSave}
                  />
                </div>
              </div>
              <div className="data-capture-page-nav-controls-section">
                <div className="data-capture-page-nav-controls-bottom-section-btn-container">
                  <SecondaryButton
                    type="button"
                    text="Cancel"
                    onClick={this.onCancel}
                  />
                </div>
                <div className="data-capture-page-nav-controls-bottom-section-btn-container">
                  <PrimaryButton
                    type="button"
                    text="Submit Data"
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default withRouter(DataCapturePageNav);
