import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";

export default function(organisationId, status) {
  let statusToSend = !!status ? '?status=' + status : ''

  return async dispatch => {
    try {
      let call = {
        method: "get",
        url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/validations${statusToSend}`
      }
      let validations = await callHandler(call);
      // Removed this from the global state for now, causing some form issues
      // dispatch(selectOrg(organisation));
  
      return validations;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error getting organisation',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}