import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import {displayNotification} from "../../../notifications";
import {DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR} from "../../../../constants/notifications";

export default async function (organisationId, brandId) {
    // let call = {
    //   method: "get",
    //   url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/brand/${brandId}/agents`
    // }
    // let { data } = await callHandler(call);
    // return data;

    let call = {
        method: "get",
        url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/brand/${brandId}/agents`
    }
    let {brandAgentLinks} = await callHandler(call);
    return brandAgentLinks;

}