import React from 'react';
import { NavLink } from 'react-router-dom';
import './ListNavBar.css';

function ListNavBar() {
  return (
    <div className="list-nav-bar">
      <NavLink to="/list/licensors">Licensors</NavLink>
      <NavLink to="/list/licensees">Licensees</NavLink>
    </div>
  );
}

export default ListNavBar;
