import React, { Component } from 'react';
import { Table, Container, Button, FormControl } from 'react-bootstrap';
import ButtonWithConfirm from 'components/ButtonWithConfirm';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import map from 'lodash/map';
import cloneDeep from "lodash/cloneDeep";
import { ReactComponent as AscIcon } from "assets/icons/sort-ascending-bold.svg";
import { ReactComponent as DescIcon } from "assets/icons/sort-descending-bold.svg";
import { ReactComponent as NeutralIcon } from "assets/icons/sort-neutre-bold.svg";
import { Link } from "react-router-dom";

class Employees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderByLabel: '',
            orderDirection: '',
            filteredData: props.data || [],
            filters: {}
        };
        this.filterInputRefs = {};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ filteredData: this.props.data });
        }
    }

    filterTableData = (field, value) => {
        const { data } = this.props;
        const newFilters = { ...this.state.filters, [field]: value };
        this.setState({ filters: newFilters });

        const filteredData = data.filter(item => {
            return Object.keys(newFilters).every(key => {
                if (!newFilters[key]) return true;
                return String(item[key]).toLowerCase().includes(newFilters[key].toLowerCase());
            });
        });

        this.setState({ filteredData });
    }

    clearFilter = (field) => {
        this.filterInputRefs[field].value = '';
        this.filterTableData(field, '');
    }

    toggleOrderList = (orderNewLabel) => {
        const { orderByLabel, orderDirection } = this.state;
        if (orderByLabel !== orderNewLabel) {
            this.setState({ orderByLabel: orderNewLabel, orderDirection: 'asc' });
        } else {
            let newOrderDirection = orderDirection === 'asc' ? 'desc' : (orderDirection === '' ? 'asc' : '');
            this.setState({ orderDirection: newOrderDirection });
        }
    }

    render() {
        const { orderByLabel, orderDirection, filteredData, filters } = this.state;
        const { tableheader, actions = [], switcher = [], onSelectBrand, selectedOrg, links, data } = this.props;
        const sortedHeaders = tableheader.sort((a, b) => a.order - b.order);
        let counter = 0;

        return (
            <div className="CustomTableWithActions">
                <Container fluid className="ml-0 pl-0">
                    {data && data.length > 0 ? (
                        <Table striped bordered hover size="sm" className="fixed-table">
                            <thead>
                            <tr>
                                {sortedHeaders.map(k => {
                                    const { label = '', field = '', sort = false, filter = false, width = '150px' } = k;
                                    return (
                                        <th key={field} style={{ width }}>
                                            <div className={`table-head-wrapper ${sort ? 'head-with-sort' : ''}`}>
                                                <span>{label}</span>
                                                {filter && (
                                                    <div className={`filter-input-box`}>
                                                        <FormControl
                                                            type="text"
                                                            placeholder="Filter"
                                                            className="x-filter-bar-search-box email-filter-box filter-box"
                                                            onChange={(event) => this.filterTableData(field, event.target.value || '')}
                                                            ref={ref => this.filterInputRefs[field] = ref}
                                                        />
                                                        <span
                                                            className={`filter-cleaner`}
                                                            onClick={() => this.clearFilter(field)}>✕</span>
                                                    </div>
                                                )}
                                                {sort && (
                                                    <i className={'order-icon'} onClick={() => this.toggleOrderList(field)}>
                                                        {field === orderByLabel ? (
                                                            <>
                                                                {orderDirection === 'asc' ? (<AscIcon />) :
                                                                    orderDirection === 'desc' ? (<DescIcon />) :
                                                                        (<NeutralIcon />)}
                                                            </>
                                                        ) : (
                                                            <NeutralIcon />
                                                        )}
                                                    </i>
                                                )}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {orderBy(filteredData,
                                [item => (item[orderByLabel] || '').toLowerCase()],
                                [orderDirection]).map(l => {
                                counter += 1;
                                return (
                                    <tr key={`${l[sortedHeaders[0].field]}_${counter}`}>
                                        {sortedHeaders.map(k => {
                                            const { field = '', link, orglink } = k;
                                            let subCounter = 0;

                                            if (field === 'brand' && !!link && selectedOrg.type === 'licensor') {
                                                return (
                                                    <td key={field}>
                                                        <a href="#" onClick={() => onSelectBrand(l)}>
                                                            {l[field]}
                                                        </a>
                                                    </td>
                                                );
                                            }

                                            if (field === 'actions') {
                                                return (
                                                    <td key={field} className='actions'>
                                                        {actions.map(({
                                                                          onClick,
                                                                          name = '',
                                                                          message = null,
                                                                          className = '',
                                                                          confirmation
                                                                      }) => {
                                                            subCounter += 1;
                                                            return (
                                                                <div key={subCounter}
                                                                     className='actionButtonsContainer'>
                                                                    {!!confirmation && !!name && (
                                                                        <ButtonWithConfirm className={className}
                                                                                           onClick={() => onClick(l)}
                                                                                           message={message}>{name}
                                                                        </ButtonWithConfirm>
                                                                    )}
                                                                    {!confirmation && !!name && (
                                                                        <Button className={className}
                                                                                variant='primary'
                                                                                onClick={() => onClick(l)}
                                                                                message={message}>{name}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </td>
                                                );
                                            }

                                            if (field === 'switcher') {
                                                return (
                                                    <td key={field}>
                                                        {switcher.map(({ onChange }) => {
                                                            subCounter += 1;
                                                            return (
                                                                <div key={subCounter}
                                                                     className='switcherContainer'>
                                                                    <span>switcher Here</span>
                                                                    <input onChange={(e) => onChange(e, l)} type="checkbox" />
                                                                </div>
                                                            );
                                                        })}
                                                    </td>
                                                );
                                            }

                                            if (includes(map(links, 'name'), field)) {
                                                return (
                                                    <td className="link" key={field}>
                                                        <a href={links[field].type === 'organisations' ? `/organisations/${l[field]}` : `/users/${l[field]}`}>{l[field]}</a>
                                                    </td>
                                                );
                                            }

                                            if (!!orglink) {
                                                return <td key={field}>
                                                    <a href={ `/organisations/${l[orglink]}`}>{l[field]}</a>
                                                </td>
                                            }

                                            return <td key={field}>{l[field]}</td>
                                        })}
                                    </tr>
                                );
                            })}
                            {data && data.length && Object.values(filters).some(f => f) && filteredData.length === 0 && (
                                <tr  className={'no-data'} >
                                    <td className={'no-data'} colSpan={sortedHeaders.length} style={{ textAlign: 'center' }}>
                                        No data available for the current filters.
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No data to show</p>
                    )}
                </Container>
            </div>
        );
    }
}

export default Employees;
