import React, {Component} from 'react';
import {connect} from "react-redux";
import reValidate from 'actions/api/validations/revalidate';
import {uniqBy} from 'lodash'

class ErrorAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    filterByTitle = (erroList) => {
        if (erroList && erroList[0] && erroList[0].problem) {
            return uniqBy(erroList, item => item.problem.title)
        } else {
            return []
        }
    }

    render() {
        const {errors, revalidate} = this.props;

        return (
            <div className='errors-wrapper container-fluid'>

                {!!errors && !!errors.length && this.filterByTitle(errors).length &&
                    this.filterByTitle(errors).map(error => {
                        return <div className='alert-error'>
                            {error.problem.title}
                        </div>
                    })}
            </div>
        );
    }
}

export default connect(
    null,
    {reValidate}
)(ErrorAlert);
