import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/brand/links`
  }
  let { brandLicenseeLinks } = await callHandler(call);
  return brandLicenseeLinks;
}