import axios from "axios";
import { apiRoutes } from "constants/api";

export const API_LIST_FETCHING = "API_LIST_FETCHING";
export const API_LIST_SUCCESS = "API_LIST_SUCCESS";
export const API_LIST_FAILURE = "API_LIST_FAILURE";

export default function ({ account_type }) {
  return dispatch => {
    dispatch({
      type: API_LIST_FETCHING
    });

    // add query param filtering if account_type was set
    const params = account_type ? { account_type } : {};

    return axios
      .get(apiRoutes.getList, { params })
      .then(({ data }) => {
        dispatch({
          type: API_LIST_SUCCESS,
          payload: data
        });
      })
      .catch(error => {
        dispatch({
          type: API_LIST_FAILURE,
          error
        });
      });
  };
}
