import Config from 'config';
import callHandler from 'actions/api/common/axiosCallHandler';
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";

export default function(organisationId) {
  return async dispatch => {
    try {
      let call = {
        url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/gtinwaiver`,
        method: 'get'
      }
      let gtin_waiver_list = await callHandler(call);
      return gtin_waiver_list;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error listing Gtin Waivers',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}
