import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { listUsers } from "actions/users";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";

export default function() {
  return async dispatch => {
    try {
      let call = {
        method: "get",
        url: `${Config.get('ADMIN_URL')}users`
      }
      let { users } = await callHandler(call);
      dispatch(listUsers(users));
  
      return users;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error listing users',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}