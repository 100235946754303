import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container } from 'react-bootstrap';
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faSync } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";

import ErrorAlert from 'components/ErrorAlert';
import {
    getPreSignedUrl,
    uploadFile,
    triggerIngest,
} from 'actions/api/organisations/exportUpdate';
import list from 'actions/api/ingestions/list'
import Pagination from 'components/lists/Pagination';
import DataTable from '../ExportUpdate/components/DataTable';
import { getPageItems, getPagesCount } from "utils/pagination";
import memoize from 'memoize-one';

library.add(
    faSpinner,
    faSync
);

function globalStateMapper(globalState) {
    const { organisations = {} } = globalState;
    return {
        organisations: organisations.list,
    };
}

class ConceptsUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            pondId: new Date(),
            auditList: [],
            currentPage: 1,
            itemsPerPage: 10,
            totalResults: 0
        }
    };

    componentDidMount() {
        this.listTable();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedOrg !== prevProps.selectedOrg || this.props.licensorOrganisationId !== prevProps.licensorOrganisationId) {
            this.listTable();
        }
    }

    listTable = () => {
        list('xelacore-concepts', 'insert', this.props.selectedOrg.organisation_id, this.props.licensorOrganisationId).then((list) => {
            this.setState({ auditList: list })
        })
    }

    refreshTable = () => {
        return this.listTable();
    }

    onPageChanged = (currentPage) => {
        this.setState({
            currentPage
        });
    };

    processFile = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        let { selectedOrg } = this.props;

        const request = new XMLHttpRequest();

        let sourceInfo;
        getPreSignedUrl(selectedOrg.organisation_id, 'private', 'concepts/ingest', 'csv')
            .then(presignedUrl => { sourceInfo = presignedUrl.sourceInfo; return presignedUrl })
            .then(presignedUrl => { return uploadFile(file, presignedUrl) })
            .then(() => triggerIngest(sourceInfo, null, selectedOrg.organisation_id, 'xelacore-concepts', 'insert', file.name || 'undefined'))
            .then(() => {setTimeout(() => this.setState({ pondId: new Date() }), 4000); this.listTable();})
            .then(load(request.responseText), () => { error('There was an error with your request.') })
    };

    paginateList = memoize(getPageItems);
    paginateCount = memoize(getPagesCount);

    render() {
        const { currentPage, auditList, itemsPerPage  } = this.state;
        const { selectedOrg, hideUpdate, errors, revalidate } = this.props;
        const paginatedList = this.paginateList(auditList, currentPage, itemsPerPage);

        return (
            <div className="export-update data-capture-section">
                <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>

                <Container fluid className="">
                    <h2>
                        {selectedOrg.name}
                    </h2>

                    {!hideUpdate && (
                        <>
                            <FilePond
                                key={this.state.pondId}
                                className="mb-4"
                                allowMultiple={false}
                                instantUpload={false}
                                allowRemove={true}
                                allowRevert={false}
                                allowReplace={true}
                                server={{
                                    process: this.processFile
                                }}
                            />

                            <div className="refresh-wrapper">
                                <button className="refresh-button" onClick={() => this.refreshTable()}><RefreshIcon width="25" height="25"/></button>
                            </div>

                            <DataTable
                                takesLicensor={false}
                                otherCounts={true}
                                organisations={this.props.organisations}
                                entries={paginatedList}
                                isLicensor={true}
                                takesRecordsRejected={true}
                            />

                            <Pagination
                                current={this.state.currentPage}
                                total={this.paginateCount(auditList, itemsPerPage)}
                                onPageChanged={this.onPageChanged}
                            />
                        </>

                    )}

                </Container>
            </div>
        );
    }
}
export default connect(
    globalStateMapper,
    null
)(ConceptsUpload);

