import { getSubDataObject, getNamePath, getSubDataPath } from "utils/table";
import { TABLE_UPDATE } from './index';

export default function ({ path, values, name }) {
  return (dispatch, getState) => {
    const { data } = getState().form;
    const subDataConfig = {
      path,
      data
    };
    const subData = getSubDataObject(subDataConfig);
    const valuePath = getNamePath({
      basePath: getSubDataPath(subDataConfig),
      name
    });
    const newValue = subData[name].filter(({ name }) => !values.includes(name));
    return dispatch({
      type: TABLE_UPDATE,
      payload: {
        valuePath,
        newValue
      },
    });
  }
};
