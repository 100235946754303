import React, { Component } from 'react';
import { connect } from "react-redux";
import TextInput from 'components/inputs/TextInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import update from "actions/form/update";
import PersonInformationForm from '../PersonInformationForm';
import plusSign from 'assets/icons/plus-sign.svg';
import './AddPersonInformation.css';

const defaultState = {
  isOpen: false,
  jobTitle: '',
  isJobTitleInvalid: false,
  personInformation: {
    title: 'Mr',
    name: '',
    email: '',
    biz_phone: '',
    mobile_phone: '',
    office_address: '',
    city: '',
    country: '',
    postcode_zipcode: '',
    pa_name: '',
    pa_email: '',
    pa_phone: ''
  }
}

class AddPersonInformation extends Component {
  state = {
    ...defaultState
  }

  openForm = () => {
    this.setState({
      isOpen: true
    });
  }

  closeForm = () => {
    this.setState({
      ...defaultState
    });
  }

  handleSave = () => {
    const { jobTitle } = this.state;
    const contactId = jobTitle.toLowerCase().replace(/ /g, '_');
    const existingAdditionalContacts = Object.keys(this.props.additional);
    if (!jobTitle || existingAdditionalContacts.includes(contactId)) {
      this.setState({
        isJobTitleInvalid: true
      });
      return;
    }

    const { personInformation } = this.state;

    this.props.update({
      path: `contacts_information.additional.${contactId}`,
      value: {
        ...personInformation,
        jobTitle,
        contactId
      }
    });

    this.closeForm();
  }

  handleFormUpdate = ({ name, value }) => {
    this.setState(state => ({
      ...state,
      personInformation: {
        ...state.personInformation,
        [name]: value
      }
    }));
  }

  handleJobTitleChange = ({ value }) => {
    this.setState({
      jobTitle: value
    });
  }

  render() {
    const { isOpen, personInformation, jobTitle, isJobTitleInvalid } = this.state;
    return (
      <div className="add-person-information">
        { !isOpen ? (
          <button onClick={this.openForm} className="add-person-information-button" type="button">
            <span className="add-person-information-button-plus">
              <img src={plusSign} alt="Plus sign" />
            </span>
            <span className="add-person-information-button-text">
              Add Another Contact
            </span>
          </button>
        ) : (
          <div className="add-person-information-form-container">
            <TextInput
              name="job_title"
              label="Job Title"
              onChange={this.handleJobTitleChange}
              value={jobTitle}
              notValid={isJobTitleInvalid}
            />
            <PersonInformationForm infoValues={personInformation} onUpdate={this.handleFormUpdate} />
            <div className="add-person-information-form-buttons">
              <PrimaryButton
                type="button"
                text="Add Contact"
                onClick={this.handleSave}
              />
              <SecondaryButton
                type="button"
                text="Cancel"
                onClick={this.closeForm}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    additional: state.form.contacts_information.additional
  }),
  { update }
)(AddPersonInformation);