import axios from "axios";
import {
  authHeader,
  unauthorizedHttpCode
} from "constants/api";
// import Config from 'config';
import { storageKeys } from "constants/storage";
import { getItem } from "utils/storage";
import { updateAccessToken } from "utils/storage";
import Config from 'config';
import { setUserOrganisation } from "actions/api/auth/login";
import { USER_REFRESH_TOKEN, USER_TOKEN } from "constants/api";


import { clearAuthData } from "utils/storage";

import cookies from 'js-cookie';

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

export const applyConfig = (store) => {
  axios.defaults.baseURL = Config.get('API_BASE_URL');
  // set token to header
  axios.interceptors.request.use(config => {
    const tokenName = storageKeys.org_access_token;
    const token = getItem(tokenName);
    const userToken = cookies.get(USER_TOKEN);
    const userRefreshToken = cookies.get(USER_REFRESH_TOKEN);

    if (token && !config.headers[authHeader]) {
      config.headers[authHeader] = `Bearer ${token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response: { status } = {}, config: currentRequest } = error;

      // logout user if we have unauthorized error
      if (status === unauthorizedHttpCode) {
        try {
          // Try and refresh the org token
          const { auth } = store.getState();
          const { user_refresh_token = '', selected_org_id = '' } = auth;

          if (!user_refresh_token || !selected_org_id) return clearAuthData();

          let orgAuthData = await setUserOrganisation({orgId: selected_org_id, refreshToken: user_refresh_token});

          updateAccessToken({ org_access_token: orgAuthData.access_token || '' });
          store.dispatch({
            type: AUTH_SUCCESS,
            payload: {
              org_access_token: orgAuthData.access_token || ''
            }
          });
          
          currentRequest.headers[authHeader] = `Bearer ${orgAuthData.access_token}`;
          return axios.request(currentRequest);
        } catch (err) {
          clearAuthData();
        }
      }
      return Promise.reject(error);
    });
};
