import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import UserAdminNavItem from './UserAdminNavItem';
import UserAdminNavHeader from './UserAdminNavHeader';

class UserAdminNav extends Component {
  static defaultProps = {
    items: []
  }

  render() {
    const { items, onNavSelect, selectedNavKey, selectedUser = {} } = this.props;
    const navItems = items.map(({key, props: { label } = {}}) => (
      <UserAdminNavItem
        key={key}
        label={label}
        id={key}
        selectedNavKey={selectedNavKey}
        onNavSelect={onNavSelect}
      />
    ));
    return (
      <div className="data-capture-page-nav-container" ref={this.navContainer}>
        <nav className="data-capture-page-nav" ref={this.nav}>
          <UserAdminNavHeader selectedUser={selectedUser}></UserAdminNavHeader>
          <div className="data-capture-page-nav-content">
            <div className="data-capture-page-nav-list">
              {navItems}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default withRouter(UserAdminNav);
