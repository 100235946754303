export const NUM_OF_PAGES = 7;

export const MAIN_SECTION_STATES = {
  '1': {
    afterPages: 2,
    lastPages: 3,
    dots: {
      before: false,
      after: true
    }
  },
  '2': {
    beforePages: 1,
    afterPages: 1,
    lastPages: 3,
    dots: {
      before: false,
      after: true
    }
  },
  '3': {
    firstPages: 1,
    beforePages: 1,
    afterPages: 1,
    lastPages: 2,
    dots: {
      before: false,
      after: true
    }
  },
  '4': {
    firstPages: 2,
    beforePages: 1,
    afterPages: 1,
    lastPages: 1,
    dots: {
      before: false,
      after: true
    }
  },
  BETWEEN: {
    firstPages: 1,
    beforePages: 1,
    afterPages: 1,
    lastPages: 1,
    dots: {
      before: true,
      after: true
    }
  },
  '-4': {
    firstPages: 1,
    beforePages: 1,
    afterPages: 1,
    lastPages: 2,
    dots: {
      before: true,
      after: false
    }
  },
  '-3': {
    firstPages: 2,
    beforePages: 1,
    afterPages: 1,
    lastPages: 1,
    dots: {
      before: true,
      after: false
    }
  },
  '-2': {
    firstPages: 3,
    beforePages: 1,
    afterPages: 1,
    dots: {
      before: true,
      after: false
    }
  },
  '-1': {
    beforePages: 2,
    firstPages: 3,
    dots: {
      before: true,
      after: false
    }
  }
};
