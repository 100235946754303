import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Container, Button, Col, Row} from 'react-bootstrap';
import listEmployees from 'actions/api/employees/list';
import addEmployee from 'actions/api/employees/create';
import deleteEmployee from 'actions/api/employees/delete';
import {employeesTable} from './tableheader'
import CustomTableWithActions from 'components/CustomTableWithActions';
import UserSelector from 'components/UserSelector';
import AddUserModal from 'components/AddUserModal';
import InviteUserModal from 'components/InviteUserModal';
import Checkbox from 'components/inputs/Checkbox';


function globalStateMapper(globalState) {
    const {users} = globalState;
    return {
        users: users.list,
    };
}

class ManageUsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            selectedUser: null,
            showAddUserPopup: false,
            showInviteUserPopup: false,
            sendNotification: false
        }
    };

    componentDidMount() {
        this.fetchEmployees();
    }

    toggleAddUserPopup = () => {
        const {showAddUserPopup} = this.state;
        this.setState({showAddUserPopup: !showAddUserPopup});
    }

    toggleInviteUserPopup = () => {
        const {showInviteUserPopup} = this.state;
        this.setState({showInviteUserPopup: !showInviteUserPopup});
    }

    handleUserSelect = (selectedUser) => {
        this.setState({selectedUser});
    }

    fetchEmployees = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        listEmployees(organisation_id)
            .then((data) => {
                this.setState({employees: data})
            })
            .catch(err => alert(err.message));
    }

    handleAddEmployee = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        let { selectedUser, sendNotification } = this.state;
        let {user_id, information} = selectedUser || {};
        let employee = {
            userId: user_id,
            information,
            notify: sendNotification
        }
        addEmployee(organisation_id, employee).then((data) => {
            this.fetchEmployees();
            this.setState({
                sendNotification: false
            });
        })
        .catch(err => alert(err.message));
    }

    handleDeleteEmployee = (employee) => {
        const {employee_id} = employee;
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};

        deleteEmployee(organisation_id, employee_id).then((data) => {
            this.fetchEmployees();
        })
            .catch(err => alert(err.message));
    }

    toggleSendNotification = () => {
        const {sendNotification} = this.state;

        this.setState({
            sendNotification: !sendNotification
        })
    }

    render() {
        const {selectedOrg, users = []} = this.props;
        const {organisation_id = null} = selectedOrg;
        const {employees, showAddUserPopup,showInviteUserPopup, sendNotification} = this.state;
        const employeeUserIds = employees.map(e => e.user_id);
        const nonEmpUsers = users.filter(u => employeeUserIds.indexOf(u.user_id) === -1);

        return (
            <div className="Employees data-capture-section p-0 mt-5">
                <Container fluid className="pt-2">
                    <h2>Employees ({employees.length})</h2>
                    <Row>
                        <Col>
                            <Form className="add_user">
                                Add Existing User
                                <Form.Group as={Col} controlId="userSelector">
                                    <UserSelector onUserSelect={this.handleUserSelect}
                                                  users={nonEmpUsers}></UserSelector>
                                </Form.Group>
                                <Form.Group as={Col} controlId="addUserButton" className="notification-wrapper">
                                    <Button className="add" onClick={this.handleAddEmployee}>Add Existing User</Button>
                                    <Checkbox
                                        checked={sendNotification}
                                        onChange={() => this.toggleSendNotification()}
                                        label={'Send notification'}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col>
                            <Row className="ml-1">Add New User</Row>
                            <Form.Group as={Row} controlId="addUserButton" className="ml-0">
                                <Button className="add ml-0" onClick={this.toggleAddUserPopup}>Add New User</Button>
                            </Form.Group>
                            <Form.Group as={Row} controlId="addUserButton" className="ml-0">
                                <Button className="add ml-0" onClick={this.toggleInviteUserPopup}>Invite User</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <CustomTableWithActions
                            data={employees}
                            tableheader={employeesTable}
                            links={{
                                'user_id': {
                                    name: 'user_id',
                                    type: 'users',
                                    className: 'link'
                                }
                            }}
                            actions={[
                                {
                                    name: "Remove",
                                    className: 'delete',
                                    onClick: this.handleDeleteEmployee,
                                    message: "You are about to REMOVE this employee from this organisation, they will no longer be able to see this organisations data",
                                    confirmation: true
                                },
                            ]}
                        />
                    </Row>
                </Container>
                <AddUserModal addUserCallback={this.fetchEmployees} show={showAddUserPopup}
                              togglePopUp={this.toggleAddUserPopup}
                              organisationId={organisation_id}></AddUserModal>
                <InviteUserModal addUserCallback={this.fetchEmployees} show={showInviteUserPopup}
                                 togglePopUp={this.toggleInviteUserPopup} organisationId={organisation_id}></InviteUserModal>

            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    null
)(ManageUsersList);
