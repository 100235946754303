import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { applyConfig, setAuthOrganisations } from "api/config";
import withAuth from "components/HOCs/withAuth";
import ScrollToTop from "components/helpers/ScrollToTop";
import VersionNumber from "components/VersionNumber"
import Login from "pages/Login";
import Home from "pages/Home";
// import Demo from "pages/Demo";
import OrganisationHomePage from "pages/OrganisationHomePage";
import Organisations from "pages/Organisations";
import Users from "pages/Users";
import UserHomePage from "pages/UserHomePage";
import LicenseeDataCapture from "pages/LicenseeDataCapture";
import LicensorDataCapture from "pages/LicensorDataCapture";
import List from "pages/List";
import CommonFiles from "pages/CommonFiles";
import Notification from "components/notification";
import store from "./store";
import AdminHeader from 'components/headers/AdminHeader';
import "./App.css";
import './scss/custom.scss';

function App() {
  applyConfig(store);
  return (
    <Provider store={store}>
      <Router>
        <Notification />
        <ScrollToTop>
          <Route
            exact
            path="/"
            component={withAuth(Home)} />
          <Route
            exact
            path="/login"
            component={withAuth(Home)} />
          <Route
            path="/organisations"
            component={withAuth(Organisations)}
            exact
          />
          <Route
            path="/organisations/:id"
            component={withAuth(OrganisationHomePage)}
          />
          <Route
            path="/users"
            component={withAuth(Users)}
            exact
          />
          <Route
            path="/users/:id"
            component={withAuth(UserHomePage)}
          />
          <Route
            path="/commonfiles"
            component={withAuth(CommonFiles)}
            exact
          />
          </ScrollToTop>
      </Router>
        <VersionNumber />
    </Provider>
  );
}

export default App;
