export const attributesTable = [
    {
        field: 'agent_organisation_name',
        label: 'Agent',
        order: 0
    },
    {
        field: 'agent_organisation_id',
        label: 'Agent Organisation ID',
        order: 1
    },
    {
        field: 'actions',
        label: 'Actions',
        order: 2
    },
  ]