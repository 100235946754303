import React, {Component} from 'react';
import PropTypes from 'prop-types';
import searchTable from 'assets/icons/searchTable.svg';
import './TableHead.css';
import {ReactComponent as AscIcon} from "assets/icons/sort-ascending-bold.svg";
import {ReactComponent as DescIcon} from "assets/icons/sort-descending-bold.svg";
import {ReactComponent as NeutralIcon} from "assets/icons/sort-neutre-bold.svg";


class TableHead extends Component {
    static defaultProps = {
        filterQuery: {},
        onUpdateFilterFields: () => {
        }
    }

    static propTypes = {
        filterQuery: PropTypes.objectOf(PropTypes.string),
        onUpdateFilterFields: PropTypes.func
    }

    onSearchInputChange = (e) => {
        const {name, value} = e.target;

        // save filter query as `field_name: search_value`
        const filterQuery = {
            ...this.props.filterQuery,
            [name]: value
        };

        this.props.onUpdateFilterFields(filterQuery);
    }

    render() {
        const {columns, onSort, orderBySettings} = this.props;
        const columnLabels = columns.map(({label, name, sort}) => (
            <div className={`table-head-th ${name}`} key={name}>
                <div className="table-cell">
                    <input
                        type="text"
                        className="table-head-th-input"
                        placeholder={label}
                        name={name}
                        size={label.length}
                        onChange={this.onSearchInputChange}
                    />

{/*                    <span className="table-head-th-name">*/}
{/*{label}*/}
{/*                    </span>*/}
                    {!!sort &&
                        (<i className="sort-icon" onClick={() => {
                            onSort(name)
                        }}>
                            {orderBySettings[name] === 'asc' ? (<AscIcon/>) :
                                orderBySettings[name] === 'desc' ? (<DescIcon/>) :
                                    (<NeutralIcon/>)}
                        </i>)
                    }

                    <img className="table-head-th-icon" src={searchTable} alt="search"/>


                </div>
            </div>
        ));
        return (
            <div className="table-head">
                <div className="table-head-row">{columnLabels}</div>
            </div>
        );
    }
}

export default TableHead;
