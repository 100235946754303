import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { listOrgsValidations } from "actions/organisations";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";

export const LIST_ORGS_VALIDATIONS = "LIST_ORGS_VALIDATIONS";

export default function() {
  return async dispatch => {
    try {
      let call = {
        method: "get",
        url: `${Config.get('ADMIN_URL')}validations`
      }
      let validations = await callHandler(call);

      dispatch(listOrgsValidations(validations));
  
      return validations;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error listing organisation validations',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}