import XError from 'errors/xerror'
import { get } from 'lodash';
import axios from 'axios';
import responseHandler from 'actions/api/common/responseHandler';

export default async function(axiosRequest) {
  try {
    let response = await axios(axiosRequest);
    let data = await responseHandler(response, 'data');
    return data;
  } catch (err) {
    let errObj = get(err, 'response.data.error', null);
    if (!errObj) {
      throw new XError({ message: "An unexpected series of events occurred" });
    }
    // Upgrade error handling crap later
    throw new XError(err.response.data.error);
  }
}