import React, { Component } from 'react';
import Label from 'components/texts/Label';
import './TextInput.css';

class TextInput extends Component {
  onChange = event => {
    const { name, value } = event.target;
    this.props.onChange({ name, value });
  }

  render() {
    const { value, notValid, id, name, placeholder, label } = this.props;
    return (
      <div className="text-input-container">
        <Label text={label} />
        <input
          id={id}
          type="text"
          name={name}
          placeholder={placeholder}
          className={`text-input ${notValid ? 'not-valid' : ''}`}
          value={value}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default TextInput;