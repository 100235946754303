import axios from "axios";
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";
import { apiRoutes } from "constants/api";
import { standartizeContactsInfo } from "utils/transformations";
import init from 'actions/form/init';

export default function ({ _id }) {
  return dispatch => {
    return axios.get(apiRoutes.organisationGet + _id)
      .then(({ data }) => {
        const { company_information } = data;
        const { contacts_information, structure } = company_information;
        const filtered_contacts_information = standartizeContactsInfo(contacts_information);

        return dispatch(init({
          company_information,
          contacts_information: filtered_contacts_information,
          data: structure || {}
        }));
      })
      .catch(() => {
        dispatch(displayNotification({
          message: 'Something went wrong with the company',
          type: TYPE_ERROR,
          timeout: DEFAULT_NOTIFICATION_TIMEOUT
        }));
      })
  }
}
