import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';

class UserAdminNavHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInView: null
    };
  }

  render() {
    const { selectedUser} = this.props;
    const { email, information={}} = selectedUser;
    const { title='', last_name='', first_name='' } = information;

    return (
      <div className='page-nav-header'>
        <Container>
            <Row>
                <div title={email} className='header-text'>{email}</div>
            </Row>
            <Row>
                <div className='header-text'>{title} {first_name} {last_name}</div>
            </Row>
        </Container>
      </div>
    );
  }
}

export default UserAdminNavHeader;
