export const attributesTable = [
    {
        field: 'attribute_key',
        label: 'Attribute key',
        order: 0
    },  
    { 
        field: 'data_type',
        label: 'Data Type',
        order: 1
    },
    { 
        field: 'data_values',
        label: 'Data Values',
        order: 3
    },
    { 
        field: 'error_type',
        label: 'Error Type',
        order: 4
    },
    { 
        field: 'conditional_on',
        label: 'Conditional On',
        order: 5
    }
  ]
