import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import OrgAdminNavItem from './OrgAdminNavItem';
import OrgAdminNavHeader from './OrgAdminNavHeader';
import './OrgAdminNav.css';

class OrgAdminNav extends Component {
    static defaultProps = {
        items: []
    }

    constructor(props) {
        super(props);
        this.state = {
            loadingNavList: true,
        };
    }

    componentDidMount() {
        this.loadMenuItems();
    }

    loadMenuItems() {
        setTimeout(() => {
            this.setState({
                loadingNavList: false,
            });
        }, 1111);
    }

    render() {
        const {items, onNavSelect, selectedNavKey, selectedOrg = {}} = this.props;
        const {loadingNavList} = this.state;

        const filteredNavItems = items.filter(item => {
            return item && item.props && !!item.props.showFor ?
                (item.props.showFor || []).includes(item.props.selectedOrg.type) : true;
        });

        const navItems = loadingNavList ? (
            <PlaceholderLoading/>
        ) : (
            filteredNavItems.map(({key, props: {label, errors} = {}}) => (
                <OrgAdminNavItem
                    errors={!!errors?.length}
                    key={key}
                    label={label}
                    id={key}
                    selectedNavKey={selectedNavKey}
                    onNavSelect={onNavSelect}
                />
            ))
        );

        const orgHeader = loadingNavList ? (
            <PlaceholderHeader/>
        ) : (

            <OrgAdminNavHeader selectedOrg={selectedOrg}></OrgAdminNavHeader>
        );

        return (
            <div className="data-capture-page-nav-container" ref={this.navContainer}>
                <nav className="data-capture-page-nav" ref={this.nav}>
                    {orgHeader}
                    <div className="data-capture-page-nav-content">
                        <div className="data-capture-page-nav-list">
                            {navItems}
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

const PlaceholderLoading = ({numItems = 8}) => {
    const placeholderItems = Array.from({length: numItems}, (_, index) => (
        <div className="placeholder-wrapper" key={index}>
            <div className="placeholder-item"></div>
        </div>
    ));

    return (
        <div className="placeholder-loading">
            {placeholderItems}
        </div>
    );
};

const PlaceholderHeader = () => {
    return (
        <div className="placeholder-header-wrapper">
            <div className="placeholder-header"></div>
        </div>
    );
};

export default withRouter(OrgAdminNav);
