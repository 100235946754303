import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Container, Button, Col } from 'react-bootstrap';
import listBrands from 'actions/api/brands/list';
import addBrand from 'actions/api/brands/create';
import deleteBrand from 'actions/api/brands/delete';
import { brandsTable } from './tableheader'
import CustomTableWithActions from 'components/CustomTableWithActions';
import Brand from './Brand'
import './Brands.css';
import ErrorAlert from 'components/ErrorAlert';

function globalStateMapper(globalState) {
  return {};
}

class Brands extends Component {
  constructor(props){
		super(props);
		this.state = {
      brands: [],
      brand: "",
      selectedBrand: null,
      tab: 'properties'
    }

    this.handleDeleteBrand = this.handleDeleteBrand.bind(this);
    this.handleAddBrand = this.handleAddBrand.bind(this);
    this.fetchBrands = this.fetchBrands.bind(this);
  };

  componentDidMount() {
    this.fetchBrands();
  }

  fetchBrands() {
    let { selectedOrg } = this.props;
    let { organisation_id } = selectedOrg || {};
    listBrands(organisation_id)
    .then((data) => {
      this.setState({ brands: data })
    })
    .catch(err => alert(err.message));
  }

  handleAddBrand() {
    let { selectedOrg } = this.props;
    let { organisation_id } = selectedOrg || {};
    let { brand } = this.state;
    addBrand(organisation_id, brand).then((data) => {
      this.fetchBrands();
      this.setState({ brand: "" })
    })
    .catch(err => alert(err.message));
  }

  handleDeleteBrand(brand) {
    const { brand_id } = brand;
    let { selectedOrg } = this.props;
    let { organisation_id } = selectedOrg || {};

    deleteBrand(organisation_id, brand_id).then((data) => {
      this.fetchBrands();
    })
    .catch(err => alert(err.message));
  }

  onSelectLinkOrg(e) {
    let selectedLinkOrg = JSON.parse(e.target.value);
    this.setState({ selectedLinkOrg });
  }

  selectBrand(brand) {
    this.setState({
      selectedBrand: brand
    })
  }

  render() {
    const { selectedOrg, errors, revalidate } = this.props;
    const { brands, selectedBrand } = this.state;

    return (
      <div className="Brands data-capture-section">
        <ErrorAlert errors={errors}  revalidate={revalidate}></ErrorAlert>
        
        <Container fluid className="">
          { !selectedBrand && (
            <div>
              <h2>Brands ({brands.length})</h2>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} controlId="userSelector">
                    <Form.Control 
                        placeholder="Enter brand" 
                        onChange={(e) => { this.setState({ brand: e.target.value })}}
                        value={this.state.brand}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addUserButton">
                      <Button className="add" onClick={this.handleAddBrand}>Add</Button>
                    </Form.Group>
                  </Form.Row>
                </Form>
                
                <CustomTableWithActions 
                  onSelectBrand={(brand) => this.selectBrand(brand)}
                  selectedOrg={selectedOrg}
                  data={brands} 
                  tableheader={brandsTable}
                  actions={[
                    {
                      name: "Remove", 
                      className: 'delete', 
                      onClick: this.handleDeleteBrand, 
                      message: "You are about to REMOVE this brand from this organisation, this brand will no longer be a valid brand for validations",
                      confirmation: true 
                    },
                  ]}
                />
            </div>
            )}

            { !!selectedBrand && (
                <Brand 
                  brand={selectedBrand} 
                  selectedOrg={selectedOrg} 
                  selectBrand={() => this.selectBrand(null)}>
                </Brand> 
            )}
        </Container>
      </div>
    );
  }
}

export default connect(
  globalStateMapper,
  null
)(Brands);
