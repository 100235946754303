import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container } from 'react-bootstrap';
import list from 'actions/api/ingestions/list'
import { sortBy } from 'lodash'
import memoize from 'memoize-one';
import { getPageItems, getPagesCount } from "utils/pagination";
import setImports from 'actions/api/ingestions/setImports'
import Pagination from 'components/lists/Pagination';
import DataTable from './DataTable';

class ImportSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalResults: 0,
    }
  };

  componentDidMount() {
    list('xelacore-catalog', 'import', this.props.selectedOrg.organisation_id, this.props.licensorOrganisationId).then((importList) => {
      this.props.setImports(importList)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedOrg !== prevProps.selectedOrg || this.props.licensorOrganisationId !== prevProps.licensorOrganisationId) {
      list('xelacore-catalog', 'import', this.props.selectedOrg.organisation_id, this.props.licensorOrganisationId)
    }
  }

  onPageChanged = (currentPage) => {
    this.setState({ currentPage });
  };

  paginateList = memoize(getPageItems);
  paginateCount = memoize(getPagesCount);
  getImportList = memoize((importHistory, licensorOrganisationId, userId) =>
    sortBy(Object.values(importHistory), 'createdAt').reverse())

  render() {
    const { currentPage, itemsPerPage } = this.state
    const importList = this.getImportList(
      this.props.imports || {},
      this.props.licensorOrganisationId,
      this.props.userId,
    )
    const paginatedList = this.paginateList(importList, currentPage, itemsPerPage);

    return (
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <h2>
            Audit
          </h2>
        </div>

        <DataTable
          takesLicensor={true}
          organisations={this.props.organisations}
          entries={paginatedList}
          takesRecordsRejected={true}
        />
        <Pagination
          current={this.state.currentPage}
          total={this.paginateCount(importList, itemsPerPage)}
          onPageChanged={this.onPageChanged}
        />
      </Container>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    imports: state.exportUpdate.imports.byLicenseeOrganisationId[ownProps.selectedOrg.organisation_id],
    organisations: state.organisations.byId
  }
}

export default connect(mapStateToProps, { setImports })(ImportSection);

