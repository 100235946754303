import React from 'react';
import AuthDropdown from './AuthDropdown';
import xelacoreLogo from 'assets/icons/xelacore-logo.svg';
import './MainHeader.css';

function MainHeader() {
  return (
    <header className="xelacore-header-main">
      <span className="xelacore-header-main-logo">
        <img src={xelacoreLogo} alt="Xelacore logo" /><br />
        <span className="xelacore-header-main-text">Data Capture</span>
      </span>
      <AuthDropdown />
    </header>
  )
}

export default MainHeader;