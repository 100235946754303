import React, { Component } from 'react';
import { connect } from "react-redux";
import { FormControl, Container, Dropdown, Row, InputGroup } from 'react-bootstrap';
import searchIcon from 'assets/icons/search.svg';

class FilterBar extends Component {

  // constructor(props) {
  //   super(props);
  // }

  updateSearch = (event, tt) => {
    this.props.onUpdateFilter({ search: event.target.value });
  }

  render() {
    return (
      <div className="x-filter-bar">
        <Container>
          <Row>
              <InputGroup>
                <img className="x-filter-bar-search-icon" src={searchIcon} alt="Search icon" />
                <FormControl
                  autoFocus={true}
                  placeholder="Search Organisations"
                  className="x-filter-bar-search-box"
                  id='x-filter-bar-search-box'
                  onChange={this.updateSearch}
                />
                <Dropdown>
                  <Dropdown.Toggle id="org-add-dropdown" className="add">+ Add Organisation</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {this.props.onToggleAddOrg('licensee')}}>Licensee</Dropdown.Item>
                    <Dropdown.Item onClick={() => {this.props.onToggleAddOrg('licensor')}}>Licensor</Dropdown.Item>
                    <Dropdown.Item onClick={() => {this.props.onToggleAddOrg('agent')}}>Agent</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(
  null,
  null
)(FilterBar);
