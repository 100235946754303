import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, categoryId) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/category/${categoryId}/attributes`
  }
  let { category_attribute_definitions } = await callHandler(call);
  return category_attribute_definitions;
}