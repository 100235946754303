import React, {Component} from 'react';
import {connect} from 'react-redux';
import Config from 'config';
// import deepmerge from 'deepmerge';
import {Form, Container, Button, Row, Col} from 'react-bootstrap';

import updateUserPassword from 'actions/api/users/changePassword';
import resetPassword from 'actions/api/users/resetPassword';
import {ReactComponent as EyeOpen} from 'assets/icons/eye-open.svg';
import {ReactComponent as EyeHidden} from 'assets/icons/eye-hidden.svg';


function globalStateMapper(globalState) {
    return {};
}

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            showPassword: false
        }
    }

    onHandleChange = (e) => {
        this.setState({newPassword: e.target.value});
    }

    onChangePassword = () => {
        const {selectedUser = {}} = this.props;
        const {newPassword} = this.state;
        this.props.updateUserPassword(selectedUser, newPassword).then(() => {
            this.setState({newPassword: ''});
        })
    };

    resetPassword = () => {
        const {selectedUser = {}} = this.props;
        let services = Config.get('services');
        let params = {
            email_parameters: {
                web_url: services.login,
                source: (!!services.reg && services.reg.replace('https://', '')) || ''
            }
        };

        this.props.resetPassword(selectedUser, params);
    };

    render() {
        const {selectedUser = {}} = this.props;
        const {email = ''} = selectedUser;
        const {newPassword, showPassword} = this.state;

        return (
            <div className="company-informtion data-capture-section">
                <Container fluid className="pt-2">
                    <h2>{email}</h2>
                    <Form>
                        <div>
                            <Form.Group as={Row} controlId="formGridName">
                                <Col className="flex-xs-grow-1">
                                    <Form.Label>Change Password</Form.Label>
                                    <div className="input-with-icon">
                                        <Form.Control
                                            placeholder="Please enter new password"
                                            onChange={this.onHandleChange}
                                            value={newPassword}
                                            type={showPassword ? 'text' : 'password'}
                                        />
                                        <i className="input-action-icon"
                                           onClick={() => {
                                               this.setState({
                                                   showPassword: !showPassword,
                                               })
                                           }}>
                                            {showPassword ? (<EyeOpen></EyeOpen>) : (<EyeHidden></EyeHidden>)}
                                        </i>
                                    </div>

                                </Col>
                                <Col>
                                    <Button className="submit mt-4" onClick={this.onChangePassword}>Update</Button>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="mt-4">
                            <Form.Group as={Row}>
                                <Col className="flex-xs-grow-1">
                                    <Form.Label>Password Reset Email</Form.Label>
                                    <div className="mr-4">Xelacore Registration</div>
                                </Col>
                                <Col>
                                    <Button className="submit mt-3" onClick={this.resetPassword}>
                                        Send
                                    </Button>
                                </Col>
                            </Form.Group>
                        </div>
                    </Form>
                </Container>
            </div>
        )
    }
}

export default connect(
    globalStateMapper,
    {updateUserPassword, resetPassword}
)(ChangePassword);
