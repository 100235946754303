import React from 'react';
import Form from 'components/dataCaptureFormComponents/Form';
import CompanyInformation from 'components/dataCaptureFormComponents/CompanyInformation';
import ContactsInformation from 'components/dataCaptureFormComponents/ContactsInformation';
import RetailChannels from 'components/dataCaptureFormComponents/RetailChannels';
import ProductCategories from 'components/dataCaptureFormComponents/ProductCategories';
import BrandHierarchy from 'components/dataCaptureFormComponents/BrandHierarchy';
import Territories from 'components/dataCaptureFormComponents/Territories';

function DataCaptureForm({ title, onSubmit }) {
  return (
    <Form title={title} onSubmit={onSubmit}>
      <CompanyInformation
        id="company-information"
        label="Company Information"
      />
      <ContactsInformation
        id="contacts-information"
        label="Contacts Information"
      />
      <RetailChannels
        id="retail-channels"
        label="Retail Channels"
      />
      <Territories
        id="territories"
        label="Territories"
      />
      <ProductCategories
        id="product-categories"
        label="Product Categories"
      />
      <BrandHierarchy
        id="brand-hierarchy"
        label="Brand Hierarchy"
      />
    </Form>
  )
}

export default DataCaptureForm;
