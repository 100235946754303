import React from 'react';
import './SubSectionTitle.css';

function SubSectionTitle({ children }) {
  return (
    <h2 className="sub-section-title">{children}</h2>
  )
}

export default SubSectionTitle;
