import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, linkId) {
  let call = {
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/brand/links/${linkId}`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let addResp = await callHandler(call);
  return addResp;
}