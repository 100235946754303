export const brandsTable = [
    {
        field: 'brand',
        label: 'Brand',
        order: 0,
        link: true,
        filter: true,
    },
    {
        field: 'brand_id',
        label: 'Brand Id',
        order: 1,
    },
    {
        field: 'organisation_id',
        label: 'Organisation Id',
        order: 2,
    },
    {
        field: 'actions',
        label: 'Actions',
        order: 3
    }
  ]