export const FORM_UPDATE = 'FORM_UPDATE';

export default function ({ path, value }) {
  return dispatch => dispatch({
    type: FORM_UPDATE,
    payload: {
      path,
      value
    }
  });
};
