import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ButtonWithConfirm extends Component {
  constructor(props){
    super(props);
		this.state = {
      show: false,
    }
  };

  componentDidMount() {
  }

  togglePopUp = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  onConfirmClick = () => {
    this.props.onClick();
    this.togglePopUp();
  }

  render() {
    let { message = null, className = 'delete', title = null } = this.props;
    let { show } = this.state;
    return (
      <div className="confirmButton">
        <Button className={className} onClick={this.togglePopUp}>{this.props.children}</Button>
        <Modal show={show} onHide={this.togglePopUp}>
          <Modal.Header closeButton>
            <Modal.Title>{ title || "Are you sure?" }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{ message || "Please confirm that you wish to do this, it was important enough for us to add a popup; so please be sure"}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel" onClick={this.togglePopUp}>Cancel</Button>
            <Button className={className} onClick={this.onConfirmClick}>{this.props.children}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ButtonWithConfirm;
