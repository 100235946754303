export const ADD_RETAILER_LISTING_EXPORTS = 'ADD_RETAILER_LISTING_EXPORTS';
export const ADD_RETAILER_LISTING_INGESTIONS = 'ADD_RETAILER_LISTING_INGESTIONS';

export function addRetailerListingExports(payload) {
  return dispatch => {
    dispatch({ type: 'ADD_RETAILER_LISTING_EXPORTS', payload })
  }
}

export function addRetailerListingIngestions(payload) {
  return dispatch => {
    dispatch({ type: 'ADD_RETAILER_LISTING_INGESTIONS', payload })
  }
}
