// import get from 'lodash';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
// import Config from 'config'

class UserSelector extends Component {
  constructor(props){
		super(props);
		this.state = {};
    this.onUserSelect = this.onUserSelect.bind(this);
  };

  componentDidMount() {}

  onUserSelect(e) {
    let selectedUser = JSON.parse(e.target.value);
    this.props.onUserSelect(selectedUser);
  }

  render() {
    const { users = [] } = this.props;
    users.unshift({ email: "None Selected" });
    return (
      <div className="userSelect">
        <Form.Group controlId="userSelect">
          <Form.Label>Select user</Form.Label>
          <Form.Control as="select" onChange={this.onUserSelect}>
            {users.map((u) => {
              return <option key={u.email} value={JSON.stringify(u)}>{u.email}</option>
            })}
          </Form.Control>
        </Form.Group>
      </div>
    );
  }
}

export default UserSelector;
