import React, { Component } from 'react';
import { Form, Col, Row, Accordion, Card, Button, useAccordionToggle } from 'react-bootstrap';
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class OrgContact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true
    }
  };

  handleUpdate = (update) => {
    const { contact = {} } = this.props;
    let updateKey = Object.keys(update)[0];
    if (update[updateKey] === contact[updateKey]) return;
    this.props.onHandleUpdate({ ...contact, ...update });
  }

  CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      const { isCollapsed } = this.state;
      this.setState({ isCollapsed: !isCollapsed})
    });
  
    return (
      <div className="contactCardToggle" onClick={decoratedOnClick}>
        {children}
      </div>
    );
  }


  render() {

    const { contact = {}, id, config = {}, onHandleDelete, onHandleSave, remainingContactTypes = [] } = this.props;
    const { showDeleteButton = true, showUpdateButton = true, showTypePicker = false } = config;
    const {
      type,
      title = "",
      first_name = "",
      last_name = "",
      email = "",
      biz_phone = "",
      mobile_phone = "",
      office_address = [],
      city = "",
      country = "",
      postcode_zipcode = "",
      pa_title = "",
      pa_first_name = "",
      pa_last_name = "",
      pa_email = "",
      pa_phone = "",
    } = contact;
    
    return (
      <div>

        <Accordion key={id} className="ml-0 mt-2">
          <Card className="pb-0">
            <Card.Header>
              <this.CustomToggle eventKey={id}>
                <Row>
                  <Col>
                    <span className="float-left ml-0">{type}</span>
                    {this.state.isCollapsed
                      ? <FontAwesomeIcon icon={faChevronDown} />
                      : <FontAwesomeIcon icon={faChevronUp} />
                    }
                  </Col>
                </Row>
              </this.CustomToggle>
              <hr />
              <h2 className="sub-section-title">
                {showDeleteButton
                  ? <Button className="delete float-right mb--3 ml-auto mr-0" onClick={onHandleDelete}>Delete</Button>
                  : ''
                }
                {showUpdateButton
                  ? <Button className="submit float-right mb--3 ml-auto mr-3" onClick={onHandleSave}>Update</Button>
                  : ''
                }

              </h2>
              {
                !showTypePicker
                ?
                ""
                :
                <Row md={16} className="ml-0">
                  <Col>
                    <Form.Group as={Row} controlId="formGridTitle">
                      <Form.Label>Type</Form.Label>
                      <Col>
                        <Form.Control as="select"
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ type: e.target.value.toLowerCase() }) }}
                          value={type}
                        >
                          {
                            remainingContactTypes.map((rtype) => <option>{rtype.toLowerCase()}</option>)
                          }
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </ Row>
              }

              <Row md={16} className="ml-0">
                <Col md={2}>
                  <Form.Group as={Row} controlId="formGridTitle">
                    <Form.Label>Title</Form.Label>
                    <Col>
                      <Form.Control as="select"
                        placeholder=""
                        onChange={(e) => { this.handleUpdate({ title: e.target.value }) }}
                        value={title}
                      >
                        <option>Mr</option>
                        <option>Mrs</option>
                        <option>Miss</option>
                        <option>Ms</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group as={Row} controlId="formGridFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Col>
                      <Form.Control
                        placeholder=""
                        onChange={(e) => { this.handleUpdate({ first_name: e.target.value }) }}
                        value={first_name}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group as={Row} controlId="formGridLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Col>
                      <Form.Control
                        placeholder=""
                        onChange={(e) => { this.handleUpdate({ last_name: e.target.value }) }}
                        value={last_name}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </ Row>

              <Row className="ml-0">
                <Col>
                  <Form.Group as={Row} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Col>
                      <Form.Control
                        placeholder=""
                        onChange={(e) => { this.handleUpdate({ email: e.target.value }) }}
                        value={email}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="ml-0">
                  <Col>
                    <Form.Group as={Row} controlId="formGridBizPhone">
                      <Form.Label>Biz Phone</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ biz_phone: e.target.value }) }}
                          value={biz_phone}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
              </Row>
            </Card.Header>
            <Accordion.Collapse eventKey={id}>
              <Card.Body className="pb-0">
                <Row className="ml-0">
                  <Col>
                    <Form.Group as={Row} controlId="formGridMobilePhone">
                      <Form.Label>Mobile Phone</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ mobile_phone: e.target.value }) }}
                          value={mobile_phone}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="ml-0">
                  <Col>
                    <Form.Group as={Row} controlId="formGridOfficeAddress">
                      <Form.Label>Office Address</Form.Label>
                      <Col>
                        <Form.Control as="textarea" rows="3"
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ office_address: e.target.value }) }}
                          value={office_address}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="ml-0">
                  <Col>
                    <Form.Group as={Row} controlId="formGridCity">
                      <Form.Label>City</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ city: e.target.value }) }}
                          value={city}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Row} controlId="formGridCountry">
                      <Form.Label>Country</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ country: e.target.value }) }}
                          value={country}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Row} controlId="formGridPostcodeZipcode">
                      <Form.Label>Postcode / Zipcode</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ postcode_zipcode: e.target.value }) }}
                          value={postcode_zipcode}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row md={16} className="ml-0">
                  <Col md={2}>
                    <Form.Group as={Row} controlId="formGridPATitle">
                      <Form.Label>PA Title</Form.Label>
                      <Col>
                        <Form.Control as="select"
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ pa_title: e.target.value }) }}
                          value={pa_title}
                        >
                          <option>Mr</option>
                          <option>Mrs</option>
                          <option>Miss</option>
                          <option>Ms</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group as={Row} controlId="formGridPAFirstName">
                      <Form.Label>PA First Name</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ pa_first_name: e.target.value }) }}
                          value={pa_first_name}
                        />

                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group as={Row} controlId="formGridPALastName">
                      <Form.Label>PA Last Name</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ pa_last_name: e.target.value }) }}
                          value={pa_last_name}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </ Row>

                <Row className="ml-0">
                  <Col>
                    <Form.Group as={Row} controlId="formGridPAEmail" style={{ marginBottom: '0' }}>
                      <Form.Label>PA Email</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ pa_email: e.target.value }) }}
                          value={pa_email}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Row} controlId="formGridPAPhone">
                      <Form.Label>PA Phone</Form.Label>
                      <Col>
                        <Form.Control
                          placeholder=""
                          onChange={(e) => { this.handleUpdate({ pa_phone: e.target.value }) }}
                          value={pa_phone}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    )
  }
}

export default OrgContact;