import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Checkbox from "components/inputs/Checkbox";
import PrimaryButton from "components/buttons/PrimaryButton";
import login from "actions/auth/login";
import { displayNotification } from "actions/notifications";
import { isEmail, isEmpty } from "utils/validations";
import { routeOnSuccessAuth } from "constants/routes";
import { DEFAULT_LOGIN_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";
import "./LoginForm.css";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    // const token = localStorage.getItem('TOKEN');
    // const password = localStorage.getItem('PASSWORD');

    this.state = { 
      email: {
        value: '',
        notValid: false
      },
      password: {
        value: '',
        notValid: false
      },
      rememberMe: true
    };
  }

  inputChange = e => {
    this.setState({
      [e.currentTarget.name]: {
        value: e.currentTarget.value,
        notValid: false
      }
    });
  };

  rememberMeChange = e => {
    this.setState({
      rememberMe: e.currentTarget.checked
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email, password, rememberMe } = this.state;
    const errorsState = {};

    if (!isEmail(email.value)) {
      errorsState.email = {
        value: email.value,
        notValid: true
      };
    }

    if (isEmpty(password.value)) {
      errorsState.password = {
        value: password.value,
        notValid: true
      };
    }

    if (Object.keys(errorsState).length) {
      this.setState(errorsState);
      const message = isEmpty(password.value) || isEmpty(email.value)
        ? 'Please fill out this field'
        : 'Please fill in a valid email';
      this.props.displayNotification({
        message,
        type: TYPE_ERROR,
        timeout: DEFAULT_LOGIN_NOTIFICATION_TIMEOUT
      });
      return;
    }

    this.props.login({
        email: email.value,
        password: password.value,
        rememberMe
      })
      .then(() => {
        this.props.history.push(routeOnSuccessAuth)
      });
  };

  render() {
    const { email, password, rememberMe } = this.state;
    return (
      <form className="login-form" onSubmit={this.handleSubmit}>
        <div className="login-form-inputs">
          <input
            type="text"
            className={`login-form-text-input ${
              email.notValid ? "not-valid" : ""
            }`}
            name="email"
            autoComplete="email"
            placeholder="Fabacus email"
            value={email.value}
            onChange={this.inputChange}
          />
          {
            email.notValid && <small style={{color: 'rgba(255, 0, 0, 1)'}}>Please enter a valid email</small>
          }
          <input
            type="password"
            className={`login-form-text-input ${
              password.notValid ? "not-valid" : ""
            }`}
            name="password"
            autoComplete="current-password"
            placeholder="Password"
            value={password.value}
            onChange={this.inputChange}
          />
          {
            password.notValid && <small style={{color: 'rgba(255, 0, 0, 1)'}}>Please enter a valid password</small>
          }
        </div>
        <div className="login-form-remember-me">
          <Checkbox
            label="Remember me"
            checked={rememberMe}
            onChange={this.rememberMeChange}
          />
        </div>
        <div className="login-form-submit-button">
          <PrimaryButton type="submit" text="Login" />
        </div>
      </form>
    );
  }
}

export default compose(
  withRouter,
  connect(
    null,
    {
      login,
      displayNotification
    }
  )
)(LoginForm);
