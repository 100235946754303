class XelacoreError extends Error {
  constructor({ code = null, message = null, statusCode = 500}, parent) {
    super(code);
    this.name = 'XelacoreError';
    this.code = code;
    this.message = message;
    this.statusCode = statusCode;
    this.parent = parent;
  }

  toString() {
    const toString = super.toString();
    if (this.parent) {
      return `Error ${this.code}: ${toString} \n Extends: ${this.parent.toString()}`;
    }
    return `Error ${this.code}: ${toString}`;
  }

  toReturnFormat() {
    return {
      code: this.code,
      message: this.message
    }
  }
}

export default XelacoreError;
