import React, { Component } from 'react';

import SectionWrapper from 'components/dataCaptureFormComponents/SectionWrapper';
import SubSectionWrapper from 'components/dataCaptureFormComponents/SubSectionWrapper';

import TerritoriesForm from './TerritoriesForm';

class Territories extends Component {
  render() {

    return (
      <SectionWrapper title="Territories">
        <SubSectionWrapper
          formComponent={<TerritoriesForm />}
        />
      </SectionWrapper>
    );
  };
}

export default Territories;
