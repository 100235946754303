import React, { Component } from 'react';
import TableButton from 'components/buttons/TableButton';
import { getNotEmptyRows } from "utils/iter";
import caretDownIcon from 'assets/icons/caret-down.svg';
import './ExpandableTextArea.css';

class ExpandableTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.expanded,
      value: ''
    };
  }

  get isTextValid() {
    const notEmptyRows = getNotEmptyRows(this.state.value);
    return notEmptyRows.length;
  }

  onChange = e => {
    const { value } = e.target;
    this.setState({
      value
    });
  }

  toggleArea = ({ eraseValue }) => {
    this.setState({
      isExpanded: !this.state.isExpanded,
      ...eraseValue && { value: '' }
    });
  }

  onAddClick = e => {
    const groomedValue = getNotEmptyRows(this.state.value).join('\n');
    this.props.onAddClick(groomedValue);
    this.toggleArea({ eraseValue: true });
  }

  render() {
    const { addButtonText, rows } = this.props;
    const { isExpanded, value } = this.state;
    return (
      <div className="expandable-textarea">
        {
          isExpanded && (
            <div className="expandable-textarea-inner">
              <textarea
                className="expandable-textarea-inner-textarea"
                rows={rows}
                onChange={this.onChange}
                value={value}
                autoFocus
              />
            </div>
          )
        }
        {
          !isExpanded
            ? (
              <div className="expandable-textarea-controls expandable-textarea--controls--collapsed">
                <button
                  type="button"
                  className="expandable-textarea-controls-add-btn"
                  onClick={this.toggleArea}
                >
                  {addButtonText}
                </button>
                <button
                  type="button"
                  className="expandable-textarea-controls-state-btn"
                  onClick={this.toggleArea}
                >
                  Expand <img className="expandable-textarea-controls-state-icon" src={caretDownIcon} alt="Caret down" />
                </button>
              </div>
            ) : (
              <div className="expandable-textarea-controls expandable-textarea-controls--expanded">
                <TableButton
                  type="button"
                  text={addButtonText}
                  onClick={this.onAddClick}
                  disabled={!this.isTextValid}
                />
                <button
                  type="button"
                  className="expandable-textarea-controls-state-btn"
                  onClick={this.toggleArea}
                >
                  Collapse
                    <img
                    className="expandable-textarea-controls-state-icon expandable-textarea-controls-state-icon--rotated"
                    src={caretDownIcon}
                    alt="Caret up"
                  />
                </button>
              </div>
            )
        }
      </div>
    );
  }
}

export default ExpandableTextArea;
