import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PaginationButton extends Component {
  static defaultProps = {
    current: 0,
    page: 0,
    isFirst: false,
    isLast: false,
    onClick: () => {}
  }

  static propTypes = {
    current: PropTypes.number,
    page: PropTypes.number,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    onClick: PropTypes.func
  }

  handleClick = () => {
    this.props.onClick(this.props.page);
  }

  getAttributes = () => {
    const {
      page,
      current,
      isFirst,
      isLast
    } = this.props;

    if (isFirst) {
      return {
        className: 'pagination-side-button',
        text: 'First'
      };
    } else if (isLast) {
      return {
        className: 'pagination-side-button',
        text: 'Last'
      };
    }

    return {
      className: `pagination-page-button ${page === current ? 'pagination-page-button--current' : ''}`,
      text: page
    };
  }

  render() {
    const {
      className,
      text
    } = this.getAttributes();

    return (
      <button
        type="button"
        className={className}
        disabled={this.props.disabled}
        onClick={this.handleClick}
      >
        {text}
      </button>
    );
  }
}

export default PaginationButton;
