import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FilePond } from "react-filepond";
import { Button } from 'react-bootstrap';

function globalStateMapper(globalState) {
  return {};
}

class RetailersFilePond extends Component {
    constructor(props){
            super(props);
            this.state = {
                files: [],
                uploadedFiles: [],
            }
    };

    fetchFiles = (file) => {
        const {uploadedFiles} = this.state;

        this.setState(
            { 
                files: uploadedFiles.map(f => {
                return {
                source: f.name,
                options: {
                    type: 'local',
                    file: f,
                }
                }
            }) 
            }
        );
    }

    resetFilePond() {
        this.setState({
            uploadedFiles: [],
            files: []
        })
    }

    render() {
        const { files } = this.state;
        const { name, processFile, title } = this.props;
        return (
            <div>
                <div className="export-header">
                    <h2 className="">
                        {title}
                    </h2>
                    <a href="#" onClick={() => this.resetFilePond()}>
                        Clear files
                    </a>
                </div>

                <FilePond
                    name={name}
                    className="mb-4"
                    acceptedFileTypes={['text/csv']}
                    allowFileTypeValidation={true}
                    files={files}
                    allowMultiple={true}
                    instantUpload={false}
                    allowRevert={false}
                    server={{
                        process: processFile
                    }}
                />
            </div>
        );
    }
}

export default connect(
  globalStateMapper,
  null
)(RetailersFilePond);
