import React, {Component} from 'react';
import {connect} from "react-redux";
import FilterBar from './FilterBar';
import AddOrgModal from './AddOrgModal';
import {addOrg} from 'actions/organisations';
import listOrgs from 'actions/api/organisations/list';
import Table from 'components/lists/Table';
import {orgTable} from 'constants/tableheaders';

import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import cloneDeep from "lodash/cloneDeep";

function globalStateMapper(globalState) {
    const {organisations, users, validations} = globalState;
    return {
        organisations,
        users,
        validations
    };
}

class OrganisationList extends Component {
    state = {
        currentPage: 1,
        totalPages: 1,
        filter: {},
        showAddOrgPopup: false,
        addOrgData: {},
        fabUsers: [],
        orderBySettings: {},
    }

    onPageChanged = (currentPage) => {
        this.setState({
            currentPage
        });

        window.scroll({
            top: 0,
            behavior: "auto"
        });
    }

    toggleAddOrgPopup = (type) => {
        const {users = {}} = this.props;
        const {showAddOrgPopup} = this.state;

        let addOrgData = {};
        let fabUsers = [];
        if (!showAddOrgPopup) {
            fabUsers = (users.list || []).filter(x => x.email.toLowerCase().includes('@fabacus.com'));
            addOrgData = {type, selectedUser: fabUsers[0]};
        }
        this.setState({showAddOrgPopup: !showAddOrgPopup, addOrgData, fabUsers});
    }

    handleAddOrganisation = () => {
        const {addOrgData = {}} = this.state;
        const {selectedUser, newCompanyName, type} = addOrgData;

        this.props.addOrg({
            userId: selectedUser.user_id,
            companyName: newCompanyName,
            companyType: type
        }).then(() => {
            this.props.listOrgs();
            this.toggleAddOrgPopup();
        });
    }

    handleUpdateAddOrgData = (addOrgData) => {
        this.setState({addOrgData});
    }

    handleUpdateFilter = (newFilter) => {
        this.setState({filter: newFilter});
    }

    findValidationError = (org) => {
        const {validations = {}} = this.props;

        return find(!!validations && !!validations.validations && validations.validations, validation => {
            return validation.organisationId === org.organisation_id && !!validation.errors.length
        })
    }

    sortDataTableBy = (by) => {
        const {orderBySettings} = this.state;
        let tempSettings = cloneDeep(orderBySettings);
        tempSettings[by] = tempSettings[by] === 'asc' ? 'desc' : (tempSettings[by] === 'desc' ? '' : 'asc');
        if (tempSettings[by] === '') {
            delete tempSettings[by]
        }
        this.setState({
            orderBySettings: tempSettings
        });
    }

    render() {
        const {organisations = {}} = this.props;
        const {
            filter = {},
            showAddOrgPopup,
            addOrgData = {},
            fabUsers = [],
            orderBySettings
        } = this.state;
        const {search = null} = filter;
        const tableData = orderBy(organisations.list
            .filter(org => {
                if (!search) return org;
                const {name, type, organisation_id, aliases} = org;
                return name.toLowerCase().includes(search.toLowerCase()) ||
                    type.toLowerCase().includes(search.toLowerCase()) ||
                    organisation_id.toLowerCase().includes(search.toLowerCase()) ||
                    aliases.find(aliasItem => !!aliasItem && !!aliasItem.alias_name&&aliasItem.alias_name.toLowerCase().includes(search.toLowerCase()))

            }).map(org => {
                const {name, organisation_id, type, aliases,} = org;
                return {
                    name,
                    organisation_id,
                    type,
                    aliases,
                    error: !!this.findValidationError(org)
                }
            }), Object.keys(orderBySettings), Object.values(orderBySettings));

        let columns = Object.keys((tableData[0] || [])).map(k => {
            return {
                name: k, label: orgTable[k] || k, sort: ['type', 'error'].includes(k)
            }
        });

        const noData = tableData.length === 0;
        return (
            <div>
                <FilterBar onUpdateFilter={this.handleUpdateFilter} onToggleAddOrg={this.toggleAddOrgPopup}></FilterBar>
                <div className="company-information-form">
                    {
                        noData
                            ?
                            (organisations.list.length > 0 ? "There is no data to show right now" : "Loading...")
                            :
                            <div className='licensee-list'>
                                <Table
                                    columns={columns}
                                    filterQuery={{}}
                                    data={tableData}
                                    idField='organisation_id'
                                    link='organisations'
                                    orderBySettings={orderBySettings}
                                    onSort={(by) => {
                                        this.sortDataTableBy(by)
                                    }}
                                    // onUpdateFilterFields={this.onUpdateFilterFields}
                                />
                                {/* <Pagination
              current={currentPage}
              total={totalPages}
              onPageChanged={this.onPageChanged}
            /> */}
                            </div>
                    }
                </div>
                <AddOrgModal
                    addOrgData={addOrgData}
                    users={fabUsers}
                    show={showAddOrgPopup}
                    togglePopUp={this.toggleAddOrgPopup}
                    handleAddOrganisation={this.handleAddOrganisation}
                    handleUpdateAddOrgData={this.handleUpdateAddOrgData}
                />
            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    {addOrg, listOrgs}
)(OrganisationList);
