import React, { Component } from 'react';
import { connect } from "react-redux";
import Label from 'components/texts/Label';

import MenuList from 'components/menus/List'

import { groupedCountries as countries } from 'constants/countries';
import update from 'actions/form/update';

class TerritoriesForm extends Component {
  onChange = (e) => {
    const value = (e.target.value || '')
      .split(',')
      .filter((item) => !!item);

    this.props.update({
      path: 'data.territories',
      value
    });
  }

  render() {
    const fieldLabel = 'Territory';
    const name = 'territories_list';

    return (
      <div className="territories-form-container">
        <Label text="Territories" />
        <MenuList
          isGrouped
          isMulti
          options={countries}
          selectedValues={this.props.territories}
          name={name}
          fieldLabel={fieldLabel}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    territories: state.form.data.territories
  }),
  { update }
)(TerritoriesForm);
