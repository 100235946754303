import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Nav} from 'react-bootstrap';
import PendingUsers from './PendingUsers';
import ManageUsersList from './ManageUsersList';
import ManageUsersPermissions from './ManageUsersPermissions';
import ErrorAlert from 'components/ErrorAlert';

function globalStateMapper(globalState) {
  const {users} = globalState;
  return {
    users: users.list,
  };
}

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: `list`,
      selectedUser: null,
      showAddUserPopup: false
    }
  };

  toggleEmployeesTab = (tab) => {
    this.setState({activeTab: tab});
  }

  handleUserSelect = (selectedUser) => {
    this.setState({selectedUser});
  }


  render() {
    const {selectedOrg, errors, revalidate} = this.props;

    return (
        <div className="Employees data-capture-section">
          <ErrorAlert errors={errors}  revalidate={revalidate}></ErrorAlert>

          <Nav defaultActiveKey="info" className="mt--2 pr-0">
            <Nav.Item>
              <Nav.Link className="mr-2 pill"
                        onClick={e => this.toggleEmployeesTab('list')}>
                Employees
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="mr-2 pill"
                        onClick={e => this.toggleEmployeesTab('pending')}>
                Pending Users
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="pill ml-0" onClick={e => this.toggleEmployeesTab('permissions')}>
                Permissions
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {this.state.activeTab === 'list'
              &&
              <ManageUsersList key='employees'
                               label='Employees'
                               selectedOrg={selectedOrg}/>
          }

          {this.state.activeTab === 'pending'
              &&
              <PendingUsers key='pending'
                            label='Pending Users'
                            selectedOrg={selectedOrg}/>
          }

          {this.state.activeTab === 'permissions'
              &&<ManageUsersPermissions  key='employees'
                                         label='Employees'
                                         selectedOrg={selectedOrg} />
          }
        </div>
    );
  }
}

export default connect(
    globalStateMapper,
    null
)(Employees);
