import React, {Component, useState} from 'react';
import './category-licensor.scss'
import Select from 'react-select';
import categoriesList from "../../../actions/api/productCategories/list";
import categoriesListMappings from "../../../actions/api/productCategories/list-mappings";
import createMappings from "../../../actions/api/productCategories/create-mapping";
import listLinks from 'actions/api/links/list';
import caretRightIcon from "../../../assets/icons/caret_right_icon.svg";
import folderIcon from "../../../assets/icons/folder.svg";
import fileIcon from "../../../assets/icons/file.svg";
import {clone, difference, find, map, orderBy} from "lodash";
import {connect} from "react-redux";
import {displayNotification} from "../../../actions/notifications";
import {DEFAULT_NOTIFICATION_TIMEOUT, TYPE_SUCCESS} from "../../../constants/notifications";
import forEach from 'lodash/forEach';
import _size from 'lodash/size';
import Icon from "../../icon";
import uniqBy from "lodash/uniqBy";
import union from "lodash/union";
import ButtonWithConfirm from "../../ButtonWithConfirm";

class ProductCategoriesLevels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productCategories: [],
            categoryMappingDataList: {},
            licensorCategoriesMappings: [],
            activeLicensorTab: 0,
            activeLicensorTabID: '',
            licensorListData: [],
            expandAllChildren: true,
            disableSubmitAction: false,
            activeTabHoldDataToPush: [],
            doNotUpdateComponent: false
        }
    };

    getLicensorsList = () => {
        let {selectedOrg} = this.props;
        let {activeLicensorTabID} = this.state;
        let {organisation_id, type} = selectedOrg || {};
        return listLinks(organisation_id, type)
            .then((data) => {
                this.setState({
                    licensorListData: data,
                    activeLicensorTabID: !!activeLicensorTabID && activeLicensorTabID !== '' ? activeLicensorTabID : data[0].licensor_organisation_id
                });
                let arrayData = [];
                data.forEach(itemkey => {
                    categoriesList(itemkey.licensor_organisation_id).then((licensorCategories => {
                        arrayData.push({key: itemkey.licensor_organisation_id, data: licensorCategories.categories});
                        this.setState({licensorCategoriesMappings: arrayData});
                    }));

                })
            });
    }

    componentDidMount() {
        this.fetchProductCategory();
        this.fetchProductCategoryMappings();
        this.getLicensorsList();
    }

    fetchProductCategory = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        categoriesList(organisation_id)
            .then((data) => {
                this.setState({
                    productCategories: data.categories,
                    productLeafNodes: this.mapCategoryObject(data.categories).filter(item => item.children.length < 1)
                });
            })
            .catch(err => alert(err.message));
    }

    fetchProductCategoryMappings = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        categoriesListMappings(organisation_id)
            .then((mappingData) => {
                this.setState({
                    categoryMappingDataList: mappingData.category_mappings
                })
            })
            .catch(err => alert(err.message));
    }

    flatMapCategories = (categories) => {
        let mappedCategories = []
        categories.forEach(item => {
            mappedCategories.push({
                string_path: item.string_path,
                name: item.string_path[item.string_path.length - 1],
                category_id: item.category_id,
                parent_category_id: item.parent_category_id,
                children: [],
                level: 1
            })
        });
        return mappedCategories
    }

    mapCategoryObject = (categories) => {
        let mappedCategories = this.flatMapCategories(categories);
        let finalMappedTree = [];
        let map = {};
        mappedCategories.forEach((node, node_index) => {
            if (!node.parent_category_id) return finalMappedTree.push(node);
            let parentIndex = map[node.parent_category_id];
            if (parentIndex !== "number") {
                parentIndex = mappedCategories.findIndex(el => el.category_id === node.parent_category_id);
                map[node.parent_category_id] = parentIndex;
            }
            if (!mappedCategories[parentIndex].children) {
                return mappedCategories[parentIndex].children = [node];
            }
            node.level = mappedCategories[parentIndex].level + 1;
            mappedCategories[parentIndex].children.push(node);
        });
        return mappedCategories
    }

    filteredCategories = (mappedCategories) => {
        let filteredList = mappedCategories.filter(item => item.parent_category_id === null);
        return filteredList
    }

    getLeafNodes = (categories, activeLicensorTabID, result = []) => {
        let activeTabData = categories.filter(tab => tab.key === activeLicensorTabID);
        if (activeTabData && activeTabData[0]) {
            result = this.mapCategoryObject(activeTabData[0].data).filter(item => item.children.length < 1)
            return orderBy(result, (item) => item.string_path.join(' > '), 'asc');
        }
    }

    setActiveLicensorTab = (index, activeLicensorTabID) => {
        this.setState({
            activeLicensorTabID: activeLicensorTabID,
            activeLicensorTab: index,
            activeTabHoldDataToPush: []
        });
    }

    findLicenseeKey = (categoryMappings, activeLicensorTab, licensorCategoryId) => {
        let listToExtract = categoryMappings[activeLicensorTab];
        if (listToExtract) {
            return listToExtract.find(item => item.licensee_category_id === licensorCategoryId);
        }
    }

    handleLicenseeCategoryChange = (licensorID, licenseeID) => {
        let categoryMappingDataListClone = clone(this.state.categoryMappingDataList);
        if (!categoryMappingDataListClone[this.state.activeLicensorTabID]) {
            categoryMappingDataListClone[this.state.activeLicensorTabID] = []
        }
        let searchResult = find(categoryMappingDataListClone[this.state.activeLicensorTabID], item => {
            return item.licensee_category_id === licensorID
        });
        if (!searchResult) {
            categoryMappingDataListClone[this.state.activeLicensorTabID].push({
                licensee_category_id: licensorID,
                licensor_category_id: licenseeID
            })
        }

        let mappedChild = map(categoryMappingDataListClone[this.state.activeLicensorTabID], item => {
            if (item.licensee_category_id === licensorID) {
                return {licensee_category_id: licensorID, licensor_category_id: licenseeID}
            } else {
                return item
            }
        });

        categoryMappingDataListClone[this.state.activeLicensorTabID] = mappedChild;
        if (difference(categoryMappingDataListClone[this.state.activeLicensorTabID], this.state.categoryMappingDataList[this.state.activeLicensorTabID])) {
            this.setState({categoryMappingDataList: categoryMappingDataListClone})
        }
    }

    pushMappingsUpdate = () => {
        let {selectedOrg} = this.props;
        let {categoryMappingDataList, activeLicensorTabID, activeTabHoldDataToPush} = this.state;
        let {organisation_id} = selectedOrg || {};

        let preSavedDataToPush = uniqBy(activeTabHoldDataToPush, 'licensee_category_id');
        let dataToPush = uniqBy(union(categoryMappingDataList[activeLicensorTabID], preSavedDataToPush), 'licensee_category_id');


        createMappings(organisation_id, this.state.activeLicensorTabID, dataToPush).then((response) => {
            setTimeout(() => {
                this.setState({disableSubmitAction: false});
                this.fetchProductCategory();
                this.fetchProductCategoryMappings();
                this.getLicensorsList();
            }, 444);
            this.props.displayNotification({
                message: 'Success, Mappings updated.',
                type: TYPE_SUCCESS,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
        })
    }

    checkEqualValuesExist = (dataToCheck) => {
        let objectToCompare = {};
        forEach(dataToCheck, (itemDataToCheck, key) => {
            let arrayToCompare = map(itemDataToCheck, item => item.string_path_joined.replace(/\s/g, ''));

            if (_size(this.state.licensorListData) > 2 ?
                (_size(arrayToCompare) > 1 && arrayToCompare.every((val, i, arr) => val === arr[0])) : (
                    arrayToCompare.every((val, i, arr) => val === arr[0]))
            ) {
                objectToCompare[key] = arrayToCompare[0]
            }
        })
        return objectToCompare
    }

    selectValueGet = (categoryMappingDataList, activeLicensorTabID, node, getLeafNodesListData, equalValuesObjectPreSaverTemp) => {

        // @todo logic must be reviewed||changed later
        let equalizer = _size(equalValuesObjectPreSaverTemp) > 0 && equalValuesObjectPreSaverTemp[node.category_id];
        let currentExist = this.findLicenseeKey(categoryMappingDataList, activeLicensorTabID, node.category_id);
        let eqItem = find(getLeafNodesListData, item => {
            let compareString = item.string_path.join('').replace(/\s/g, '');
            return equalizer === compareString
        });
        let suggestedItem = find(getLeafNodesListData, item => {
            let compareString = item.string_path.join('').replace(/\s/g, '');
            let suggestedCompareString = node.string_path.join('').replace(/\s/g, '');
            return suggestedCompareString === compareString
        });

        let currentFoundedValue = currentExist && currentExist.licensor_category_id ? find(getLeafNodesListData, item => {
            return item.category_id === currentExist.licensor_category_id
        }) : null;

        // activeLicensorTabID === licensor.licensor_organisation_id

        if (!currentFoundedValue && ((suggestedItem && suggestedItem.category_id) || (eqItem && eqItem.category_id))) {
            let activeTabToBePushed = this.state.activeTabHoldDataToPush;
            let licensorToPushItem = suggestedItem || eqItem;
            let itemToPushExist = activeTabToBePushed.find(item => (item.licensee_category_id === node.category_id) && (item.licensor_category_id === licensorToPushItem.category_id));

            if (!itemToPushExist) {
                this.setState({doNotUpdateComponent: true});
                setTimeout(() => {
                    activeTabToBePushed.push({
                        licensee_category_id: node.category_id,
                        licensor_category_id: licensorToPushItem.category_id
                    });

                    // categoryMappingDataList[activeLicensorTabID] = activeTabToBePushed

                    this.setState({
                        activeTabHoldDataToPush: uniqBy(activeTabToBePushed, 'licensee_category_id')
                    })
                }, 1)
                setTimeout(() => {
                    this.setState({doNotUpdateComponent: false});
                }, 333)
            }

        }

        const dataValue = currentFoundedValue ? {
                value: currentFoundedValue,
                option_type: 'selected'
            } :
            (suggestedItem && suggestedItem.string_path ?
                {
                    value: suggestedItem,
                    option_type: 'suggested'
                }
                :
                '');

        return dataValue;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.doNotUpdateComponent) {
            return false
        }
        return !nextState.doNotUpdateComponent
    }

    render() {
        const {
            licensorCategoriesMappings,
            activeLicensorTab,
            activeLicensorTabID,
            categoryMappingDataList,
            licensorListData,
            productCategories,
            expandAllChildren,
            disableSubmitAction,
            activeTabHoldDataToPush,
        } = this.state;

        const getLeafNodesListData = this.getLeafNodes(licensorCategoriesMappings, activeLicensorTabID);
        let objectPreSaver = {};
        let equalValuesObjectPreSaverTemp = {};

        forEach(categoryMappingDataList, (c_item, c_key) => {
            let c_categories = this.getLeafNodes(licensorCategoriesMappings, c_key);
            c_item.forEach((i, k) => {
                objectPreSaver[i.licensee_category_id] = objectPreSaver[i.licensee_category_id] || [];

                let categoryToPushItem = find(c_categories, item => {
                    return item.category_id === i.licensor_category_id
                });

                if (categoryToPushItem && categoryToPushItem.string_path) {
                    categoryToPushItem.string_path_joined = categoryToPushItem.string_path.join('');
                    objectPreSaver[i.licensee_category_id].push(categoryToPushItem);
                    this.checkEqualValuesExist(objectPreSaver)

                    equalValuesObjectPreSaverTemp = this.checkEqualValuesExist(objectPreSaver);
                }
            });
        });

        const Tree = ({data = [], node = {}}) => {
            return (<div className="d-tree">
                <ul className="d-flex d-tree-container flex-column">
                    {data.map((tree, index) => {
                        return (<TreeNode key={`tree-node-${index}`} node={tree}/>)
                    })}
                </ul>
            </div>);
        };

        const TreeNode = ({node}) => {
            const [childVisible, setChildVisiblity] = useState(false);

            const hasChild = node.children.length > 0 ? true : false;

            return (<li className={`d-tree-node ${hasChild ? 'has-child' : ''}`}>
                <div className="d-flex">
                    {hasChild && (<div
                        className={`c-pointer d-inline d-tree-toggler ${childVisible ? "active" : ""}`}
                    >
                                <span className={"node-action-icon"}>
                                    <img className=""
                                         src={caretRightIcon}
                                         alt="indicator icon"
                                    />
                                </span>
                    </div>)}

                    <div className="col d-tree-head-row " onClick={(e) => setChildVisiblity((v) => !v)}>
                            <span className={"node-icon"}>
                                 {!!hasChild && (<img className=""
                                                      src={folderIcon}
                                                      alt="folder icon"
                                 />)}
                                {!hasChild && (<img className=""
                                                    src={fileIcon}
                                                    alt="file icon"
                                />)}
                            </span>
                        <div>
                            {node.name}

                        </div>
                        {!hasChild && (<div className={"licensor-category-action"}>
                            <div className={'select leafe'}>
                                {!!categoryMappingDataList && (
                                    <div className={`select-suggested-selected 
                                    ${this.selectValueGet(categoryMappingDataList, activeLicensorTabID, node, getLeafNodesListData, equalValuesObjectPreSaverTemp).option_type}__option-type`}>

                                        <div className="suggestion-info">
                                            <div className="suggestion-tooltip">
                                                <Icon
                                                    icon={'error'}
                                                    size={13}
                                                    fill="orange"
                                                    className="suggestion-icon"
                                                />
                                                <span className="suggestion-tooltip">
                                                    This is an auto-selected suggestion. <br/>
                                                    Please check before Saving
                                                </span>
                                            </div>


                                        </div>

                                        <div className="select-holder">
                                            <Select
                                                className={`${this.selectValueGet(categoryMappingDataList, activeLicensorTabID, node, getLeafNodesListData, equalValuesObjectPreSaverTemp).option_type}__select`}
                                                onChange={(e) => {
                                                    this.handleLicenseeCategoryChange(node.category_id, e.category_id)
                                                }}
                                                options={getLeafNodesListData}
                                                getOptionLabel={(option) => option.string_path.join(' > ')}
                                                getOptionValue={(option) => option.category_id}
                                                value={this.selectValueGet(categoryMappingDataList, activeLicensorTabID, node, getLeafNodesListData, equalValuesObjectPreSaverTemp).value}
                                            />
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>)}
                    </div>
                </div>


                {hasChild && (childVisible || expandAllChildren) && (
                    <div className="d-tree-content">
                        <ul className="d-flex d-tree-container flex-column">
                            <Tree node={node} data={node.children}/>
                        </ul>
                    </div>
                )}
            </li>);
        };

        return (<div className='companyInfoBlock category-list-display-builder'>
            <div className={'category-header'}>
                <h3>Licensor Mappings</h3>
                <button className={'btn add'} onClick={() => {
                    this.setState({expandAllChildren: !this.state.expandAllChildren})
                }}>
                    {!!expandAllChildren ? 'Collapse' : 'Expand'} tree
                </button>
            </div>
            <div className={"tree-wrapper"}>
                <div className={`mapping-control`}>
                    {licensorListData.map((licensor, licensorIndex) => {
                        if (!!licensor && !!licensor.licensor_organisation_id) {
                            return (<div key={`control-${licensorIndex}`} className={`mapping-control-tab  
                            ${activeLicensorTabID === licensor.licensor_organisation_id ? 'active' : ''}`}
                                         data-org-id={licensor.licensor_organisation_id}
                                         onClick={() => {
                                             this.setActiveLicensorTab(licensorIndex, licensor.licensor_organisation_id)
                                         }}>
                                <h4>{licensor.licensor_organisation_name}</h4>
                            </div>)
                        }
                    })}
                </div>
                <div className={'mapping-display'}>
                    {licensorCategoriesMappings.map((licensor, licensorIndex) => {

                        if (!!(activeLicensorTab === licensorIndex) && !!(!!licensor && (licensor.data && licensor.data.length > 0))) {
                            return (<div key={`display-${licensorIndex}`}>
                                {!!licensor.data.length && (<div>
                                    <Tree
                                        data={this.filteredCategories(this.mapCategoryObject(productCategories))}/>
                                </div>)}
                            </div>)
                        }
                    })}
                </div>
            </div>

            <div className={"category-action"}>
                {activeTabHoldDataToPush.length > 0 ? (
                        <ButtonWithConfirm className={"btn add"}
                                           onClick={() => {
                                               this.pushMappingsUpdate()
                                           }}
                                           message={'Auto-selected categories are present in this update, do you want to continue?'}>
                            Update Mappings
                        </ButtonWithConfirm>
                    )
                    :
                    (<button className={"btn add"}
                             disabled={disableSubmitAction}
                             onClick={this.pushMappingsUpdate}>
                        Update Mappings
                    </button>)}


            </div>
        </div>)
    }
}

export default connect(
    null,
    {displayNotification}
)(ProductCategoriesLevels);
