import { displayNotification } from "actions/notifications";
import addOrganisation from "actions/api/organisations/create"
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_SUCCESS, TYPE_ERROR } from "constants/notifications";

export const LIST_ORGS = 'LIST_ORGS';
export const SELECT_ORG = 'SELECT_ORG';
export const LIST_ORGS_VALIDATIONS = 'LIST_ORGS_VALIDATIONS';

export const listOrgs = (payload) => ({
  type: LIST_ORGS,
  payload: { list: payload },
});

export const listOrgsValidations = (payload) => {
  return {
    type: LIST_ORGS_VALIDATIONS,
    payload: { validations: payload }
  }
};

export const selectOrg = (payload) => ({
  type: SELECT_ORG,
  payload: { selected: payload },
});

export const addOrg = (orgData) => {
  return async dispatch => {
    try {
      let addOrgResp = await addOrganisation(orgData);
      dispatch(displayNotification({
        message: 'Successfully added organisation',
        type: TYPE_SUCCESS,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
      return addOrgResp;
    } catch (err) {
      const message = `Failed to add organisation, please try again`;
      dispatch(displayNotification({
        message,
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
      throw err;
    }
  };
}