import React from 'react';
import SubSectionTitle from 'components/texts/SubSectionTitle';
import './SubSectionWrapper.css';

function SubSectionWrapper({ title, formComponent, infoComponent }) {
  return (
    <div className="sub-section-wrapper">
      { title && (<div className="sub-section-wrapper-title">
        <SubSectionTitle>{ title }</SubSectionTitle>
      </div>)
      }
      <div className="sub-section-wrapper-main">
        <div className="data-capture-section-column data-capture-section-form-column">
          {formComponent}
        </div>
        <div className="data-capture-section-column data-capture-section-info-column">
          {infoComponent}
        </div>
      </div>
    </div>
  );
}

export default SubSectionWrapper;