import React, {Component} from 'react';
import {Button, Col, Form} from "react-bootstrap";
import categoriesList from "../../../actions/api/productCategories/list";
import categoriesLevelsList from "../../../actions/api/productCategories/list-levels";
import addCategory from "../../../actions/api/productCategories/create";
import addCategoryLevel from "../../../actions/api/productCategories/create-levels";

class ProductCategoriesLevels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productCategories: [],
            productCategoriesLevels: [],
            categoryLevel: "",
            showAddMoreFrom: false,
            validateLevelName:false,
        }
        this.handleAddProductCategory = this.handleAddProductCategory.bind(this);
        this.fetchProductCategory = this.fetchProductCategory.bind(this);
    };

    componentDidMount() {
        this.fetchProductCategoryLevels();
    }

    fetchProductCategory = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        categoriesList(organisation_id)
            .then((data) => {
                this.setState({productCategories: data})
            })
            .catch(err => alert(err.message));
    }

    fetchProductCategoryLevels = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        categoriesLevelsList(organisation_id)
            .then((data) => {
                this.setState({productCategoriesLevels: data})
            })
            .catch(err => alert(err.message));
    }

    fetchCategoriesLevelsData = (data) => {
        return data.map(item => {
            return {name: item}
        })
    }

    validateName = (name,productCategoriesLevels) =>{
        let includes = productCategoriesLevels.includes(name);
        let vstate = (!!name&& name.length>0) && !includes ?name: false
        this.setState({validateLevelName: vstate});
    }

    handleAddProductCategory() {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        let {category, productCategoriesLevels} = this.state;
        let categories = productCategoriesLevels.push(category)
        addCategory(organisation_id, categories).then((data) => {
            this.fetchProductCategory();
            this.setState({category: ""})
        })
            .catch(err => alert(err.message));
    }

    handleAddProductCategoryLevel = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        let {categoryLevel, productCategoriesLevels} = this.state;
        productCategoriesLevels.push(categoryLevel)
        addCategoryLevel(organisation_id, productCategoriesLevels).then((data) => {
            this.fetchProductCategoryLevels();
            this.setState({categoryLevel: "",showAddMoreFrom:false});
        })
            .catch(err => alert(err.message));
    }

    handleDeleteProductCategoryLevel = (category) => {
        let {productCategoriesLevels} = this.state;
        const {name} = category;
        let index = productCategoriesLevels.indexOf(name)
        productCategoriesLevels.splice(index, 1)
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        addCategoryLevel(organisation_id, productCategoriesLevels).then((data) => {
            this.fetchProductCategoryLevels();
        })
            .catch(err => alert(err.message));

    }

    render() {
        const {
            productCategoriesLevels,
            showAddMoreFrom,
            validateLevelName,
        } = this.state


        return (
            <div className='companyInfoBlock'>

                <h3>Levels</h3>

                <div className={"product-categories-levels-wrapper"}>


                    <div className={"product-categories-levels"}>
                        {
                            this.fetchCategoriesLevelsData(productCategoriesLevels).map((item,index)=>{
                                return(
                                    <h4>{item.name}</h4>
                                )
                            })
                        }
                    </div>

                    {!showAddMoreFrom &&
                        <div className={"add-more-wrapper"}>
                            <button className={"btn add"} onClick={() => {
                                this.setState({showAddMoreFrom: true})
                            }}>+ add more
                            </button>
                        </div>
                    }

                    {!!showAddMoreFrom &&<Form>
                        <Form.Row>
                            <Form.Group as={Col} controlId="userSelector" className="input-wrapper">
                                <Form.Control
                                    placeholder="Product Category"
                                    onChange={(e) => {
                                        this.setState({categoryLevel: e.target.value});
                                        this.validateName(e.target.value,productCategoriesLevels);
                                    }}
                                    value={this.state.categoryLevel}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="addUserButton" className="button-wrapper">
                                <Button
                                    disabled={!validateLevelName}
                                    className={`add`}
                                    onClick={this.handleAddProductCategoryLevel}>Add</Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                    }
                </div>
            </div>
        )
    }
}

export default ProductCategoriesLevels;
