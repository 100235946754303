import React, { Component } from 'react';
import uuid from 'uuid/v4';
import './Checkbox.css';

class Checkbox extends Component {
  static defaultProps = {
    subLabels: []
  }

  constructor() {
    super();
    this.uuid = uuid();
  }

  render() {
    const { label, checked, defaultChecked, onChange, name, value, subLabels } = this.props;
    const subLabelsList = subLabels.map(subLabel => (<li className="sub-label" key={subLabel}>{subLabel}</li>));
    const isWithSublist = !!subLabelsList.length;
    return (
      <div className="checkbox-container">
        <input
          type="checkbox"
          name={name}
          value={value}
          id={this.uuid}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <div className="checkbox-label-container">
          <label htmlFor={this.uuid} className={`main-label ${isWithSublist ? 'main-label--head' : ''}`}>{label}</label>
          { isWithSublist && (
            <label htmlFor={this.uuid} className="sub-labels-list"><ul>{subLabelsList}</ul></label>
          )}
        </div>
      </div>
    );
  }
}

export default Checkbox;
