import React, { Component } from "react";
import arrowDown from "assets/icons/arrow-down-filled-triangle.svg";
import "./DropdownMenu.css";

class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
    this.dropdownMenu = React.createRef();
  }

  showMenu = () => {
    this.setState(
      {
        isMenuOpen: true
      },
      () => {
        document.addEventListener("click", this.closeMenu);
      }
    );
  };

  closeMenu = e => {
    this.setState(
      {
        isMenuOpen: false
      },
      () => {
        document.removeEventListener("click", this.closeMenu);
      }
    );
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.closeMenu);
  }

  render() {
    const { items, title } = this.props;
    const { isMenuOpen } = this.state;
    const menuItems = items.map(({ label, onClick }) => (
      <button
        type="button"
        className="dropdown-menu-options-list-item"
        key={label}
        onClick={onClick}
      >
        {label}
      </button>
    ));
    return (
      <div className="dropdown-menu">
        <button
          className="dropdown-menu-head-button"
          type="button"
          onClick={this.showMenu}
        >
          <span className="dropdown-menu-title">{title}</span>
          <img
            className="dropdown-menu-arrow"
            src={arrowDown}
            alt="Dropdown Menu down arrow"
          />
        </button>
        {isMenuOpen && (
          <div ref={this.dropdownMenu} className="dropdown-menu-options-list">
            {menuItems}
          </div>
        )}
      </div>
    );
  }
}

export default DropdownMenu;
