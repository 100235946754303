import React, {Component} from 'react';
import getIPLevels from "../../../actions/api/IPHierarchy/ip-levels";
import folderIcon from "../../../assets/icons/folder.svg";
import uniqBy from "lodash/uniqBy";

class IPHierarchyLevels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productCategories: [],
            IPLevels: [],
            IPList: [],
            categoryLevel: "",
            showAddMoreFrom: false,
            validateLevelName: false,
        }
    };

    componentDidMount() {
        this.fetchProductCategoryLevels();

    }

    fetchProductCategoryLevels = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        getIPLevels(organisation_id, organisation_id)
            .then((data) => {
                this.setState({IPLevels: data})
            })
            .catch(err => alert(err.message));
    }

    mapIPHierarchyObject = (mappedIPHierarchyList) => {
        let finalMappedTree = [];
        let map = {};
        mappedIPHierarchyList.forEach((node, node_index) => {
            if (!node.parent_ip_level_id) return finalMappedTree.push(node);
            let parentIndex = map[node.parent_ip_level_id];
            if (parentIndex !== "number") {
                parentIndex = mappedIPHierarchyList.findIndex(el => el.ip_level_id === node.parent_ip_level_id);
                map[node.parent_ip_level_id] = parentIndex;
            }
            if (!mappedIPHierarchyList[parentIndex].children) {
                return mappedIPHierarchyList[parentIndex].children = [node];
            }
            mappedIPHierarchyList[parentIndex].children.push(node);
            mappedIPHierarchyList[parentIndex].children = uniqBy(mappedIPHierarchyList[parentIndex].children, 'ip_level_id');
        })
        let filteredMapedList = mappedIPHierarchyList.filter(item => item.parent_ip_level_id === null);
        return filteredMapedList
    }

    render() {
        const {
            IPLevels,
        } = this.state;
        const Tree = ({data = [], node = {}}) => {
            return (
                <div className="d-tree">
                    <ul className="d-flex d-tree-container flex-column">
                        {data.map((tree) => (
                            <TreeNode node={tree}/>
                        ))}
                    </ul>
                </div>
            );
        };

        const TreeNode = ({node}) => {
            const hasChild = node.children && node.children.length > 0;
            return (
                <li className={`d-tree-node ${hasChild ? 'has-child' : ''}`}>
                    <div className="d-flex">

                        <div className="col d-tree-head">
                            <span className={"node-icon"}>
                                     <img className=""
                                          src={folderIcon}
                                          alt="folder icon"
                                     />
                            </span>
                            <span className={"node-name"}>
                            {node.level_name}
                            </span>
                        </div>
                    </div>
                    {hasChild && (
                        <div className="d-tree-content">
                            <ul className="d-flex d-tree-container flex-column">
                                <Tree node={node} data={node.children}/>
                            </ul>
                        </div>
                    )}
                </li>
            );
        };

        return (
            <div className='companyInfoBlock'>

                <h3>IP Levels</h3>

                <div className={"ip-hierarchy-levels-wrapper"}>

                    {IPLevels && IPLevels.length > 0 && (
                        <div className={"ip-hierarchy-levels"}>
                                <Tree data={this.mapIPHierarchyObject(IPLevels)}/>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default IPHierarchyLevels;
