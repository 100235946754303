import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import AdminHeader from 'components/headers/AdminHeader';
import XelacoreFilesHandler from 'components/XelacoreFilesHandler';

class CommonFiles extends Component {
  constructor(props){
		super(props);
		this.state = {}
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <AdminHeader />
        <div className='x-background'>
          <Container>
            <div className='x-section-heading'>
              <span>Common Files</span>
            </div>
            <XelacoreFilesHandler organisationId={'common'} />
          </Container>
        </div>
      </div>
    );
  }
}

export default CommonFiles
