import React from 'react';
import './Label.css';

function Label({ text, warningSign }) {
  return (
    <div className="label-container">
      { text && <label className="label">{text}</label> }
      { warningSign && <span className="label-warning-sign">{warningSign}</span> }
    </div>
  );
}

export default Label;