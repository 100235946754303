import React, {Component} from 'react';
import { Form, Col } from 'react-bootstrap';
import {connect} from 'react-redux';
import { filter } from 'lodash';
import categoriesList from '../../../actions/api/productCategories/list';
import listAttributes from '../../../actions/api/productCategories/attributes-list';
import './category-levels.scss'
import {displayNotification} from '../../../actions/notifications';
import { attributesTable } from './attributesTable';

import CustomTableWithActions from 'components/CustomTableWithActions';

class ProductCategoryAttributes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productCategories: [],
            attributes: [],
            selectedCategory: ''
        }
    };

    componentDidMount() {
        this.fetchProductCategory();
    }

    fetchProductCategory = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        categoriesList(organisation_id)
            .then((data) => {
                this.setState({
                    productCategories: filter(data.categories, category => {
                        return !category.parent_category_id
                    }),
                    categoryLevels: data.category_levels
                })

                if(!!this.state.productCategories && !!this.state.productCategories[0]) {
                    this.fetchAttributes(this.state.productCategories[0].category_id);
                }
            })
            .catch(err => alert(err.message));
    }

    onChangeCategory(e) {
        this.fetchAttributes(JSON.parse(e.target.value));
    }

    fetchAttributes(selectedCategory) {
        let { selectedOrg } = this.props;
        let { organisation_id } = selectedOrg || {};

        listAttributes(organisation_id, selectedCategory).then((data) => {
            this.setState({ 
                attributes: data,
                selectedCategory
            })
        }).catch(err => alert(err.message));
    }

    render() {
        const {
            productCategories,
            attributes
        } = this.state;

        return (
            <div className='companyInfoBlock category-list-display-builder'>
                <h2>Category Attributes</h2>
                
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="userSelect">
                            <Form.Label>Select Product Category</Form.Label>
                            <Form.Control as="select" 
                                          onChange={(e) => this.onChangeCategory(e)}>
                                {productCategories.map((category) => {
                                    return (
                                        <option key={category.category_id} 
                                                value={JSON.stringify(category.category_id)}>
                                            {category.string_path[0]}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="addUserButton">
                            {/* <Button className="add" onClick={this.handleAddBrand}>Add</Button> */}
                        </Form.Group>
                    </Form.Row>
                </Form>

                <CustomTableWithActions 
                    data={attributes} 
                    tableheader={attributesTable}
                />
            </div>
        )
    }
}

export default connect(
    null,
    {displayNotification}
)
(ProductCategoryAttributes);
