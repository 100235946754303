export const errorTypes = {
    company_information: [
        '',
    ],
    brand_link: [
        'https://xela.co/validation/policy/licensor-brand-link-not-found'
    ],
    licensee_link: [
        'https://xela.co/validation/policy/licensee-link-not-found'
    ],
    link_to_licensor: [
        'https://xela.co/validation/policy/licensor-licensee-link-not-found'
    ],
    employees: [
        'https://xela.co/validation/policy/no-employees-found'
    ],
    aliases: [
        'https://xela.co/validation/policy/duplicate-aliases-found',
        'https://xela.co/validation/policy/no-alias-found'
    ],
    config: [
        'https://xela.co/validation/policy/no-gcp-config-found'
    ],
    brands: [
        'https://xela.co/validation/policy/no-brands-found'
    ],
    product_categories: [
        'https://xela.co/validation/policy/unexpected-category-level-name',
        'https://xela.co/validation/policy/category-levels-not-defined',
        'https://xela.co/validation/policy/sub-categories-not-found',
        'https://xela.co/validation/policy/category-level-not-found-in-ingest-map',
        'https://xela.co/validation/policy/category-mapping-not-found',
        'https://xela.co/validation/policy/no-category-root-found'
    ],
    modules: [
        'https://xela.co/validation/policy/module-not-found',
        'https://xela.co/validation/policy/no-application-modules-found'
    ],
    my_files: [

    ],
    export: [

    ],
    retailer_listings: [

    ],
    ip_hierarchy: [
        'https://xela.co/validation/policy/no-ip-levels-found',
        'https://xela.co/validation/policy/invalid-root-ip-levels',
        'https://xela.co/validation/policy/unexpected-ip-level-name',
        'https://xela.co/validation/policy/invalid-number-of-root-ip-levels',
        'https://xela.co/validation/policy/no-matching-ip-found',
        'https://xela.co/validation/policy/no-matching-brand-found',
        'https://xela.co/validation/policy/ip-brand-name-mismatch',
        'https://xela.co/validation/policy/ip-level-not-found-in-ingest-map'
    ],
    concepts_upload: [

    ]
  }
