import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, fileName, meta) {
  try {
    let call = {
      url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/myFiles/upload`,
      method: 'post',
      data: { 
        file: {
          name: fileName
        },
        meta
      },
      headers: {
        'Content-Type': 'application/json'
      },
    }
    let { uploadRequest = {} } = await callHandler(call);
    return uploadRequest;
  } catch (err) {
    console.error(`Error uploading file`, err);
    return;
  }
}