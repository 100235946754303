import React from 'react';
import searchIcon from 'assets/icons/search.svg';
import './DataCaptureTableColumnHeader.css';

function DataCaptureTableColumnHeader({
  title, count, onRemove, columnFor
}) {
  return (
    <div className="data-capture-table-column-header">
      <div className="data-capture-table-column-header-left">
        <div className="data-capture-table-column-header-left-main">
          <img className="data-capture-table-column-header-icon" src={searchIcon} alt="Search icon" />
          <span className="data-capture-table-column-header-title">{title}</span>
          {!!count && <span className="data-capture-table-column-header-count">({count})</span>}
        </div>
        {columnFor && (
          <div className="data-capture-table-column-header-left-bottom">
            {columnFor}
          </div>
        )}
      </div>
      {onRemove && (
        <div className="data-capture-table-column-header-right">
          <button
            type="button"
            className="data-capture-table-column-header-remove"
            onClick={onRemove}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
}

export default DataCaptureTableColumnHeader;
