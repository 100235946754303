import produce from "immer";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { FORM_UPDATE } from "actions/form/update";
import { FORM_ERASE } from "actions/form/erase";
import { FORM_INIT } from "actions/form/init";
import { TABLE_UPDATE } from "actions/form/table";

const initialState = {
  company_information: {
    legal_company_name: '',
    company_registration_number: '',
    main_office_company_address: '',
    main_office_city: '',
    main_office_country: '',
    main_office_postcode_zipcode: '',
    billing_location_address: '',
    billing_location_address_city: '',
    billing_location_address_country: '',
    billing_location_address_postcode_zipcode: '',
    gcp_list: [],
    subsidiaries: [],
    trade_names: [],
    doing_business_as: [],
    account_type: ''
  },
  contacts_information: {
    relationship_executive: {
      title: 'Mr',
      name: '',
      email: '',
      biz_phone: '',
      mobile_phone: '',
      office_address: '',
      city: '',
      country: '',
      postcode_zipcode: '',
      pa_name: '',
      pa_email: '',
      pa_phone: ''
    },
    primary_ambassador: {
      title: 'Mr',
      name: '',
      email: '',
      biz_phone: '',
      mobile_phone: '',
      office_address: '',
      city: '',
      country: '',
      postcode_zipcode: '',
      pa_name: '',
      pa_email: '',
      pa_phone: ''
    },
    marketing_contact: {
      title: 'Mr',
      name: '',
      email: '',
      biz_phone: '',
      mobile_phone: '',
      office_address: '',
      city: '',
      country: '',
      postcode_zipcode: '',
      pa_name: '',
      pa_email: '',
      pa_phone: ''
    },
    finance_contact: {
      title: 'Mr',
      name: '',
      email: '',
      biz_phone: '',
      mobile_phone: '',
      office_address: '',
      city: '',
      country: '',
      postcode_zipcode: '',
      pa_name: '',
      pa_email: '',
      pa_phone: ''
    },
    additional: {}
  },
  data: {
    // table data is placed here
    territories: []
  }
};

export default function form(state = initialState, action) {
  switch (action.type) {
    case FORM_INIT:
      const newState = cloneDeep(state);
      merge(newState, action.payload);
      return newState;
    case FORM_UPDATE:
      const { path, value } = action.payload;
      return produce(state, draft => {
        set(draft, path, value);
      })
    case FORM_ERASE:
      return {
        ...initialState
      };
    case TABLE_UPDATE:
      const { valuePath, newValue } = action.payload;
      return produce(state, draft => {
        set(draft.data, `${valuePath}`, newValue);
      });
    default:
      return {
        ...state
      };
  }
}
