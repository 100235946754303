import React from 'react';
import { ReactReduxContext } from 'react-redux';
import FormContent from './FormContent';

function Form(props) {
  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <FormContent store={store} {...props} />
      )}
    </ReactReduxContext.Consumer>
  );
}

export default Form;
