import React, { Component } from 'react';
import isArray from 'lodash/isArray';
import Label from 'components/texts/Label';
import './TextArea.css';

class TextArea extends Component {
  onChange = event => {
    const { name, value } = event.target;
    this.props.onChange({
      name,
      value: this.props.isSingleValue ? value : value.split('\n')
    });
  }

  render() {
    const { label, rows, name, id, value, warningSign, placeholder, notValid, isSingleValue } = this.props;
    const textareaValue = (isSingleValue || !isArray(value))
      ? value
      : value.join('\n');
    return (
      <div className={`text-area-container ${isSingleValue ? 'single-value' : ''}`}>
        <Label text={label} warningSign={warningSign} />
        <textarea
          id={id}
          className={`text-area ${notValid ? 'not-valid' : ''}`}
          name={name}
          rows={rows}
          value={textareaValue}
          onChange={this.onChange}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export default TextArea;
