import { AUTH_SUCCESS } from "actions/auth/login";
import { LOG_OUT } from "actions/auth/logout";
import { getAuthStorage } from "utils/storage";
import { storageKeys } from "constants/storage";

const getInitialState = () => {
  const authStorage = getAuthStorage();
  return {
    name: authStorage ? authStorage.name : "",
    org_access_token: authStorage ? authStorage.org_access_token : "",
    user_refresh_token: authStorage ? authStorage.user_refresh_token : "",
    selected_org_id: authStorage ? authStorage.selected_org_id : "",
  };
};

export default function auth(state = getInitialState(), action) {
  switch (action.type) {
    case AUTH_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    case LOG_OUT:
      return {
        ...state,
        name: "",
        org_access_token: "",
        user_refresh_token: "",
        selected_org_id: ""
      };
    default:
      return state;
  }
}
