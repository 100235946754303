export const getIncreasingArray = (n, start) => Array.from(Array(n).keys()).map(i => i + start);

const getArrayValuePath = (obj, item) => {
  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    if (obj[key] && typeof obj[key] === "object") {
      var result = getArrayValuePath(obj[key], item);
      if (result) {
        result.unshift(key);
        return result;
      }
    } else if (obj[key] === item) {
      return [key];
    }
  }
}

/**
 * returns path to search value in object
 * @param {*} obj object where to search
 * @param {*} value value for which to search
 */
export const getValuePath = (obj, value) => {
  var path = getArrayValuePath(obj, value);
  if (path == null) {
    return "";
  }
  return path.join('.');
}

/**
 * returns path to all parent values
 * input: 'path.to.attribute'
 * output: ['path', 'path.to', 'path.to.attribute']
 */
export const getIncreasingSubpaths = ({ path, splitter, finalKey, finalValue }) => {
  const pathAttrs = path.split(splitter);
  pathAttrs.pop();
  return pathAttrs.map((_, i) => ({
    name: pathAttrs.slice(0, i + 1).join(splitter) + finalKey,
    values: pathAttrs.slice(0, i + 1).join(splitter) + finalValue
  }));
}

export const getNotEmptyRows = stringValue => stringValue.split('\n').filter(value => value.trim())
