import React, { Component } from 'react';
import { Button, Container, Nav } from 'react-bootstrap';

import {
  getPreSignedUrl,
  uploadFile,
  triggerIngest,
} from 'actions/api/organisations/exportUpdate';

import draftTemplate from 'assets/files/Xelacore-Retailer-Listing-Template-Licensee.csv'
import RetailersFilePond from './RetailersFilePond';
import ErrorAlert from 'components/ErrorAlert';
import DataTable from '../ExportUpdate/components/DataTable';
import list from 'actions/api/ingestions/list'
import listExports from 'actions/api/exports/list'
import memoize from 'memoize-one';
import Pagination from 'components/lists/Pagination';
import { getPageItems, getPagesCount } from "utils/pagination";
import { sortBy } from 'lodash'
import { connect } from "react-redux";
import { addRetailerListingExports, addRetailerListingIngestions } from 'actions/retailerListings'
import { exportRecords } from 'actions/api/organisations/retailerListings';


class RetailerListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'download',
      fetching: false,
      updatePondId: new Date(),
      deletePondId: new Date(),
      currentExportPage: 1,
      currentUpsertPage: 1,
      currentDeletePage: 1,
      itemsPerPage: 10,
      ingestions: [],
      errorMessage: ''
    }
  };

  componentDidMount() {
    list('xelacore-retailer-listings', 'upsert', this.props.selectedOrg.organisation_id).then((ingestions) => {
      this.props.addRetailerListingIngestions(ingestions)
    })
    list('xelacore-retailer-listings', 'delete', this.props.selectedOrg.organisation_id).then((ingestions) => {
      this.props.addRetailerListingIngestions(ingestions)
    })
    listExports(this.props.selectedOrg.organisation_id, 'xelacore-retailer-listings').then((exports) => {
      this.props.addRetailerListingExports(exports)
    })
  }

  onPageChanged = (currentPage) => {
    switch (this.state.tab) {
      case 'download':
        this.setState({ currentExportPage:currentPage });
        break;
      case 'upsert':
        this.setState({ currentUpsertPage:currentPage });
        break;
      case 'delete':
        this.setState({ currentDeletePage:currentPage });
        break;

      default:
        this.setState({ currentPage });
    }

  };

  handleExportRecords = () => {
    this.setState({
      fetching: true
    });
    return exportRecords(this.props.selectedOrg.organisation_id).then(() => {
      this.setState({
        errorMessage: '',
        fetching: false
      })
    }, (error) => {
      this.setState({
        errorMessage: 'There was an error with your request.',
        fetching: false
      })
    });
  };

  uploadRetailers = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
    let { selectedOrg } = this.props;

    const request = new XMLHttpRequest();

    let sourceInfo;
    getPreSignedUrl(selectedOrg.organisation_id, 'private', 'catalog/import', 'csv')
      .then(presignedUrl => { sourceInfo = presignedUrl.sourceInfo; return presignedUrl })
      .then(presignedUrl => { return uploadFile(file, presignedUrl) })
      .then(() => triggerIngest(sourceInfo, selectedOrg.organisation_id, null, 'xelacore-retailer-listings', 'upsert', file.name || 'undefined'))
      .then(() => setTimeout(() => this.setState({ pondId: new Date() }), 4000))
      .then(load(request.responseText), () => { error('There was an error with your request.') })
  };

  deleteRetailers = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
    let { selectedOrg } = this.props;

    const request = new XMLHttpRequest();

    let sourceInfo;
    getPreSignedUrl(selectedOrg.organisation_id, 'private', 'catalog/delete', 'csv')
      .then(presignedUrl => { sourceInfo = presignedUrl.sourceInfo; return presignedUrl })
      .then(presignedUrl => { return uploadFile(file, presignedUrl) })
      .then(() => triggerIngest(sourceInfo, selectedOrg.organisation_id, undefined, 'xelacore-retailer-listings', 'delete', file.name || 'undefined'))
      .then(() => setTimeout(() => this.setState({ pondId: new Date() }), 4000))
      .then(load(request.responseText), () => { error('There was an error with your request.') })
  };

  toggleView = (e, navtab) => {
    e.preventDefault(); // keep on page
    this.setState({ tab: navtab });
  }

  paginateList = memoize(getPageItems);
  paginateCount = memoize(getPagesCount);
  getIngestionList = memoize((history) =>
    sortBy(history, 'createdAt').reverse());

  render() {
    const { selectedOrg, errors, revalidate } = this.props;
    const { errorMessage } = this.state;
    const exportList = this.getIngestionList(this.props.exports)
    const paginatedExportList = this.paginateList(exportList, this.state.currentExportPage, this.state.itemsPerPage);
    const upsertList = this.getIngestionList(this.props.upserts)
    const paginatedUpsertList = this.paginateList(upsertList, this.state.currentUpsertPage, this.state.itemsPerPage);
    const deleteList = this.getIngestionList(this.props.deletes)
    const paginatedDeleteList = this.paginateList(deleteList, this.state.currentDeletePage, this.state.itemsPerPage);

    return (
      <div className="export-update data-capture-section">
        <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>

        <Container fluid className="">
          <div className="export-header">
            <h2>
              {selectedOrg.name}
            </h2>

            <Nav defaultActiveKey="download" className="mt--2 pr-0">
              <Nav.Item>
                <Nav.Link className="mr-2 pill" onClick={e => this.toggleView(e, 'download')}>Download Listings</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="pill mr-2 ml-0" onClick={e => this.toggleView(e, 'upsert')}>Upload Listings</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="pill mr-2 ml-0" onClick={e => this.toggleView(e, 'delete')}>Delete Listings</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          {
            this.state.tab === 'download' && (
              <>
                <div className="export-actions">
                  <Button
                    disabled={this.state.fetching}
                    className="add ml-0 mt-3 mb-4"
                    onClick={() => this.handleExportRecords()}>
                    Download Listings
                  </Button>

                  {errorMessage && (
                    <span className="error-message ml-2">
                      {errorMessage}
                    </span>
                  )}
                </div>
                <DataTable
                  takesLicensor={false}
                  organisations={this.props.organisations}
                  entries={paginatedExportList}
                />
                <Pagination
                  current={this.state.currentExportPage}
                  total={this.paginateCount(exportList, this.state.itemsPerPage)}
                  onPageChanged={this.onPageChanged}
                />
              </>
            )
          }

          {
            this.state.tab === 'upsert' && (
              <>
                <a href={draftTemplate} download="Xelacore-Retailer-Listing-Template-Licensee">
                  Download Blank Template
                </a>
                <RetailersFilePond
                  title={'Upload Create/Update feed'}
                  processFile={this.uploadRetailers}
                  name={'upload'}>
                </RetailersFilePond>
                <DataTable
                  takesLicensor={false}
                  organisations={this.props.organisations}
                  entries={paginatedUpsertList}
                  takesRecordsRejected={true}
                />
                <Pagination
                  current={this.state.currentUpsertPage}
                  total={this.paginateCount(upsertList, this.state.itemsPerPage)}
                  onPageChanged={this.onPageChanged}
                />
              </>
            )
          }

          {
            this.state.tab === 'delete' && (
              <>
                <RetailersFilePond
                  key={this.state.deletePondId}
                  title={'Upload Delete feed'}
                  processFile={this.deleteRetailers}
                  name={'remove'}>
                </RetailersFilePond>
                <DataTable
                  takesLicensor={false}
                  organisations={this.props.organisations}
                  entries={paginatedDeleteList}
                  takesRecordsRejected={true}
                />
                <Pagination
                  current={this.state.currentDeletePage}
                  total={this.paginateCount(deleteList, this.state.itemsPerPage)}
                  onPageChanged={this.onPageChanged}
                />
              </>
            )
          }
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    upserts: state.retailerListings.ingestions.upsert,
    deletes: state.retailerListings.ingestions.delete,
    exports: state.retailerListings.exports['xelacore-retailer-listings'],
  }
}

export default connect(mapStateToProps, { addRetailerListingExports, addRetailerListingIngestions })(RetailerListings);
