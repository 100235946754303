import React, { Component } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import OrgTypeBanner from './OrgTypeBanner';
import './OrgAdminNavHeader.css';

class OrgAdminNavHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInView: null
    };
  }

  render() {
    const { selectedOrg = {} } = this.props;
    return (
      <div className='data-capture-page-nav-header'>
        <Container>
          <Row className="m-0">
            <Col>
              <Row>
                <div className='data-capture-page-nav-header-text'>
                  {selectedOrg.name}
                </div>
              </Row>
              <Row>
                <OrgTypeBanner type={selectedOrg.type} />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default OrgAdminNavHeader;
