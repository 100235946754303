
import {
  ADD_RETAILER_LISTING_EXPORTS,
  ADD_RETAILER_LISTING_INGESTIONS,
} from "actions/retailerListings";
import { groupBy } from "lodash";

const getInitialState = () => ({
  ingestions: {
    upsert: {},
    delete: {},
  },
  exports: {

  }
});

export default function retailerListings(state = getInitialState(), action) {
  switch (action.type) {
    case ADD_RETAILER_LISTING_INGESTIONS: {
      const newState = {
        ...state,
        ingestions: {
          ...state.ingestions
        }
      }

      const grouped = groupBy(action.payload, 'licenseeOrganisationId')
      Object.keys(grouped).forEach(key => {
        grouped[key].forEach(item => {
          newState.ingestions[item.jobType] = newState.ingestions[item.jobType] ? { ...newState.ingestions[item.jobType] } : {} // New job types will be added but probably unused
          newState.ingestions[item.jobType][item.ingestionId] = item
        })
      })

      return newState;
    }
    case ADD_RETAILER_LISTING_EXPORTS: {
      const newState = {
        ...state,
      }

      const grouped = groupBy(action.payload, 'licenseeOrganisationId')
      Object.keys(grouped).forEach(key => {
        grouped[key].forEach(item => {
          newState.exports[item.jobType] = newState.exports[item.jobType] ? { ...newState.exports[item.jobType] } : {} // New job types will be added but probably unused
          newState.exports[item.jobType][item.catalogExportId] = item
        })
      })

      return newState;
    }
    default:
      return state;
  }
}
