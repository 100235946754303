import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import GeneralAuthWrapper from 'components/wrappers/GeneralAuthWrapper';
import ListNavBar from 'components/menus/ListNavBar';
import Licensors from './Licensors';
import Licensees from './Licensees';
import './List.css';

class List extends Component {
  render() {
    return (
      <GeneralAuthWrapper>
        <div className="data-list">
          <div className="list-nav-bar-container">
            <ListNavBar />
          </div>
          <div className="list-container">
            <Route path='/list/licensors' component={Licensors} />
            <Route path='/list/licensees' component={Licensees} />
          </div>
        </div>
      </GeneralAuthWrapper>
    );
  }
}

export default List;
