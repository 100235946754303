import React, { Component } from 'react';
import DataCaptureFormTable from 'components/dataCaptureFormComponents/DataCaptureFormTable';
import DataCaptureTableColumn from 'components/dataCaptureTableComponents/DataCaptureTableColumn';
import ExpandableTextArea from 'components/inputs/ExpandableTextArea';
import { BRAND_HIERARCHY_SCHEME } from 'constants/structure';

class BrandHierarchy extends Component {
  render() {
    const { label } = this.props;
    return (
      <DataCaptureFormTable
        label={label}
        scheme={BRAND_HIERARCHY_SCHEME}
      >
        <DataCaptureTableColumn
          title="Brands"
          name="brand"
          withSelectAll
          addData={{
            Component: ExpandableTextArea,
            buttonText: "Add Brands"
          }}
        />
        <DataCaptureTableColumn
          title="Franchises"
          name="franchise"
          withSelectAll
          addData={{
            Component: ExpandableTextArea,
            buttonText: "Add Franchises"
          }}
        />
        <DataCaptureTableColumn
          title="Characters"
          name="character"
          withSelectAll
          addData={{
            Component: ExpandableTextArea,
            buttonText: "Add Characters"
          }}
        />
      </DataCaptureFormTable>
    );
  }
}

export default BrandHierarchy;
