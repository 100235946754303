import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, type) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/links/${type}`
  }
  let { links } = await callHandler(call);
  return links;
}