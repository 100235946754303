import axios from 'axios';
import config from 'config';
import CryptoJS from 'crypto-js';
import forEach from 'lodash/forEach';
import responseHandler from '../common/responseHandler';
import fileDownload from '../common/fileDownload';

const axiosUpload = axios.create({ headers: { Authorization: '' } });

export function exportRecords(licenseeOrganisationId, licensorOrganisationId) {
    return axios({
        method: "get",
        url: `${config.get('ADMIN_URL')}organisations/${licenseeOrganisationId}/records/${licensorOrganisationId}`,
        params: {},
    })
}

export function getPreSignedUrl(organisationId, repositoryId, category, extension) {
    const url = `${config.get('ADMIN_URL')}organisations/${organisationId}/upload`
    const params = {
        repositoryId,
        category,
        extension
    };

    return axios
        .request({
            url,
            method: 'get',
            params,
        })
        .then((response) => {
            const data = responseHandler(response, 'data');
            var decrypted = CryptoJS.AES.decrypt(
                data,
                'process.env.NODE_ENV'
            ).toString(CryptoJS.enc.Utf8);

            return JSON.parse(decrypted);
        })
}

export function uploadFile(file, presignedUrl) {
    const { fields, url } = presignedUrl

    return new Promise((resolve, reject) => {
        const data = new FormData();
        forEach(fields, (val, key) => data.append(key, val));
        data.append('file', file);

        return axiosUpload
            .request({
                url,
                method: 'post',
                data
            })
            .then((result) => resolve(result))
            .catch((err) => reject(err));
    });
}

export function triggerIngest(file, licenseeOrganisationId, licensorOrganisationId, destination, jobType, label) {
    try {
        return axios({
            method: 'post',
            url: `${config.get('ADMIN_URL')}data/${destination}/file`,
            data: {
                job_type: jobType,
                licensee_organisation_id: licenseeOrganisationId,
                licensor_organisation_id: licensorOrganisationId,
                file,
                label,
            }
        }).then((response) => {
            return response && response.data
        });
    } catch (error) {
        return error;
    }
}

export function getFileDownloadUrl(organisationId, repositoryId, category, filename) {
    try {
        const url = `${config.get('ADMIN_URL')}organisations/${organisationId}/retrieve`

        const params = {
            repositoryId,
            category,
            filename
        };

        return axios({
            method: 'GET',
            url,
            params,
        }).then((response) => {
            let data = responseHandler(response, 'data');
            const decrypted = CryptoJS.AES.decrypt(
                data,
                'process.env.NODE_ENV'
            ).toString(CryptoJS.enc.Utf8);

            return decrypted.slice(1, -1);
        });
    } catch (error) {
        return error;
    }
}

export function getRejectedRecordsFileDownloadUrl(ingestId, organisationId, fileName) {
    try {
        return axios({
            method: 'get',
            url: `${config.get('ADMIN_URL')}organisations/${organisationId}/ingest/${ingestId}/rejected_records/downloads`,
            headers: {
                Accept: 'text/csv'
            }
        }).then((response) => {
            const ingestion = responseHandler(response, 'data');
            return getFileDownloadUrl(organisationId, ingestion.sourceInfo.repositoryId, ingestion.sourceInfo.category, ingestion.sourceInfo.filename)
        });
    } catch (error) {
        return error;
    }
}

export function downloadFromUrl(url) {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
