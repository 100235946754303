import React, {Component} from 'react';
import {connect} from 'react-redux';
import deepmerge from 'deepmerge';
import {Form, Container, Nav} from 'react-bootstrap';
import TextArea from '../../inputs/TextArea';
import ButtonWithConfirm from 'components/ButtonWithConfirm';
import PasswordConfirmation from 'components/PasswordConfirmation';
import getOrgConfig from 'actions/api/orgConfig/get';
import updateOrgConfig from 'actions/api/orgConfig/update';
import nukeRecords from 'actions/api/organisations/nukeRecords';
import GtinWaiver from './GtinWaiver';
import ErrorAlert from 'components/ErrorAlert';

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

function globalStateMapper(globalState) {
    return {};
}

class OrgConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orgConfig: {},
            orgConfigId: null,
            tab: this.props.selectedOrg.type !== 'licensee' ? 'validation':'basic',
            selectedUser: null,
            nukeDisabled: false
        };

        this.handleUpdateConfig = this.handleUpdateConfig.bind(this);
        this.fetchOrgConfig = this.fetchOrgConfig.bind(this);
    };

    componentDidMount() {
        this.fetchOrgConfig();
    }

    onOrgConfigChange = ({name, value}) => {
        const {orgConfig = {}} = this.state;
        this.setState({orgConfig: deepmerge.all([orgConfig, {[name]: value}], {arrayMerge: overwriteMerge})});
    };

    fetchOrgConfig() {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        getOrgConfig(organisation_id)
            .then((data) => {
                let {config, config_id} = data;
                this.setState({orgConfig: config, initialOrgConfig: config, orgConfigId: config_id})
            })
            .catch(err => alert(err.message));
    }

    handleUpdateConfig() {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        let {orgConfig, orgConfigId} = this.state;
        this.props.updateOrgConfig(organisation_id, orgConfigId, orgConfig).then((data) => {
            this.fetchOrgConfig();
        });
    }

    toggleView = (e, navtab) => {
        e.preventDefault(); // keep on page
        this.setState({tab: navtab});
    };

    onUpdateOrgDetails = (newAddOrgData) => {
        const {addOrgData} = this.props;
        this.props.handleUpdateAddOrgData({...addOrgData, ...newAddOrgData});
    };

    nukeRecords = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        this.setState({nukeDisabled: true});

        this.props.nukeRecords(organisation_id).then(() => {
            this.setState({nukeDisabled: false});
        });
    };

    render() {
        const {orgConfig = {}, nukeDisabled} = this.state;
        const {selectedOrg = {}, errors, revalidate} = this.props;
        const {name} = selectedOrg;
        const confirmMessage = 'You are about to update this organisations config, this will have a direct affect on parts of the LIVE SYSTEM';
        return (
            <div className="company-informtion data-capture-section">
                <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>

                <Container fluid className="">
                    <h2>{name}</h2>

                    <Nav defaultActiveKey="info" className="mb-2 pr-0">
                        {selectedOrg.type === 'licensee' && (<Nav.Item>
                            <Nav.Link className="mr-5 pill" onClick={e => this.toggleView(e, 'basic')}>Basic</Nav.Link>
                        </Nav.Item>)}
                        {selectedOrg.type === 'licensee' && (<Nav.Item>
                            <Nav.Link className="pill mr-5" onClick={e => this.toggleView(e, 'exemption')}>GTIN
                                Exemption</Nav.Link>
                        </Nav.Item>)}
                        <Nav.Item>
                            <Nav.Link className="pill mr-5" onClick={e => this.toggleView(e, 'validation')}>
                                Validation
                            </Nav.Link>
                        </Nav.Item>
                        {selectedOrg.type === 'licensee' && (
                            <Nav.Item>
                                <Nav.Link className="pill ml-0"
                                          onClick={e => this.toggleView(e, 'nuke')}>Nuke</Nav.Link>
                            </Nav.Item>)
                        }
                    </Nav>

                    {this.state.tab === 'basic'
                        ?
                        <div>
                            <h2>Basic</h2>
                            <Form>
                                <Form.Row>
                                    <Form.Group className="gcp_list mb-0 ml-1 " controlId="gcpList">
                                        <TextArea
                                            name="gcp_list"
                                            label="List of Global Company Prefixes"
                                            warningSign="Put each GCP on a separate line"
                                            onChange={this.onOrgConfigChange}
                                            value={orgConfig.gcp_list}
                                            rows={6}
                                            className="mb-0"
                                        />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <ButtonWithConfirm className="submit" onClick={this.handleUpdateConfig}
                                                       message={confirmMessage}>Update</ButtonWithConfirm>
                                </Form.Row>
                            </Form>
                        </div>
                        : this.state.tab === 'exemption'
                            ?
                            <div>
                                <h2>GTIN Exemption</h2>
                                <GtinWaiver
                                    organisationId={selectedOrg.organisation_id}
                                    org_waiver_status={orgConfig.gtin_waiver}
                                    fetchOrgConfig={this.fetchOrgConfig}
                                />
                            </div>
                            : this.state.tab === 'validation'
                                ?
                                <div className={`validation-tabulate-wrapper`}>

                                    <div className={`validation-status`}>
                                        <h4 className="mt-4 mb-4">Validations:</h4>
                                        <button className={`${orgConfig.ignore_validations ? 'disabled' : 'enabled'}`}
                                                disabled={true}>
                                            {orgConfig.ignore_validations ? 'Disabled' : 'Enabled'}
                                        </button>
                                    </div>
                                    <div className={``}>
                                    <span className='submit btn btn-primary mb-5'
                                          onClick={() => revalidate()}>Re-validate</span>

                                    </div>
                                </div> :
                                <div>
                                    <h2 className="mt-4 mb-4">Nuke</h2>

                                    <Form.Row>
                                        <PasswordConfirmation
                                            onClick={() => this.nukeRecords()}
                                            disabled={nukeDisabled}
                                            className="submit">
                                            Nuke records
                                        </PasswordConfirmation>
                                    </Form.Row>
                                </div>
                    }
                </Container>
            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    {
        updateOrgConfig,
        nukeRecords
    }
)(OrgConfig);
