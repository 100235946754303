import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import login from "actions/auth/login";
import { displayNotification } from "actions/notifications";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { routeOnSuccessAuth } from "constants/routes";

class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { org_access_token } = this.props;
    if(!org_access_token) {
      this.props.login().then(() => {
        if (!!window.location && window.location.pathname.length > 1) {
          window.location.replace(window.location.pathname);
        } else {
          window.location.replace(routeOnSuccessAuth);
        }
      })
    } else {
      window.location.replace(routeOnSuccessAuth);
    }
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      org_access_token: state.auth.org_access_token || ''
    }),
    {
      login,
      displayNotification
    }
  )
)(Home);