import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/categories/mappings`
  }
  let { category_mappings, } = await callHandler(call);
  return { category_mappings };
}
