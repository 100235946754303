import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";

export default function(selectedUser, newPassword) {
  return async dispatch => {
    try {
      const { user_id = null } = selectedUser;
      let call = {
        url: `${Config.get('ADMIN_URL')}users/${user_id}/changePassword`,
        method: 'post',
        data: { 
          user: selectedUser, 
          newPassword: newPassword,
        },
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let addResp = await callHandler(call);
      dispatch(displayNotification({
        message: 'Successfully changed users password',
        type: TYPE_SUCCESS,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
      return addResp;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error changing users password',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}