import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

class UploadSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    }
  };

  render() {
    return (
      <>
        <Container>
          <h2 className="mb-4">
            Upload Update feed
          </h2>

          <FilePond
            disabled={this.props.disabled}
            files={this.state.files}
            className="mb-4"
            acceptedFileTypes={['text/csv']}
            allowMultiple={false}
            instantUpload={false}
            allowRemove={true}
            allowRevert={false}
            allowReplace={true}
            server={{
              process: this.props.onProcess
            }}
            onupdatefiles={fileItems => {
              this.setState({ files: fileItems.map(fileItem => fileItem.file) });
            }}
          />

          <p className="alert-info p-3">Please use only files recently exported via the Export functionality.</p>
        </Container>
      </>
    )
  }
}

export default UploadSection
