import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, brandId) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/brands/${brandId}/attributes/static`
  }
  let { static_attribute_definitions } = await callHandler(call);
  return static_attribute_definitions;
}