import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import './Table.css';

class Table extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string, // refers to the attribute name
            label: PropTypes.string // title of the column
        })),
        data: PropTypes.arrayOf(PropTypes.object),
        filterQuery: PropTypes.objectOf(PropTypes.string)
    }

    render() {
        const {columns, data, idField = 'id', link, onSort, orderBySettings} = this.props;
        const rows = data.map((entry) => (
            <TableRow key={entry[idField]} uid={entry[idField]} data={entry} columns={columns} link={link}/>
        ));
        return (
            <div className="list-table">
                <TableHead
                    columns={columns}
                    onSort={onSort}
                    orderBySettings={orderBySettings}
                    filterQuery={this.props.filterQuery}
                    onUpdateFilterFields={this.props.onUpdateFilterFields}
                />
                <div className="list-table-body">
                    {rows}
                </div>
            </div>
        );
    }
}

export default Table;
