import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './TableRow.css';
import Icon from 'components/icon';
import {isArray, isString} from "lodash";

class TableRow extends Component {

    render() {
        const {data, columns, link, uid} = this.props;

        const cells = columns.map(({name}) => (<div className={`table-body-td ${name}`} key={name}>
            <div className="table-cell">
          <span className="table-cell-value">
            {name === 'error' && data[name] === true && (<Icon
                icon={'error'}
                size={22}
                fill="red"
            />)}

              {name !== 'error' && isString(data[name]) && data[name]}
              {isArray(data[name]) && name === 'aliases' && (
                  <ul className={'aliases-list'}>
                      {data[name].map(arrayItem => (
                          <li>{arrayItem.alias_name}</li>))}
                  </ul>)}

          </span>
            </div>
        </div>));
        return (

            <Link to={`/${link}/${uid}`} className="table-row-link">
                <div className="table-row">
                    {cells}
                </div>
            </Link>

        );
    }
}

export default TableRow;
