import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form, Col, Container, Button, Modal,} from 'react-bootstrap';
import inviteUser from 'actions/api/users/invite';
import listUsers from 'actions/api/users/list';
import {displayNotification} from 'actions/notifications';
import {DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS} from 'constants/notifications';
import Checkbox from "../inputs/Checkbox";

class InviteUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invitedUserFirstName: '',
            invitedUserLastName: '',
            invitedUserEmail: '',
            inviteUserPermissionCanInvite: false
        }
    }

    handleUpdateUserDetails = (newUserdata) => {
        this.setState(newUserdata);
    }

    handlePermissionCanInvite = (e) => {
        this.setState({
            inviteUserPermissionCanInvite: e.currentTarget.checked
        });
    }

    handleSubmitUserDetails = () => {
        const {organisationId = null, togglePopUp} = this.props;
        const {invitedUserEmail, invitedUserFirstName, invitedUserLastName, inviteUserPermissionCanInvite} = this.state;
        inviteUser(organisationId, invitedUserEmail, invitedUserFirstName, invitedUserLastName, inviteUserPermissionCanInvite)
            .then((invitedUser) => {

                this.setState({
                    invitedUserFirstName: '',
                    invitedUserLastName: '',
                    invitedUserEmail: '',
                    inviteUserPermissionCanInvite: false
                });
                this.props.displayNotification({
                    message: `Successfully added user ${organisationId ? `and created employee for this organisation` : ''}`,
                    type: TYPE_SUCCESS,
                    timeout: DEFAULT_NOTIFICATION_TIMEOUT
                });
                togglePopUp();
            }).catch(err => {

            this.props.displayNotification({
                message: `Failed to invite user ${organisationId ? `and employee` : ''}`,
                type: TYPE_ERROR,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            })
        });

    }

    render() {
        const {organisationId = null, show = false, togglePopUp} = this.props;
        const {invitedUserFirstName, invitedUserLastName, invitedUserEmail, inviteUserPermissionCanInvite} = this.state;
        return (
            <div className="x-add-org-modal">
                <Modal show={show} onHide={togglePopUp}>
                    <Modal.Header closeButton>
                        <Modal.Title>Invite User {organisationId ? 'And Employee' : ''}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridName">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            placeholder="Enter user email"
                                            onChange={(e) => {
                                                this.handleUpdateUserDetails({invitedUserEmail: e.target.value})
                                            }}
                                            value={invitedUserEmail}
                                        />
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            placeholder="Enter user first name"
                                            onChange={(e) => {
                                                this.handleUpdateUserDetails({invitedUserFirstName: e.target.value})
                                            }}
                                            value={invitedUserFirstName}
                                        />
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            placeholder="Enter user last name"
                                            onChange={(e) => {
                                                this.handleUpdateUserDetails({invitedUserLastName: e.target.value})
                                            }}
                                            value={invitedUserLastName}
                                        />


                                        <Checkbox
                                            checked={inviteUserPermissionCanInvite}
                                            onChange={this.handlePermissionCanInvite}
                                            label={'Can Invite other users'}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={togglePopUp}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleSubmitUserDetails}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default connect(
    null,
    {listUsers, displayNotification}
)(InviteUserModal);
