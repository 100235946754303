import React, { Component } from 'react';

class UserAdminNavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { label, onNavSelect, selectedNavKey, id } = this.props;
    return (
      <button
        type="button"
        className={`admin-nav-item ${id === selectedNavKey ? 'selected' : ''}`}
        onClick={() => onNavSelect(id)}
      >
        {label}
      </button>
    );
  }
}

export default UserAdminNavItem;
