import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";

export default function(organisationId, {gtin_waiver, gtin_reason, gtin_requesting_username, gtin_requesting_user_id}) {
  return async dispatch => {
    try {
      if (!gtin_reason) throw new Error('No reason provided');
      if (!gtin_requesting_username) throw new Error('no requesting_username provided');

      let call = {
        url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/gtinwaiver`,
        method: 'post',
        data: {
          gtin_waiver,
          gtin_reason,
          gtin_requesting_username,
          gtin_requesting_user_id
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
      let addResp = await callHandler(call);

      dispatch(displayNotification({
        message: 'Success Adding GTIN Waiver Log',
        type: TYPE_SUCCESS,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));

      return addResp;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error AddingGTIN Waiver Log',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}