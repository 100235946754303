import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";

export default function(organisationId, configId, orgConfig) {
  return async dispatch => {
    try {
      let call = {
        url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/config`,
        method: 'post',
        data: { 
          configId,
          orgConfig
        },
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let addResp = await callHandler(call);

      dispatch(displayNotification({
        message: 'Success Updating Config',
        type: TYPE_SUCCESS,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));

      return addResp;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error Updating Config',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}