export const SET_EXPORTS = 'SET_EXPORTS';
export const SET_IMPORTS = 'SET_IMPORTS';

export const setExports = (payload) => ({
  type: SET_EXPORTS,
  payload: { exports: payload },
});

export const setImports = (payload) => ({
  type: SET_IMPORTS,
  payload: { imports: payload },
});
