import React, { Component } from 'react';
import uuid from 'uuid/v4';
import './RadioButton.css';

class RadioButton extends Component {
  constructor() {
    super();
    this.uuid = uuid();
  }

  render() {
    const { label, name, valuePrefix, value, checked, defaultChecked, onChange } = this.props;
    return (
      <div className="radio-button-container">
        <input
          type="radio"
          name={`${valuePrefix}-${name}`}
          value={value}
          id={this.uuid}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <label htmlFor={this.uuid}>{label}</label>
      </div>
    );
  }
}

export default RadioButton;