import React, { Component } from 'react';
import { connect } from "react-redux";
import SectionWrapper from 'components/dataCaptureFormComponents/SectionWrapper';
import SubSectionWrapper from 'components/dataCaptureFormComponents/SubSectionWrapper';
// import SubSectionDescription from 'components/dataCaptureFormComponents/SubSectionDescription';
// import DescriptionText from 'components/texts/DescriptionText';
import PersonInformationForm from './PersonInformationForm';
import AddPersonInformation from './AddPersonInformation';

class ContactsInformation extends Component {
  render() {
    const additionalProfiles = Object.values(this.props.additional).filter(profile => profile);
    const additionalPersonInformations = !!additionalProfiles.length && additionalProfiles.map(({ jobTitle, contactId}) => (
      <SubSectionWrapper
        key={contactId}
        title={jobTitle}
        formComponent={<PersonInformationForm valuePrefix={`additional.${contactId}`} />}
      />
    ));
    return (
      <SectionWrapper title="Contacts information">
        <SubSectionWrapper
          title="Relationship Executive"
          formComponent={<PersonInformationForm valuePrefix="relationship_executive" />}
          // infoComponent={
          //   <SubSectionDescription>
          //     <DescriptionText>
          //       Lorem Ipsum is simply dummy text of the printing and
          //       typesetting industry. Lorem Ipsum has been the industry’s
          //       standard dummy text ever since the 1500s,
          //       when an unknown printer took a galley of type
          //       and scrambled it to make a type specimen book.
          //       It has survived not only five centuries,
          //       but also the leap into electronic
          //     </DescriptionText>
          //   </SubSectionDescription>
          // }
        />
        <SubSectionWrapper
          title="Primary Ambassador"
          formComponent={<PersonInformationForm valuePrefix="primary_ambassador" />}
        />
        <SubSectionWrapper
          title="Marketing Contact"
          formComponent={<PersonInformationForm valuePrefix="marketing_contact" />}
        />
        <SubSectionWrapper
          title="Finance Contact"
          formComponent={<PersonInformationForm valuePrefix="finance_contact" />}
        />
        <div className="additional-contacts">
          {additionalPersonInformations}
        </div>
        <AddPersonInformation />
      </SectionWrapper>
    );
  }
}

export default connect(
  state => ({
    additional: state.form.contacts_information.additional
  })
)(ContactsInformation);
