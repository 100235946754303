import React, { Component } from 'react';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';

import Icon from 'components/icon';
import Checkbox from 'components/inputs/Checkbox';

import './MenuList.css';

class MenuList extends Component {
  constructor(props) {
    super(props);
    const ungroupedList = props.isGrouped ? this.buildUngrouppedList(props.options) : props.options;
    this.state = {
      selectedData: props.selectedValues,
      isGrouped: props.isGrouped,
      ungroupedList: props.isGrouped ? this.buildUngrouppedList(props.options) : props.options,
      filteredList: [...ungroupedList],
      searchedValue: '',
      expandedGroups: [],
      showSelected: [],
      showSelectedAll: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.selectedValues, this.props.selectedValues)) {
      this.setState({
        selectedData: this.props.selectedValues
      });
    }
  }

  buildUngrouppedList(groupedList) {
    const ungroupedList = [];
    groupedList.forEach(option => {
      option.items.forEach(item => ungroupedList.push(item));
    });
    return ungroupedList;
  }

  onChangeValue(e, value) {
    let { onChange } = this.props;
    const selectedData = [...this.state.selectedData];
    if (e.target.checked) {
      selectedData.push(value);
    } else {
      const selectedIndex = selectedData.findIndex(selectedItem => selectedItem === value);
      selectedData.splice(selectedIndex, 1);
    }
    this.setState({selectedData});

    e.target.value = selectedData;
    onChange(e);
  }

  selectValue(e, value) {
    let { onChange } = this.props;
    this.setState({selectedData: value});
    e.target.value = value;
    onChange(e);
  }

  onChangeSearch (e) {
    const { value } = e.target;
    const { isGrouped } = this.props;
    const { ungroupedList } = this.state;
    if(value.length === 0) {
      this.setState({
        searchedValue: value,
        filteredList: [...ungroupedList],
        isGrouped
      });
    } else {
      const newFilteredList = ungroupedList.filter(item => item.label.toLowerCase().startsWith(value.toLowerCase()));
      this.setState({
        searchedValue: value,
        filteredList: newFilteredList,
        isGrouped: false
      });
    }
  }

  onChangeGroup(e, groupName) {
    e.stopPropagation();
    const { options, onChange } = this.props;
    const selectedData = [...this.state.selectedData];
    const group = options.find(group => group.label === groupName);
    const selectedVals = group.items.filter(item => selectedData.includes(item.value));
    if(selectedVals.length) {
      group.items.forEach(item => {
        const index = selectedData.findIndex(i => i === item.value);
        if(index !== -1) {
          selectedData.splice(index, 1);
        }
      });
    } else {
      group.items.forEach(item => {
        selectedData.push(item.value);
      });
    }

    this.setState({selectedData});
    e.target.value = selectedData;
    onChange(e);
  }

  isSelected(value) {
    const { isMulti } = this.props;
    return isMulti ? this.state.selectedData.includes(value) : this.state.selectedData === value;
  }

  selectAll(e) {
    let { onChange } = this.props;
    const { ungroupedList, selectedData, searchedValue, filteredList } = this.state;
    const allData = searchedValue.length ? [...filteredList] : [...ungroupedList];
    const alreadySelected = allData.filter(item => selectedData.includes(item.value));
    let selectedValues = [...selectedData];
    if(alreadySelected.length === allData.length) {
      selectedValues = selectedValues.filter(item => !allData.find(i => i.value === item));
    } else {
      allData.forEach(item => selectedValues.push(item.value));
    }
    selectedValues = uniq(selectedValues);
    this.setState({selectedData: selectedValues});
    e.target.value = selectedValues.join(',');
    onChange(e);
  }

  showHideGroup(e, groupName) {
    e.stopPropagation();
    const { expandedGroups } = this.state;
    if(expandedGroups.includes(groupName)) {
      const groupIndex = expandedGroups.findIndex(selectedGroup => selectedGroup === groupName);
      expandedGroups.splice(groupIndex, 1);
    } else {
      expandedGroups.push(groupName);
    }

    this.setState({
      expandedGroups
    });
  }

  showSelected(e, groupName) {
    e.stopPropagation();
    const { expandedGroups, showSelected } = this.state;

    if(showSelected.includes(groupName)) {
      const showSelectedIndex = showSelected.findIndex(opt => opt === groupName);
      showSelected.splice(showSelectedIndex, 1);
      this.setState({
        showSelected
      });
    } else {
      showSelected.push(groupName);
      if(!expandedGroups.includes(groupName)) {
        expandedGroups.push(groupName);
      }
      this.setState({
        expandedGroups,
        showSelected
      });
    }

  }

  showAllSelectedCount() {
    const { isGrouped } = this.props;
    const { searchedValue, ungroupedList, selectedData, filteredList, showSelectedAll } = this.state;
    if(isGrouped || searchedValue.length) {
      return {
        isShowSelectedAllButton: false,
        selectedCount: 0,
        lastSelected: 0,
        filteredOptions: filteredList,
        showSelectedAll: false
      };
    }

    const selectedOptions = ungroupedList.filter(opt => !!selectedData.includes(opt.value));
    const selectedLength = selectedOptions.length;
    let items = [...ungroupedList];
    if (showSelectedAll) {
      const notSelectedOptions = ungroupedList.filter(opt => !selectedData.includes(opt.value));
      items = [...selectedOptions, ...notSelectedOptions];
    }
    return {
      isShowSelectedAllButton: true,
      selectedCount: ungroupedList.filter(item => !!selectedData.includes(item.value)).length,
      lastSelected: selectedLength-1,
      filteredOptions: items,
      showSelectedAll
    };

  }

  showAllSelected() {
    this.setState({
      showSelectedAll: !this.state.showSelectedAll
    })
  }

  groupInfo(groupName) {
    const { options } = this.props;
    const { selectedData, expandedGroups, showSelected } = this.state;
    const group = options.find(group => group.label === groupName);
    if (!group) {
      return {
        selectedCount: 0,
        isExpanded: false,
        items: [],
        lastSelected: 0,
        isShowSelected: false
      };
    }
    const selectedVals = group.items.filter(item => selectedData.includes(item.value));

    let items = [...group.items];
    let lastSelected = 0;
    if (showSelected.includes(groupName)) {
      const selectedOptions = group.items.filter(opt => !!selectedData.includes(opt.value));
      const notSelectedOptions = group.items.filter(opt => !selectedData.includes(opt.value));
      items = [...selectedOptions, ...notSelectedOptions];
      lastSelected = selectedOptions.length - 1;
    }

    return {
      selectedCount: selectedVals.length,
      isExpanded: expandedGroups.includes(groupName),
      items: items,
      lastSelected,
      isShowSelected: showSelected.includes(groupName)
    }
  }

  renderGroup(option) {
    const { fieldLabel, isMulti, name } = this.props;
    const {selectedCount, items, isExpanded, isShowSelected, lastSelected} = this.groupInfo(option.label);
    return (
      <div className="menulist__results__group" key={`${name}_group_${option.label}`}>
        <div
          className={`menulist__results__group__title ${isExpanded ? 'expanded' : ''}`}
          onClick={(e) => this.showHideGroup(e, option.label)}
        >
          <div className="menulist__results__group__title__name">
            {isMulti &&
            <Checkbox
              value={option.label}
              onChange={(e) => this.onChangeGroup(e, option.label)}
              checked={selectedCount !== 0}
              label={option.label}
            />
            }
          </div>
          {isMulti ?
            <div className="menulist__results__group__title__selected_count" onClick={(e) => this.showSelected(e, option.label)}>
              {selectedCount} {fieldLabel} (ies) selected
            </div>
            :
            <div className="menulist__results__group__title__selected_count inactive">
              {selectedCount} {fieldLabel} selected
            </div>
          }

          <div className="menulist__results__group__title__arrow">
            <span className={`menulist__results__group__title__arrow__triangle ${isExpanded ? 'expanded' : ''}`}/>
          </div>
        </div>
        {isExpanded &&
        <div className="menulist__results__items">
          {this.renderItems(items, lastSelected, isShowSelected)}
        </div>
        }
      </div>
    );
  }

  renderItems(items, lastSelected = 0, isShowSelected = false) {
    const { name, isMulti } = this.props;
    return items.map((item, i) => {
      if(isMulti) {
        return (
          <div
            key={`${name}_item_${item.value}_${i}`}
            className={`menulist__results__items__item ${isShowSelected && i === lastSelected ? 'last_selected' : ''}`}
          >
            <Checkbox
              value={item.value}
              onChange={(e) => this.onChangeValue(e, item.value)}
              checked={this.isSelected(item.value)}
              label={item.label}
            />
          </div>
        );
      }
      return (
        <div
          key={`${name}_item_${item.value}_${i}`}
          className={`menulist__results__items__item single ${this.isSelected(item.value) ? 'selected' : ''}`}
          onClick={(e) => this.selectValue(e, item.value)}
        >
          <span>{item.label}</span>
        </div>
      );
    });
  }

  renderSearch() {
    const { searchedValue } = this.state;
    return (
      <div className="menulist__search">
        <input
          type="text"
          name="search"
          placeholder="Search"
          className="menulist__search__field"
          value={searchedValue}
          onChange={(e) => this.onChangeSearch(e)}
        />
        <span className="menulist__search__icon">
                    <Icon
                      icon={'search'}
                      size={13}
                      fill="default"
                      onClick={() => this.setFilterValue()}
                    />
                </span>
      </div>
    )
  }

  render() {
    const { options = [], fieldLabel, isMulti } = this.props;
    const { isGrouped, selectedData, filteredList } = this.state;
    const { selectedCount, isShowSelectedAllButton, lastSelected, filteredOptions, showSelectedAll } = this.showAllSelectedCount();

    const filteredSelected =  isMulti ? filteredList.filter(item => selectedData.includes(item.value)) : null;

    return (
      <div className="menulist">
        <div className="menulist__container">
          {isShowSelectedAllButton && isMulti &&
          <div className="menulist__container__selected_count"
               onClick={(e) => this.showAllSelected(e)}>
            {selectedCount} {fieldLabel} (ies) selected
          </div>
          }
          {this.renderSearch()}
          <div className="menulist__results">
            { isGrouped ?
              options.map(option => {
                return this.renderGroup(option);
              })
              :
              <div className="menulist__results__items">
                { this.renderItems(filteredOptions, lastSelected, showSelectedAll) }
              </div>
            }
          </div>
        </div>
        <div className="menulist__footer">
          {isMulti &&
          <div
            className="menulist__footer__select_all"
            onClick={(e) => this.selectAll(e)}
          >
            {filteredList.length === filteredSelected.length ? 'Unselect all' : 'Select all'}
          </div>
          }
        </div>
      </div>
    );
  }
}

export default MenuList;
