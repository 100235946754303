import React from 'react';
import './LinkButton.css';

function LinkButton({ type, text, onClick }) {
  return (
    <button className="link-button" type={type} onClick={onClick}>
      {text}
    </button>
  );
}

export default LinkButton;