import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function (destination, jobType, licenseeOrganisationId, licensorOrganisationId) {
  let paramsData = {
    'filter[destination]': destination,
    'filter[job_type]': jobType
  };
  if (!!licensorOrganisationId) {
    paramsData['filter[registered_with]'] = licensorOrganisationId
  }
  let call = {
    method: 'get',
    url: `${Config.get('ADMIN_URL')}organisations/${licenseeOrganisationId}/ingest/status`,
    params: paramsData
  }

  let response = await callHandler(call);
  return response;
}
