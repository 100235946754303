import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import AdminHeader from 'components/headers/AdminHeader';
import OrganisationList from 'components/OrganisationList';
import listOrgs from 'actions/api/organisations/list';
import listOrgsValidations from 'actions/api/validations/list';
import listUsers from 'actions/api/users/list';

class Organisations extends Component {
  constructor(props){
		super(props);
		this.state = {
      selectedOrg: {
        name: "none selected",
        organisation_id: null
      },
      organisations: [],
      users: []
    }
    this.handleOrgSelect = this.handleOrgSelect.bind(this);
    this.onNavSelect = this.onNavSelect.bind(this);
    this.fetchOrganisations = this.fetchOrganisations.bind(this);
    this.handleOrgAdd = this.handleOrgAdd.bind(this);
    this.handleUserAdd = this.handleUserAdd.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  };

  componentDidMount() {
    this.props.listOrgs();
    this.props.listOrgsValidations();
    this.props.listUsers();
  }

  fetchOrganisations() {
    listOrgs()
    .then((data) => {

    this.setState({ organisations: [{
        name: "none selected",
        organisation_id: null,
        information: { licensor: false }
      }].concat(data) })
    });
  }

  handleOrgSelect(selectedOrg) {
    this.setState({ selectedOrg });
  }

  handleOrgAdd() {
    this.fetchOrganisations();
  }

  handleUserAdd() {
    this.fetchUsers();
  }

  handleLogOut() {
    this.setState({
      selectedOrg: {
        name: "none selected",
        organisation_id: null
      }
    })
  }

  onNavSelect(eventKey, event) {
    this.setState({ activeTab: eventKey});
  }

	render(){
		return (
      <div>
        <AdminHeader />
        <div className='x-background'>
          <Container>
            <div className='x-section-heading'>
              <span>Organisations</span>
            </div>
            <OrganisationList></OrganisationList>
          </Container>
        </div>
      </div>
			);
	};
}

export default connect(null, { listOrgs, listUsers, listOrgsValidations })(Organisations);