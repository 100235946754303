import axios from 'axios';
import config from 'config';

export function getLogins(userId) {
    try {
        return axios ({
            method: 'get',
            url: `${config.get('ADMIN_URL')}users/${userId}/logins`
        }).then((response) => {
            return response && response.data
        });
    } catch (error) {
        return error;
    }
}

export const sendLoginReminder = (userId, email) => {
    return axios.post(`${config.get('ADMIN_URL')}trigger-notification`, {
        emails: [email],
        notification: 'user_login_reminder'
    });
};