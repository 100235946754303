import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { clearAuthData } from "utils/storage";
import login from "actions/auth/login";
import { routeOnSuccessAuth } from "constants/routes";
import { USER_REFRESH_TOKEN, USER_TOKEN } from "constants/api";
import { storageKeys } from "constants/storage";

import cookies from 'js-cookie';

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

function withAuth(WrappedComponent) {
  return ({ login, org_access_token, user_refresh_token, rememberMe = false, dispatch, ...props }) => {
    const userRefreshToken = cookies.get(USER_REFRESH_TOKEN);
    const userToken = cookies.get(USER_TOKEN);
    const storageToUse = rememberMe ? localStorage : sessionStorage;


    if (!userToken || !userRefreshToken) {
      clearAuthData();
    }

    if (!org_access_token && !user_refresh_token) {
      if (!org_access_token) {
        login().then((resp) => {
          storageToUse.setItem(storageKeys.org_access_token, (resp.org_access_token || ''));

          if(!!window.location && window.location.pathname.length > 1) {
            window.location.replace(window.location.pathname);
          } else {
            window.location.replace(routeOnSuccessAuth);
          }
        })
      } else {
        if (!!window.location && window.location.pathname.length > 1) {
          return;
        } else {
          window.location.replace(routeOnSuccessAuth);
        }
      }
    }

    return <WrappedComponent {...props} />;
  };
}

export default compose(
  connect(state => ({
    org_access_token: state.auth.org_access_token || '',
    user_refresh_token: state.auth.user_refresh_token || ''
  }),
  {
    login
  }),
  withAuth
);