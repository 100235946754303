import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, invite_token,user_id) {
  let call = {
    url: `${Config.get('ADMIN_URL')}admin/organisations/${organisationId}/employees/${invite_token}`,
    method: 'post',
    data:{
      user_id,
      web_url: `${Config.get('services').login}`
    },
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let {user = {}} = await callHandler(call);
  return user;
}