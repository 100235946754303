const development = {
  services: {
    login: 'https://login.testing.xelacore.io',
    reach: 'https://reach.testing.xelacore.io',
    account: 'https://account.testing.xelacore.io',
    reg: 'https://testing.xelacore.io',
    plm: 'https://testing.app.xelacore.com',
    cam: 'https://dev.concepts.xelacore.io',
    admin: 'http://local.xelacore.io:3000'
  },
  "API_BASE_URL": "https://admin.dev.xelacore.io",
  "AUTH_BASE_URL": "https://dev.xelacore.io",
  "CLIENT_ID": "f21ce494-0385-4c2e-9175-96f15344aab7",
  "REDIRECT_URL": "https://admin.testing.xelacore.io/",
  "WSS_URL": "https://dev.xelacore.io",
};

const testing = {
  services: {
    login: 'https://login.testing.xelacore.io',
    reach: 'https://reach.testing.xelacore.io',
    account: 'https://account.testing.xelacore.io',
    reg: 'https://testing.xelacore.io',
    plm: 'https://testing.app.xelacore.com',
    cam: 'https://dev.concepts.xelacore.io',
    admin: 'https://admin.testing.xelacore.io'
  },
  "API_BASE_URL": "https://api.testing.xelacore.io",
  "AUTH_BASE_URL": "https://api.testing.xelacore.io",
  "CLIENT_ID": "f21ce494-0385-4c2e-9175-96f15344aab7",
  "REDIRECT_URL": "https://admin.testing.xelacore.io/",
  "WSS_URL": "wss://api.testing.xelacore.io",
};

const staging = {
  services: {
    login: 'https://login.staging.xelacore.io',
    reach: 'https://reach.staging.xelacore.io',
    account: 'https://account.staging.xelacore.io',
    reg: 'https://staging.xelacore.io',
    plm: 'https://staging.app.xelacore.com',
    cam: 'https://uat.concepts.xelacore.io',
    admin: 'https://admin.staging.xelacore.io'
  },
  "API_BASE_URL": "https://api.staging.xelacore.io",
  "AUTH_BASE_URL": "https://api.staging.xelacore.io",
  "CLIENT_ID": "548a8f84-6f19-429a-9702-de81d2b67869",
  "REDIRECT_URL": "https://admin.staging.xelacore.io/",
  "WSS_URL": "wss://api.staging.xelacore.io",
};

const live = {
  services: {
    login: 'https://login.xelacore.io',
    reach: 'https://reach.xelacore.io',
    account: 'https://account.xelacore.io',
    reg: 'https://fabacus.xelacore.io',
    plm: 'https://app.xelacore.com',
    cam: 'https://concepts.xelacore.io',
    admin: 'https://admin.xelacore.io'
  },
  "API_BASE_URL": "https://api.live.xelacore.io",
  "AUTH_BASE_URL": "https://api.live.xelacore.io",
  "CLIENT_ID": "e4bdcdd8-f69d-4b40-bf54-266410e64c08",
  "REDIRECT_URL": "https://admin.live.xelacore.io/",
  "WSS_URL": "wss://api.live.xelacore.io",
};

let config = {};
switch (process.env.REACT_APP_STAGE) {
  case 'live':
    config = live;
    break;
  case 'staging':
    config = staging;
    break;
  case 'development':
    config = development;
    break;
  default:
    config = testing;
    break;
}

export default {
  "OAUTH_URL": "/oauth/",
  "AUTH_URL": "/v1/auth/",
  "CATALOG_URL": "/v1/catalog/",
  "CONCEPTS_URL": "/v1/concepts/",
  "FILES_URL": "/v1/files/",
  "INGEST_URL": "/v3/ingest/",
  "REDEMPTION_URL": "/v1/redemption/",
  "POLICY_URL": "/v1/policy/",
  "ADMIN_URL": "/v1/admin/",
  ...config
}
