export const licenseesorTable = [
    {
        field: 'licensee_organisation_name',
        label: 'licensee Name',
        order: 0,
        hideFor: 'licensee',
        filter: true,
    },
    {
        field: 'licensor_organisation_name',
        label: 'Licensor Name',
        order: 1,
        hideFor: 'licensor',
        filter: true,
    },
    {
        field: 'link_id',
        label: 'Link Id',
        order: 2,
    },
    {
        field: 'state',
        label: 'State',
        order: 3,
    },
    {
        field: 'licensee_organisation_id',
        label: 'Licensee Id',
        order: 4,
        hideFor: 'licensee'
    },
    {
        field: 'licensor_organisation_id',
        label: 'Licensor Id',
        order: 5,
        hideFor: 'licensor'
    },
    {
        field: 'created_at',
        label: 'Created',
        order: 6,
    },
    {
        field: 'updated_at',
        label: 'Updated',
        order: 7,
    },
    {
        field: 'actions',
        label: 'Actions',
        order: 8,
    }
]
