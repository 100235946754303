export const LIST_USERS = 'LIST_USERS';
export const SELECT_USER = 'SELECT_USER';

export const listUsers = (payload) => ({
  type: LIST_USERS,
  payload: { list: payload },
});

export const selectUser = (payload) => ({
  type: SELECT_USER,
  payload: { selected: payload },
});
