import React, { Component } from "react";
import { connect } from "react-redux";
import DropdownMenu from "components/menus/DropdownMenu";
import { logout } from "utils/storage";

class AuthDropdown extends Component {
  onClickHandler = () => {
   logout();
  }

  render() {
    return (
      <DropdownMenu
        title={this.props.name}
        items={[{ label: "Logout", onClick: this.onClickHandler }]}
      />
    );
  }
}

export default connect(
  state => ({
    name: state.auth.name
  })
)(AuthDropdown);
