import { combineReducers } from "redux";
import auth from "./auth";
import notification from "./notification";
import organisations from "./organisations";
import validations from "./validations";
import users from "./users";
import list from "./list";
import exportUpdate from "./exportUpdate"
import retailerListings from "./retailerListings";

export default function createReducer(asyncReducers) {
  return combineReducers({
    auth,
    notification,
    list,
    organisations,
    validations,
    users,
    exportUpdate,
    retailerListings,
    ...asyncReducers
  })
};
