export const storageKeys = {
  token: "TOKEN",
  name: "NAME",
  date: "DATE",
  password: "PASSWORD",
  user_refresh_token: "REFRESH_TOKEN",
  user_token: "USER_TOKEN",
  selected_org_id: "SELECTED_ORG_ID",
  org_access_token: "ACCESS_TOKEN"
};
