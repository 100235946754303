import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
// import { selectOrg } from "actions/organisations";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";

export default function(userId) {

  return async dispatch => {
    try {
      let call = {
        method: "get",
        url: `${Config.get('ADMIN_URL')}users/${userId}`
      }
      let { user } = await callHandler(call);
      // Removed this from the global state for now, causing some form issues
      // dispatch(selectOrg(organisation));
  
      return user;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error getting user',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}