import React from 'react';
import './PrimaryButton.css';

function PrimaryButton({ type, text, onClick }) {
  return (
    <button className="primary-button" type={type} onClick={onClick}>
      {text}
    </button>
  );
}

export default PrimaryButton;