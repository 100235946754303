import React, { Component } from 'react';
import { getIncreasingArray } from 'utils/iter';
import { MAIN_SECTION_STATES, NUM_OF_PAGES } from './constants';
import PaginationButton from 'components/buttons/PaginationButton';
import './Pagination.css';

class Pagination extends Component {
  static defaultProps = {
    current: 0,
    total: 0
  }

  get stateToUse() {
    const { current, total } = this.props;
    if (current <= 4) {
      return MAIN_SECTION_STATES[`${current}`];
    }
    if (current > total - 4) {
      return MAIN_SECTION_STATES[`${current - total - 1}`];
    }
    return MAIN_SECTION_STATES.BETWEEN;
  }

  getPaginationSection = ({ count, start }) => {
    const { current } = this.props;
    return getIncreasingArray(count, start).map(page => {
      return (
        <PaginationButton
          key={page}
          page={page}
          current={current}
          onClick={this.props.onPageChanged}
        />
      )
    })
  }

  getPagination = () => {
    const { total, current } = this.props;
    if (total <= NUM_OF_PAGES) {
      const pages = this.getPaginationSection({ count: total, start: 1 });
      return (
        <span className="pagination-page-cells">
          {pages}
        </span>
      );
    }

    /**
     * queue of page cells:
     * firstPages dots.before beforePages page afterPages dots.after lastPages
     */
    const stateToUse = this.stateToUse;
    return (
      <span className="pagination-page-cells">
        <span className="pagination-page-cells-before-current">
          {
            stateToUse.firstPages && (
              <span className="pagination-page-cells-first-pages">
                {
                  this.getPaginationSection({
                    count: stateToUse.firstPages,
                    start: 1
                  })
                }
              </span>
            )
          }
          {
            stateToUse.dots.before && (<span className="pagination-page-cells-dots">...</span>)
          }
          {
            stateToUse.beforePages && (
              <span className="pagination-page-cells-before-pages">
                {
                  this.getPaginationSection({
                    count: stateToUse.beforePages,
                    start: current - stateToUse.beforePages
                  })
                }
              </span>
            )
          }
        </span>
        {
          current && (
            <button
              type="button"
              className="pagination-page-button pagination-page-button--current"
            >
              {current}
            </button>
          )
        }
        <span className="pagination-page-cells-after-current">
          {
            stateToUse.afterPages && (
              <span className="pagination-page-cells-after-pages">
                {
                  this.getPaginationSection({
                    count: stateToUse.afterPages,
                    start: current + 1
                  })
                }
              </span>
            )
          }
          {
            stateToUse.dots.after && (<span className="pagination-page-cells-dots">...</span>)
          }
          {
            stateToUse.lastPages && (
              <span className="pagination-page-cells-last-pages">
                {
                  this.getPaginationSection({
                    count: stateToUse.lastPages,
                    start: total - stateToUse.lastPages + 1
                  })
                }
              </span>
            )
          }
        </span>
      </span>
    )
  }

  render() {
    const { total, current } = this.props;
    // if no total -> pagination not needed (no results, empty table)
    if (!total) {
      return null;
    }
    const pages = this.getPagination();
    return (
      <div className="pagination-container">
        <div className="pagination">
          <PaginationButton
            isFirst
            current={current}
            disabled={current === 1}
            page={1}
            onClick={this.props.onPageChanged}
          />
          <span className="pagination-main">
            {pages}
          </span>
          <PaginationButton
            isLast
            current={current}
            disabled={current === total}
            page={total}
            onClick={this.props.onPageChanged}
          />
        </div>
      </div>
    );
  }
}

export default Pagination;
