import {
  API_LIST_FETCHING,
  API_LIST_SUCCESS,
  API_LIST_FAILURE
} from "actions/api/list";

const getInitialState = () => ({
  isLoading: false,
  list: [],
  error: null
});

export default function list(state = getInitialState(), action) {
  switch (action.type) {
    case API_LIST_FETCHING:
      return {
        ...getInitialState(),
        isLoading: true
      };
    case API_LIST_SUCCESS:
      return {
        ...getInitialState(),
        list: action.payload
      };
    case API_LIST_FAILURE:
      return {
        ...getInitialState(),
        error: action.error
      };
    default:
      return state;
  }
}
