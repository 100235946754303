import Config from 'config';
import axios from 'axios';

export default async function(LicensorOrganisationId) {
    const call = {
        method: "get",
        url: `${Config.get('ADMIN_URL')}organisations/${LicensorOrganisationId}/exports/amazon_identifiers`,
        responseType: 'blob', // Așteptăm un fișier blob ca răspuns
    };

    try {
        const response = await axios(call);
        return response.data; // Returnăm datele blob
    } catch (error) {
        throw new Error('Failed to fetch Amazon Identifiers');
    }
}
