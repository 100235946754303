import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import listLinkedLicensees from 'actions/api/linkedLicensees/list';
import addAlias from 'actions/api/aliases/create';
import deleteAlias from 'actions/api/aliases/delete';
import { LinkedLicenseesTable } from './tableheader';
import CustomTableWithActions from 'components/CustomTableWithActions';
import ErrorAlert from 'components/ErrorAlert';

function globalStateMapper(globalState) {
  return {};
}

class LinkedLicensees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkedLicensees: [],
      name: "",
      type: "Trade Name",
      filters: {
        name: "",
        type: "",
      }
    };
  }

  componentDidMount() {
    this.fetchLinkedLicensees();
  }

  fetchLinkedLicensees = () => {
    let { selectedOrg = {} } = this.props;
    let { organisation_id } = selectedOrg;
    listLinkedLicensees(organisation_id)
        .then((data) => {
          this.setState({ linkedLicensees: data });
        })
        .catch(err => alert(err.message));
  }

  handleAddAlias = () => {
    let { selectedOrg } = this.props;
    let { organisation_id } = selectedOrg || {};
    let { name, type } = this.state;
    this.props.addAlias(organisation_id, name, type).then(() => {
      this.fetchLinkedLicensees();
      this.setState({ name: "", type: "Trade Name" });
    });
  }

  handleDeleteAlias = (alias) => {
    const { alias_id } = alias;
    let { selectedOrg } = this.props;
    let { organisation_id } = selectedOrg || {};

    deleteAlias(organisation_id, alias_id)
        .then(() => {
          this.fetchLinkedLicensees();
        })
        .catch(err => alert(err.message));
  }

  render() {
    const { errors, revalidate } = this.props;
    const { linkedLicensees } = this.state;
    return (
        <div className="Aliases data-capture-section">
          <ErrorAlert errors={errors} revalidate={revalidate} />

          <Container fluid>
            <h2>Linked Licensees ({linkedLicensees.length})</h2>

            <CustomTableWithActions
                data={linkedLicensees}
                tableheader={LinkedLicenseesTable}
                actions={[
                  {
                    name: "Remove",
                    className: 'delete',
                    onClick: this.handleDeleteAlias,
                    message: "You are about to REMOVE this alias from this organisation, this alias will no longer be a valid selection for data belonging to this organisation"
                  },
                ]}

            />
          </Container>
        </div>
    );
  }
}

export default connect(
    globalStateMapper,
    { addAlias }
)(LinkedLicensees);
