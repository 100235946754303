import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Container, Button } from 'react-bootstrap';
import listMyFiles from 'actions/api/myFiles/list';
import downloadMyFiles from 'actions/api/myFiles/download';
import uploadMyFiles from 'actions/api/myFiles/upload';
import removeMyFiles from 'actions/api/myFiles/remove';
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
// import Config from 'config'

function globalStateMapper(globalState) {
  return {};
}

class XelacoreFilesHandler extends Component {
  constructor(props){
		super(props);
		this.state = {
      files: [],
      showConfirmFileRemovePopup : false,
    }
    this.removePromiseInfo = {};
  };

  componentDidMount() {
    this.fetchFiles();
  }

  fetchFiles = () => {
    let { organisationId = null } = this.props;
    listMyFiles(organisationId)
    .then((res) => {
      const { data = {} } = res;


      this.setState(
        { 
          files: data.files.map(f => {
            return {
              source: f.name,
              options: {
                  type: 'local',
                  file: f,
              }
            }
          }) 
        }
      );
    })
    .catch(err => alert(err.message));
  }

  handleDownloadFile = (name) => {
    let { organisationId } = this.props;
    return downloadMyFiles(organisationId, name);
  }

  handleUploadFile = () => {
    let { organisationId } = this.props;
    return uploadMyFiles(organisationId, 'file', {});
  }

  removeFile = (source, load, error) => {
    let { organisationId } = this.props;
    return removeMyFiles(organisationId, source).then(() => {
      load();
      return this.fetchFiles();
    });
  }

  toggleFileRemovePopUp = () => {
    const { showConfirmFileRemovePopup } = this.state;
    this.setState({ showConfirmFileRemovePopup: !showConfirmFileRemovePopup });
  }

  processFile = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => { 
    let { organisationId } = this.props;
    const { name } = file;
    return uploadMyFiles(organisationId, name, {}).then((uploadRequest) => {
      const { data = {} } = uploadRequest;
      const { location, post_body } = data;

      // fieldName is the name of the input field
      // file is the actual file object to send
      const formData = new FormData();

      Object.keys(post_body).forEach(k => {
        formData.append(k, post_body[k])
      })

      formData.append('file', file);

      const request = new XMLHttpRequest();
      request.open('POST', location);

      // Should call the progress method to update the progress to 100% before calling load
      // Setting computable to false switches the loading indicator to infinite mode
      request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
      };

      // Should call the load method when done and pass the returned server file id
      // this server file id is then used later on when reverting or restoring a file
      // so your server knows which file to return without exposing that info to the client
      request.onload = function() {
          if (request.status >= 200 && request.status < 300) {
              // the load method accepts either a string (id) or an object
              load(request.responseText);
          }
          else {
              // Can call the error method if something is wrong, should exit after
              error('oh no');
          }
      };

      request.send(formData);
      
      // Should expose an abort method so the request can be cancelled
      return {
          abort: () => {
              // This function is entered if the user has tapped the cancel button
              request.abort();

              // Let FilePond know the request has been cancelled
              abort();
          }
      };

    })
  }

  onRemoveFile = (item) => {
    return new Promise((resolve, reject) => {
      this.removePromiseInfo = { resolve, reject };
      this.toggleFileRemovePopUp();
    });
  }

  confirmRemoveFile = (isConfirmed) => {
    const { resolve } = this.removePromiseInfo; 
    this.toggleFileRemovePopUp(); 
    return resolve(isConfirmed);
  }

  render() {
    const { showConfirmFileRemovePopup } = this.state;
    const { rmConfirmMsg = null } = this.props;
    return (
      <div className="MyFiles">
        <Container>
          <Container style={{marginTop: 20, marginBottom: 20}}>
          <FilePond
            labelIdle={'Drag & Drop your files or <span class="filepond--label-action"> Browse </span> to upload --- Click on a file to download it'}
            ref={ref => (this.pond = ref)}
            files={this.state.files}
            allowMultiple={true}
            allowRevert={false}
            server={{
              process: this.processFile,
              revert: this.revertFile,
              load: null,
              remove: this.removeFile,
              fetch: null,
            } }
            onprocessfile={(error, file) => { if (!error) this.fetchFiles()}}
            onupdatefiles={fileItems => {
              // Set currently active file objects to this.state
              this.setState({
                files: fileItems.map(fileItem => fileItem.file)
              });
            }}
            onactivatefile={(file) => {
              const { filename } = file;
              return this.handleDownloadFile(filename);
            }}
            beforeRemoveFile={this.onRemoveFile}
          />
          </Container>
          <Modal show={showConfirmFileRemovePopup} onHide={() => this.confirmRemoveFile(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{ rmConfirmMsg || 'Please confirm you wish to remove this file, it will no longer be available for download'}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button className="cancel" onClick={() => this.confirmRemoveFile(false)}>Cancel</Button>
              <Button className="delete" onClick={() => this.confirmRemoveFile(true)}>Remove</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

export default connect(
  globalStateMapper,
  null
)(XelacoreFilesHandler);
