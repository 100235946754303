import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { getAuthStorage } from "utils/storage";
import login from "actions/auth/login";
import { fetchOauthCode } from "actions/api/auth/login";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './PasswordConfirmation.css';

class PasswordConfirmation extends Component {
    constructor(props){
        super(props);
        this.state = {
            show: false,
            password: '',
            emptyPassword: false,
            confirmedNuke: false
        };
    };

    togglePopUp = () => {
        const { show } = this.state;
        this.setState({ show: !show, confirmedNuke: false });
    };

    onConfirmClick = (e) => {
        e.preventDefault(); // keep on page

        const { confirmedNuke } = this.state;

        const authStorage = getAuthStorage();
        // const userInfo = !!authStorage.org_access_token && jwtDecode(authStorage.org_access_token)
        //     ? jwtDecode(authStorage.org_access_token).data
        //     : {};

        if (!confirmedNuke) {
            this.setState({ emptyPassword: true });
        } else {
            this.props.onClick();
            this.togglePopUp();
            this.setState({
                confirmedNuke: false
            });
            // fetchOauthCode({ email: userInfo.user, password }).then(() => {
            //     this.props.onClick();
            //     this.togglePopUp();
            // }, () => {
            //     this.setState({ emptyPassword: true });
            // });
        }
    };

    handleModuleCheckboxChange(e) {
        this.setState({
            confirmedNuke: e.target.checked
        });
    }

    render() {
        let { className = 'delete', disabled } = this.props;
        let { show, emptyPassword, confirmedNuke } = this.state;

        return (
            <div className="passwordConfirmation">
                <Button className={className}
                        disabled={disabled}
                        onClick={this.togglePopUp}>
                    { disabled && (
                        <span>
                            <FontAwesomeIcon icon={['fas', 'spinner']} spin/>
                            {' '}
                        </span>
                    )}

                    {this.props.children}
                </Button>

                <Modal show={show} onHide={this.togglePopUp}>
                    <Modal.Header closeButton>
                        <Modal.Title className="modalTitleH4">Confirm</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="modalBodyTitle">
                            Please confirm before continuing
                        </p>

                        <Form name="formName">
                            <Form.Row>
                                <Form.Group as={Col} controlId="userSelector">
                                    {/* <Form.Control
                                        type="password"
                                        className={ emptyPassword ? 'error' : ''}
                                        placeholder="Type password"
                                        onChange={(e) => {
                                            this.setState({
                                                password: e.target.value,
                                                emptyPassword: false
                                            })
                                        }}
                                        value={this.state.password}
                                    />

                                    { emptyPassword && (
                                        <span className="error-label">
                                            Invalid email and/or password
                                        </span>
                                    )} */}

                                    <span className="fake-checkbox-switcher nuke-records">
                                        <label>
                                            <input defaultChecked={confirmedNuke}
                                                value={confirmedNuke}
                                                onChange={e => {
                                                    this.handleModuleCheckboxChange(e, confirmedNuke);
                                                }}
                                                type="checkbox" />
                                            <span className="fake-switcher"></span>
                                        </label>
                                    </span>
                                    <span className='nuke-confirm'>Confirm</span>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>

                        <Button className="cancel" onClick={this.togglePopUp}>
                            Cancel
                        </Button>

                        <Button className="delete" disabled={!confirmedNuke} onClick={this.onConfirmClick}>
                            Nuke records
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default PasswordConfirmation;
