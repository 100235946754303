import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";

export default function(organisationId) {
  return async dispatch => {
    try {
      let call = {
        method: "post",
        url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/validations`
      }
      let validations = await callHandler(call);
  
      dispatch(displayNotification({
        message: 'Successfully re-validated',
        type: TYPE_SUCCESS,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));

      return validations;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error getting organisation',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}