export const licenseesorTable = [
    {
        field: 'brandName',
        label: 'Brand',
        order: 0,
        filter: true,
    },
    {
        field: 'agentOrganisationName',
        label: 'Agent',
        order: 1,
        filter: true,
    },
    {
        field: 'agentOrganisationId',
        label: 'agent Organisation ID',
        order: 2,
    },
    {
        field: 'actions',
        label: 'Actions',
        order: 3,
    }
  ]
