import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Nav, Container} from 'react-bootstrap';
import ProductCategoriesNames from './ProductCategoriesNames';
import ProductCategoriesLicensor from './ProductCategoriesLicensor';
import ProductCategoriesLevels from './ProductCategoriesLevels';
import ProductCategoryAttributes from './ProductCategoryAttributes';
import ErrorAlert from 'components/ErrorAlert';


class ProductCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'levels',
        };
    };

    toggleView = (e, navtab) => {
        e.preventDefault();
        this.setState({tab: navtab});
    }

    render() {
        const { tab } = this.state;
        const {selectedOrg = {}, errors, revalidate} = this.props;
        const {name = '', organisation_id = ''} = selectedOrg;

        return (
            <div className="company-informtion data-capture-section">            
                <ErrorAlert errors={errors}  revalidate={revalidate}></ErrorAlert>

                <Container fluid className="">
                    <h2 className="mb-3">{name}</h2>
                    <Nav defaultActiveKey="info" className="mt--2 pr-0 mb-3">
                        <Nav.Item>
                            <Nav.Link className={`mr-2 pill ${tab === 'levels' ? 'active' : ''}`} 
                                      onClick={e => this.toggleView(e, 'levels')}>
                                Levels
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className={`mr-2 pill ${tab === 'names' ? 'active' : ''}`}  onClick={e => this.toggleView(e, 'names')}>
                                Category Names
                            </Nav.Link>
                        </Nav.Item>
                        {selectedOrg.type === 'licensee' && 
                            <Nav.Item>
                                <Nav.Link className={`mr-2 pill ${tab === 'licensor' ? 'active' : ''}`}  onClick={e => this.toggleView(e, 'licensor')}>
                                    Licensor Mappings
                                </Nav.Link>
                            </Nav.Item>
                        }
                        {selectedOrg.type === 'licensor' && 
                            <Nav.Item>
                                <Nav.Link className={`mr-2 pill ${tab === 'attributes' ? 'active' : ''}`}  onClick={e => this.toggleView(e, 'attributes')}>
                                    Category Attributes
                                </Nav.Link>
                            </Nav.Item>
                        }
                    </Nav>

                    {this.state.tab === 'levels'
                        && <ProductCategoriesLevels
                            selectedOrg={selectedOrg}
                            name={name}
                            organisationId={organisation_id}
                        />
                    }
                    {this.state.tab === 'names'
                        && <ProductCategoriesNames
                            selectedOrg={selectedOrg}
                        />
                    }
                    {this.state.tab === 'licensor'
                        && <ProductCategoriesLicensor
                            selectedOrg={selectedOrg}
                        />
                    }
                    {this.state.tab === 'attributes'
                        && <ProductCategoryAttributes
                            selectedOrg={selectedOrg}
                        />
                    }
                </Container>
            </div>
        );
    }
}

export default connect(
    null
)(ProductCategories);
