import {
  LIST_ORGS
} from "actions/organisations";
import {
  SELECT_ORG
} from "actions/organisations";

const getInitialState = () => ({
  list: [],
  byId: {},
  selected: null
});

export default function organisations(state = getInitialState(), action) {
  switch (action.type) {
    case LIST_ORGS:
      const newState = {
        ...state,
        ...action.payload,
        byId: {
          ...state.byId
        }
      }
      action.payload.list.forEach(organisation => {
        newState.byId[organisation.organisation_id] = organisation
      })
      return newState
    case SELECT_ORG:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
