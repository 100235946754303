import React, { Component } from 'react';
import './OrgAdminNavItem.css';
import Icon from 'components/icon';

class DataCapturePageNavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { label, onNavSelect, selectedNavKey, id, errors } = this.props;

    return (
      <button
        type="button"
        className={`data-capture-page-nav-item ${id === selectedNavKey ? 'selected' : ''}`}
        onClick={() => onNavSelect(id)}
      >
        {label}

        {
            !!errors && (
                <Icon
                  icon={'error'}
                  size={22}
                  fill="red"
                />
            )
          }
      </button>
    );
  }
}

export default DataCapturePageNavItem;
