import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId) {
  let call = {
    method: "get",
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/employees`
  }
  let { employees } = await callHandler(call);
  return employees;
}