import {
  LIST_USERS
} from "actions/users";
import {
  SELECT_USER
} from "actions/users";

const getInitialState = () => ({
  list: [],
  selected: null
});

export default function users(state = getInitialState(), action) {
  switch (action.type) {
    case LIST_USERS:
      return {
        ...state,
        ...action.payload
      };
      case SELECT_USER:
        return {
          ...state,
          ...action.payload
        };
    default:
      return state;
  }
}
