import React, { Component } from 'react';
import { connect } from "react-redux";
import jwtDecode from 'jwt-decode';
import md5 from 'md5';
import { Navbar, Image, Nav, Dropdown } from 'react-bootstrap';
import { getAuthStorage } from "utils/storage";
import xelacoreLogo from 'assets/icons/xelacore-logo.svg';
import dropDownArrow from 'assets/icons/drop-down-arrow-black.svg';
import './AdminHeader.css';

import { logout } from "utils/storage";

class AdminHeader extends Component {
    render() {
        const authStorage = getAuthStorage();
        const userInfo = !!authStorage.org_access_token && jwtDecode(authStorage.org_access_token)
            ? jwtDecode(authStorage.org_access_token).data
            : {};

        const hashedEmail = userInfo && userInfo.user ? md5(userInfo.user) : null;
        const img = `//www.gravatar.com/avatar/${hashedEmail}?s=50&d=identicon`;

        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="/">
                    <img src={xelacoreLogo} alt="Xelacore logo"/> |
                    <small>Admin</small>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                    <Nav>
                        <Nav.Link href="/">Organisations</Nav.Link>
                        <Nav.Link href="/users">Users</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Nav className="justify-content-end">
                    <Dropdown alignRight>
                        <Dropdown.Toggle id="user-actions-dropdown" className="x-user-actions-dropdown"
                                         bsPrefix="cheese">
                            <Image src={img}
                                   roundedCircle
                                   style={{ height: 40, paddingRight: 10 }}/>

                            {userInfo.user}

                            <img className="x-user-actions-drop-down-icon"
                                 src={dropDownArrow}
                                 alt="Search icon"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </Navbar>
        )
    }
}

export default connect(
    null,
    { logout }
)(AdminHeader);

