import React, { Component } from "react";
import GeneralHeader from "components/headers/GeneralHeader";
import LoginForm from "./LoginForm";
import "./Login.css";

class Login extends Component {
  render() {
    return (
      <div className="login">
        <GeneralHeader />
        <main>
          <div className="login-main-form">
            <h1>Xelacore Admin Panel</h1>
            <LoginForm />
          </div>
        </main>
      </div>
    );
  }
}

export default Login;
