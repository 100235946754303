import React, { Component } from 'react';
import RadioButton from 'components/inputs/RadioButton';


class RadioButtonGroup extends Component {
  onChange = e => {
    const { name } = this.props;
    this.props.onChange({
      name,
      value: e.target.value
    });
  }

  render() {
    const { name, valuePrefix, options, selected, onChange } = this.props;
    const radioButtons = options.map(({ label, value }) => {
      const radioButtonProps = {
        label, name, valuePrefix, value, onChange,
        ...(selected &&
          onChange
          ? { checked: value === selected }
          : { defaultChecked: value === selected }
        )
      };
      return (
        <RadioButton key={value} {...radioButtonProps} />
      );
    });
    return (
      <div onChange={this.onChange} className="radio-button-group">
        {radioButtons}
      </div>
    );
  }
}

export default RadioButtonGroup;
