import React, { Component } from 'react';
import DataCaptureFormTable from 'components/dataCaptureFormComponents/DataCaptureFormTable';
import DataCaptureTableColumn from 'components/dataCaptureTableComponents/DataCaptureTableColumn';
import ExpandableTextArea from 'components/inputs/ExpandableTextArea';
import { PRODUCT_CATEGORIES_SCHEME } from 'constants/structure';

class ProductCategories extends Component {
  render() {
    const { label } = this.props;
    return (
      <DataCaptureFormTable
        label={label}
        scheme={PRODUCT_CATEGORIES_SCHEME}
      >
        <DataCaptureTableColumn
          title="Categories"
          name="category"
          withSelectAll
          addData={{
            Component: ExpandableTextArea,
            buttonText: "Add Categories"
          }}
        />
        <DataCaptureTableColumn
          title="Sub-categories 1"
          name="sub_category_1"
          withSelectAll
          addData={{
            Component: ExpandableTextArea,
            buttonText: "Add Sub-categories 1"
          }}
        />
        <DataCaptureTableColumn
          title="Sub-categories 2"
          name="sub_category_2"
          withSelectAll
          addData={{
            Component: ExpandableTextArea,
            buttonText: "Add Sub-categories 2"
          }}
        />
      </DataCaptureFormTable>
    );
  }
}

export default ProductCategories;
