import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId) {
  try {
    let call = {
      method: "get",
      url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/myFiles/list`
    }
    let { files } = await callHandler(call);
    return files;
  } catch (err) {
    console.error('Error fetching files', err);
    return [];
  }

}