import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, FormControl, Row} from 'react-bootstrap';
import listEmployees from 'actions/api/employees/list';
import updatePermissions from 'actions/api/employees/updatePermissions';
import AddUserModal from 'components/AddUserModal';
import getOrgConfig from 'actions/api/orgConfig/get';
import {cloneDeep, filter, includes, isEqual} from 'lodash';

function globalStateMapper(globalState) {
    const {users} = globalState;
    return {
        users: users.list,
    };
}

class ManageUsersPermissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            filteredEmployees: [],
            selectedUser: null,
            showAddUserPopup: false,
            userCanView: ['view'],
            userCanInvite: ['invite', 'view', 'edit', 'delete'],
            filterValue: '',
            orgConfig: null,
            isPosExportEnabled: false,
        };
        this.filterInputRef = React.createRef();
    }

    componentDidMount() {
        this.fetchEmployees();
        this.fetchOrgConfig();
    }

    toggleAddUserPopup = () => {
        const {showAddUserPopup} = this.state;
        this.setState({showAddUserPopup: !showAddUserPopup});
    }

    handleUserSelect = (selectedUser) => {
        this.setState({selectedUser});
    }

    fetchEmployees = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        listEmployees(organisation_id)
            .then((data) => {
                this.setState({employees: data, filteredEmployees: data});
            })
            .catch(err => alert(err.message));
    }

    fetchOrgConfig = async () => {
        const {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};

        try {
            const orgConfigResponse = await getOrgConfig(organisation_id);
            const config = orgConfigResponse.config || {};
            const isPosExportEnabled = !!config.pos_export_attributes;
            this.setState({orgConfig: config, isPosExportEnabled});
        } catch (error) {
            console.error('Error fetching org config', error);
        }
    }

    handleSwitch = (e, user, featureKey) => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        let employeesClone = cloneDeep(this.state.employees);

        employeesClone.forEach(userItem => {
            if (user.employee_id === userItem.employee_id) {
                if (!userItem.permissions.features) {
                    userItem.permissions.features = {};
                }

                if (featureKey === 'users') {
                    userItem.permissions.system.users.can = e.currentTarget.checked
                        ? this.state.userCanInvite
                        : this.state.userCanView;
                } else {
                    if (e.currentTarget.checked) {
                        userItem.permissions.features[featureKey] = {can: ["view", "manage"]};
                    } else {
                        delete userItem.permissions.features[featureKey];
                        if (Object.keys(userItem.permissions.features).length === 0) {
                            delete userItem.permissions.features;
                        }
                    }
                }
            }
        });

        const updatedUser = employeesClone.find(u => u.employee_id === user.employee_id);

        updatePermissions(organisation_id, updatedUser.permissions, user.employee_id)
            .then(() => {
                this.setState({employees: employeesClone});
            })
            .catch(err => alert(err.message));
    };

    filterEmployees = (value) => {
        const {employees} = this.state;
        this.setState({filterValue: value});
        if (!value) {
            this.setState({filteredEmployees: employees});
            return;
        }
        const filteredEmployees = filter(employees, item => {
            return includes(item.email.toLowerCase(), value.toLowerCase());
        });
        this.setState({filteredEmployees});
    }

    clearFilter = () => {
        this.filterInputRef.current.value = '';
        this.filterEmployees('');
    }

    checkIfCanInvite = (user) => {
        if (user.permissions && user.permissions.system && user.permissions.system.users) {
            return isEqual(user.permissions.system.users.can.sort(), ['invite', 'view', 'edit', 'delete'].sort());
        }
        return false;
    }

    checkIfCanFeature = (user, featureKey) => {
        if (user.permissions && user.permissions.features && user.permissions.features[featureKey] && user.permissions.features[featureKey].can) {
            return isEqual(user.permissions.features[featureKey].can, ['view', 'manage']);
        }
        return false;
    }

    render() {
        const {selectedOrg} = this.props;
        const {organisation_id = null} = selectedOrg;
        const {filteredEmployees, showAddUserPopup, filterValue, isPosExportEnabled} = this.state;

        const isLicensee = selectedOrg.type === 'licensee';
        const isLicensor = selectedOrg.type === 'licensor';

        return (
            <div className="Employees data-capture-section p-0 mt-5">
                <Container fluid className="pt-2">
                    <h2>Manage Permissions </h2>
                    <Row>
                        <div className={'user-permissions-list-wrapper'}>
                            <table className="table table-lg table-striped table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th rowSpan="2">
                                        <div className='email-wrapper'>
                                            <span>Email</span>
                                            <div className={`filter-input-box`}>
                                                <FormControl
                                                    type="text"
                                                    autoFocus={true}
                                                    placeholder="Filter"
                                                    className="x-filter-bar-search-box email-filter-box"
                                                    onChange={(event) => this.filterEmployees(event.target.value || '')}
                                                    ref={this.filterInputRef}
                                                />
                                                <span
                                                    className={`filter-cleaner`}
                                                    onClick={() => this.clearFilter()}>✕</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th rowSpan="2">Manage Users</th>
                                    {(isLicensee || isLicensor) && (
                                        <th colSpan="2"
                                            style={{textAlign: 'center', padding: 0, border: 0}}>Features</th>
                                    )}
                                </tr>
                                {isLicensee && (
                                    <tr>
                                        <th>Royalty Copilot</th>
                                        <th>ESG View</th>
                                    </tr>
                                )}

                                {isLicensor && (
                                    <tr>
                                        <th style={{textAlign: 'center'}}>POS Export</th>
                                    </tr>
                                )}
                                </thead>
                                <tbody>
                                {filteredEmployees.map(user => {
                                    return (
                                        <tr key={user.user_id}>
                                            <td>{user.email}</td>
                                            <td className="fake-checkbox-switcher">
                                                <label>
                                                    <input defaultChecked={this.checkIfCanInvite(user)}
                                                           onChange={e => {
                                                               this.handleSwitch(e, user, 'users');
                                                           }}
                                                           type="checkbox"/>
                                                    <span className="fake-switcher"></span>
                                                </label>
                                            </td>

                                            {isLicensor && (
                                                <td className="fake-checkbox-switcher">
                                                    <label>
                                                        <input
                                                            defaultChecked={this.checkIfCanFeature(user, 'licensor_pos_export')}
                                                            onChange={e => this.handleSwitch(e, user, 'licensor_pos_export')}
                                                            type="checkbox"
                                                            disabled={!isPosExportEnabled}
                                                        />
                                                        <span className="fake-switcher"></span>
                                                    </label>
                                                </td>
                                            )}

                                            {isLicensee && (
                                                <>
                                                    <td className="fake-checkbox-switcher">
                                                        <label>
                                                            <input
                                                                defaultChecked={this.checkIfCanFeature(user, 'licensee_royalty_assistant')}
                                                                onChange={e => this.handleSwitch(e, user, 'licensee_royalty_assistant')}
                                                                type="checkbox"/>
                                                            <span className="fake-switcher"></span>
                                                        </label>
                                                    </td>
                                                    <td className="fake-checkbox-switcher">
                                                        <label>
                                                            <input
                                                                defaultChecked={this.checkIfCanFeature(user, 'esg_view')}
                                                                onChange={e => this.handleSwitch(e, user, 'esg_view')}
                                                                type="checkbox"/>
                                                            <span className="fake-switcher"></span>
                                                        </label>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    )
                                })}
                                {
                                    filteredEmployees.length === 0 && (
                                        <tr>
                                            <td colSpan={isLicensor ? 3 : 4} style={{textAlign: 'center'}}>
                                                No data available {filterValue && `for filter value "${filterValue}"`}
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Container>
                <AddUserModal addUserCallback={this.fetchEmployees} show={showAddUserPopup}
                              togglePopUp={this.toggleAddUserPopup} organisationId={organisation_id}></AddUserModal>
            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    null
)(ManageUsersPermissions);
