import React, {Component} from 'react';
import {connect} from "react-redux";
import CompanyInformation from './CompanyInformation'
import LinkToLicenseesor from './LinkToLicenseesor'
import Employees from './Employees'
import ExportUpdate from './ExportUpdate'
import Aliases from './Aliases'
import OrgConfig from './OrgConfig'
import Brands from './Brands'
import ProductCategories from './ProductCategories'
import IPHierarchy from './IPHierarchy'
import Modules from './Modules'
import MyFiles from './MyFiles'
import RetailerListings from './RetailerListings'
import OrgAdminNav from 'components/OrgAdminNav';
import ConceptsUpload from './ConceptsUpload';
import ContractsUpload from './ContractsUpload';
import {errorTypes} from 'constants/validations';
import each from 'lodash/each';
import includes from 'lodash/includes';
import LinkToAgent from "./LinkToAgent";
import listOrgs from 'actions/api/organisations/list';
import listUsers from 'actions/api/users/list';
import LinkedBrands from "./LinkedBrands";
import LinkedLicensees from "./LinkedLicensees";
import Exports from "./Exports";
import Notifications from "./Notifications";


class OrgHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNavKey: undefined,
            groupedErrors: []
        };
    };

    componentDidMount() {
        this.props.listOrgs();
        this.props.listUsers();
    }

    handleNavSelect = (key) => {
        this.setState({
            selectedNavKey: key
        })
    }

    groupErrors = (tab) => {
        const { validations } = this.props;
        let tabValidations = [];

        each(validations, validation => {
            each(!!validation && !!validation.errors && validation.errors, error => {
                if(!!includes(errorTypes[tab], error.problem.type)) {
                    tabValidations.push(error);
                }
            })
        })

        return tabValidations;
    }

    render() {
        const {organisations, selectedOrg = {}, handleOrgInfoUpdate, handleOrgInfoSubmit, revalidate} = this.props;

        let exportTab =
            <ExportUpdate
                key='exportUpdate'
                label='Export/Update'
                errors={this.groupErrors('export')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </ExportUpdate>;
        let ExportsPage =
            <Exports
                key='exports'
                label='Exports'
                selectedOrg={selectedOrg}
                errors={this.groupErrors('exports')}
                revalidate={revalidate}
                handleOrgInfoUpdate={handleOrgInfoUpdate}
                handleOrgInfoSubmit={handleOrgInfoSubmit}
            />;
        let NotificationsPage =
            <Notifications
                key='notifications'
                label='Notifications'
                selectedOrg={selectedOrg}
                errors={this.groupErrors('notifications')}
                revalidate={revalidate}
                handleOrgInfoUpdate={handleOrgInfoUpdate}
                handleOrgInfoSubmit={handleOrgInfoSubmit}
            />;
        let retailersTab =
            <RetailerListings
                key='retailerListings'
                label='Retailer Listings'
                errors={this.groupErrors('retailer_listings')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </RetailerListings>
        let IPHierarchyTab =
            <IPHierarchy
                key='IPHierarchy'
                label='IP Hierarchy'
                errors={this.groupErrors('ip_hierarchy')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </IPHierarchy>
        let ConceptsUploadTab =
            <ConceptsUpload
                key='ConceptsUpload'
                label='Concepts Upload'
                errors={this.groupErrors('concepts_upload')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </ConceptsUpload>
        let ContractsUploadTab =
            <ContractsUpload
                key='ContractsUpload'
                label='Contracts Upload'
                errors={this.groupErrors('contracts_upload')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </ContractsUpload>
        let menuItems = [
            <CompanyInformation
                key='companyInfo'
                label='Company Information'
                selectedOrg={selectedOrg}
                errors={this.groupErrors('company_information')}
                revalidate={revalidate}
                handleOrgInfoUpdate={handleOrgInfoUpdate}
                handleOrgInfoSubmit={handleOrgInfoSubmit}>
            </CompanyInformation>,
            <LinkToLicenseesor
                key='linkToLicenseesor'
                showFor={['licensee', 'licensor']}
                errors={this.groupErrors('link_to_licensor')}
                label={selectedOrg.type === 'licensee' ? 'Link To Licensor' : 'Link To Licensee'}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </LinkToLicenseesor>,
            <LinkToAgent
                key='linkToAgent'
                showFor={['licensee']}
                errors={this.groupErrors('link_to_agent')}
                label={'Link to Agent'}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </LinkToAgent>,
            <LinkedLicensees
                key='LinkedLicensees'
                label='Linked Licensees'
                showFor={['agent']}
                errors={this.groupErrors('licensee_link')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </LinkedLicensees>,
            <Employees
                key='employees'
                label='Employees'
                errors={this.groupErrors('employees')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </Employees>,
            <Aliases
                key='aliases'
                label='Aliases'
                errors={this.groupErrors('aliases')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </Aliases>,
            <LinkedBrands
                key='LinkedBrands'
                label='Linked Brands'
                showFor={['agent']}
                errors={this.groupErrors('brand_link')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </LinkedBrands>,
            <OrgConfig
                key='orgConfig'
                label='Config'
                revalidate={revalidate}
                errors={this.groupErrors('config')}
                selectedOrg={selectedOrg}>
            </OrgConfig>,
            <Brands
                key='brands'
                label='Brands'
                showFor={['licensor']}
                errors={this.groupErrors('brands')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </Brands>,
            <ProductCategories
                key='ProductCategories'
                errors={this.groupErrors('product_categories')}
                revalidate={revalidate}
                showFor={['licensee', 'licensor']}
                label='Product Categories'
                selectedOrg={selectedOrg}>
            </ProductCategories>,
            <Modules
                key='modules'
                label='Modules'
                errors={this.groupErrors('modules')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </Modules>,
            <MyFiles
                key='myFiles'
                label='My Files'
                errors={this.groupErrors('my_files')}
                revalidate={revalidate}
                selectedOrg={selectedOrg}>
            </MyFiles>
        ];
        const {selectedNavKey = menuItems[0].key} = this.state;

        if (selectedOrg.type === 'licensee') {
            menuItems.push(exportTab);
            menuItems.push(retailersTab);
        }
        if (selectedOrg.type === 'licensor') {
            menuItems.push(IPHierarchyTab);
            menuItems.push(ConceptsUploadTab);
            menuItems.push(ContractsUploadTab);
            menuItems.push(ExportsPage);
        }

        menuItems.push(NotificationsPage);

        return (
            <div>
                <div className="data-capture-page-form-wrapper-main">
                    <div className="data-capture-page-form-wrapper-nav">
                        <OrgAdminNav
                            title={"title"}
                            items={menuItems}
                            selectedOrg={selectedOrg}
                            selectedNavKey={selectedNavKey}
                            onNavSelect={this.handleNavSelect}
                        />
                    </div>
                    <div className="data-capture-page-form-wrapper-content">
                        {menuItems.filter(mI => mI.key === selectedNavKey).map(cp => cp)}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    {listOrgs, listUsers}
)(OrgHome);

