import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Col, Row } from 'react-bootstrap';
import listEmployees from 'actions/api/employees/list';
import UserSelector from 'components/UserSelector';
import addGtinWaiverLog from 'actions/api/orgConfig/gtinWaiver/create';
import listGtinWaivers from 'actions/api/orgConfig/gtinWaiver/list';
import { waiversTable } from './gtinWaiverTableHeader';
import CustomTableWithActions from 'components/CustomTableWithActions';
import ButtonWithConfirm from 'components/ButtonWithConfirm';
import './gtinwaiver.scss';

function globalStateMapper(globalState) {
  const { users } = globalState;
  return {
    users: users.list,
  };
}

class GtinWaiver extends Component {

  constructor(props) {
    super(props);
    this.state = {
      gtin_reason: "",
      gtin_requesting_username: "",
      gtin_requesting_user_id: "",
      employees: [],
      selectedUser: null,
      username: "",
      gtin_waivers: [],
      button_label: "",
    }
  }

  componentDidMount() {
    this.fetchEmployees();
    this.fetchGtinWaiverList();
  }

  handleUserSelect = (selectedUser) => {
    this.setState({
      selectedUser,
      gtin_requesting_user_id: selectedUser.user_id,
      gtin_requesting_username: selectedUser.email
    });
  }

  fetchEmployees = () => {
    let { organisationId } = this.props;
    listEmployees(organisationId).then((data) => {
      this.setState({ employees: data })
    })
      .catch(err => alert(err.message));
  }

  handleAddWaiver = () => {
    let { organisationId, org_waiver_status } = this.props;
    let { gtin_reason, gtin_requesting_username, gtin_requesting_user_id } = this.state;
    let waiver = {
      gtin_waiver: !org_waiver_status,
      gtin_reason,
      gtin_requesting_username,
      gtin_requesting_user_id,
    }

    this.props.addGtinWaiverLog(organisationId, waiver).then(() => {
      this.fetchGtinWaiverList();
      this.clearFormValues();
      this.props.fetchOrgConfig();
    })
      .catch(err => alert(err.message));
  }

  fetchGtinWaiverList = () => {
    let { organisationId } = this.props;
    this.props.listGtinWaivers(organisationId)
      .then((data) => {
        let { gtin_waivers = [] } = data;
        this.setState({ gtin_waivers });
      })
      .catch(err => alert(err.message));
  }

  clearFormValues = () => {
    this.setState({
      gtin_reason: '',
      selectedUser: null,
      gtin_requesting_user_id: "",
      gtin_requesting_username: "",
    });
    document.getElementById('userSelect').selectedIndex = 0;
  }

  handleButtonLabelUpdate = (currentStatus) => {
    let button_name = (currentStatus) ? 'Remove Exemption' : 'Activate Exemption';
    return button_name;
  }

  render() {
    const { users, org_waiver_status = false } = this.props;
    const { employees, gtin_waivers } = this.state;
    const employeeUserIds = employees.map(e => e.user_id);
    const empUsers = users.filter(u => employeeUserIds.indexOf(u.user_id) > -1);
    const confirmMessage = 'You are about to update this organisations config, this will have a direct affect on parts of the LIVE SYSTEM';

    return (
      <div>
        <Form>
          <Form.Row className="ml-1">
            <Col>

              <Row>
                Status:
                            {org_waiver_status
                  ? <div className="status_label on">Exemption On</div>
                  : <div className="status_label off">Exemption Off</div>
                }
              </Row>
              <Row>
                <Form.Label>Reason</Form.Label>
                <Form.Control as="textarea"
                  label="Reason for Exemption"
                  onChange={(e) => { this.setState({ gtin_reason: e.target.value }) }}
                  value={this.state.gtin_reason}
                  rows={6}
                  className="mb-0 mr-5"
                />
              </Row>
            </Col>
            <Col>
              <Form.Label>Please Note:</Form.Label>
              <p>
                This setting controls whether the validation rules, conflict determination and management and Quarantine handling that will be used.
                This setting is fundamental and effects each record significantly.
                All records ingested will forever include the GTIN waiver setting that was in-force the moment each record is ingested.
                Changing the system GTIN waiver status will not impact the record level GTIN waiver status for already existing records UNLESS THE CORE FIELDS ARE CHANGED.
                Admins should be fully familiar with the Confluence page on GTIN Waiver Functionality to ensure they fully understand the impacts of using this toggle before they use it.
              </p>
            </Col>
          </Form.Row>
          <Form.Row className="mt-2">
            <Col>
              <Form.Label>Requester</Form.Label>
              <Form.Group as={Col} controlId="userSelector" className="pl-0">
                <UserSelector onUserSelect={this.handleUserSelect} users={empUsers} ></UserSelector>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridName" className="mt-5 pl-0">
                <Form.Control
                  placeholder="Enter email / username of Requester "
                  onChange={(e) => this.setState({ gtin_requesting_username: e.target.value, gtin_requesting_user_id: null })}
                  value={this.state.gtin_requesting_username}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          {
            (this.state.gtin_requesting_username === '' || this.state.gtin_reason === '')
              ? <div className="btn disabled">{this.handleButtonLabelUpdate(org_waiver_status)}</div>
              : <ButtonWithConfirm className="submit" onClick={this.handleAddWaiver} message={confirmMessage}>{this.handleButtonLabelUpdate(org_waiver_status)}</ButtonWithConfirm>
          }
        </Form>
        <Row className="mt-4 ml-1">
          { gtin_waivers.length === 0
              ? "There are no waivers to show right now"
              : <CustomTableWithActions
                data={gtin_waivers}
                tableheader={waiversTable}
              />
          }
        </Row>
      </div>
    );
  }
}

export default connect(
  globalStateMapper, { addGtinWaiverLog, listGtinWaivers }
)(GtinWaiver);
