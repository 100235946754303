import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Container, Button, Col } from 'react-bootstrap';
import listAliases from 'actions/api/aliases/list';
import addAlias from 'actions/api/aliases/create';
import deleteAlias from 'actions/api/aliases/delete';
import { aliasesTable } from './tableheader'

import CustomTableWithActions from 'components/CustomTableWithActions';
import ErrorAlert from 'components/ErrorAlert';

function globalStateMapper(globalState) {
  return {};
}

class Aliases extends Component {
  constructor(props){
		super(props);
		this.state = {
      aliases: [],
      name: "",
      type: "Trade Name",
    }
  };

  componentDidMount() {
    this.fetchAliases();
  }

  fetchAliases = () => {
    let { selectedOrg = {} } = this.props;
    let { organisation_id } = selectedOrg;
    listAliases(organisation_id)
    .then((data) => {
      this.setState({ aliases: data })
    })
    .catch(err => alert(err.message));
  }

  handleAddAlias = () => {
    let { selectedOrg } = this.props;
    let { organisation_id } = selectedOrg || {};
    let { name, type } = this.state;
    this.props.addAlias(organisation_id, name, type).then((data) => {
      this.fetchAliases();
      this.setState({ name: "", type: "Trade Name" })
    });
  }

  handleDeleteAlias = (alias) => {
    const { alias_id } = alias;
    let { selectedOrg } = this.props;
    let { organisation_id } = selectedOrg || {};

    deleteAlias(organisation_id, alias_id).then((data) => {
      this.fetchAliases();
    })
    .catch(err => alert(err.message));
  }
  
  render() {
    const { errors, revalidate } = this.props;
    const { aliases } = this.state;
    return (
      <div className="Aliases data-capture-section">
        <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>
        
        <Container fluid className="">
        <h2>Aliases ({aliases.length})</h2>

          <Form>
            <Form.Row>

              <Form.Group as={Col} controlId="name">
                <Form.Control 
                    placeholder="Enter Name" 
                    onChange={(e) => { this.setState({ name: e.target.value })}}
                    value={this.state.name}
                  />
              </Form.Group>

              <Form.Group as={Col} controlId="type">
                <Form.Control as="select"
                    placeholder="" 
                    onChange={(e) => { this.setState({ type: e.target.value })}}
                    value={this.state.type}
                    >
                    <option>Trade Name</option>
                    <option>Doing Business As</option>
                    <option>Subsidiary</option>
                    <option>Legal Name</option>
                </Form.Control> 
              </Form.Group>

              <Form.Group as={Col} controlId="addUserButton">
                <Button className="add" onClick={this.handleAddAlias}>Add</Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <CustomTableWithActions 
            data={aliases}
            tableheader={aliasesTable}
            actions={[
              {
                name: "Remove",
                className: 'delete',
                onClick: this.handleDeleteAlias,
                message: "You are about to REMOVE this alias from this organisation, this alias will no longer be a valid selection for data belonging to this organisation",
                confirmation: true
              },
            ]}
            />
        </Container>
      </div>
    );
  }
}

export default connect(
  globalStateMapper,
  { addAlias }
)(Aliases);
