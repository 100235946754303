import React, { Component } from 'react';
import XelacoreFilesHandler from 'components/XelacoreFilesHandler';
import ErrorAlert from 'components/ErrorAlert';

class MyFiles extends Component {
  constructor(props){
		super(props);
		this.state = {}
  };

  componentDidMount() {}

  render() {
    const { selectedOrg, errors, revalidate } = this.props;
    
    return (
      <div className="MyFiles">
        <ErrorAlert errors={errors}  revalidate={revalidate}></ErrorAlert>

        <h1>My Files</h1>
        <p>These files are unique to the organisation you are viewing and will ONLY affect this organisation</p>
        <XelacoreFilesHandler organisationId={selectedOrg.organisation_id} rmConfirmMsg={'Please confirm you wish to remove this file, it will no longer be available for download by THIS organisation'}/>
        <hr />
        <h1>Common</h1>
        <p>These files are common to all organisations and will affect EVERY organisation when changed</p>
        <XelacoreFilesHandler organisationId={'common'} rmConfirmMsg={'Please confirm you wish to remove this file, it will no longer be available for download by ALL organisations'}/>
      </div>
    );
  }
}

export default MyFiles
