import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function({ newUserEmail, newUserFirstName, newUserLastName }) {
  let call = {
    url: `${Config.get('ADMIN_URL')}users`,
    method: 'post',
    data: { 
      email: newUserEmail, 
      firstName: newUserFirstName, 
      lastName: newUserLastName 
    },
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let { user = {} } = await callHandler(call);
  return user;
}