import config from 'config';
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";

export default function(user, data) {
    return async dispatch => {
        try {
            let call = {
                method: 'post',
                url: `${config.get('ADMIN_URL')}users/${user.user_id}/reset`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data
            };

            let addResp = await callHandler(call);

            dispatch(displayNotification({
                message: 'A password reset message was sent to user email successfully.',
                type: TYPE_SUCCESS,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            }));

            return addResp;
        } catch (err) {
            dispatch(displayNotification({
                message: 'Oops, something wrong. Please try again.',
                type: TYPE_ERROR,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            }));
        }
    }

}