import React, { Component } from 'react';
import { Table, Container } from 'react-bootstrap';
import includes from 'lodash/includes';
import map from 'lodash/map';

class BasicTable extends Component {
  constructor(props){
    super(props);
		this.state = {
    }
  };

  componentDidMount() {
  }

  render() {
    let { data, headers = [], links } = this.props;
    if (!headers || (headers.length === 0 && (data && data.length > 0))) {
      headers = Object.keys(data[0]);
    }
    return (
      <div className="BasicTable">
        <Container>
          {
              data && data.length > 0 ? <Table striped bordered hover size="sm">
              <thead>
                <tr>
                {
                  headers.map(k => {
                    return <th>{k}</th>
                  })
                }
                </tr>
              </thead>
              <tbody>
                {
                  data.map(l => {
                    return <tr>{ 
                      headers.map(k => {
                        if (includes(map(links, 'name'), k)) {
                          return <td className="link" key={k}>
                            <a href={links[k].type === 'organisations' ? `/organisations/${l[k]}` : `/users/${l[k]}`}>{l[k]}</a>
                          </td>
                        }

                        return <td>{l[k]}</td>
                        })
                      }</tr>
                  })
                }
              </tbody>
            </Table> : 
            <p>No data to show</p>
          }
        </Container>
      </div>
    );
  }
}

export default BasicTable;
