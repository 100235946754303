import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button} from 'react-bootstrap';
import {exportRecords} from 'actions/api/organisations/exportUpdate';
import "filepond/dist/filepond.min.css";
import DataTable from './DataTable';
import setExports from 'actions/api/exports/setExports'
import Pagination from 'components/lists/Pagination';
import {getPageItems, getPagesCount} from "utils/pagination";
import list from 'actions/api/exports/list'
import {sortBy} from 'lodash';
import memoize from 'memoize-one';

class ExportSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            fetching: false,
            currentPage: 1,
            itemsPerPage: 10,
            totalResults: 0,
        }
    };

    componentDidMount() {
        list(this.props.selectedOrg.organisation_id, 'xelacore-catalog').then((exportList) => {
            this.props.setExports(exportList)
        })
    }

    handleExportRecords = (licensorId) => {
        let {selectedOrg} = this.props;

        this.setState({
            fetching: true
        });
        return exportRecords(selectedOrg.organisation_id, licensorId).then(() => {
            this.setState({
                errorMessage: '',
                fetching: false
            })
        }, (error) => {
            this.setState({
                errorMessage: 'There was an error with your request.',
                fetching: false
            })
        });
    };

    onPageChanged = (currentPage) => {
        this.setState({currentPage});
    };

    getEntries = memoize((exports, licensorOrganisationId, userId) =>
        sortBy(Object.values(exports), 'createdAt').reverse()
    )


    paginateList = memoize(getPageItems);
    paginateCount = memoize(getPagesCount);

    render() {
        const {currentPage, itemsPerPage} = this.state
        const exportList = this.getEntries(this.props.exports || {}, this.props.licensorOrganisationId, this.props.userId)
        const paginatedList = this.paginateList(exportList, currentPage, itemsPerPage);

        return (
            <>
                <div className="export-actions">

                    <Button
                        disabled={this.state.fetching || !this.props.licensorOrganisationId}
                        className="add ml-0 mt-3 mb-4"
                        onClick={() => this.handleExportRecords(this.props.licensorOrganisationId)}>

                        <span>Create Export</span>

                        {!!this.state.fetching && (
                            <i className={"spin-loader"}></i>
                        )}
                    </Button>


                    {this.state.errorMessage && (
                        <span className="error-message ml-2">
              {this.state.errorMessage}
            </span>
                    )}
                </div>
                <DataTable
                    takesLicensor={true}
                    organisations={this.props.organisations}
                    entries={paginatedList}
                />
                <Pagination
                    current={this.state.currentPage}
                    total={this.paginateCount(exportList, itemsPerPage)}
                    onPageChanged={this.onPageChanged}
                />
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        exports: state.exportUpdate.exports.byLicenseeOrganisationId[ownProps.selectedOrg.organisation_id],
        organisations: state.organisations.byId
    }
}

export default connect(mapStateToProps, {setExports})(ExportSection);
