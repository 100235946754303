import React, { Component } from 'react';
import { connect } from "react-redux";
import get from 'lodash/get';
import set from 'lodash/set';
import produce from 'immer';
import DataCaptureTableColumnHeader from './DataCaptureTableColumnHeader';
import CheckboxGroup from 'components/inputs/CheckboxGroup';
import putDataValue from "actions/form/table/put";
import deleteDataValues from "actions/form/table/delete";
import { getValuePath, getIncreasingSubpaths } from "utils/iter";
import { getSubDataObject } from "utils/table";
import { NAME_KEY, VALUES_KEY, CHILDREN_KEY } from "constants/structure";
import './DataCaptureTableColumn.css';

class DataCaptureTableColumn extends Component {
  get namePath() {
    const { tableSelection, name } = this.props;
    return getValuePath(tableSelection, name);
  }

  getValuesPath = () => {
    return this.namePath.replace(NAME_KEY, VALUES_KEY);
  }

  getChildrenPath = () => {
    return this.namePath.replace(NAME_KEY, CHILDREN_KEY);
  }

  get selection() {
    const { tableSelection } = this.props;
    return get(tableSelection, this.getValuesPath());
  }

  /**
   * returns array of paths to all parents
   */
  get parentsDataPaths() {
    const { tableSelection } = this.props;
    const parentsPaths = getIncreasingSubpaths({
      path: this.getValuesPath(),
      splitter: CHILDREN_KEY,
      finalKey: NAME_KEY,
      finalValue: `${VALUES_KEY}.0`
    });

    return parentsPaths.map(parentPath => ({
      name: get(tableSelection, parentPath.name),
      value: get(tableSelection, parentPath.values)
    }))
  }

  getDataObject = () => {
    const { data } = this.props;
    return getSubDataObject({
      path: this.parentsDataPaths,
      data
    })
  }

  onRemoveData = values => {
    this.removeMultipleSelection(values);
    this.props.deleteDataValues({
      path: this.parentsDataPaths,
      name: this.props.name,
      values
    });
  }

  onAddData = value => {
    this.props.putDataValue({
      path: this.parentsDataPaths,
      name: this.props.name,
      value
    });
  }

  putSelection = (newSelection) => {
    const { tableSelection, initialSelection } = this.props;
    const childrenPath = this.getChildrenPath();
    const children = get(initialSelection, childrenPath);
    this.props.updateSelection(produce(tableSelection, draft => {
      set(draft, this.getValuesPath(), newSelection);
      set(draft, childrenPath, children);
    }));
  }

  handleSelection = e => {
    const { value } = e.target;
    const { selection } = this;

    const newSelection = selection.includes(value)
      ? selection.filter(el => el !== value)
      : [...selection, e.target.value];

    this.putSelection(newSelection);
  }

  removeMultipleSelection = values => {
    const { selection } = this;

    const newSelection = selection.filter(el => !values.includes(el));

    this.putSelection(newSelection);
  }

  onSelectAll = () => {
    const { name } = this.props;
    const dataObject = this.getDataObject();
    const options = dataObject[name];
    const newSelection = options.map(({ name }) => name);
    this.putSelection(newSelection);

  }

  onUnselectAll = () => {
    this.putSelection([]);
  }

  getOptions = () => {
    const { name } = this.props;
    const dataObject = this.getDataObject();
    const { subListSplitter } = this.props;
    return dataObject[name] && dataObject[name].map(val => {
      if (subListSplitter) {
        const splittedValue = val.name.split(subListSplitter);
        return {
          label: splittedValue[0],
          subLabels: splittedValue[1].split(', '),
          value: val.name
        }
      }

      return {
        label: val.name,
        value: val.name
      }
    });

  }

  isSelectedNotSingleParent = () => {
    const { tableSelection } = this.props;
    const directSelectionPath = this.getValuesPath();
    const lastChildIndex = directSelectionPath.lastIndexOf(CHILDREN_KEY);
    const parentPathRaw = directSelectionPath.slice(0, lastChildIndex) + directSelectionPath.slice(lastChildIndex).replace(CHILDREN_KEY, VALUES_KEY);
    const parentPath = parentPathRaw.substring(0, parentPathRaw.indexOf(VALUES_KEY) + VALUES_KEY.length);
    const parentValues = get(tableSelection, parentPath);

    return lastChildIndex !== -1 && parentValues.length !== 1
  }

  render() {
    const {
      addData, title, onRemove, name, withDirectParentSign,
      withSelectAll, charactersCount
    } = this.props;

    if (this.isSelectedNotSingleParent()) {
      return (
        <div className="data-capture-table-column">
          <div className="data-capture-table-column-header-container">
            <DataCaptureTableColumnHeader
              title={title}
            />
          </div>
        </div>
      )
    }

    const options = this.getOptions();
    const { selection } = this;
    return (
      <div className="data-capture-table-column">
        <div className="data-capture-table-column-header-container">
          <DataCaptureTableColumnHeader
            title={title}
            count={options && options.length}
            columnFor={withDirectParentSign && this.getDataObject().name}
            charactersCount={charactersCount}
            onRemove={(onRemove)}
          />
        </div>
        <div className="data-capture-table-column-add-data">
          <addData.Component
            addButtonText={addData.buttonText}
            onAddClick={this.onAddData}
          />
        </div>
        <div className="data-capture-table-column-data-list">
          <CheckboxGroup
            name={name}
            options={options}
            selected={selection}
            onChange={this.handleSelection}
            handleSelection={this.handleSelection}
            withSelectAll={withSelectAll}
            onRemove={this.onRemoveData}
            onSelectAll={this.onSelectAll}
            onUnselectAll={this.onUnselectAll}
          />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  data: state.form.data
}), { putDataValue, deleteDataValues })(DataCaptureTableColumn);
