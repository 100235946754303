import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

// brandId,organisationName,agentOrganisationName,agentOrganisationId
export default async function(organisationId, dataToSend) {
  let call = {
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/brand/links`,
    method: 'post',
    data: dataToSend,
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let addResp = await callHandler(call);
  return addResp;
}