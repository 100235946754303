import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationData) {
  let call = {
    url: `${Config.get('ADMIN_URL')}organisations`,
    method: 'post',
    data: organisationData,
    headers: {
      'Content-Type': 'application/json'
    },
  }
  let addResp = await callHandler(call);
  return addResp;
}