import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { attributesTable } from './tableHeader';
import listAttributes from 'actions/api/brands/attributes/list';
import CustomTableWithActions from 'components/CustomTableWithActions';

function globalStateMapper(globalState) {
  return {};
}

class Attributes extends Component {
  constructor(props){
		super(props);
		this.state = {
            attributes: []
        }
  };

  componentDidMount() {
    this.fetchAttributes();
  }

  fetchAttributes() {
    let { selectedOrg, brand } = this.props;
    let { organisation_id } = selectedOrg || {};

    listAttributes(organisation_id, brand.brand_id).then((data) => {
      this.setState({ attributes: data })
    }).catch(err => alert(err.message));
  }

  render() {
    const { attributes } = this.state;

    return (
        <Container fluid className="p-0">
            <div>
                <h2>Attributes</h2>
            </div>
            
            <CustomTableWithActions 
                data={attributes} 
                tableheader={attributesTable}
            />
        </Container>
    );
  }
}

export default connect(
  globalStateMapper,
  null
)(Attributes);
