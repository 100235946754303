import { storageKeys } from "constants/storage";
import { expirationTime, XELACORE_DOMAIN, USER_REFRESH_TOKEN, USER_TOKEN } from "constants/api";
import cookies from 'js-cookie';
import Config from 'config';
import axios from "axios";

export const setAuthStorage = ({ org_access_token, name, rememberMe, password, selected_org_id, user_refresh_token }) => {
  const storageToUse = rememberMe ? localStorage : sessionStorage;

  // clear all previous possible storages
  eraseAuthStorage();

  storageToUse.setItem(storageKeys.org_access_token, (org_access_token || ''));
  storageToUse.setItem(storageKeys.selected_org_id, (selected_org_id) || '');
  storageToUse.setItem(storageKeys.user_refresh_token, (user_refresh_token || ''));
  storageToUse.setItem(storageKeys.name, (name || ''));
  storageToUse.setItem(storageKeys.password, (password || ''));
  storageToUse.setItem(storageKeys.date, Date.now());
};

export const updateAccessToken = ({ org_access_token, rememberMe = false }) => {
  const storageToUse = rememberMe ? localStorage : sessionStorage;
  storageToUse.setItem(storageKeys.org_access_token, (org_access_token || ''));
  storageToUse.setItem(storageKeys.date, Date.now());
};

export const getItem = key => {
  return sessionStorage.getItem(key) || localStorage.getItem(key);
};

export const getAuthStorage = () => {
  const writeDate = getItem(storageKeys.date);
  const dateDifference = Date.now() - writeDate;
  if (dateDifference > expirationTime) {
    return null;
  }

  return {
    name: getItem(storageKeys.name),
    org_access_token: getItem(storageKeys.org_access_token),
    user_refresh_token: getItem(storageKeys.user_refresh_token),
    selected_org_id: getItem(storageKeys.selected_org_id)
  };
};

export const eraseAuthStorage = () => {
  [localStorage, sessionStorage].forEach(storage => {
    Object.values(storageKeys).forEach(key => {
      storage.removeItem(key);
    });
  });
}; 

export const clearUserCookies = () => {
  cookies.remove(USER_REFRESH_TOKEN, { domain: XELACORE_DOMAIN });
  cookies.remove(USER_TOKEN, { domain: XELACORE_DOMAIN });
  eraseAuthStorage();
};

export const logout = () => {
  return axios
    .request({
      url: [`${Config.get('OAUTH_URL')}logout`].join('/'),
      method: 'get'
    })
    .then(() => {
      clearAuthData(true);
    })
    .catch(() => {
      clearAuthData(true);
    });
};

export const clearAuthData = (logout) => {
  clearUserCookies();
  if(!logout) {
    redirectToLogin(window.location.pathname)
  } else {
    redirectToLogin()
  };
};

export const redirectToLogin= (pathname) => {
  const source = Config.get('services.admin').split('://')[1] + (!!pathname && pathname.split('/').length > 2 ? pathname : ' ');
  const path = `${Config.get('services.login')}?source=${source}`;
  window.location.replace(path);
}