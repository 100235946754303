import React, { Component } from 'react';
import Dropdown from 'components/inputs/Dropdown';

class FormDropdown extends Component {
  handleChange = ({ value }) => {
    this.props.onChange({
      name: this.props.name,
      value
    });
  }

  render() {
    const { options, placeholder, name, value, label } = this.props;
    const dropdownValue = value
      && {
        label: value,
        value
      }
    return (
      <Dropdown
        name={name}
        options={options}
        placeholder={placeholder}
        value={dropdownValue}
        label={label}
        onChange={this.handleChange}
      />
    )
  }
}

export default FormDropdown;
