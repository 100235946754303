import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import './ip-hierarchy-levels.scss'
import caretRightIcon from 'assets/icons/caret_right_icon.svg';
import fileIcon from 'assets/icons/file.svg';
import folderIcon from 'assets/icons/folder.svg';
import {ReactComponent as PlusIcon} from 'assets/icons/plus.svg';
import {ReactComponent as MinusIcon} from 'assets/icons/minus.svg';
import {ReactComponent as ChevronRight} from 'assets/icons/chevron-right.svg';
import {displayNotification} from '../../../actions/notifications';
import getIPList from "../../../actions/api/IPHierarchy/ip-list";
import getIPLevels from "../../../actions/api/IPHierarchy/ip-levels";
import {groupBy, toArray, filter} from "lodash";
import findIndex from "lodash/findIndex";

class IPHierarchyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IIPHierarchyList: [],
            expandAllChildren: true,
            IPHierarchyLevels: [],
            SelectedLevelBranchList: {},
            disableSubmitAction: false,
        }
    };

    componentDidMount() {
        this.fetchIPHierarchy();
    }

    fetchIPHierarchy = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};

        getIPList(organisation_id, organisation_id)
            .then((data) => {
                this.setState({IIPHierarchyList: data})
            })
            .catch(err => alert(err.message));

        getIPLevels(organisation_id, organisation_id)
            .then((data) => {

                data.forEach(item => {
                    item.expanded = true
                })
                this.setState({IPHierarchyLevels: data})
            })
            .catch(err => alert(err.message));
    }

    flatMapIPHierarchyList = (IPList) => {
        let mappedIPHierarchyList = []
        IPList.forEach(item => {
            mappedIPHierarchyList.push({
                string_path: item.string_path,
                name: item.string_path[item.string_path.length - 1],
                ip_id: item.ip_id,
                parent_ip_id: item.parent_ip_id,
                children: [],
                removable: item.removable,
            })
        });
        return mappedIPHierarchyList
    }

    mapIPHierarchyObject = (IPLevelList) => {
        let mappedIPHierarchyList = this.flatMapIPHierarchyList(IPLevelList);
        let finalMappedTree = [];
        let map = {};
        mappedIPHierarchyList.forEach((node, node_index) => {
            if (!node.parent_ip_id) return finalMappedTree.push(node);
            let parentIndex = map[node.parent_ip_id];
            if (parentIndex !== "number") {
                parentIndex = mappedIPHierarchyList.findIndex(el => el.ip_id === node.parent_ip_id);
                map[node.parent_ip_id] = parentIndex;
            }
            if (!mappedIPHierarchyList[parentIndex].children) {
                return mappedIPHierarchyList[parentIndex].children = [node];
            }
            mappedIPHierarchyList[parentIndex].children.push(node);
        })

        let filteredMapedList = mappedIPHierarchyList.filter(item => item.parent_ip_id === null);

        filteredMapedList.forEach(parent => {
            this.traverse(parent)
        })
        return filteredMapedList
    }

    traverse = (parent, depth) => {
        if (typeof depth == 'number') {
            depth++;
            parent.depth = depth
        } else {
            depth = 1;
            parent.depth = depth
        }
        if (parent.children) {
            parent.children.forEach(child => {
                this.traverse(child, depth);
            })
        }
    }

    groupedLevels = (levelsBranches) => {
        return toArray(groupBy(levelsBranches, 'level_index'));
    }

    filterIpList = (IIPHierarchyList, levelItem, branchIndex, SelectedLevelBranchList) => {
        return filter(IIPHierarchyList, {
            ip_level_id: levelItem.ip_level_id,
            parent_ip_id: branchIndex === 0 ? null : SelectedLevelBranchList[branchIndex - 1]
        })
    }

    render() {
        const {
            IIPHierarchyList,
            IPHierarchyLevels,
            SelectedLevelBranchList,
        } = this.state;

        const Display = ({levelsBranches = [], ipList = []}) => {
            let selectedItems = SelectedLevelBranchList;
            return (
                <div className={'level-branch-row'}>

                    {this.groupedLevels(IPHierarchyLevels).map((branch, branchIndex,) => {
                        return (
                            <div className={'level-branch-col'}>
                                {branch.map((levelItem, levelIndex) => (
                                    <div className={'level-item-cell'}>
                                        <div className={`level-item-cell-name ${levelItem.expanded ? 'active' : ''}`}
                                             onClick={() => {
                                                 let currentIndex = findIndex(IPHierarchyLevels, {ip_level_id: levelItem.ip_level_id});
                                                 IPHierarchyLevels[currentIndex].expanded = !IPHierarchyLevels[currentIndex].expanded;
                                                 this.setState({IPHierarchyLevels: IPHierarchyLevels})
                                             }}>
                                            <h4>
                                                {levelItem.level_name}
                                                {this.filterIpList(IIPHierarchyList, levelItem, branchIndex, SelectedLevelBranchList).length ? (
                                                    <span>[{this.filterIpList(IIPHierarchyList, levelItem, branchIndex, SelectedLevelBranchList).length}]</span>
                                                ) : null}
                                            </h4>
                                            <i className={'colapse-icon'}>
                                                {levelItem.expanded ? (<MinusIcon/>) : (<PlusIcon/>)}
                                            </i>
                                        </div>
                                        <div
                                            className={`level-item-cell-list  ${levelItem.expanded ? 'active' : 'hidden'}`}>
                                            {
                                                this.filterIpList(IIPHierarchyList, levelItem, branchIndex, SelectedLevelBranchList).map((item, itemIndex) => (
                                                    <div
                                                        className={`level-item-branch ${selectedItems[branchIndex] === item.ip_id ? 'active' : ''}`}
                                                        onClick={() => {
                                                            selectedItems[branchIndex] = item.ip_id;
                                                            this.setState({SelectedLevelBranchList: selectedItems})
                                                        }}
                                                    >
                                                        <span>{item.string_path[item.string_path.length - 1]}</span>
                                                        {filter(IIPHierarchyList, {parent_ip_id: item.ip_id}).length ?
                                                            (<i><ChevronRight/></i>) : null}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    })}
                </div>
            );
        };

        const Tree = ({data = [], node = {}}) => {
            return (
                <div className="d-tree">
                    <ul className="d-flex d-tree-container flex-column">
                        {data.map((tree) => (
                            <TreeNode node={tree}/>
                        ))}
                    </ul>
                </div>
            );
        };

        const TreeNode = ({node}) => {
            const {expandAllChildren} = this.state;
            const [childVisible, setChildVisiblity] = useState(expandAllChildren);
            const hasChild = node.children.length > 0;
            return (
                <li className={`d-tree-node ${hasChild ? 'has-child' : ''}`}>
                    <div className="d-flex">
                        {hasChild && (
                            <div
                                className={`c-pointer d-inline d-tree-toggler ${
                                    childVisible ? "active" : ""
                                }`}
                            >
                                <span className={"node-action-icon"}>
                                    <img className=""
                                         src={caretRightIcon}
                                         alt="indicator icon"
                                    />
                                </span>
                            </div>
                        )}

                        <div className="col d-tree-head"
                             onClick={(e) => setChildVisiblity((v) => !v)}>
                            <span className={"node-icon"}>
                                 {!!hasChild && (
                                     <img className=""
                                          src={folderIcon}
                                          alt="folder icon"
                                     />)
                                 }
                                {!hasChild && (
                                    <img className=""
                                         src={fileIcon}
                                         alt="file icon"
                                    />)
                                }
                            </span>
                            <span className={"node-name"}>

                            {node.name}
                            </span>
                        </div>
                    </div>

                    {/*@todo implement add new [no endpoint fot it yet]*/}

                    {hasChild && childVisible && (
                        <div className="d-tree-content">
                            <ul className="d-flex d-tree-container flex-column">
                                <Tree node={node} data={node.children}/>
                            </ul>

                        </div>
                    )}
                </li>
            );
        };
        return (
            <div className='companyInfoBlock ip-hierarchy-list-display-builder'>
                <div className={'ip-hierarchy-header'}>
                    <h3>IP Hierarchy List</h3>
                </div>

                <Display levelsBranches={IPHierarchyLevels}></Display>

            </div>
        )
    }
}

export default connect(
    null,
    {displayNotification}
)
(IPHierarchyList);
