import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Nav } from 'react-bootstrap';
import Attributes from './Attributes'
import LinkedAgencies from "./LinkedAgencies";

function globalStateMapper(globalState) {
  return {};
}

class Brand extends Component {
  constructor(props){
		super(props);
		this.state = {
            brands: [],
            tab: 'attributes'
        }
  };

  toggleView = (e, navtab) => {
    e.preventDefault();
    this.setState({tab: navtab});
  }

  render() {
    const { tab } = this.state;
    const { brand, selectedOrg, selectBrand } = this.props;

    return (
        <div className="Brands data-capture-section">
            <Container fluid className="pt-2">
                <div>
                    <h2 className='mb-3'>
                        <a href="#" onClick={() => selectBrand()}>Brands </a>
                        / {brand.brand}
                    </h2>

                    <div className='mb-3'>
                        <Nav defaultActiveKey="info" className="mt--2 pr-0">
                        <Nav.Item>
                            <Nav.Link
                                className={`pill ml-0 mr-2 ${tab === 'attributes' ? 'active' : ''}`}
                                onClick={e => this.toggleView(e, 'attributes' )}>
                                    Attributes
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                className={`pill ml-0 ${tab === 'linked_agencies' ? 'active' : ''}`}
                                onClick={e => this.toggleView(e, 'linked_agencies' )}>
                                Linked Agencies
                            </Nav.Link>
                        </Nav.Item>
                        </Nav>
                    </div>

                    { tab === 'attributes' && (
                        <Attributes
                            brand={brand}
                            selectedOrg={selectedOrg}>
                        </Attributes>
                    )}
                    { tab === 'linked_agencies' && (
                        <LinkedAgencies
                            brand={brand}
                            selectedOrg={selectedOrg}>
                        </LinkedAgencies>
                    )}
                </div>
            </Container>
        </div>
    );
  }
}

export default connect(
  globalStateMapper,
  null
)(Brand);
