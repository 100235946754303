import React, { Component } from "react";
import { connect } from "react-redux";
import { hideNotification } from "actions/notifications";
import "./Notification.css";
import { TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";

const SUPPORTED_TYPES = [
  TYPE_ERROR,
  TYPE_SUCCESS
];

class Notification extends Component {
  componentDidUpdate(prevProps) {
    const {
      message,
      timeout
    } = this.props.notification;

    if (!message || prevProps.message === message) {
      return;
    }

    if (timeout) {
      this.hideNotification(timeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.notificationTimerId);
  }

  hideNotification = (timeout) => {
    clearTimeout(this.notificationTimerId);

    this.notificationTimerId = setTimeout(this.props.hideNotification, timeout);
  }

  // avoid `classnames` usage
  getClassNames = () => {
    const { type } = this.props.notification;

    const typeClassName = SUPPORTED_TYPES.includes(type) ? `c-notification--${type}` : 'c-notification--info';

    return `c-notification ${typeClassName}`
  }

  render() {
    const { message } = this.props.notification;

    if (!message) {
      return null;
    }

    const classNames = this.getClassNames();

    return (
      <div className={classNames} onClick={this.props.hideNotification}>
        <div className='c-notification__notification-text'>
          {message}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ notification }) => ({
  notification
});
const mapDispatchToProps = ({
  hideNotification
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
