import React, { Component } from 'react';
import './OrgTypeBanner.css';

class OrgTypeBanner extends Component {
  render() {
    const { type } = this.props;
    let colorChart = {
      licensee: 'rgba(177, 112, 242, 1)',
      licensor: 'rgba(177, 112, 242, 1)',
      agent: 'rgba(177, 112, 242, 1)',
      default: 'rgba(177, 112, 242, 1)'
    }
    let colour = colorChart[type] || colorChart['default'];
    return (
      <div className='org-admin-nav-header-type-banner' style={{backgroundColor: colour}}>
        {type}
      </div>
    );
  }
}

export default OrgTypeBanner;
