export const employeesTable = [
    {
        field: 'email',
        label: 'Email',
        order: 0,
        sort: true,
        filter: true,
    },
    {
        field: 'first_name',
        label: 'First Name',
        order: 1,
        sort: true,
    },
    {
        field: 'last_name',
        label: 'Last Name',
        order: 2,
        sort: true,
    },
    {
        field: 'employee_id',
        label: 'Employee Id',
        order: 3,
    },
    {
        field: 'user_id',
        label: 'User Id',
        order: 4,
    },
    {
        field: 'actions',
        label: 'Actions',
        order: 5
    }
]

export const employeesPendingTable = [
    {
        field: 'Email',
        label: 'Email',
        order: 0,
        filter: true,
    },
    {
        field: 'Date Created',
        label: 'Date Created',
        order: 1,
    },
    {
        field: 'Link',
        label: 'Link',
        order: 2,
    },
    {
        field: 'actions',
        label: 'Actions',
        order: 5
    }
]
