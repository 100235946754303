import Config from 'config'
import { isArray } from 'lodash';
import callHandler from 'actions/api/common/axiosCallHandler'

export default async function(organisationId, linkInfo) {
  if (!isArray(linkInfo)) linkInfo = [linkInfo];
  let call = {
    url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/links`,
    method: 'post',
    data: { linkInfo: linkInfo },
    headers: {
      'Content-Type': 'application/json',
    },
  }
  let addResp = await callHandler(call);
  return addResp;
}