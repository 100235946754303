import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import Table from 'components/lists/Table';
import Pagination from 'components/lists/Pagination';

import { getObjectNestedKey } from "utils/nested-keys";
import { getPageItems, getPagesCount } from "utils/pagination";

const FIRST_PAGE = 1;
const ITEMS_PER_PAGE = 10;
const COLUMNS = [
  { name: 'company_name', label: 'Company name' },
  { name: 'senior_exec_name', label: 'Senior exec name' },
  { name: 'senior_exec_email', label: 'Senior exec email' },
  { name: 'ambassador_name', label: 'Ambassador name' },
  { name: 'ambassador_email', label: 'Ambassador email' },
  { name: 'status', label: 'Status' }
];

class OrganisationList extends Component {
  static defaultProps = {
    data: []
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})) // @todo add hierarchy when it will be clear
  }

  state = {
    currentPage: 1,
    totalPages: 1,
    filterQuery: {}
  }

  onPageChanged = (currentPage) => {
    this.setState({
      currentPage
    });

    window.scroll({
      top: 0,
      behavior: "auto"
    });
  }

  onUpdateFilterFields = (filterQuery) => {
    this.setState({
      filterQuery,
      currentPage: FIRST_PAGE
    });
  }

  listMapper = memoize(
    (list) => list.map((item, index) => ({
      _id: getObjectNestedKey(item, '_id'),
      company_id: getObjectNestedKey(item, 'company_id'),
      account_type: getObjectNestedKey(item, 'company_information.account_type'),
      company_name: getObjectNestedKey(item, 'company_information.legal_company_name'),
      senior_exec_name: getObjectNestedKey(item, 'company_information.contacts_information.relationship_executive.name'),
      senior_exec_email: getObjectNestedKey(item, 'company_information.contacts_information.relationship_executive.email'),
      ambassador_name: getObjectNestedKey(item, 'company_information.contacts_information.primary_ambassador.name'),
      ambassador_email: getObjectNestedKey(item, 'company_information.contacts_information.primary_ambassador.email'),
      status: getObjectNestedKey(item, 'company_information.status', 'Not initiated')
    }))
  )

  filterList = memoize(
    (list, query) => list.filter((item) => {
      return Object.keys(query)
        .every((key) => (item[key] || '').includes(query[key]));
    })
  )

  paginateList = memoize(getPageItems)

  getPagesCount = memoize(getPagesCount)

  render() {
    const { data } = this.props;

    const {
      filterQuery,
      currentPage
    } = this.state;

    // apply mapper
    const list = this.listMapper(data);

    // filter list
    const filteredList = this.filterList(list, filterQuery);

    // paginate list
    const paginatedList = this.paginateList(filteredList, currentPage, ITEMS_PER_PAGE);

    // calculate pages quantity
    const totalPages = this.getPagesCount(filteredList, ITEMS_PER_PAGE);

    const classNames = `${this.props.type}-list`;

    return (
      <div className={classNames}>
        <Table
          columns={COLUMNS}
          filterQuery={this.state.filterQuery}
          data={paginatedList}
          onUpdateFilterFields={this.onUpdateFilterFields}
        />
        <Pagination
          current={currentPage}
          total={totalPages}
          onPageChanged={this.onPageChanged}
        />
      </div>
    );
  }
}

export default OrganisationList;
