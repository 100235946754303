import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import { getSubDataObject, getSubDataPath, getNamePath } from "utils/table";
import { NAME_KEY } from "constants/structure";
import { TABLE_UPDATE } from './index';

export default function ({ path, name, value }) {
  return (dispatch, getState) => {
    const { data } = getState().form;
    const subDataConfig = {
      path,
      data
    };
    const subData = getSubDataObject(subDataConfig);
    const valuePath = getNamePath({
      basePath: getSubDataPath(subDataConfig),
      name
    })
    const mergedValue = [
      ...get(subData, name, []),
      ...value.split('\n').map(name => ({ name }))
    ]
    const newValue = uniqBy(mergedValue, NAME_KEY);
    return dispatch({
      type: TABLE_UPDATE,
      payload: {
        valuePath,
        newValue
      },
    })
  };
};
