export const orgTable = {
  name: 'Name',
  organisation_id: 'Organisation Id',
  type: 'Type',
  error: 'Status',
  aliases: 'Aliases'
}

export const userTable = {
  user_id: 'User Id',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email'
}

export const waiverTable = {
  updated_at: 'Date',
  gtin_waiver: 'Action',
  actioning_user_id: 'Name',
  reason: 'Reason',
  requesting_username: 'Requester'
}
