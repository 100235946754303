import React from 'react';
import MainHeader from 'components/headers/MainHeader';
import './GeneralAuthWrapper.css';

function GeneralAuthWrapper({ children }) {
  return (
    <div className="general-auth-wrapper">
      <MainHeader />
      <div className="general-auth-wrapper-content">
        { children }
      </div>
    </div>
  );
}

export default GeneralAuthWrapper;
