import { displayNotification } from "actions/notifications";
import { TYPE_ERROR, TYPE_SUCCESS } from "constants/notifications";
import { eraseAuthStorage } from "utils/storage";

export const LOG_OUT = "LOG_OUT";

export default function(isUnauthorized = false) {
  return dispatch => {
    dispatch(displayNotification({
      message: isUnauthorized ? 'Token has been expired' : 'You have been successfully logged out',
      type: isUnauthorized ? TYPE_ERROR : TYPE_SUCCESS
    }));
    eraseAuthStorage();
    return dispatch({
      type: LOG_OUT
    });
  };
}
