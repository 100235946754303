import Config from 'config'
import axios from 'axios';

export default async function(organisationId, name) {
  try {
    return axios ({
      method: "get",
      url: `${Config.get('ADMIN_URL')}organisations/${organisationId}/myFiles/download/${name}`,
      headers: {
          Accept: '*'
      },
      responseType: 'arraybuffer'
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    });
  } catch (err) {
    console.error('Error downloading file', err);
    return;
  }
}