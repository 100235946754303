import Config from 'config'
import callHandler from 'actions/api/common/axiosCallHandler'
import { displayNotification } from "actions/notifications";
import { listOrgs } from "actions/organisations";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_ERROR } from "constants/notifications";

export const LIST_ORGS = "LIST_ORGS";

export default function() {
  return async dispatch => {
    try {
      let call = {
        method: "get",
        url: `${Config.get('ADMIN_URL')}organisations`
      }
      let { organisations } = await callHandler(call);
      
      dispatch(listOrgs(organisations));
  
      return organisations;
    } catch (err) {
      dispatch(displayNotification({
        message: 'Error listing organisations',
        type: TYPE_ERROR,
        timeout: DEFAULT_NOTIFICATION_TIMEOUT
      }));
    }
  }
}