import {
  SET_EXPORTS,
  SET_IMPORTS,
} from "actions/exportUpdate";
import { groupBy } from "lodash";

const getInitialState = () => ({
  exports: {
    byLicenseeOrganisationId: {},
  },
  imports: {
    byLicenseeOrganisationId: {},
  },
  ingestions: []
});

export default function exports(state = getInitialState(), action) {
  switch (action.type) {
    case SET_IMPORTS:
    case SET_EXPORTS: {
      const newState = {
        ...state,
        exports: {
          ...state.exports,
          byLicenseeOrganisationId: {
            ...state.exports.byLicenseeOrganisationId
          }
        },
        imports: {
          ...state.imports,
          byLicenseeOrganisationId: {
            ...state.imports.byLicenseeOrganisationId
          }
        }
      }
      const grouped = groupBy(action.payload, (action.type === 'SET_IMPORTS') ? 'licenseeOrganisationId' : 'organisationId')
      const activeSectionKey = (action.type === 'SET_IMPORTS') ? 'imports' : 'exports'
      Object.keys(grouped).forEach(key => {
        newState[activeSectionKey].byLicenseeOrganisationId[key] = {
          ...newState[activeSectionKey].byLicenseeOrganisationId[key]
        }
        grouped[key].forEach(item => {
          newState[activeSectionKey].byLicenseeOrganisationId[key][item.ingestionId || item.catalogExportId] = item
        })
      })

      return newState;
    }
    default:
      return state;
  }
}
