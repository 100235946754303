import React, { Component } from 'react';
import DataCaptureTableWrapper from 'components/dataCaptureTableComponents/DataCaptureTableWrapper';
import { structureComposer } from 'utils/table';

class DataCaptureFormTable extends Component {
  get initialSelection() {
    return structureComposer(this.props.scheme)
  }

  state = {
    selection: {
      ...this.initialSelection
    }
  }

  updateSelection = newSelection => {
    this.setState({
      ...this.state,
      selection: newSelection
    });
  }

  render() {
    const { label } = this.props;
    const children = React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        index,
        initialSelection: this.initialSelection,
        updateSelection: this.updateSelection,
        tableSelection: this.state.selection
      });
    });
    return (
      <DataCaptureTableWrapper title={label}>
        {children}
      </DataCaptureTableWrapper>
    );
  }
}

export default DataCaptureFormTable;
