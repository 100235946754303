export const apiRoutes = {
  login: "user/login",
  // licensees or licensors list
  getList: "organisation",
  organisationGet: "organisation/",
  organisationUpdate: "organisation/update/",
  
};

export const authHeader = "authorization";

export const XELACORE_DOMAIN = '.xelacore.io';
export const USER_REFRESH_TOKEN = 'user_refresh_token';
export const USER_TOKEN = 'user_token';

export const expirationTime = 86400000; // 24h in miliseconds

export const badRequestHttpCode = 400;
export const unauthorizedHttpCode = 401;
