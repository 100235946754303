import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import LastLogins from './LastLogins'
import ChangePassword from './ChangePassword'
import LinkedOrganisations from './LinkedOrganisations'
import UpdateUser from './UpdateUser'
import UserAdminNav from '../UserAdminNav';
import { Nav } from "react-bootstrap";

class UserHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNavKey: undefined
        };
    };

    handleNavSelect = (key) => {
        this.setState({
            selectedNavKey: key
        })
    }

    onUserSelect(e) {
        let selectedUser = JSON.parse(e.target.value);
        this.props.onUserSelect(selectedUser);
    }

    render() {
        const { users = [], selectedUser, getUser, userId } = this.props;

        if (!users.selected && (!users.list || users.list.length === 0)) {
            // return <Redirect to="/users"/>;
        }

        let menuItems = [
            <UpdateUser key='userInfo'
                        label='Update User'
                        getUser={getUser}
                        userId={userId}
                        selectedUser={selectedUser}>
            </UpdateUser>,
            <ChangePassword key='userPassword'
                            label='Change Password'
                            selectedUser={selectedUser}>
            </ChangePassword>,
            <LastLogins key='userLogins'
                        label='Logins'
                        selectedUser={selectedUser}>
            </LastLogins>,
            <LinkedOrganisations key='linkedOrganisations'
                                 label='Linked Organisations'
                                 selectedUser={selectedUser}>
            </LinkedOrganisations>
        ];

        const { selectedNavKey = menuItems[0].key } = this.state;

        return (
            <div>
                <div className="data-capture-page-form-wrapper-main">
                    <div className="data-capture-page-form-wrapper-nav">
                        <UserAdminNav
                            title={"title"}
                            items={menuItems}
                            selectedUser={selectedUser}
                            selectedNavKey={selectedNavKey}
                            onNavSelect={this.handleNavSelect}
                        />
                    </div>
                    <div className="data-capture-page-form-wrapper-content">
                        {menuItems.filter(mI => mI.key === selectedNavKey).map(cp => cp)}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => {
        return {

            users: state.users
        }
    },
    null
)(UserHome);

