import axios from 'axios';
import responseHandler from '../common/responseHandler';
import Config from 'config';
import { clearAuthData } from "utils/storage";

const SCOPE = 'xelacore-auth xelacore-catalog xelacore-ingest xelacore-files xelacore-redemption xelacore-admin';
/**
 * Fetches an authorisation code for a user
 *
 * @param  {String} token
 * @param  {String} grant_type
 * @return {Promise}
 */

const serializeObject = function (obj) {
    const str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    return str.join('&');
};


export function fetchOauthCode({email = "", password}, grant_type) {
    const postBody = {
        grant_type: grant_type ? grant_type : 'password', // urn:xelacore.io
        username: email.toLowerCase(),
        password: password,
        client_id: Config.get('CLIENT_ID'),
        client_secret: Config.get('CLIENT_SECRET'),
        scope: 'xelacore-auth xelacore-catalog xelacore-ingest xelacore-files xelacore-redemption xelacore-admin'
    };

    let request = {
        url: `${Config.get('OAUTH_URL')}token`,
        method: 'post',
        data: serializeObject(postBody),
        params: {},
        headers: {}
    };

    let baseAuth = Config.get('AUTH_BASE_URL', null);
    if (baseAuth) request.baseURL = baseAuth

    return axios
        .request(request)
        .then(responseHandler)
        .catch(e => {
            e.message = 'Invalid username or password.';
            if (e.response && e.response.data.error === 'invalid_grant') {
                // browserHistory.push('/logout');
            }
            return Promise.reject(e);
        });
}

/**
 *  This API call lists the organistion a user belongs to.
 *  Applying an ID will fetch data for that specific organisation
 *
 * @param  {String} token
 * @param  {string} id
 * @return {Promise}
 */
export function fetchUserOrganisation(token, id = '') {
    let request = {
        url: `${Config.get('AUTH_URL')}organisations/${id}`,
        method: 'get',
        headers: {authorization: ['Bearer', token].join(' ')},
        params: {
            page: 1,
            page_size: 10000
        }
    }
    let baseAuth = Config.get('AUTH_BASE_URL', null);
    if (baseAuth) request.baseURL = baseAuth

    return axios
        .request(request)
        .then(responseHandler)
        .catch(e => {
            return Promise.reject(e);
        });
}

/**
 * Set the bearer token based on a users organisation that they belong to
 *
 * @param  {Object} details
 * @param  {String} details.orgId
 * @param  {String} details.refreshToken
 * @return {Promise}
 */

export function setUserOrganisation(details) {
    const postBody = {
        grant_type: details.orgId
            ? 'urn:xelacore.io:oauth2:refresh-organisation'
            : 'urn:xelacore.io:oauth2:refresh',
        refresh_token: details.refresh_token || details.refreshToken,
        client_id: Config.get('CLIENT_ID'),
        redirect_uri: Config.get('REDIRECT_URL'),
        scope: SCOPE
    };

    if (details.orgId) {
        postBody.organisation_id = details.orgId;
    }

    let request = {
        url: `${Config.get('OAUTH_URL')}token`,
        method: 'post',
        data: serializeObject(postBody)
    };
    let baseAuth = Config.get('AUTH_BASE_URL', null);
    if (baseAuth) request.baseURL = baseAuth

    return axios
        .request(request)
        .then(responseHandler)
        .catch(e => {
            if (e.response && e.response.data.error === 'invalid_grant') {
                clearAuthData();
            }
        });
}