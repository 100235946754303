export const LinkedLicenseesTable = [
    {

        field: 'brandName',
        label: 'Brand',
        order: 1,
        filter: true,
    },
    {
        field: 'licenseeOrganisationName',
        label: 'Licensee',
        order: 2,
        orglink: 'licenseeOrganisationId',
        filter: true,
    },
  ]
