import React, { Component } from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import getList from "actions/api/list";

import OrganisationList from "components/lists/OrganisationList";

class Licensors extends Component {
  componentDidMount() {
    // get licensors list
    this.props.getList({
      account_type: 'licensor'
    });
  }

  render() {
    return (
      <OrganisationList
        type="licensors"
        data={this.props.licensorsList}
      />
    );
  }
}

// @todo add isLoading and error props to page
const mapStateToProps = (state) => ({
  licensorsList: state.list.list,
  isLoading: state.list.isLoading,
  error: state.list.error
});

export default compose(
  connect(
    mapStateToProps,
    { getList }
  )
)(Licensors);
