// get nested object key by string path, such as 'key1.key2.key3' etc
export const getObjectNestedKey = (obj, key, defaultValue = '') => {
  const path = key.split('.');

  const value = path.reduce((prevValue, currentValue) => {
    return prevValue && prevValue[currentValue];
  }, obj);

  return value || defaultValue;
}
