import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container} from 'react-bootstrap';
import getUser from "../../../actions/api/users/get";
import listOrgs from 'actions/api/organisations/list';
import listUsers from 'actions/api/users/list';
import BasicTable from "../../BasicTable";

function globalStateMapper(globalState) {
    const {organisations, users} = globalState;
    return {
        organisations,
        users
    };
}

class LinkedOrganisations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisations: [],
            users: []
        }
    }

    componentDidMount() {
        this.props.listOrgs();
        this.props.listUsers();

    }

    fetchOrganisations() {
        listOrgs()
            .then((data) => {

                this.setState({
                    organisations: [{
                        name: "none selected",
                        organisation_id: null,
                        information: {licensor: false}
                    }].concat(data)
                })
            });
    }

    render() {
        const {selectedUser = {}} = this.props;
        const {users = {}, organisations = {}} = this.props;
        let filteredOrganisations = [];
        if (users.list && organisations.list) {
            const currentUserOrgIdList = users.list.filter(user => user.user_id === selectedUser.user_id)[0];
            if (currentUserOrgIdList && currentUserOrgIdList.organisations) {
                filteredOrganisations = organisations.list.filter(organisation => currentUserOrgIdList.organisations.includes(organisation.organisation_id));
            }

        }

        return (
            <div className="company-informtion data-capture-section">
                <Container fluid className="pt-2">
                    <div>
                        <BasicTable data={filteredOrganisations.filter(x => !!x).map(
                            item => {
                                return {'Name': item.name, 'Organisation ID': item.organisation_id}
                            })
                        }
                        links={{
                            'Organisation ID': {
                                name: 'Organisation ID',
                                type: 'organisations',
                                className: 'link'
                            }
                        }}/>
                    </div>
                </Container>
            </div>
        )
    }
}

export default connect(
    globalStateMapper,
    {listOrgs, listUsers, getUser}
)(LinkedOrganisations);
  