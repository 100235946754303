import React from 'react';
import Select from 'react-select';
import Label from 'components/texts/Label';
import arrowDown from 'assets/icons/arrow-down-filled-triangle.svg';
import './Dropdown.css';

function Dropdown({ name, isDisabled, options, placeholder, onChange, value, label, isMulti }) {
  return (
    <div className="dropdown-container">
      {label && <Label text={label} />}
      <Select
        name={name}
        className="dropdown-select-container"
        classNamePrefix="dropdown-select"
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
        options={options}
        placeholder={placeholder}
        isMulti={isMulti}
        components={
          {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <img className="dropdown-select-indicator" src={arrowDown} alt="Dropdown indicator" />
            )
          }
        }
      />
    </div>
  );
}

export default Dropdown;
