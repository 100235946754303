export const aliasesTable = [
    { 
        field: 'alias',
        label: 'Alias',
        order: 0,
    },  
    { 
        field: 'name',
        label: 'Name',
        order: 1,
    },
    { 
        field: 'type',
        label: 'Type',
        order: 2,
    },
    { 
        field: 'alias_id',
        label: 'Alias Id',
        order: 3,
    },
    { 
        field: 'organisation_id',
        label: 'Organisation Id',
        order: 4,
    },
    { 
        field: 'actions',
        label: 'Actions',
        order: 5,
    }
  ]
